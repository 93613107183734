import { Image, ImageStyle, StyleProp } from "react-native";
import React, { memo, useEffect, useState } from "react";

interface CurrencyIconProps {
	name: string;
	style?: StyleProp<ImageStyle>;
}

const CurrencyIcon = memo(function CurrencyIcon({
	style,
	...props
}: CurrencyIconProps) {
	const name = props.name?.toUpperCase();

	const [source, setSource] = useState(`/static/images/currencies/${name ?? 'UNKNOWN'}.svg`);

	useEffect(() => {
		setSource(`/static/images/currencies/${name ?? 'UNKNOWN'}.svg`);
	}, [name]);

	return (
		<Image
			style={style}
			source={{ uri: source }}
			onError={() => setSource(`/static/images/currencies/UNKNOWN.svg`)}
		/>
	);
});

export default CurrencyIcon;
