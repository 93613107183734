import React, { ReactElement, useState } from "react";
import { useQuery } from "react-query";
import { DataTable, ProgressBar } from "react-native-paper";
import { t } from "i18next";
import { Text, View, Button, Icon } from "hubchain-storybook-design-pattern";
import { FiltersEntries } from "hubchain-storybook-design-pattern/lib/components/HeaderContent/types";
import WebhookService from "../../src/services/webhook/webhooksServices";
import { TableRow } from "../../src/components/table/CustomTable";
import GeneralCSS from "../../src/global-constants/Styles";
import DefaultLayout from "../../src/layouts/default-layout";
import Colors from "../../src/global-constants/Colors";
import styles from "../webhooks/styles";
import Util from "../../src/utils";
import { WebhookModal } from "../../src/components/webhookModal/WebhookModal";

export default function CurrenciesScreen(props: any) {
	const {
		data: webhookListResponse,
		isFetching,
		isLoading,
		refetch,
	} = useQuery("webhooks", {
		queryFn: WebhookService.list,
		staleTime: 0,
		retry: false,
		retryDelay: 1000 * 10,
		refetchOnWindowFocus: false,
		keepPreviousData: false,
	});

	const webhooks = webhookListResponse?.data.data || [];

	const displayedColumns = ["#", "url", "createdAt", "updatedAt"];

	const columnsMinWidth = [30, 500, 200, 200];
	const columnsMaxWidth = [30, 500, 200, 200];

	const [webhookModalConfig, setWebhookModalConfig] = useState<any>({
		webhook: null,
		visible: false,
		isDelete: false,
	});

	const TableHeader = () => {
		return (
			<DataTable.Header style={[GeneralCSS.defaultTableHeader, { gap: 8 }]}>
				{displayedColumns.map((column, index) => {
					return (
						<DataTable.Title
							key={column}
							style={[
								{
									minWidth: columnsMinWidth[index],
									maxWidth: columnsMaxWidth[index],
								},
							]}
						>
							<Text
								style={[GeneralCSS.tableColumnTitle]}
								typeStyle={{ type: "table", name: "table-header" }}
							>
								<>{t(`webhooks.table.${column}`)}</>
							</Text>
						</DataTable.Title>
					);
				})}
			</DataTable.Header>
		);
	};

	const filters: FiltersEntries = [
		{
			name: "newSwapButton",
			label: t("webhooks.header.addWebhook"),
			icon: {
				name: "AddIcon",
				web: {
					size: "19px",
				},
				mobile: {
					size: "19px",
				},
			},
			params: {
				action: "new-webhook",
			},
			disabled: false,
			visible: true,
			type: "button",
			whiteSpace: "nowrap",
		},
		{
			name: "refreshButton",
			icon: {
				name: "Redo",
				web: {
					size: "19px",
				},
				mobile: {
					size: "19px",
				},
			},
			params: {
				action: "refresh",
			},
			disabled: isFetching || isLoading,
			visible: true,
			isLoading: isFetching || isLoading,
			type: "button",
		},
	];

	const handleAddWebhook = () => {
		setWebhookModalConfig({
			webhook: null,
			visible: true,
			isDelete: false,
		});
	};

	const handleDeleteWebhook = (webhook) => {
		setWebhookModalConfig({
			webhook: webhook,
			visible: true,
			isDelete: true,
		});
	};

	const handleEditWebhook = (webhook) => {
		setWebhookModalConfig({
			webhook: webhook,
			visible: true,
			isDelete: false,
		});
	};

	const closeWebhookModal = () => {
		setWebhookModalConfig({
			webhook: null,
			visible: false,
			isDelete: false,
		});
	};

	const WebhooksTableBody = () => {
		const rows: ReactElement[] = [];

		webhooks.map((webhook, index) => {
			rows.push(
				<TableRow key={webhook.url} index={index} zIndex={1} gap={8}>
					<View
						style={[
							GeneralCSS.defaultTableValue,
							{
								minWidth: columnsMinWidth[0],
								maxWidth: columnsMaxWidth[0],
								justifyContent: "center",
								gap: 2,
							},
						]}
					>
						<Text
							style={[GeneralCSS.tableColumnTitle]}
							typeStyle={{ type: "table", name: "table-header" }}
						>
							{index + 1}
						</Text>
					</View>

					<View
						style={[
							GeneralCSS.defaultTableValue,
							{
								minWidth: columnsMinWidth[1],
								maxWidth: columnsMaxWidth[1],
								justifyContent: "center",
								paddingLeft: 8,
							},
						]}
					>
						<View style={{ position: "absolute", width: "100%" }}>
							<Text numberOfLines={1} style={{ lineHeight: 1.5 }}>
								{webhook.url}
							</Text>
						</View>
					</View>

					<View
						style={[
							GeneralCSS.defaultTableValue,
							{
								minWidth: columnsMinWidth[2],
								maxWidth: columnsMaxWidth[2],
								justifyContent: "center",
								paddingLeft: 8,
							},
						]}
					>
						<View style={{ position: "absolute", width: "100%" }}>
							<Text numberOfLines={1} style={{ lineHeight: 1.5 }}>
								{Util.formatDate(webhook.createdAt, undefined, "L LTS")}
							</Text>
						</View>
					</View>

					<View
						style={[
							GeneralCSS.defaultTableValue,
							{
								minWidth: columnsMinWidth[3],
								maxWidth: columnsMaxWidth[3],
								justifyContent: "center",
								paddingLeft: 8,
							},
						]}
					>
						<View style={{ position: "absolute", width: "100%" }}>
							<Text numberOfLines={1} style={{ lineHeight: 1.5 }}>
								{Util.formatDate(webhook.updatedAt, undefined, "L LTS")}
							</Text>
						</View>
					</View>

					<View
						style={[
							GeneralCSS.defaultTableValue,
							{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-end",
							},
						]}
					>
						<Button
							label={t("webhooks.table.edit")}
							size="table"
							icon={<Icon fontSize={14} name="Edit" />}
							onClick={() => {
								handleEditWebhook(webhook);
							}}
						/>
						<Button
							label={t("webhooks.table.delete")}
							size="table"
							variant="danger"
							icon={<Icon fontSize={14} name="Trash" />}
							onClick={() => {
								handleDeleteWebhook(webhook);
							}}
						/>
					</View>
				</TableRow>
			);
		});

		if (isFetching) {
			rows.push(
				<TableRow key={0} style={[GeneralCSS.defaultTableRow]}>
					<DataTable.Cell>
						<Text style={{ marginTop: 12, marginLeft: 6 }} size={"13px"}>
							<>{t(`webhooks.table.loading`)}</>
						</Text>
					</DataTable.Cell>
				</TableRow>
			);
		} else if (!rows?.length) {
			rows.push(
				<TableRow key={0} style={[GeneralCSS.defaultTableRow]}>
					<DataTable.Cell>
						<Text style={{ marginTop: 12, marginLeft: 6 }} size={"13px"}>
							<>{t(`webhooks.table.noRows`)}</>
						</Text>
					</DataTable.Cell>
				</TableRow>
			);
		}

		return <>{rows}</>;
	};

	return (
		<>
			<DefaultLayout
				{...props}
				title={t("webhooks.header.title")}
				filters={filters}
				onClick={(param: any) => {
					if (param.action === "refresh") {
						refetch();
					} else if (param.action === "new-webhook") {
						handleAddWebhook();
					}
				}}
			>
				<View style={[styles.card, { borderRadius: 10 }]}>
					<View style={[{ overflow: "auto" } as any]}>
						<TableHeader />
						<ProgressBar
							visible={false}
							color={Colors.light.primary}
							indeterminate={true}
						/>
						<WebhooksTableBody />
					</View>
					<DataTable.Pagination
						page={0}
						numberOfPages={1}
						numberOfItemsPerPage={0}
						onPageChange={(page) => {}}
						showFastPaginationControls={true}
						label={``}
					/>
				</View>
			</DefaultLayout>

			{webhookModalConfig?.visible && (
				<WebhookModal
					onClose={closeWebhookModal}
					webhook={webhookModalConfig.webhook}
					isDelete={webhookModalConfig.isDelete}
				/>
			)}
		</>
	);
}
