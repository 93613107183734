import {StyleSheet} from "react-native";
import Colors from "../../src/global-constants/Colors";

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
    paddingHorizontal: 20,
    marginTop: 15
  },
  card: {
    backgroundColor: "#FFF",
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8,
    marginTop:2
  },
});

export default styles;