import { Icon, Text, View } from "hubchain-storybook-design-pattern";
import React, { ReactNode } from "react";
import styles from "../styles";
import { IconProps } from "hubchain-storybook-design-pattern/lib/components/Icon/types";

interface DetailRowTransferModalProps {
	label: string;
	value: string | ReactNode;
	icon?: IconProps,
	leftAddon?: ReactNode;
	rightAddon?: ReactNode;
	fontSize?: string;
	height?: string;
}

const DetailRowTransferModal = ({
	label,
	value,
	icon,
	leftAddon,
	rightAddon,
	fontSize,
	height,
}: DetailRowTransferModalProps) => {
	return (
		<View style={[styles.detailRow]}>
			<Text variant={"gray"} fontStyle={"bold"} size={fontSize}>
				{label}
			</Text>
			<View style={[styles.detailValue, height ? { height: height } : {}]}>
				{leftAddon}
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						flex: 1,
						flexWrap: "wrap",
					}}
				>
					{typeof value === "string" ? (
						<>
							{
								icon && <Icon {...icon} />
							}
							<Text
								fontStyle={"bold"}
								size={fontSize}
								style={{
									paddingLeft: icon ? "4px" : "0px",
									overflowWrap: "anywhere",
								}}
							>
								{value}
							</Text>
						</>
					) : (
						value
					)}
				</View>
				{rightAddon}
			</View>
		</View>
	);
};

export default DetailRowTransferModal;
