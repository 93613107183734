export const LayoutConfig = {
  header: {
    size: {
      mobile: "56px",
      desktop: "64px"
    }
  },
  sidenav: {
    size: {
      mobile: 260,
      desktop: 260
    },
    menu: {
      entrySize: {
        mobile: 32,
        desktop: 32
      },
      subEntrySize: {
        mobile: 24,
        desktop: 24
      }
    }
  },
  info: {
    Text: "Menu BackOffice",
    size: {
      mobile: 32,
      desktop: 32
    },
  }
}
