import styled from "styled-components";

const CustomHoverableText = styled.span<{ size?: string, hoverColor?: string }>`
  transition: color 0.2s;
  font-size: ${props => props.size || "13px"};
  &:hover {
    color: ${props => props.hoverColor || "#3498db"};
    text-decoration: underline;
  }
`

export { CustomHoverableText }
