import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from './src/hooks/useCachedResources';
import Navigation from './src/navigation';
import { AuthProvider } from "./src/services/auth";

import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

import { CustomProvider as RSuiteProvider } from 'rsuite';

import Pt_BR from 'rsuite/locales/pt_BR';

import { MultiTenancyProvider } from "./src/services/multi-tenancy/multi-tenancy";
import { LoggedParamList } from "./src/navigation/logged.routes";
import { NotLoggedParamList } from "./src/navigation/not-logged.routes";
import ResetCSS from "./src/assets/specific-css/ResetCSS";
import { pdfjs } from "react-pdf";

import "./src/assets/specific-css/rsuite";
import "@szhsin/react-menu/dist/index.css";
import { ExportTableProvider } from "hubchain-storybook-design-pattern";
import { AlertProvider } from "./src/hooks/useAlert";
import { QuoteProvider } from "./src/hooks/useQuote";
import { CurrencyProvider } from "./src/hooks/useCurrency";
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

declare global {
  namespace ReactNavigation {
    interface RootParamList extends LoggedParamList, NotLoggedParamList { }
  }
}

const queryClient = new QueryClient();

export default function App() {

  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {

    return null;

  } else {

    return (
			<SafeAreaProvider>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<RSuiteProvider locale={Pt_BR}>
							<MultiTenancyProvider>
								<AlertProvider>
									<ResetCSS />
									<AuthProvider>
										<QuoteProvider>
											<CurrencyProvider>
												<ExportTableProvider>
													<Navigation />
												</ExportTableProvider>
											</CurrencyProvider>
										</QuoteProvider>
									</AuthProvider>
								</AlertProvider>
							</MultiTenancyProvider>
						</RSuiteProvider>
					</BrowserRouter>
				</QueryClientProvider>
			</SafeAreaProvider>
		);
  }
}
