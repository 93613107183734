import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import API from "./api";
import { AdminUserService } from "./user/adminUserService";

import { UserModel } from "../models/UserModel";
import { PermissionType } from "../permissions/permissions";
import { useMultiTenancy } from "./multi-tenancy/multi-tenancy";
import { TenantAppDomainEnum } from './multi-tenancy/types';
import { PusherService } from './pusher';
import { OneSignalService } from './onesignal';


export const UserSessionStorage = {
  jwtToken: {
    getKey: `${window.location.host}/TOKEN_KEY`,
    getValue: async () => {
      return await AsyncStorage.getItem(UserSessionStorage.jwtToken.getKey);
    },
    setValue: async (value: any) => {
      return await AsyncStorage.setItem(UserSessionStorage.jwtToken.getKey, value);
    },
    removeValue: async () => {
      return await AsyncStorage.removeItem(UserSessionStorage.jwtToken.getKey);
    },
  },
  userData: {
    getKey: `${window.location.host}/USER_DATA`,
    getValue: async () => {
      return await AsyncStorage.getItem(UserSessionStorage.userData.getKey);
    },
    setValue: async (value: any) => {
      return await AsyncStorage.setItem(UserSessionStorage.userData.getKey, value);
    },
    removeValue: async () => {
      return await AsyncStorage.removeItem(UserSessionStorage.userData.getKey);
    },
  },
  phoneVerified: {
    getKey: `${window.location.host}/PHONE_VERIFIED`,
    getValue: async () => {
      return await AsyncStorage.getItem(UserSessionStorage.phoneVerified.getKey);
    },
    setValue: async (value: any) => {
      return await AsyncStorage.setItem(UserSessionStorage.phoneVerified.getKey, value);
    },
    removeValue: async () => {
      return await AsyncStorage.removeItem(UserSessionStorage.phoneVerified.getKey);
    },
  }
}

interface AuthContextData {
  isLogged: boolean;
  user: UserModel | null;
  loading: boolean;

  refreshUserData(): Promise<UserModel>,

  login(token: string, email: string, name: string): Promise<void>,

  logout(): Promise<void>
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const getPhoneVerified = () => localStorage.getItem(UserSessionStorage.phoneVerified.getKey);

export const setPhoneVerified = (value: string) => localStorage.setItem(UserSessionStorage.phoneVerified.getKey, value);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [user, setUser] = useState<UserModel | null>(null);
  const [loading, setLoading] = useState(true);
  const { tenant } = useMultiTenancy();

  const userService = new AdminUserService();

  const loadStorageData = async function () {

    const storagedUser = await UserSessionStorage.userData.getValue();
    const storagedToken = await UserSessionStorage.jwtToken.getValue();;

    if (storagedUser && storagedToken) {
      API.defaults.headers["x-jwt-token"] = `${storagedToken}`;
      unauthorizedInterceptor();

      try {
        await refreshUserData();
      } catch (e) {
        await retryRefreshUserData();
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    loadStorageData();
    OneSignalService.init(tenant);
  }, []);

  const refreshUserData = async () => {

    const user = await userService.getUserInfo();

    if (user) {

      if (user.isAdmin === "N") {
        logout();
      }

      user.isActionAllowed = (actions: PermissionType[] | PermissionType) => {
        return isActionAllowed(user.permissions?.filter(permission => tenant.permissions.includes(permission)), actions);
      };

      user.isHubchainUser = user.email.endsWith('@hubchain.com') || user.email.endsWith('@hubchain.io');
      user.isEWalletModuleEnabled = user.isHubchainUser || ![TenantAppDomainEnum.EZZEPAY].includes(tenant.name)

      setUser(user);

    }
    return user;
  }

  const retryRefreshUserData = async () => {
    let retryCount = 0;

    return await new Promise(resolve => {
      const retry = setInterval(() => {
        refreshUserData().then(user => {
          clearInterval(retry);
          resolve(true);
        })
        retryCount++;
        if (retryCount >= 5) {
          clearInterval(retry);
          logout();
          resolve(false);
        }
      }, 5000);
    });
  }

  const isActionAllowed = (permissions: string[], actions: PermissionType[] | PermissionType): boolean => {
    const actionList = Array.isArray(actions) ? actions : [actions];

    if (!permissions) {
      return false;
    }

    return actionList.every(action => {
      return permissions.includes(action);
    });
  }

  const unauthorizedInterceptor = () => {
    API.interceptors.response.use(
      response => {
        if (response.data.status == "error") {
          if (response.data.reason == "unauthorized") {
            logout();
            window.location.reload();
          }
        }
        return response;
      }
    )
  }

  const login = async (token: string, email: string, name: string) => {
    await UserSessionStorage.jwtToken.setValue(token);
    API.defaults.headers["x-jwt-token"] = `${token}`;
    await UserSessionStorage.userData.setValue(JSON.stringify({ email, name }));
    unauthorizedInterceptor();
    if (UserSessionStorage.jwtToken.getValue()) PusherService.signin();
    await refreshUserData();
  }

  const logout = async () => {
    await UserSessionStorage.jwtToken.removeValue();
    await UserSessionStorage.userData.removeValue();
    setUser(null);
  }

  return (
    <AuthContext.Provider value={{ isLogged: !!user, user, loading, refreshUserData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}


export { AuthProvider, useAuth };
