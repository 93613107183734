import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import {
	Button,
	Icon,
	Input,
	Modal,
	Select,
	SelectSearch,
	Text,
	TooltipConfig,
	View,
} from "hubchain-storybook-design-pattern";

import UsersService from "../../services/approvedUsers/UsersService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TCurrencySymbol } from "../../types/currency";
import { UserModel } from "../../models/UserModel";
import styles from "./styles";

import { validateCNPJ, validateCPF } from "validations-br";
import { ActivityIndicator } from "react-native";
import { useTranslation } from "react-i18next";

import { RequestStatusId } from "../../enums/RequestStatusId.enum";
import { useCurrency } from "../../hooks/useCurrency";
import { useAlert } from "../../hooks/useAlert";
import { useFormik } from "formik";
import Util from "../../utils";
import * as yup from "yup";
import InvoiceService, {
	IQuoteInvoice,
} from "../../services/invoice/invoiceService";
import CurrencyUtil from "../../utils/CurrencyUtil";
import { PaymentCurrencyList } from "./components/PaymentCurrencyList";
import {
	InvoiceFormData,
	IPaymentModalContext,
	IPaymentModalCurrencyOption,
	IPaymentModalProps,
	IPaymentModalUserOption,
} from "./models";
import {
	AdditionalInformationButton,
	AdditionalInformationInput,
} from "./components/PaymentAdditionalInformation";
import { Title } from "./components/common";
import { PaymentDetailsCard } from "./components/PaymentDetailsCard";
import SwapService from "../../services/swap/swapService";
import { useDebouncedValue } from "../../hooks/useDebounce";
import { isYesOrNoEnum } from "../../types/types";
import { SelectSearchOption } from "hubchain-storybook-design-pattern/lib/components/SelectSearch/SelectSearch";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";

const additionalInformationFields: string[] = [
	"memo",
	"externalId",
	"payerEmail",
	"payerPhone",
	"payerName",
	"payerPersonCompanyId",
];

const userService = UsersService.getInstance();

const defaultAdditionalInformationValidation = yup
	.object({
		value: yup.string(),
	})
	.nullable();

export const PaymentModalContext = createContext<IPaymentModalContext>(
	{} as IPaymentModalContext
);

interface PaymentQuote {
	currencyIn: TCurrencySymbol;
	network: string;
	amountIn: number;
	user: string;
}

interface IUserOption extends SelectSearchOption {
	user: UserModel;
}

const parseUserToOption = (user: UserModel): IUserOption => {
	return {
		value: user.id,
		label: user.name || user.personLegalName,
		subLabel: user.email,
		user: user,
		query: `${user.name} ${user.personLegalName} ${user.personCompanyId} ${user.email}`,
	};
};

const PaymentModal = (props: IPaymentModalProps) => {
	const [selectedCurrencies, setSelectedCurrencies] = useState<
		IPaymentModalCurrencyOption[]
	>([]);

	const [storedSelectedCurrencies, setStoredSelectedCurrencies] = useState<
		IPaymentModalCurrencyOption[]
	>([]);

	const isReadOnly = props.action === "cancel" || props.action === "view";

	const [allowAllCurrencies, setAllowAllCurrencies] = useState(true);

	const handleToggleAllowAllCurrencies = (value?: boolean) => {
		if (isReadOnly) return;

		setAllowAllCurrencies((prev) => {
			const next = value === undefined ? !prev : value;

			if (next) {
				//if the next value is true
				setStoredSelectedCurrencies(selectedCurrencies);
				setSelectedCurrencies([]);
			} else {
				//if the next value is false
				setSelectedCurrencies(storedSelectedCurrencies);
				setStoredSelectedCurrencies([]);
			}

			return next;
		});
	};

	const toggleSelectedCurrency = (currency: IPaymentModalCurrencyOption) => {
		setSelectedCurrencies((prev) => {
			const index = prev.findIndex((item) => item.value === currency.value);

			if (index === -1) {
				return [...prev, currency];
			} else {
				return prev.filter((item) => item.value !== currency.value);
			}
		});
	};

	return (
		<PaymentModalContext.Provider
			value={{
				request: props.request,
				userId: props.userId,
				selectedCurrencies,
				setSelectedCurrencies,
				toggleSelectedCurrency,
				setAllowAllCurrencies,
				allowAllCurrencies,
				handleToggleAllowAllCurrencies,
				isReadOnly,
			}}
		>
			<PaymentModalChildren {...props} />
		</PaymentModalContext.Provider>
	);
};

const NotAvailableView = () => {
	return (
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				height: 460,
			}}
		>
			<ActivityIndicator color={"#000"} size={36} />
		</View>
	);
};

const PaymentModalChildren = ({
	currencyIn,
	request,
	onClose,
	userId,
	action,
}: IPaymentModalProps) => {
	const { t } = useTranslation();
	const { currencies, markets } = useCurrency();
	const { showAlert } = useAlert();
	const {
		allowAllCurrencies,
		selectedCurrencies,
		setSelectedCurrencies,
		setAllowAllCurrencies,
	} = useContext(PaymentModalContext);

	const swap = request?.swap;
	const invoice = request?.invoice;

	const isEdit = action === "edit" || action === "cancel";
	const isCancel = action === "cancel";

	const isReadOnly = isCancel || action === "view";

	const modalRef = useRef(null);

	const queryClient = useQueryClient();

	const { data: _cryptohubInfoBalancesData } = useQuery(
		["CryptoHubInfoBalances"],
		{
			queryFn: SwapService.getCryptoHubInfoBalances,
			staleTime: 60 * 1000 * 5, //5 minutes
			retry: 2,
			retryDelay: 60 * 1000 * 2,
			refetchOnWindowFocus: false,
		}
	);

	const { mutate: invoiceCreate, isLoading: isCreatingInvoice } = useMutation({
		mutationFn: InvoiceService.create,
		onSuccess: () => {
			showAlert(
				t(`payment-gateway-crypto-modal.actions.new.success`),
				"success"
			);
			queryClient.refetchQueries(["payment-gateway/invoices"]);
			onClose();
		},
		onError: () => {
			showAlert(t(`payment-gateway-crypto-modal.actions.new.error`), "danger");
		},
	});

	const { mutate: invoiceUpdate, isLoading: isUpdatingInvoice } = useMutation({
		mutationFn: InvoiceService.update,
		onSuccess: () => {
			showAlert(
				t(`payment-gateway-crypto-modal.actions.edit.success`),
				"success"
			);
			queryClient.refetchQueries(["payment-gateway/invoices"]);
			onClose();
		},
		onError: () => {
			showAlert(t(`payment-gateway-crypto-modal.actions.edit.error`), "danger");
		},
	});

	const { mutate: invoiceCancel, isLoading: isCancelingInvoice } = useMutation({
		mutationFn: InvoiceService.cancel,
		onSuccess: () => {
			showAlert(
				t(`payment-gateway-crypto-modal.actions.cancel.success`),
				"success"
			);
			queryClient.refetchQueries(["payment-gateway/invoices"]);
			request.swap.status = {
				...request.swap.status,
				id: RequestStatusId.CANCELLED,
				key: "CANCELLED",
				namePt: "Cancelado",
			};
			onClose();
		},
		onError: () => {
			showAlert(
				t(`payment-gateway-crypto-modal.actions.cancel.error`),
				"danger"
			);
		},
	});

	const isSubmitting =
		isCreatingInvoice || isUpdatingInvoice || isCancelingInvoice;

	const [userFilter, setUserFilter] = useState({
		page: 0,
		limit: 20,
		total: 0,
		search: {
			name: { like: "" },
			isLocked: { equal: "" },
		},
	});

	//Multiple users

	const {
		data: getUsersResponse,
		refetch: refetchUsers,
		isFetching: isLoadingUsers,
	} = useQuery(["users"], {
		queryFn: () => userService.getUsers(userFilter),
		enabled: !isEdit && !userId,
		staleTime: 60 * 5 * 1000, //5 minutes
	});

	const isLoadingUserData = isLoadingUsers;

	const [isLoaded, setIsLoaded] = useState(false); //needed to fix a select rendering issue

	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true); //needed to fix a select rendering issue
		}, 0);
	}, []);

	const { currencyInOptions, currencyOutOptions, currencyOptions } =
		useMemo(() => {
			const availableCurrencies: TCurrencySymbol[] = currencies.map(
				(currency) => currency.currency
			);

			const currencyInOptions: IPaymentModalCurrencyOption[] = markets
				.map((market) => market.quoteCurrency)
				.filter(
					(currency, index, array) =>
						array.findIndex((item) => item.currency === currency.currency) ===
							index && availableCurrencies.includes(currency.currency)
				)
				.sort((currency) =>
					currency.currency === TCurrencySymbol.BRL ? -1 : 1
				)
				.map((currency) => ({
					value: currency.currency,
					label: currency.currency,
					subLabel: currency.name,
					icon: currency.currency,
					currency: currency.currency,
					type: currency.type,
				}));

			const currencyIn = currencyInOptions[0]?.value;
			const availableCurrencyOutCurrencies = markets
				.filter((market) => market.quoteCurrency.currency === currencyIn)
				.map((market) => market.baseCurrency.currency)
				.filter((currency) => availableCurrencies.includes(currency));

			const filteredCurrencyOutList = markets
				.map((market) => market.baseCurrency)
				.filter(
					(currency, index, array) =>
						array.findIndex((item) => item.currency === currency.currency) ===
							index &&
						availableCurrencyOutCurrencies.includes(currency.currency)
				)
				.sort((currency) =>
					currency.currency === TCurrencySymbol.USDT ? -1 : 1
				);

			const currencyOutOptions = filteredCurrencyOutList
				.map((currency) =>
					currency.deposit.networks.map((network) => ({
						value: currency.currency + "/" + network.nameId,
						label: currency.currency,
						subLabel: currency.name,
						icon: currency.currency,
						type: currency.type,
						currency: currency.currency,
						network: network,
					}))
				)
				.flat();

			const currencyOptions: IPaymentModalCurrencyOption[] = [
				...currencyInOptions,
				...filteredCurrencyOutList.map((currency) => ({
					value: currency.currency,
					label: currency.currency,
					subLabel: currency.name,
					icon: currency.currency,
					type: currency.type,
					currency: currency.currency,
					network: currency.deposit.networks[0],
				})),
			];

			return {
				currencyInOptions,
				currencyOutOptions,
				currencyOptions,
			};
		}, [currencies, markets]);

	const handleSubmit = (values: InvoiceFormData) => {
		if (isCancel || isReadOnly) return;

		const {
			payerName,
			payerEmail,
			payerPhone,
			payerPersonCompanyId,
			memo,
			externalId,
		} = values; //additionalFields

		const invoiceAdditionalFields = {
			...(payerName ? { payerName } : {}),
			...(payerEmail ? { payerEmail } : {}),
			...(payerPhone ? { payerPhone } : {}),
			...(payerPersonCompanyId
				? {
						payerPersonCompanyId: {
							...payerPersonCompanyId,
							value: Util.removeMask(payerPersonCompanyId.value),
						},
				  }
				: {}),
		};

		const allowedPaymentMethods =
			selectedCurrencies.length === 0 || allowAllCurrencies
				? ["*"]
				: selectedCurrencies.map((currency) => ({
						currency: currency.currency,
						networks: [currency?.network?.nameId || "*"],
				  }));

		if (isEdit) {
			invoiceUpdate({
				id: request.id,
				user: values.user.value,
				amount: CurrencyUtil.getNumberByValue(values.amount),
				currency: values.currency.value as TCurrencySymbol,
				allowedPaymentMethods: allowedPaymentMethods,
				externalId: externalId?.value,
				memo: memo?.value,
				fields: Object.keys(invoiceAdditionalFields).map((fieldname) => ({
					name: fieldname,
					...invoiceAdditionalFields[fieldname],
				})),
				// ...invoiceAdditionalFields
			});
		} else {
			invoiceCreate({
				user: values.user.value,
				amount: CurrencyUtil.getNumberByValue(values.amount),
				currency: values.currency.value as TCurrencySymbol,
				allowedPaymentMethods: allowedPaymentMethods,
				externalId: externalId?.value,
				memo: memo?.value,
				fields: Object.keys(invoiceAdditionalFields).map((fieldname) => ({
					name: fieldname,
					...invoiceAdditionalFields[fieldname],
				})),
				// ...invoiceAdditionalFields
			});
		}
	};

	const { mutateAsync: handleQuoteInvoice, isLoading: isQuotingInvoice } =
		useMutation({
			mutationFn: InvoiceService.quoteInvoice,
			onError: () => {
				setPaymentQuoteCalculated(undefined);
			},
		});

	const [paymentQuote, setPaymentQuote] = useState<PaymentQuote>();
	const [paymentQuoteCalculated, setPaymentQuoteCalculated] =
		useState<IQuoteInvoice>(null);

	const debouncedQuote = useDebouncedValue(paymentQuote, 300);

	const invoiceFormValidationSchema = useMemo(
		() =>
			yup.object({
				user: yup.object().required(""),
				amount: yup
					.string()
					.required("")
					.typeError("")
					.test("", "", (value) => {
						return CurrencyUtil.getNumberByValue(value) > 0;
					})
					.test("is-quoted", "", (value, context) => {
						return !(
							!paymentQuoteCalculated &&
							context.parent.currency.value !== TCurrencySymbol.BRL
						);
					})
					.test(
						"valid-amount",
						t(`swap-modal.fields-errors.amount-min-max`, {
							replace: {
								min: `${CurrencyUtil.formatCurrency(
									paymentQuoteCalculated?.amountInMin,
									paymentQuoteCalculated?.currencyIn
								)}`,
								max: `${CurrencyUtil.formatCurrency(
									paymentQuoteCalculated?.amountInMax,
									paymentQuoteCalculated?.currencyIn
								)}`,
							},
						}),
						(value, context) => {
							if (!paymentQuoteCalculated) {
								return true;
							}

							const parsedValue = CurrencyUtil.getNumberByValue(value);

							return (
								parsedValue >= paymentQuoteCalculated.amountInMin &&
								parsedValue <= paymentQuoteCalculated.amountInMax
							);
						}
					),
				payerName: defaultAdditionalInformationValidation,
				payerPhone: defaultAdditionalInformationValidation,
				payerEmail: yup
					.object({
						value: yup.string().trim().email(""),
					})
					.nullable(),
				memo: defaultAdditionalInformationValidation,
				externalId: defaultAdditionalInformationValidation,
				webhookUrl: defaultAdditionalInformationValidation,
				payerPersonCompanyId: yup
					.object({
						value: yup
							.string()
							.trim()
							.test("", "", (value) => {
								if (!value) {
									return true;
								}

								return validateCPF(value) || validateCNPJ(value);
							}),
					})
					.nullable(),
			}),
		[paymentQuoteCalculated, isQuotingInvoice]
	);

	const invoiceForm = useFormik<InvoiceFormData>({
		initialValues: {
			amount: "",
			currency: currencyInOptions[0],
			user: null,
		},
		validationSchema: invoiceFormValidationSchema,
		validateOnChange: true,
		validateOnMount: true,
		onSubmit: handleSubmit,
	});

	const users: UserModel[] | undefined = getUsersResponse?.data?.rows;

	const [defaultUsers, setDefaultUsers] = useState<IUserOption[] | []>([]);

	const usersAccountOptions: IUserOption[] | any = useMemo(() => {
		if (defaultUsers.length === 0) {
			setDefaultUsers(users ? users.map(parseUserToOption) : []);
		}

		if (request) {
			const user = invoice.user;

			const userAccount = {
				value: user.id,
				label: user.name || user.personLegalName,
				subLabel: user.email,
				query: `${user.name} ${user.personLegalName} ${user.id} ${user.email}`,
			};

			return userAccount;
		}

		if (userId && !request) {
			const responseUser = getUsersResponse?.data?.rows[0];

			return responseUser ? [parseUserToOption(responseUser)] : [];
		} else if (isEdit) {
			return [parseUserToOption(swap.user as UserModel)];
		} else {
			return users ? users.map(parseUserToOption) : [];
		}
	}, [getUsersResponse, users, request]);

	useEffect(() => {
		if (request && swap) {
			swap.paidAmount = 0;

			let updatedValues = {};

			invoice?.fields?.forEach((field) => {
				let parsedValue = field.value || "";

				if (field.name === "payerPersonCompanyId") {
					parsedValue = Util.maskCpfOrCnpj(parsedValue);
				}

				updatedValues[field.name] = {
					value: parsedValue,
					isRequired: field.isRequired,
					isReadonly: field.isReadonly,
					isVisible: field.isVisible,
				};
			});

			invoiceForm.setValues({
				...invoiceForm.values,
				amount: request.invoice.amount,
				externalId: {
					value: invoice?.externalId || "",
					isRequired: isYesOrNoEnum.NO,
					isReadonly: isYesOrNoEnum.NO,
					isVisible: isYesOrNoEnum.YES,
				},
				memo: {
					value: invoice?.memo || "",
					isRequired: isYesOrNoEnum.NO,
					isReadonly: isYesOrNoEnum.NO,
					isVisible: isYesOrNoEnum.YES,
				},
				...updatedValues,
			});

			if (invoice?.allowedPaymentMethods[0] === "*") {
				setAllowAllCurrencies(true);
			} else {
				setAllowAllCurrencies(false);

				const allowedPaymentMethods = [];

				invoice?.allowedPaymentMethods.forEach((paymentMethod) => {
					if ("currency" in paymentMethod) {
						// check if paymentMethod is an object
						paymentMethod.networks.forEach((network) => {
							if (network === "*") {
								allowedPaymentMethods.push(paymentMethod.currency); //ex: USDT -> allows all USDT networks
							}
							allowedPaymentMethods.push(
								paymentMethod.currency + "/" + network
							); //ex: USDT/eth-mainnet -> allows USDT on the eth-mainnet network
						});
					} else {
						if (paymentMethod === "*") {
							return; // allows all payment methods
						}
						allowedPaymentMethods.push(paymentMethod); //ex: USDT -> allows all USDT networks
					}
				});

				if (currencyOutOptions) {
					setSelectedCurrencies(
						currencyOutOptions?.filter((option) => {
							return (
								allowedPaymentMethods.includes(option.value) ||
								allowedPaymentMethods.includes(option.currency)
							);
						}) || []
					);
				}
			}
		}
	}, [request]);

	// useEffect(() => {
	// 	const fetchUserData = async () => {

	// 		// if (action != "new" && swap && usersAccountOptions[0]) {
	// 		// 	const userOptionFilter = {
	// 		// 		page: 0,
	// 		// 		limit: 1,
	// 		// 		total: 0,
	// 		// 		search: {
	// 		// 			id: { equal: userId },
	// 		// 		},
	// 		// 	};

	// 		// 	const userData = await userService.getUsers(userOptionFilter);

	// 		// 	const userOption = parseUserToOption(userData.data.rows[0]);

	// 		// 	if (userData) {
	// 		// 		invoiceForm.setFieldValue("user", userOption);
	// 		// 	}
	// 		// 	return;
	// 		// }

	// 		// if (userId && usersAccountOptions[0]) {
	// 		// 	const currentUser = [parseUserToOption(swap.user as UserModel)];
	// 		// 	return invoiceForm.setFieldValue("user", currentUser);

	// 		// 	// return invoiceForm.setFieldValue("user", usersAccountOptions[0]);
	// 		// }

	// 		// if (
	// 		// 	!userId &&
	// 		// 	invoiceForm.values.user &&
	// 		// 	usersAccountOptions.length > 0
	// 		// ) {
	// 		// 	// const updatedOption = usersAccountOptions.find(
	// 		// 	// 	(option) => option.value === invoiceForm.values.user.value
	// 		// 	// );

	// 		// 	// invoiceForm.setFieldValue("user", updatedOption);
	// 		// 	// return;
	// 		// 	const updatedOption = usersAccountOptions.find(
	// 		// 		(option) => option.value === invoiceForm.values.user.value
	// 		// 	);
	// 		// 	invoiceForm.setFieldValue("user", updatedOption);
	// 		// 	return;
	// 		// }
	// 		// if (
	// 		// 	_cryptohubInfoBalancesData?.data?.userId &&
	// 		// 	usersAccountOptions?.length &&
	// 		// 	swap
	// 		// ) {
	// 		// 	// const userOption = usersAccountOptions.find(
	// 		// 	// 	(option) => option.value === _cryptohubInfoBalancesData.data.userId
	// 		// 	// );

	// 		// 	// if (userOption) {
	// 		// 	// 	invoiceForm.setFieldValue("user", userOption);
	// 		// 	// }

	// 		// 	const currentUser = [parseUserToOption(swap.user as UserModel)];

	// 		// 	return invoiceForm.setFieldValue("user", currentUser);
	// 		// }
	// 	};

	// 	fetchUserData();
	// }, [_cryptohubInfoBalancesData, usersAccountOptions, swap]);

	// Set o valor default de usuário ao abrir o modal para criar nova cobrança
	useEffect(() => {
		// if (action != "new") {
		// 	return;
		// }

		if (action != "new" && invoice && usersAccountOptions) {
			invoiceForm.setFieldValue("user", usersAccountOptions);
			return;
		}

		const fetchUserData = async () => {
			if (
				_cryptohubInfoBalancesData?.data?.userId &&
				usersAccountOptions?.length
			) {
				const userOptionFilter = {
					page: 0,
					limit: 1,
					total: 0,
					search: {
						id: { equal: _cryptohubInfoBalancesData?.data?.userId },
					},
				};

				const userData = await userService.getUsers(userOptionFilter);

				const userOption = parseUserToOption(userData.data.rows[0]);

				if (userData) {
					invoiceForm.setFieldValue("user", userOption);
				}
			}
		};

		fetchUserData();
	}, [_cryptohubInfoBalancesData, usersAccountOptions]);

	useEffect(() => {
		if (debouncedQuote) {
			if (debouncedQuote.currencyIn === TCurrencySymbol.BRL) {
				return;
			}

			handleQuoteInvoice({
				user: debouncedQuote.user,
				currencyIn: debouncedQuote.currencyIn,
				amountIn: debouncedQuote.amountIn,
				networkIn: debouncedQuote.network,
			})
				.then((data) => {
					if (data) {
						setPaymentQuoteCalculated(data);
					} else {
						setPaymentQuoteCalculated(null);
					}
				})
				.catch(() => {
					setPaymentQuoteCalculated(null);
				})
				.finally(() => {
					setTimeout(() => {
						invoiceForm.setFieldTouched("amount");
					}, 50);
				});
		}
	}, [debouncedQuote]);

	useEffect(() => {
		if (selectedCurrencies.length === currencyOutOptions.length) {
			setAllowAllCurrencies(true);
		}
	}, [selectedCurrencies]);

	const calculateQuote = () => {
		if (invoiceForm.values.currency.value !== TCurrencySymbol.BRL) {
			setPaymentQuote({
				user: invoiceForm.values.user.value,
				amountIn: CurrencyUtil.getNumberByValue(invoiceForm.values.amount),
				currencyIn: invoiceForm.values.currency.currency,
				network: invoiceForm.values.currency.network.nameId,
			});
		}
	};

	const handleCancelInvoice = () => {
		invoiceCancel(request.id);
	};

	const { additionalFieldsCount, enabledAdditionalFieldsCount } =
		useMemo(() => {
			const enabledAdditionalFieldsCount = Object.keys(invoiceForm.values)
				.filter((key) => {
					return additionalInformationFields.includes(key);
				})
				.filter((key) => {
					return !!invoiceForm.values[key];
				}).length;

			return {
				additionalFieldsCount: additionalInformationFields.length,
				enabledAdditionalFieldsCount,
			};
		}, [invoiceForm.values]);

	const disableSubmitButton =
		!invoiceForm.isValid ||
		isSubmitting ||
		(!allowAllCurrencies && selectedCurrencies.length === 0);

	const onChangeSearch = async (text: string) => {
		if (text) {
			userFilter.search = Util.mountDefaultUserSearch(text, userFilter.search);

			if (text?.length >= 3 || !text?.length) {
				const response = await refetchUsers();

				const users = response?.data?.data.rows;

				return users ? users.map(parseUserToOption) : [];
			} else {
				return defaultUsers;
			}
		}
	};

	return (
		<>
			<Modal
				ref={modalRef}
				title={t(`payment-gateway-crypto-modal.title.${action}`)}
				titleIcon={"SwapHorizontal"}
				visible={true}
				onClose={onClose}
				contentOverflowY={"auto"}
				fullHeightModal={true}
				onClickOutside={() => {}}
				backgroundColor={"new"}
				customWidth={{
					width: "100%",
					maxWidth: "900px",
					minWidth: "180px",
				}}
				minHeight={360}
				contentPadding={"8px 16px"}
				isLoading={isSubmitting}
				footer={
					<View style={[styles.footer]}>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-start",
							}}
						>
							{action !== "new" && (
								<>
									<View
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-start",
										}}
									>
										<Text size={"12px"}>{request.id}</Text>
										<Text variant="gray" size={"11px"}>
											{Util.formatDate(
												request.swap.createdAt,
												undefined,
												"L HH[h]mm"
											)}
										</Text>
									</View>
								</>
							)}
						</View>

						<View style={[styles.footerButtons]}>
							<Button
								variant={"secondary"}
								size={"small"}
								fillVariant={"ghost"}
								label={t("payment-gateway-crypto-modal.buttons.cancel")}
								onClick={() => onClose()}
								disabled={isSubmitting}
							/>

							{action !== "view" && (
								<Button
									icon={
										<Icon
											name={
												isEdit
													? isCancel
														? "CloseCircleIcon"
														: "SaveDisk"
													: "Send2"
											}
											fontSize={"20px"}
										/>
									}
									label={t(
										`payment-gateway-crypto-modal.buttons.submit.${
											isEdit ? (isCancel ? "cancel" : "edit") : "new"
										}`
									)}
									size={"small"}
									variant={isCancel ? "danger" : undefined}
									onClick={() =>
										isCancel
											? handleCancelInvoice()
											: invoiceForm.handleSubmit()
									}
									disabled={disableSubmitButton && !isCancel}
									disableHover={disableSubmitButton && !isCancel}
								/>
							)}
						</View>
					</View>
				}
				footerDivider={true}
			>
				{currencyInOptions.length > 0 && currencyOutOptions.length > 0 ? (
					<View style={[styles.container, { height: "100%" }]}>
						<View
							style={[
								styles.card,
								{
									minWidth: 360,
									maxWidth: 424,
									height: "100%",
									gap: 20,
									paddingTop: 8,
								},
							]}
						>
							<View
								style={{ display: "flex", flexDirection: "column", gap: 12 }}
							>
								<Title title={t("payment-gateway-crypto-modal.fields.user")} />
								<SelectSearch
									label={""}
									options={usersAccountOptions}
									selectedOption={invoiceForm.values.user}
									icons={{
										label: "PersonFill",
										subLabel: "EnvelopeFill",
									}}
									readonly={!!userId || isEdit || isReadOnly}
									disabled={isSubmitting}
									width={"100%"}
									noOptionsMessage={
										isLoadingUsers && usersAccountOptions?.length === 0
											? t("payment-gateway-crypto-modal.fields.user-loading")
											: undefined
									}
									onChange={(option) => {
										if (isReadOnly) return;

										invoiceForm.setFieldValue("user", option).finally(() => {
											invoiceForm.setFieldTouched("user");
										});
									}}
									loadOptions={onChangeSearch}
									defaultOptions={defaultUsers}
								/>
							</View>

							<View
								style={{ display: "flex", flexDirection: "column", gap: 12 }}
							>
								<Title
									title={t("payment-gateway-crypto-modal.fields.amountIn")}
								/>
								<Input
									label={""}
									autoComplete={"off"}
									paddingRight={"176px"}
									size={"large"}
									coin={
										invoiceForm.values?.currency?.value === TCurrencySymbol.BRL
											? "BRL"
											: "BTC"
									}
									disabled={isSubmitting}
									readOnly={isReadOnly}
									value={invoiceForm.values.amount}
									onChange={(value) =>
										invoiceForm.setFieldValue("amount", value)
									}
									onKeyUp={async () => {
										if (isCancel) return;

										await invoiceForm.setFieldTouched("amount");
										calculateQuote();
									}}
									decimalConfig={{
										decimalScale:
											invoiceForm.values.currency?.value === TCurrencySymbol.BRL
												? 2
												: 4,
										decimalsLimit:
											invoiceForm.values.currency?.value === TCurrencySymbol.BRL
												? 2
												: 4,
									}}
									variant={
										invoiceForm.touched.amount &&
										invoiceForm.errors.amount !== undefined
											? "danger"
											: undefined
									}
									errorMessage={
										invoiceForm.touched.amount && invoiceForm.errors.amount
									}
									rightAddon={{
										buttons: [
											<Select
												size={"medium"}
												inputMinWidth={"112px"}
												iconSet={"currency"}
												selectPadding={"0px"}
												placeholder={""}
												iconSize={24}
												disabled={isSubmitting}
												readonly={isReadOnly}
												options={currencyOptions}
												value={invoiceForm.values?.currency}
												isSearchable={false}
												onChange={(option) => {
													invoiceForm
														.setFieldValue("currency", option)
														.finally(() => {
															setAllowAllCurrencies(false);
															if (option.type === "crypto") {
																setSelectedCurrencies([option]);
															} else {
																setSelectedCurrencies([]);
															}
															invoiceForm
																.setFieldValue("amount", "")
																.finally(() => {
																	invoiceForm.setFieldTouched("amount");
																	setPaymentQuoteCalculated(null);
																});
														});
												}}
											/>,
										],
									}}
								/>
							</View>

							<PaymentCurrencyList
								currencies={currencyOutOptions}
								invoiceForm={invoiceForm}
							/>
						</View>

						<View
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 12,
								flex: 1,
							}}
						>
							<PaymentDetailsCard
								request={request}
								invoiceForm={invoiceForm}
								paidAmount={request?.swap?.paidAmount}
								quote={paymentQuoteCalculated}
								isQuoting={isQuotingInvoice}
							/>

							<View
								style={[
									styles.card,
									styles.detailsCard,
									{ height: "fit-content" },
								]}
							>
								<Title
									title={
										t(`payment-gateway-crypto-modal.additional-fields.title`) +
										(action === "new"
											? ` (${enabledAdditionalFieldsCount}/${additionalFieldsCount})`
											: ``)
									}
								/>

								<View
									style={[styles.detailsContent, { paddingLeft: 16, gap: 7 }]}
								>
									<AdditionalInformationButton
										fieldName={"payerName"}
										invoiceForm={invoiceForm}
									/>
									<AdditionalInformationButton
										fieldName={"payerPhone"}
										invoiceForm={invoiceForm}
									/>
									<AdditionalInformationButton
										fieldName={"payerEmail"}
										invoiceForm={invoiceForm}
									/>
									<AdditionalInformationButton
										fieldName={"payerPersonCompanyId"}
										invoiceForm={invoiceForm}
									/>
									<AdditionalInformationButton
										fieldName={"externalId"}
										invoiceForm={invoiceForm}
									/>
									<AdditionalInformationButton
										fieldName={"memo"}
										invoiceForm={invoiceForm}
									/>

									<AdditionalInformationInput
										fieldName={"payerName"}
										invoiceForm={invoiceForm}
									/>

									<AdditionalInformationInput
										fieldName={"payerPhone"}
										invoiceForm={invoiceForm}
										mask={"phone"}
									/>

									<AdditionalInformationInput
										fieldName={"payerEmail"}
										invoiceForm={invoiceForm}
									/>

									<AdditionalInformationInput
										fieldName={"payerPersonCompanyId"}
										invoiceForm={invoiceForm}
										mask={"cpfOrCnpj"}
									/>

									<AdditionalInformationInput
										fieldName={"externalId"}
										invoiceForm={invoiceForm}
									/>

									<AdditionalInformationInput
										fieldName={"memo"}
										invoiceForm={invoiceForm}
									/>
								</View>
							</View>
						</View>
					</View>
				) : (
					<NotAvailableView />
				)}

				<TooltipConfig />
			</Modal>
		</>
	);
};

export { PaymentModal };
