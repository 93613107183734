import * as Linking from "expo-linking";
import { LinkingOptions } from "@react-navigation/native/lib/typescript/src/types";

const linking: LinkingOptions<any> = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      NotFound: '*',
      livenessCheck: 'liveness_check',
      readinessCheck: 'readiness_check',
      signIn: 'sign-in',
      logOut: 'logout',
      forgotPass: 'sign-in/forgot-password',
      administrators: 'administrators',
      usersApproval: 'users/approval',
      usersAccounts: 'users/accounts',
      userAccount: "users/accounts/:id",
      blockedAdmins: 'blocked/admins',
      approvedDeposits: "approval-management/deposits",
      calc: "calc",

      approvedWithdrawals: "approval-management/withdrawals",
      approvedWithdrawalsCrypto: "approval-management/withdrawals-crypto",
      approvedOrderBuy: "order-management/buy",
      approvedOrderSell: "order-management/sell",
      approvedUsers: "approval-management/users",

      bankAccountReport: "reports/bank-accounts",
      inputOutputOfValues: "reports/input-output-of-values",
      financialTransactions: "reports/financialTransactions",
      inputOfValues: "reports/input-of-values",
      outputOfValues: "reports/output-of-values",
      userRegistrationReport: "reports/user-registration",
      depositsCrypto: "reports/deposits-crypto",
      withdrawalCommissions: "reports/commissions/withdrawal",
      depositCommissions: "reports/commissions/deposit",

      permissions: "permissions",
      dashboard: "dashboard",
      sac: "sac",
      "trade-buy": "trade/buy",
      "trade-sell": "trade/sell",
      "trade-swap": "trade/swap",
      "payment-gateway-crypto": "payment-gateway/crypto",

      settings: "settings",
      settingsIcons: "settings/icons",
      settingsWebhooks: "settings/webhooks",
      settingsCurrencies: "settings/currencies",
      settingsMarkets: "settings/markets",
      settingsTicker: "settings/ticker",
      warmUp: "_ah/warmup"
    },
  },
};

export default linking;
