import React from "react";

import {
    Icon,
    Text,
    TooltipConfig,
    View,
} from "hubchain-storybook-design-pattern";

export const ToastAlertDefaults = {
    style: {
        background: "#fff",
        width: "auto",
        minWidth: "144px",
        maxWidth: "384px",
        height: "auto",
        minHeight: "55px",
        borderRadius: "10px",
        boxShadow: "0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)",
        paddingLeft: "0px",
        paddingRight: "0px",
        padding: "0px",
        div: {
            padding: "0px",
            background: "red",
        },
    },
}

export const ToastAlert = ({ message, title }) => (
    <View
        style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px",
            flex: 1,
        }}
    >
        <View
            style={{
                backgroundColor: "#188351",
                borderRadius: "9px 0 0 9px",
                padding: "0px 12px 0px 12px",
                minHeight: "53px",
                color: "#212529",
                height: "auto",
                display: "flex",
                margin: "0",
            }}
        >
            <View
                style={{
                    display: "flex",
                    paddingTop: "12px",
                    flex: 1,
                }}
            >
                <Icon
                    name={"InfoIcon"}
                    fontSize={22}
                    size="small"
                    color={"#FFF"}
                    variant={"white"}
                />
            </View>
        </View>
        <View
            style={{
                flex: 1,
                height: "auto",
                width: "auto",
                textAlign: "left",
                paddingBottom: "12px",
                paddingTop: "12px",
                paddingRight: "32px",
                paddingLeft: "8px",
            }}
        >
            <Text
                style={{
                    marginLeft: "0px",
                    fontWeight: "bold",
                    lineHeight: 1.4,
                    color: "#333",
                    marginTop: "1px",
                    fontSize: "14px",
                }}
                fontStyle={"bold"}
            >
                {title}
            </Text>
            {typeof message === "string" ? (
                <Text
                    style={{
                        marginLeft: "0px",
                        lineHeight: 1.4,
                        color: "#333",
                        marginTop: "1px",
                        fontSize: "14px",
                    }}
                >
                    {message}
                </Text>
            ) : (
                message
            )}
        </View>
        <View
            style={{
                borderRadius: "0 10px 10px 0",
                right: "6px",
                position: "absolute",
                top: "4px",
                cursor: "pointer",
            }}
        >
            <Icon
                name={"CloseIcon"}
                fontSize={20}
                size="smallest"
                variant={"danger"}
            />
        </View>
    </View>
);
