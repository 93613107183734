import { TenantAppDomainEnum } from "./types";

export class MultiTenancyService {

    private static tenant: { name: string, appDomain: string };

    public static async getTenant(): Promise<{ name: string, appDomain: string }> {

        if (!MultiTenancyService.tenant) {

            const _hostname = window.location.hostname;
            const _tenantName = _hostname.split('.')[0].split('-')[0];

            // console.log("..::", _hostname, _tenantName);

            for (const tenantKey of Object.keys(TenantAppDomainEnum)) {
                const _appDomain = TenantAppDomainEnum[tenantKey];
                // console.log("..::", { _hostname, _tenantName, tenantKey, _appDomain });
                if (_tenantName === _appDomain.split('.')[0].split('-')[0]) {
                    MultiTenancyService.tenant = { name: _tenantName, appDomain: _appDomain }
                    // console.log("..::", `..:: [Tenant By Preffix ${_tenantName}-]`, MultiTenancyService.tenant);
                    break;
                }
            }

            if (!MultiTenancyService.tenant) {
                for (const tenantKey of Object.keys(TenantAppDomainEnum)) {
                    const _appDomain = TenantAppDomainEnum[tenantKey];
                    // console.log("..::", { _hostname, _tenantName, tenantKey, _appDomain });
                    if (_hostname.endsWith(_appDomain)) {
                        MultiTenancyService.tenant = { name: tenantKey.toLowerCase(), appDomain: _appDomain }
                        // console.log(`..:: [Tenant By Suffix *.${_appDomain}]`, MultiTenancyService.tenant);
                        break;
                    }
                }
            }

        }

        return MultiTenancyService.tenant;
    }
}
