import {
    NavigationContainer,
} from "@react-navigation/native";

import 'bootstrap/dist/css/bootstrap.min.css';
import LinkingConfiguration from "./LinkingConfiguration";
import {useAuth} from "../services/auth";

import LoggedRoutes from "./logged.routes";
import NotLoggedRoutes from "./not-logged.routes";
import {useMultiTenancy} from "../services/multi-tenancy/multi-tenancy";
import LoadingScreen from "../components/Loading";
import React from "react";

export default function Navigation() {
    const {tenant} = useMultiTenancy();

    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            documentTitle={{
                enabled: true,
                formatter: (options, route) => `${tenant?.title || "..."} ${options?.title ? "- " + options?.title : ""}`
            }}
        >
            <RootNavigator/>
        </NavigationContainer>
    );
}

function RootNavigator() {
    const {isLogged, loading} = useAuth();
    const {tenantLoading} = useMultiTenancy();

    if (loading || tenantLoading) {
        return (
            <LoadingScreen/>
        );
    }

    return isLogged ? <LoggedRoutes/> : <NotLoggedRoutes/>;
}
