import RequestSwapService, {
	RequestSwapModel,
} from "../../../src/services/swap/swapService";
import {
	Button,
	Icon,
	Modal,
	Text,
	View,
	Input,
	Select,
	TooltipConfig,
} from "hubchain-storybook-design-pattern";
import styles from "../../../src/components/UserSwapModal/styles";
import Util from "../../../src/utils";
import React, { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";
import { useMutation, useQueryClient } from "react-query";
import { useAlert } from "../../../src/hooks/useAlert";
import CurrencyUtil from "../../../src/utils/CurrencyUtil";
import { TCurrencySymbol } from "../../../src/types/currency";

import { Platform, TouchableOpacity } from "react-native";

import { setStringAsync } from "expo-clipboard";
interface DoneWithdrawalModalProps {
	request: RequestSwapModel;
	onClose: () => void;
}

interface FormData {
	transactionId: string;
}

const formValidationSchema = yup.object({
	transactionId: yup.string().required(" "),
});

export default function DoneWithdrawalModal({
	request,
	onClose,
}: DoneWithdrawalModalProps) {
	const { t } = useTranslation();
	const { showAlert } = useAlert();

	const queryClient = useQueryClient();

	const { mutate: handleDoneWithdrawal, isLoading: isSubmitting } = useMutation(
		{
			mutationFn: RequestSwapService.doneWithdrawal,
			onSuccess: () => {
				onClose();
				showAlert(t(`swap-done-withdrawal-modal.alerts.success`), "success");
				queryClient.refetchQueries(["swaps"]);
				queryClient.refetchQueries(["CryptoHubInfoBalances"]);
			},
			onError: () => {
				showAlert(t(`swap-done-withdrawal-modal.alerts.error`), "danger");
				queryClient.refetchQueries(["swaps"]);
			},
		}
	);

	const handleSubmit = (values: FormData) => {
		handleDoneWithdrawal({
			id: request.id,
			transactionId: values.transactionId.trim(),
		});
	};

	const { networkOutOption, currencyOutOption } = useMemo(() => {
		const networkOut = request.swap.networkOut;
		const currencyOut = request.swap.currencyOut;

		const networkOutOption: SelectOptionModel = {
			value: networkOut.nameId,
			label: networkOut.description,
			icon: networkOut.nameId.substring(0, 3).toUpperCase(),
		};

		const currencyOutOption: SelectOptionModel = {
			value: currencyOut.currency,
			label: currencyOut.currency,
			subLabel: currencyOut.name,
			icon: currencyOut.currency,
		};

		return {
			networkOutOption,
			currencyOutOption,
		};
	}, [request]);

	const currencySymbol = currencyOutOption.value;

	const form = useFormik<FormData>({
		initialValues: {
			transactionId: "",
		},
		onSubmit: handleSubmit,
		validateOnChange: false,
		validateOnMount: true,
		validationSchema: formValidationSchema,
	});

	return (
		<>
			<Modal
				visible={true}
				title={t(`swap-done-withdrawal-modal.title`)}
				titleIcon={"SwapHorizontal"}
				onClose={onClose}
				contentOverflowY={"auto"}
				fullHeightModal={true}
				onClickOutside={() => { }}
				backgroundColor={"new"}
				customWidth={{
					width: "100%",
					maxWidth: "480px",
					minWidth: "180px",
				}}
				isLoading={isSubmitting}
				footer={
					<View style={[styles.footer]}>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-start",
							}}
						>
							<View
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
								}}
							>
								<Text size={"12px"}>{request.id}</Text>
								<Text variant="gray" size={"11px"}>
									{Util.formatDate(
										request.swap.createdAt,
										undefined,
										"L HH[h]mm"
									)}
								</Text>
							</View>
						</View>

						<View style={[styles.footerButtons]}>
							<Button
								variant={"secondary"}
								size={"small"}
								fillVariant={"ghost"}
								label={t("swap-done-withdrawal-modal.buttons.close")}
								onClick={() => onClose()}
								disabled={isSubmitting}
							/>

							<Button
								icon={<Icon name={"Send2"} fontSize={"20px"} />}
								label={t("swap-done-withdrawal-modal.buttons.submit")}
								size={"small"}
								variant={undefined}
								onClick={() => form.submitForm()}
								disabled={isSubmitting || !form.isValid}
								disableHover={isSubmitting || !form.isValid}
							/>
						</View>
					</View>
				}
				footerDivider={true}
			>
				<View style={[styles.container]}>
					<View style={[styles.card]}>
						<Select
							label={t("swap-modal.destination.network")}
							options={[networkOutOption]}
							value={networkOutOption}
							iconSet={"crypto-net"}
							labelStyle="default"
							placeholder={""}
							inputWidth={"100%"}
							size={"large"}
							iconSize={28}
							readonly={true}
						/>
						<Input
							label={t("swap-done-withdrawal-modal.destination.amount")}
							value={CurrencyUtil.formatCurrency(
								request.swap.netAmountOut,
								request.swap.currencyOut.currency,
								false,
								2,
								6
							)}
							placeholder={""}
							inputWidth={"100%"}
							size={"large"}
							readOnly={true}
							rightAddon={{
								buttons: [
									<a
										data-tooltip-id={"tooltip-on-click"}
										data-tooltip-content={t(`general.tooltip.copied`)}
										data-tooltip-variant="success"
										style={{ marginRight: "-16px" }}
									>
										<TouchableOpacity
											style={{ marginLeft: 4, marginRight: 0 }}
											onPress={() => {
												setStringAsync(request.swap.netAmountOut);
											}}
										>
											<Icon name={"CopyIcon"} fontSize={"11px"} />
										</TouchableOpacity>
									</a>,
									<Select
										size={"large"}
										inputMinWidth={"112px"}
										iconSet={"currency"}
										iconSize={24}
										readonly
										disabled
										options={[currencyOutOption]}
										value={currencyOutOption}
										hideIndicatorsContainer={true}
									/>
								],
							}}
						/>

						<Input
							label={t("swap-done-withdrawal-modal.destination.wallet")}
							size={"large"}
							value={Util.maskBlockchainAddressOrTransactionId(request.swap.addressOut)}
							readOnly={true}
							rightAddon={{
								buttons: [
									<a
										data-tooltip-id={"tooltip-on-click"}
										data-tooltip-content={t(`general.tooltip.copied`)}
										data-tooltip-variant="success"
										style={{ paddingRight: "8px" }}
									>
										<TouchableOpacity
											style={{ marginLeft: 4 }}
											onPress={() => {
												setStringAsync(request.swap.addressOut);
											}}
										>
											<Icon name={"CopyIcon"} fontSize={"11px"} />
										</TouchableOpacity>
									</a>,
								],
							}}
						/>

						<Input
							label={t("swap-done-withdrawal-modal.transactionId")}
							size={"large"}
							placeholder={"0xAaBbCc"}
							autoComplete={"off"}
							value={form.values.transactionId}
							onChange={(value) => {
								if (value) value = value.split('/').slice(-1)[0];
								return form.setFieldValue("transactionId", value);
							}}
							onKeyUp={() => form.setFieldTouched("transactionId")}
							variant={
								form.touched.transactionId &&
									form.errors.transactionId !== undefined
									? "danger"
									: undefined
							}
						/>
					</View>
				</View>
				<TooltipConfig />
			</Modal>
		</>
	);
}
