// @ts-ignore
import walletAddressValidator from "@getdelta/wallet-address-validator";
import { isAddress, getAddress } from "ethers/lib/utils";
import Util from "../../utils";

export default class WalletService {

  static addressValidate(address: string, network: string) {

    if (Util.isNullOrEmpty(address)) {
      throw new Error("address is required");
    }

    if (Util.isNullOrEmpty(network)) {
      throw new Error("network is required");
    }

    const _network = (['bsc', 'pol'].includes(network.toLowerCase()) ? 'eth' : network.toLowerCase());
    const _address = (_network === 'eth' && isAddress(address.toLowerCase())) ? getAddress(address.toLowerCase()) : address;

    return {
      address: _address,
      network: network,
      isValid: walletAddressValidator.validate(_address, _network),
    }

  }

}
