import {
	InvoiceFormData,
	PaymentAdditionalInformationButtonProps,
	PaymentAdditionalInformationInputProps,
} from "../models";
import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native";
import {
	Button,
	Checkbox,
	Icon,
	Input,
	Text,
	TooltipConfig,
	View,
} from "hubchain-storybook-design-pattern";
import React, { useContext } from "react";
import { InvoiceAdditionalFieldItem } from "../../../services/invoice/invoiceService";
import { FormikProps } from "formik";
import { PaymentModalContext } from "../PaymentModal";
import { isYesOrNoEnum } from "../../../types/types";

import asteriskPrimary from "../../../assets/icons/asteriskSolidPrimary.svg";
import asteriskWhite from "../../../assets/icons/asteriskSolidWhite.svg";

const toggleAdditionalInformationField = ({
	fieldName,
	value,
	invoiceForm,
}: {
	fieldName: string;
	value?: InvoiceAdditionalFieldItem;
	invoiceForm: FormikProps<InvoiceFormData>;
}) => {
	const fieldValue = invoiceForm.values[fieldName];
	const initialValue: InvoiceAdditionalFieldItem = {
		value: "",
		isRequired: isYesOrNoEnum.NO,
		isReadonly: isYesOrNoEnum.NO,
		isVisible: isYesOrNoEnum.YES,
	};

	if (!fieldValue || value) {
		invoiceForm.setFieldValue(
			fieldName,
			value !== undefined ? value : initialValue
		);
	} else {
		invoiceForm.setFieldValue(fieldName, undefined);
	}
};

export const AdditionalInformationButton = ({
	fieldName,
	invoiceForm,
	visible,
}: PaymentAdditionalInformationButtonProps) => {
	const { t } = useTranslation();
	const { isReadOnly } = useContext(PaymentModalContext);

	visible =
		visible === undefined
			? !isReadOnly && !invoiceForm.values[fieldName]
			: visible;

	if (!visible) {
		return null;
	}

	return (
		<TouchableOpacity
			onPress={() =>
				toggleAdditionalInformationField({ fieldName, invoiceForm })
			}
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: 4,
				height: 20,
			}}
		>
			<Icon name={"AddIcon"} fontSize={"13px"} />
			<Text variant={"primary"}>
				{t(`payment-gateway-crypto-modal.additional-fields.${fieldName}.label`)}
			</Text>
		</TouchableOpacity>
	);
};

export const AdditionalInformationInput = (
	props: PaymentAdditionalInformationInputProps
) => {
	const { t } = useTranslation();
	const { fieldName, invoiceForm } = props;
	const { isReadOnly } = useContext(PaymentModalContext);

	const visible = !!invoiceForm.values[fieldName] || isReadOnly;

	if (!visible) {
		return null;
	}

	return (
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				gap: 4,
			}}
		>
			<a
				data-tooltip-id="tooltip-default"
				data-tooltip-html={
					props.fieldName === "memo"
						? invoiceForm.values[fieldName]?.value.length > 50
							? invoiceForm.values[fieldName]?.value
							: null
						: null
				}
				style={{ width: "100%" }}
			>
				<Input
					style={{ minHeight: "24px" }}
					label={""}
					placeholder={t(
						`payment-gateway-crypto-modal.additional-fields.${fieldName}.placeholder`
					)}
					size={"smallest"}
					height={props.fieldName === "memo" ? "48px" : "24px"}
					fontSize={"12px"}
					onlyNumber={props.mask === "number"}
					mask={props.mask}
					type={props.fieldName === "memo" ? "textarea" : "text"}
					maxLength={
						props.fieldName === "payerPhone"
							? 20
							: props.fieldName === "memo"
							? 1024
							: 255
					}
					value={invoiceForm.values[fieldName]?.value}
					variant={
						invoiceForm.touched[fieldName] &&
						invoiceForm.errors[fieldName] !== undefined
							? "danger"
							: undefined
					}
					onChange={(value) => {
						invoiceForm.setFieldValue(fieldName, {
							...invoiceForm.values[fieldName],
							value: value,
						});
					}}
					onKeyUp={() => {
						invoiceForm.setFieldTouched(fieldName);
					}}
					readOnly={
						invoiceForm.values[fieldName]?.isReadonly === isYesOrNoEnum.YES ||
						isReadOnly
					}
					paddingRight={"32px"}
					rightAddon={{
						buttons: ["memo", "externalId"].includes(fieldName)
							? []
							: [
									<>
										<a
											data-tooltip-id="tooltip-default"
											data-tooltip-html={t(
												`payment-gateway-crypto-modal.additional-fields.required.${
													!isReadOnly
														? "tip"
														: invoiceForm.values[fieldName]?.isReadonly ===
														  isYesOrNoEnum.YES
														? "payer"
														: "myself"
												}`
											)}
											style={{ width: "100%" }}
										>
											<TouchableOpacity
												onPress={() => {
													if (isReadOnly) return;
													invoiceForm.setFieldValue(fieldName, {
														...invoiceForm.values[fieldName],
														value: "",
														isReadonly:
															invoiceForm.values[fieldName]?.isReadonly ===
															isYesOrNoEnum.YES
																? isYesOrNoEnum.NO
																: isYesOrNoEnum.YES,
														isRequired:
															invoiceForm.values[fieldName]?.isReadonly ===
															isYesOrNoEnum.YES
																? isYesOrNoEnum.NO
																: isYesOrNoEnum.YES,
														isVisible:
															invoiceForm.values[fieldName]?.isReadonly ===
															isYesOrNoEnum.YES
																? isYesOrNoEnum.NO
																: isYesOrNoEnum.YES,
													});
													setTimeout(
														() => invoiceForm.setFieldTouched(fieldName),
														50
													);
												}}
											>
												{invoiceForm.values[fieldName]?.isReadonly ===
												isYesOrNoEnum.YES ? (
													<Icon
														name={"AsteriskFill"}
														variant={"primary"}
														fontSize={16}
													/>
												) : (
													<Icon
														name={"Asterisk"}
														variant={"white"}
														fontSize={16}
													/>
												)}
											</TouchableOpacity>
										</a>
									</>,
							  ],
					}}
				/>
			</a>

			{!isReadOnly && (
				<TouchableOpacity
					onPress={() =>
						toggleAdditionalInformationField({ fieldName, invoiceForm })
					}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: 28,
						height: 28,
					}}
				>
					<Icon name={"CloseIcon"} variant={"danger"} fontSize={"20px"} />
				</TouchableOpacity>
			)}
		</View>
	);
};
