import { StyleSheet } from "react-native";
import Colors from "../../../src/global-constants/Colors";
import Fonts from "../../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";


const stylesMobile = StyleSheet.create({
  forgotPassBox: {
    width: "100%",
    maxWidth: 380,
    height: "100%",
    borderRadius: 8
  },
  boxButtons: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    alignContent: "flex-end",
    marginTop: "1.5rem"
  },
  subTitleBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  subTitleText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "300",
    marginTop:14,
    marginLeft:15,
    marginRight:15,
    width:"68%"
  },
  subTitleTextBold: {
    color: Colors.light.text,
    textAlign: "center",
    fontWeight: "600",
  },
  descriptionMailAccept: {
    color: Colors.light.text,
    textAlign: "center",
    fontWeight: "300",
  },
  iconBox:{
    justifyContent:"center",
    alignItems:"center"
  },
  iconMail:{
    fontSize: 64,
    color:Colors.light.text,
    fontWeight: "100"
  }
});

const stylesWeb = StyleSheet.create({
  forgotPassBox: {
    backgroundColor: Colors.light.background,
    width: "100%",
    maxWidth: 380,
    height: "100%",
    borderRadius: 8,
    padding: "1.5rem"
  },
  boxButtons: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    alignContent: "flex-end",
    marginTop: "1.5rem"
  },
  subTitleBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  subTitleText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 14
  },
  subTitleTextBold: {
    textAlign: "center",
    fontWeight: "bold"
  },
  descriptionMailAccept: {
    textAlign: "center",
  },
  iconBox:{
    justifyContent:"center",
    alignItems:"center"
  },
  iconMail:{
    fontSize: 64,
    color:Colors.light.text,
    fontWeight: "100"
  }
});

const styles = isMobile ? stylesMobile : stylesWeb
export default styles;
