export enum RequestTypeEnum {
  withdrawal = 1,
  deposit = 2,
  payment = 3,
  sell = 4,
  buy = 5,
  // commission = 6,

  swapExactAmountIn = 98,
  swapExactNetAmountOut = 99,
  sweep = 101,
  swapApprove = 102,

  /* Swaps */
  swapExactCryptoForCrypto = 200,
  swapCryptoForExactCrypto = 201,

  swapExactCryptoForFiat = 202,
  swapCryptoForExactFiat = 203,

  swapExactFiatForCrypto = 204,
  swapFiatForExactCrypto = 205,

  swapExactFiatForFiat = 206,
  swapFiatForExactFiat = 207,
}

export enum RequestDescriptionEnum {
  withdrawal = 1,
  deposit = 3,
  sell = 4,
  buy = 5,
  transferToInternal = 7,
  transferFromInternal = 8,
  // SWAP = 14, <= este tipo não existe.
}
