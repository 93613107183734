import Util from ".";

function maskPostalCode(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  return value;
}

function maskPhone(value: string, areaCodeSpace = true) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/g, areaCodeSpace ? "($1) $2" : "($1)$2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
}

function maskHour(value: string) {
  value = value.replace(/\D/g, "");
  if (/^(0[0-9]|1[0-9]|2[0-3])(\d)/.test(value) && value.length > 0) {
    value = value.replace(/^(0[0-9]|1[0-9]|2[0-3])(\d)/, "$1:$2");
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/.test(value) && value.length > 4) {
      value = "00:00";
    }
  } else if (value.length > 2) {
    value = "00:";
  }
  return value;
}
function maskEmailOrCpf(text: string) {
  if (Util.isNullOrEmpty(text)) {
    return text;
  }

  if (/^[0-9]+\.?[0-9]+\.?[0-9]*$/.test(text)) {
    // setLenghtMail(14);
    let mask: any = text
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    return !mask[2]
      ? mask[1]
      : `${mask[1]}.${mask[2]}${mask[3] ? '.' + mask[3] : ''
      }${mask[4] ? '-' + mask[4] : ''}`;
  } else if (/^[0-9]+\.?[0-9]+\.?[0-9]+\-?[0-9]+[0-9]+[a-z0-9]+$/.test(text)) {

    let mask: any = text
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    return !mask[2]
      ? mask[1]
      : `${mask[1]}.${mask[2]}${mask[3] ? '.' + mask[3] : ''
      }${mask[4] ? '-' + mask[5] : ''}`;
    //12.828.790/0001-87
    //return text.slice(0, -1);
  } else {
    return text;
  }
}
function maskEmailOrCpfOrCnpj(text: string) {
  if (Util.isNullOrEmpty(text)) {
    return text;
  }

  if (/^[0-9]+\.?[0-9]+\.?[0-9]*$/.test(text)) {
    // setLenghtMail(14);
    let mask: any = text
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    return !mask[2]
      ? mask[1]
      : `${mask[1]}.${mask[2]}${mask[3] ? '.' + mask[3] : ''
      }${mask[4] ? '-' + mask[4] : ''}`;
  } else if ((/^[0-9]+\.?[0-9]+\.?[0-9]+\-?[0-9]+[a-z0-9]+$/.test(text) || /^[0-9]+\.?[0-9]+\.?[0-9]+\/?[0-9]\-?[0-9]+$/.test(text)) && text.length > 14) {
    let mask: any = text
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    var teste = !mask[2]
      ? mask[1]
      : `${mask[1]}.${mask[2]}${mask[3] ? '.' + mask[3] : ''}
      ${mask[4] ? '/' + mask[4] + '-' : ''}
      ${mask[5] ? mask[5] : ''}`;
    return teste.replace(/ /g, '')

  } else if (/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{3}$/.test(text)) {
    return text.slice(0, -1);
  } else {
    return text;
  }
}


function maskCpfOrCnpj(text: string) {
  if (Util.isNullOrEmpty(text)) {
    return text;
  }

  if (/^[0-9]+\.?[0-9]+\.?[0-9]*$/.test(text) && text.length < 14) {
    let mask: any = text
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    return !mask[2]
      ? mask[1]
      : `${mask[1]}.${mask[2]}${mask[3] ? '.' + mask[3] : ''
      }${mask[4] ? '-' + mask[4] : ''}`;
  } else if ((/^[0-9]+\.?[0-9]+\.?[0-9]+\-?[0-9]+[a-z0-9]+$/.test(text) || /^[0-9]+\.?[0-9]+\.?[0-9]+\/?[0-9]\-?[0-9]+$/.test(text)) && text.length > 14 || (text.match(/^[0-9]+$/) && text.length == 14)) {
    let mask: any = text
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    var teste = !mask[2]
      ? mask[1]
      : `${mask[1]}.${mask[2]}${mask[3] ? '.' + mask[3] : ''}${mask[4] ? '/' + mask[4] + '-' : ''}${mask[5] ? mask[5] : ''}`;
    return teste.replace(/ /g, '')
  } else if (/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{3}$/.test(text)) {
    return text.slice(0, -1);
  } else if (/[a-zA-Z]/g.test(text) || text.indexOf(' ') >= 0) {
    return "";
  } else {
    return text;
  }
}

function maskBRL(value: string) {

  //value = parseFloat(value)/100;
  value = Number(value).toLocaleString('pt-br', { minimumFractionDigits: 2, useGrouping: false }).replace(".", ",");
  return value

}



const maskPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export { maskPostalCode, maskPhone, maskHour, maskCpfOrCnpj, maskEmailOrCpf, maskEmailOrCpfOrCnpj, maskBRL, maskPassword };
