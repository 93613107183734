import {StyleSheet} from "react-native";
import Colors from "../../src/global-constants/Colors";
import { isMobile } from "react-device-detect";

const styles = StyleSheet.create({
  loginBox: {
    backgroundColor: isMobile ? "none" : Colors.light.background,
    width: "100%",
    maxWidth: 380,
    height: "100%",
    borderRadius: 8,
    padding: isMobile ? 0 : "1.5rem",
    paddingHorizontal: isMobile ? 0 : "1.5rem",
  },
  buttonDisable: {
    backgroundColor: "#B2B2B2",
    flexDirection: "row"
  },
  buttonDisableText: {
    marginTop: 11,
    marginLeft: 3,
    color: "#FFF",
  },
  forgetPassBox: {
    flexDirection: "row",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    paddingTop: "0.5rem"
  },
  forgetPassText: {
    color: Colors.light,
    textAlign: "right"
  } as any,
  boxButtons: {
    width: "100%",
    flexDirection: "row",
    marginTop: isMobile ? "1.5rem" : "1.5rem",
    justifyContent: "center"
  },
  buttonSignInBox: {
    height: 37,
    minWidth: 150,
    paddingHorizontal: 10,
    backgroundColor: Colors.dark.background,
    justifyContent: "center",
    alignSelf: "flex-end",
    borderRadius: 5
  },
  buttonSignInText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 12
  },
  lookPassView: {
    position: "absolute",
    right: 12,
    top: 38,
    zIndex: 1,
    elevation: 200,
  },
});

export default styles;
