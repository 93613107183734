import { TCurrencySymbol } from "../currency";

// ********************************************

export type smartContractERC20ImplementationType = 'ERC20' | 'BEP20' | 'TRC20' | 'POL20';

export enum NetworkChainType {
    ON_CHAIN = "on-chain",
    OFF_CHAIN = "off-chain",
}

export enum NetworkName {
    INTERNAL = "internal",
    PIX = "pix",
    P2P = "p2p",
    DREX = "drex",
    BTC = "btc",
    ETH = "eth",
    BSC = "bsc",
    TRX = "trx",
    POL = "pol",
}

export enum NetworkEnvironment {
    MAINNET = "mainnet",
    TESTNET = "testnet"
}

export enum NetworkNameId {
    INTERNAL_MAINNET = "internal-mainnet",
    PIX_MAINNET = "pix-mainnet",
    P2P_MAINNET = "p2p-mainnet",
    BTC_MAINNET = "btc-mainnet",
    BTC_TESTNET = "btc-testnet",
    ETH_MAINNET = "eth-mainnet",
    ETH_TESTNET = "eth-testnet",
    BSC_MAINNET = "bsc-mainnet",
    BSC_TESTNET = "bsc-testnet",
    TRX_MAINNET = "trx-mainnet",
    TRX_TESTNET = "trx-testnet",
    POL_MAINNET = "pol-mainnet",
    POL_TESTNET = "pol-testnet",
}