import { isYesOrNoEnum } from "../types/types";
import { TCurrencySymbol } from "../types/currency";

export enum CurrencyTypeEnum {
  FIAT = "fiat",
  CRYPTO = "crypto"
}

export interface CurrencyModel {
  id: string,
  type: CurrencyTypeEnum,
  currency: TCurrencySymbol,
  decimals: number,
  decimalsSeparator: string,
  thousandsSeparator: string,
  prefix: string,
  // isFiat?: isYesOrNoEnum,
  isActive?: isYesOrNoEnum,
  name?: string,
  namePt?: string,
  nameEs?: string,
  nameEn?: string,
  nameZh?: string,
  nameRu?: string,
  deflationaryInPercent?: number
  isFavorite?: isYesOrNoEnum
}
