import API, { corePrefix } from "../api";
import { TCurrencySymbol } from "../../types/currency";

import { AxiosResponse } from "axios";
import { CurrencyTypeEnum } from "src/models/CurrencyModel";
import { isYesOrNoEnum } from "src/types/types";

export interface INetwork {
  nameId: string,
  description: string,
  currencySymbol: TCurrencySymbol,
  placeholder: string
  contractType?: string,
}

export interface ICurrencyTicker {
  updatedAt: number,
  provider: string,
  name: string,
  EUR: number,
  USD: number,
  BRL: number,
}

export interface CurrencyData {
  "currency": TCurrencySymbol,
  "name": string,
  type: CurrencyTypeEnum,
  "decimals": number,
  "deposit": {
    "enabled": isYesOrNoEnum,
    "minConfirmations": number,
    "amountMin": string,
    "networks": INetwork[]
  },
  "withdraw": {
    "enabled": isYesOrNoEnum,
    "amountMin": string,
    "amountFee": string,
    "networks": INetwork[]
  },
  ticker?: ICurrencyTicker,
  "category": {
    "code": string,
    "name": string
  }
}

interface GetCurrenciesResponse {
  data: CurrencyData[],
  status: string
}

export interface MarketData {
  "symbol": string,
  "baseAmountMin": string,
  "baseAmountIncrement": string,
  "baseAmountDecimals": number,
  "quoteAmountMin": string,
  "quoteAmountIncrement": string,
  "quoteAmountDecimals": number,
  "baseCurrency": {
    "currency": TCurrencySymbol,
    "name": string,
    "type": CurrencyTypeEnum,
    "precision": number,
    "deposit": {
      "enabled": isYesOrNoEnum,
      "minConfirmations": number,
      "amountMin": string,
      "networks": INetwork[]
    },
    "withdraw": {
      "enabled": isYesOrNoEnum,
      "amountMin": string,
      "amountFee": string,
      "networks": INetwork[]
    },
    "category": {
      "code": string,
      "name": string
    }
  },
  "quoteCurrency": {
    "currency": TCurrencySymbol,
    "name": string,
    "type": CurrencyTypeEnum,
    "precision": number,
    "deposit": {
      "enabled": isYesOrNoEnum,
      "minConfirmations": number,
      "amountMin": string,
      "networks": INetwork[]
    },
    "withdraw": {
      "enabled": isYesOrNoEnum,
      "amountMin": string,
      "amountFee": string,
      "networks": INetwork[]
    },
    "category": {
      "code": string,
      "name": string
    }
  }
}

interface GetMarketsResponse {
  data: MarketData[],
  status: string
}

export default class CurrencyService {
  async getCurrencies(): Promise<AxiosResponse<GetCurrenciesResponse>> {
    return API.get(`${corePrefix}/currencies?fiat=*`);
  }

  async getMarkets(): Promise<AxiosResponse<GetMarketsResponse>> {
    return API.get(`${corePrefix}/markets`);
  }
}
