import SwapService, { RequestSwapModel } from "../../../src/services/swap/swapService";
import Util from "../../../src/utils";
import CurrencyUtil from "../../../src/utils/CurrencyUtil";
import React from "react";
import { Button, Modal, View } from "hubchain-storybook-design-pattern";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useAlert } from "../../../src/hooks/useAlert";
import { ConfirmModalInfoRow } from "../../../src/components/ConfirmModalInfoRow";
import { TCurrencySymbol } from "../../../src/types/currency";
import { RequestStatusId } from "../../../src/enums/RequestStatusId.enum";

interface RateEditModalProps {
  request: RequestSwapModel,
  newRateValue: string,
  onClose: () => void,
  onSubmit: () => void
}

interface SwapCancelModalProps {
  request: RequestSwapModel,
  onClose: () => void,
  onSubmit: () => void
}

const ConfirmModalCommonInfos = ({ request }: { request: RequestSwapModel }) => {
  const { t } = useTranslation();
  const swap = request.swap;

  return (
    <>
      <ConfirmModalInfoRow
        label={t(`swap.rate-info-modal.infos.identifier`)}
        value={"#" + request.id}
        subValues={[
          Util.formatDate(swap.createdAt)
        ]}
      />

      <ConfirmModalInfoRow
        label={t(`swap.rate-info-modal.infos.account-in`)}
        value={swap.user.personLegalName}
        subValues={[
          swap.user.email,
        ]}
      />

      <ConfirmModalInfoRow
        label={t(`swap.rate-info-modal.infos.address-out`)}
        value={swap.addressOut || t(`swap.rate-info-modal.infos.address-out-empty`)}
        allowCopy={!!swap.addressOut}
        subValues={[
          swap.networkOut?.description?.split("(")[0]
        ]}
      />

      <ConfirmModalInfoRow label={t(`swap.rate-info-modal.infos.amount-in`)} value={CurrencyUtil.formatCurrency(swap.amountIn, swap.currencyIn.currency)} />

      <ConfirmModalInfoRow label={t(`swap.rate-info-modal.infos.amount-out`)} value={CurrencyUtil.formatCurrency(swap.netAmountOut, swap.currencyOut.currency)} />
    </>
  );
}

export const SwapCancelConfirmModal = ({ request, onClose, onSubmit }: SwapCancelModalProps) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const swap = request.swap;

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: SwapService.cancel,
    onSuccess: () => {
      showAlert(t(`swap.actions.cancel.success`), "success");
      queryClient.refetchQueries(["CryptoHubInfoBalances"]);
      request.swap.status = {
        ...request.swap.status,
        id: RequestStatusId.CANCELLED,
        key: "CANCELLED",
        namePt: "Cancelado"
      }
      onSubmit();
    },
    onError: () => {
      showAlert(t(`swap.actions.cancel.error`), "danger");
    }
  })

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  }

  const handleSubmit = () => {
    if (isLoading) return;

    mutate(request.id);
  }

  return (
    <Modal
      visible={true}
      onClose={handleClose}
      icon={"interrogation"}
      header={"clean"}
      title={t(`swap.rate-info-modal.title.cancel`)}
      fullHeightModal={true}
      isLoading={isLoading}
    >
      <View style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <ConfirmModalCommonInfos request={request} />
      </View>

      <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 12, marginTop: 32 } as any}>
        <Button
          label={t(`swap.rate-info-modal.buttons.cancel`)}
          size={"small"}
          variant={"danger"}
          onClick={handleClose}
        />

        <Button
          label={t(`swap.rate-info-modal.buttons.confirm`)}
          size={"small"}
          variant={"primary"}
          onClick={handleSubmit}
        />
      </View>
    </Modal>
  );
}

export const RateEditConfirmModal = ({ request, newRateValue, onClose, onSubmit }: RateEditModalProps) => {
  const { t } = useTranslation();
  const swap = request.swap;

  const { showAlert } = useAlert();

  const { mutate, isLoading } = useMutation({
    mutationFn: SwapService.update,
    onSuccess: () => {
      showAlert(t(`swap.actions.rate-edit.success`), "success");
      onSubmit();
    },
    onError: () => {
      showAlert(t(`swap.actions.rate-edit.error`), "danger");
    }
  })

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  }

  const handleSubmit = () => {
    if (isLoading) return;

    const rate = CurrencyUtil.getNumberByValue(newRateValue);

    mutate({
      id: request.id,
      rate: rate
    });
  }

  const originalRate = CurrencyUtil.getNumberByValue(swap.rate.amount);
  const newRate = CurrencyUtil.getNumberByValue(newRateValue);

  const rateDiff = Math.abs(originalRate - newRate);

  const rateDiffPercentage = (newRate * 100 / originalRate) - 100;

  return (
    <Modal
      visible={true}
      onClose={handleClose}
      icon={"interrogation"}
      header={"clean"}
      title={t(`swap.rate-info-modal.title.edit`)}
      fullHeightModal={true}
      isLoading={isLoading}
    >
      <View style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <ConfirmModalCommonInfos request={request} />

        <View style={{ borderBottomWidth: 1, borderColor: "#8484844D" }} />

        <ConfirmModalInfoRow label={t(`swap.rate-info-modal.infos.original-rate`)} value={CurrencyUtil.formatCurrency(originalRate, TCurrencySymbol.USDT, false, 4, 4) + " " + swap.currencyIn.currency} />

        <ConfirmModalInfoRow label={t(`swap.rate-info-modal.infos.new-rate`)} value={CurrencyUtil.formatCurrency(Number(newRate), TCurrencySymbol.USDT, false, 4, 4) + " " + swap.currencyIn.currency} variant={"danger"} />

        <ConfirmModalInfoRow label={t(`swap.rate-info-modal.infos.diff-rate`)} value={CurrencyUtil.formatCurrency(rateDiff, TCurrencySymbol.USDT, false, 4, 4) + " " + swap.currencyIn.currency + " (" + rateDiffPercentage.toFixed(2) + "%)"} variant={"danger"} />
      </View>

      <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 12, marginTop: 32 } as any}>
        <Button
          label={t(`swap.rate-info-modal.buttons.cancel`)}
          size={"small"}
          variant={"danger"}
          onClick={handleClose}
        />

        <Button
          label={t(`swap.rate-info-modal.buttons.confirm`)}
          size={"small"}
          variant={"primary"}
          onClick={handleSubmit}
        />
      </View>
    </Modal>
  );
}
