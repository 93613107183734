import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import LivenessCheckScreen from "../../pages/blank-page";
import ReadinessCheckScreen from "../../pages/blank-page";
import SignInScreen from "../../pages/sign-in";
import ForgotPasswordScreen from "../../pages/sign-in/forgot-password";
import CalcScreen from '../../pages/calc';

const NotLoggedStack = createNativeStackNavigator();

export interface NotLoggedParamList {
  signIn: {},
  forgotPass: {
    authcode?: string
  },
}

const NotLoggedRoutes: React.FC = () => {
  return (
		<NotLoggedStack.Navigator
			screenOptions={{ headerShown: false }}
			initialRouteName={"signIn"}
		>
			<NotLoggedStack.Screen name="signIn" component={SignInScreen} />

			<NotLoggedStack.Screen name="calc" component={CalcScreen} />

			<NotLoggedStack.Screen
				name="livenessCheck"
				component={LivenessCheckScreen}
			/>

			<NotLoggedStack.Screen
				name="readinessCheck"
				component={ReadinessCheckScreen}
			/>

			<NotLoggedStack.Screen name="warmUp" component={ReadinessCheckScreen} />

			<NotLoggedStack.Screen
				name="forgotPass"
				component={ForgotPasswordScreen}
			/>
		</NotLoggedStack.Navigator>
	);
};

export default NotLoggedRoutes;
