import React, {
	forwardRef,
	ReactElement,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { LayoutConfig } from "../../global-constants/Layout";
import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";
import { useAuth } from "../../services/auth";

import { APP_PERMISSIONS } from "../../permissions/permissions";
import {
	MenuEntryModel,
	SubmenuEntryModel,
} from "hubchain-storybook-design-pattern/lib/components/SideMenu/types";

import { SideMenu, Icon, View } from "hubchain-storybook-design-pattern";
import { useMultiTenancy } from "../../services/multi-tenancy/multi-tenancy";

interface SidebarProps {
	children: ReactElement;
	hide?: boolean;
	[key: string]: any;
}

const iconStyle = {
	fontSize: "20px",
};

const icons = {
	home: <Icon name={"House"} fontSize={iconStyle.fontSize} variant={"dark"} />,
	sac: <Icon name={"Headset"} fontSize={iconStyle.fontSize} variant={"dark"} />,
	reports: (
		<Icon
			name={"ClipboardData"}
			fontSize={iconStyle.fontSize}
			variant={"dark"}
		/>
	),
	admins: (
		<Icon name={"Shield"} fontSize={iconStyle.fontSize} variant={"dark"} />
	),
	lock: <Icon name={"Person"} fontSize={iconStyle.fontSize} variant={"dark"} />,
	approval: (
		<Icon name={"CheckCircle"} fontSize={iconStyle.fontSize} variant={"dark"} />
	),
	permission: (
		<Icon name={"Key"} fontSize={iconStyle.fontSize} variant={"dark"} />
	),
	market: (
		<Icon name={"Repeat"} fontSize={iconStyle.fontSize} variant={"dark"} />
	),
	paymentGateway: (
		<Icon name={"Cart3"} fontSize={iconStyle.fontSize} variant={"dark"} />
	),
	exit: <Icon name={"Power"} fontSize={iconStyle.fontSize} variant={"dark"} />,
	orders: (
		<Icon name={"ListUl"} fontSize={iconStyle.fontSize} variant={"dark"} />
	),
	settings: (
		<Icon name={"Gear"} fontSize={iconStyle.fontSize} variant={"dark"} />
	),
};

export const Sidebar = forwardRef((props: SidebarProps, ref) => {
	const { user } = useAuth();
	const { tenant } = useMultiTenancy();
	const { t } = useTranslation();

	const name = props.route?.name;

	const [isOpen, setIsOpen] = useState(true);
	const [isShowingMenuReduced, setIsShowingMenuReduced] = useState(false);

	const toggleMenuReduced = () => {
		setIsShowingMenuReduced(!isShowingMenuReduced);
	};

	const toggleSidenav = () => {
		setIsOpen((state) => !state);
	};

	const getApprovalRoute = () => {
		if (!user) return "";

		let route = "approvedUsers";

		if (
			user.isActionAllowed(
				APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_USER_DOC
			) &&
			tenant.routes["approved-users"]
		) {
			route = "approvedUsers";
		} else if (
			user.isActionAllowed(
				APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT
			) &&
			tenant.routes["approved-withdrawals"]
		) {
			route = "approvedWithdrawals";
		} else if (
			user.isActionAllowed(
				APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT
			) &&
			tenant.routes["approved-deposits"]
		) {
			route = "approvedDeposits";
		}

		return route;
	};

	const getReportsRoute = () => {
		if (!user) return "";

		let route = "userRegistrationReport";

		if (
			user.isActionAllowed(APP_PERMISSIONS.reports.USER_REGISTRATION) &&
			tenant.routes["reports-user-registration"]
		) {
			route = "userRegistrationReport";
		} else if (
			user.isActionAllowed(APP_PERMISSIONS.reports.BANK_ACCOUNTS) &&
			tenant.routes["reports-account-banks"]
		) {
			route = "bankAccountReport";
		} else if (
			user.isActionAllowed(
				APP_PERMISSIONS.reports.FINANCIAL_INPUT_OUTPUT_VALUES
			) &&
			tenant.routes["reports-input-of-values"]
		) {
			route = "inputOfValues";
		} else if (
			user.isActionAllowed(
				APP_PERMISSIONS.reports.FINANCIAL_INPUT_OUTPUT_VALUES
			) &&
			tenant.routes["reports-output-of-values"]
		) {
			route = "outputOfValues";
		} else if (
			user.isActionAllowed(APP_PERMISSIONS.reports.FINANCIAL_TRANSACTIONS) &&
			tenant.routes["reports-financial-transactions"]
		) {
			route = "financialTransactions";
		} else if (
			user.isActionAllowed(
				APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT
			) &&
			tenant.routes["reports-deposits-crypto"]
		) {
			route = "depositsCrypto";
		}

		return route;
	};

	const [entries, setEntries] = useState<MenuEntryModel[]>();

	useEffect(() => {
		setEntries([
			{
				title: t("sidebar.menu.dashboard"),
				link: "dashboard",
				icon: icons.home,
				visible: tenant.routes.dashboard,
			},
			{
				title: t("sidebar.menu.users.title"),
				link: "users",
				icon: icons.lock,
				visible:
					(tenant.routes["users-approval"] ||
						tenant.routes["blocked-admins"]) &&
					![
						APP_PERMISSIONS.user.USER_BLOCK_UNLOCK,
						APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_USER_DOC,
					].every((permission) => user?.isActionAllowed(permission) === false),
				submenu: [
					{
						title: t("sidebar.menu.users.accounts"),
						link: "usersAccounts",
						visible:
							tenant.routes["users-accounts"] &&
							!!user?.isActionAllowed([APP_PERMISSIONS.user.USER_BLOCK_UNLOCK]),
					},
					{
						title: t("sidebar.menu.users.approval"),
						link: "usersApproval",
						visible:
							tenant.routes["users-approval"] &&
							!!user?.isActionAllowed([
								APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_USER_DOC,
							]) &&
							!!user.isHubchainUser,
					},
					{
						title: "",
						link: "userAccount",
						visible: false,
					},
				],
			},
			{
				title: t("sidebar.menu.paymentGateway.title"),
				link: "payment-gateway-crypto",
				icon: icons.paymentGateway,
				visible:
					tenant.routes["trade"] &&
					!!user?.isActionAllowed(APP_PERMISSIONS.trade.TRADE_MAKE),
				submenu: [
					{
						title: t("sidebar.menu.paymentGateway.paymentGatewayCrypto"),
						link: "payment-gateway-crypto",
						visible: tenant.routes["payment-gateway-crypto"],
					},
				],
			},
			{
				title: t("sidebar.menu.tradeManagement.title"),
				link: "trade-buy",
				icon: icons.market,
				visible:
					tenant.routes["trade"] &&
					!!user?.isActionAllowed(APP_PERMISSIONS.trade.TRADE_MAKE),
				submenu: [
					{
						title: t("sidebar.menu.tradeManagement.tradeSwap"),
						link: "trade-swap",
						visible: tenant.routes["trade-swap"],
					},
					{
						title: t("sidebar.menu.tradeManagement.tradeBuy"),
						link: "trade-buy",
						visible:
							tenant.routes["trade-buy"] &&
							!!user?.isActionAllowed(APP_PERMISSIONS.trade.TRADE_MAKE) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.tradeManagement.tradeSell"),
						link: "trade-sell",
						visible:
							tenant.routes["trade-sell"] &&
							!!user?.isActionAllowed(APP_PERMISSIONS.trade.TRADE_MAKE) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.ordersManagement.approvedOrderBuy"),
						link: "approvedOrderBuy",
						visible:
							tenant.routes["trade-buy"] &&
							!!user?.isActionAllowed(APP_PERMISSIONS.trade.TRADE_MAKE) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.ordersManagement.approvedOrderSell"),
						link: "approvedOrderSell",
						visible:
							tenant.routes["trade-sell"] &&
							!!user?.isActionAllowed(APP_PERMISSIONS.trade.TRADE_MAKE) &&
							!!user.isHubchainUser,
					},
				],
			},
			{
				title: t("sidebar.menu.approvalManagement.title"),
				link: getApprovalRoute(),
				icon: icons.approval,
				visible:
					![
						APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_USER_DOC,
						APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT,
					].every(
						(permission) => user?.isActionAllowed(permission) === false
					) && !!user?.isHubchainUser,
				submenu: [
					{
						title: t("sidebar.menu.approvalManagement.approvedUsers"),
						link: "approvedUsers",
						visible:
							tenant.routes["approved-users"] &&
							!!user?.isActionAllowed([
								APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_USER_DOC,
							]) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.approvalManagement.approvedWithdrawal"),
						link: "approvedWithdrawals",
						visible:
							tenant.routes["approved-withdrawals"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT
							),
					},
					{
						title: t("sidebar.menu.approvalManagement.approvedDeposits"),
						link: "approvedDeposits",
						visible:
							tenant.routes["approved-deposits"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT
							),
					},
					{
						title: t(
							"sidebar.menu.approvalManagement.approvedWithdrawalCrypto"
						),
						link: "approvedWithdrawalsCrypto",
						visible:
							tenant.routes["approved-withdrawals-crypto"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT
							) &&
							!!user.isHubchainUser,
					},
				],
			},
			{
				title: t("sidebar.menu.reports.title"),
				link: getReportsRoute(),
				icon: icons.reports,
				visible:
					tenant.routes["reports"] &&
					![
						...Object.values(APP_PERMISSIONS.reports),
						APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT,
					].every((permission) => user?.isActionAllowed(permission) === false),
				submenu: [
					{
						title: t("sidebar.menu.reports.userRegistration"),
						link: "userRegistrationReport",
						visible:
							tenant.routes["reports-user-registration"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.reports.USER_REGISTRATION
							) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.reports.accountBanks"),
						link: "bankAccountReport",
						visible:
							tenant.routes["reports-account-banks"] &&
							!!user?.isActionAllowed(APP_PERMISSIONS.reports.BANK_ACCOUNTS) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.reports.inputOfValues"),
						link: "inputOfValues",
						visible:
							tenant.routes["reports-input-of-values"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.reports.FINANCIAL_INPUT_OUTPUT_VALUES
							),
					},
					{
						title: t("sidebar.menu.reports.outputOfValues"),
						link: "outputOfValues",
						visible:
							tenant.routes["reports-output-of-values"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.reports.FINANCIAL_INPUT_OUTPUT_VALUES
							),
					},
					{
						title: t("sidebar.menu.reports.financialTransactions"),
						link: "financialTransactions",
						visible:
							tenant.routes["reports-financial-transactions"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.reports.FINANCIAL_TRANSACTIONS
							) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.approvalManagement.approvedDepositsCrypto"),
						link: "depositsCrypto",
						visible:
							tenant.routes["reports-deposits-crypto"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT
							) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.reports.withdrawalCommissions"),
						link: "withdrawalCommissions",
						visible:
							tenant.routes["reports-withdrawal-commissions"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.reports.FINANCIAL_COMMISSION
							) &&
							!!user.isHubchainUser,
					},
					{
						title: t("sidebar.menu.reports.depositCommissions"),
						link: "depositCommissions",
						visible:
							tenant.routes["reports-deposit-commissions"] &&
							!!user?.isActionAllowed(
								APP_PERMISSIONS.reports.FINANCIAL_COMMISSION
							) &&
							!!user.isHubchainUser,
					},
				],
			},
			{
				title: t("sidebar.menu.sac"),
				link: "sac",
				icon: icons.sac,
				visible: tenant.routes["sac"],
			},
			{
				title: t("sidebar.menu.administrators"),
				link: "administrators",
				icon: icons.admins,
				visible:
					tenant.routes["administrators"] &&
					!!user?.isActionAllowed(APP_PERMISSIONS.admin.ADMIN_LIST),
			},
			{
				title: t("sidebar.menu.permissionManagement.title"),
				link: "permissions",
				icon: icons.permission,
				visible:
					tenant.routes["permission-management"] &&
					!!user?.isActionAllowed(APP_PERMISSIONS.role.ROLE_LIST) &&
					!!user.isHubchainUser,
			},
			{
				title: t("sidebar.menu.settings.title"),
				link: "settings",
				icon: icons.settings,
				visible:
					tenant.routes["settings"] &&
					!!user?.isActionAllowed(APP_PERMISSIONS.role.ROLE_LIST) &&
					!!user?.isHubchainUser,
				submenu: [
					{
						title: t("sidebar.menu.settings.general"),
						link: "settings",
						visible: tenant.routes["settings"] && !!user?.isHubchainUser,
					},
					{
						title: t("sidebar.menu.settings.icons"),
						link: "settingsIcons",
						visible: tenant.routes["settings-icons"] && !!user?.isHubchainUser,
					},
					{
						title: t("sidebar.menu.settings.currencies"),
						link: "settingsCurrencies",
						visible:
							tenant.routes["settings-currencies"] && !!user?.isHubchainUser,
					},
					{
						title: t("sidebar.menu.settings.markets"),
						link: "settingsMarkets",
						visible:
							tenant.routes["settings-markets"] && !!user?.isHubchainUser,
					},
					{
						title: t("sidebar.menu.settings.ticker"),
						link: "settingsTicker",
						visible: tenant.routes["settings-ticker"] && !!user?.isHubchainUser,
					},
					{
						title: t("sidebar.menu.settings.webhooks"),
						link: "settingsWebhooks",
						visible:
							tenant.routes["settings-webhooks"] && !!user?.isHubchainUser,
					},
				],
			},
		]);
	}, []);

	const handleNavigate = (entry: MenuEntryModel | SubmenuEntryModel) => {
		const { params, link } = entry;

		if (link.includes("OfValues") || props.route.name.includes("OfValues")) {
			props.navigation.replace(link, params);
		} else {
			props.navigation.navigate(link, params);
		}
	};

	useImperativeHandle(ref, () => ({
		toggleSidenav: () => toggleSidenav(),
	}));

	if (props.hide) {
		return (
			<View
				style={{
					justifyContent: "center",
					height: "100%",
					border: 1,
					flex: 1,
				}}
			>
				{props.children}
			</View>
		);
	}

	return (
		<>
			<SideMenu
				menuEntries={entries}
				isOpen={isOpen}
				hasMenuReduced={!isMobile}
				isShowingMenuReduced={isShowingMenuReduced}
				onClickMenuReduced={toggleMenuReduced}
				headerUserLabel={user ? LayoutConfig.info.Text : " "}
				pageContent={props.children}
				menuTopOffset={
					isMobile
						? LayoutConfig.header.size.mobile
						: LayoutConfig.header.size.desktop
				}
				menuEntryOnClick={handleNavigate}
				activeRouteName={name}
				onClickOutside={() => toggleSidenav()}
				position={isMobile ? "float-left" : "fixed-left"}
				hasLogout={false}
			/>
		</>
	);
});
