import React, {createContext, ReactNode, useContext, useState} from "react";
import {Variants} from "hubchain-storybook-design-pattern/src/components/Alert/types";
import {useTranslation} from "react-i18next";
import {Alert, Tooltip, View} from "hubchain-storybook-design-pattern";

type AlertTypes = Variants;

interface AlertContextData {
    showAlert: (
        message: string | React.ReactNode,
        type?: AlertTypes,
        title?: string,
        duration?: number,
        position?: AlertPosition
        ) => void
}

type AlertPosition = "topRight" | "topLeft" | "topMiddle" | "middle" | "bottomLeft" | "bottomMiddle" | "bottomRight";

interface AlertConfigProps {
    visible: boolean,
    title: string,
    message: string | React.ReactNode,
    type: AlertTypes,
    duration: number,
    position: AlertPosition
}

const AlertContext = createContext<AlertContextData>({} as AlertContextData);

const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const {t} = useTranslation();

    const [alertConfig, setAlertConfig] = useState<AlertConfigProps>({
        visible: false,
        title: t(`snackBar.title.warning`),
        message: "",
        duration: 5000,
        type: "success",
        position: "topRight"
    });

    const showAlert = (
        message: string | React.ReactNode,
        type: AlertTypes = "danger",
        title?: string,
        duration?: number,
        position?: AlertPosition,
        ) => {
        setAlertConfig(state => ({
            ...state,
            visible: true,
            message,
            title: title || t(`snackBar.title.warning`),
            type,
            duration,
            position: position || "topRight"
        }))
    }

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {
                alertConfig.visible &&
                    <Alert
                    position={alertConfig.position}
                    title={alertConfig.title}
                    message={alertConfig.message}
                    size={"medium"}
                    variant={alertConfig.type as any}
                    visible={alertConfig.visible}
                    setVisible={(value) => setAlertConfig(state => ({ ...state, visible: value }))}
                    duration={alertConfig.duration ?? 5000}
                    padding={70}/>
                }
                { children }
        </AlertContext.Provider>
    );
}

const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider.');
    }
    return context;
}

export { AlertProvider, useAlert };
