import DetailRowTransferModal from "./DetailRowTransferModal";
import { Icon, Text, View } from "hubchain-storybook-design-pattern";
import SwapModalTitle from "./TitleTransferModal";
import { useTranslation } from "react-i18next";
import styles from "../styles";
import React from "react";
import Util from "../../../utils";
import CurrencyUtil from "../../../utils/CurrencyUtil";
import { TouchableOpacity } from "react-native";
import { setStringAsync } from "expo-clipboard";

const DetailsCardTransferModal = ({ title, cryptohubData, userData }) => {
	const { t } = useTranslation();

	// if (cryptohubData?.accountType === "e-wallet") {
	// 	// console.log("userData", userData);

	// 	// console.log("cryptohubData?.balances", cryptohubData);
	// }

	return (
		<View style={[styles.card, styles.detailsCard, { flex: "flex" }]}>
			<SwapModalTitle title={title} />

			{cryptohubData?.accountType === "baas" && (
				<View style={[styles.detailsContent]}>
					<DetailRowTransferModal
						label={t("transfer-modal.details.description")}
						value={cryptohubData?.label}
					/>

					<DetailRowTransferModal
						label={
							t("transfer-modal.details.name") +
							" / " +
							t("transfer-modal.details.companyId") +
							":"
						}
						value={`${
							cryptohubData?.details?.pix?.BRL[0]?.name
						} (${Util.maskCpfOrCnpj(
							cryptohubData?.details?.pix?.BRL[0]?.taxId
						)})`}
					/>

					{cryptohubData?.details?.internal?.BRL[0] && (
						<DetailRowTransferModal
							label={t("transfer-modal.details.deposit-bank-account") + ":"}
							value={`Banco: ${cryptohubData?.details?.internal?.BRL[0]?.bank?.code} | Ag.: ${cryptohubData?.details?.internal?.BRL[0]?.bank?.branch} | Conta: ${cryptohubData?.details?.internal?.BRL[0]?.bank?.account}`}
						/>
					)}

					<DetailRowTransferModal
						label={t("transfer-modal.details.deposit-pix-key") + ":"}
						value={cryptohubData?.details?.pix?.BRL[0]?.pix?.key}
					/>
				</View>
			)}

			{cryptohubData?.accountType === "exchange" && (
				<View style={{ ...styles.detailsContent, gap: 12, minHeight: "170px" }}>
					<DetailRowTransferModal
						label={
							t("transfer-modal.details.name") +
							" / " +
							t("transfer-modal.details.description") +
							":"
						}
						value={
							cryptohubData?.label +
							(cryptohubData?.details?.accountName
								? " - " + cryptohubData?.details?.accountName
								: "")
						}
					/>

					<View
						style={{
							gap: 8,
						}}
					>
						<Text variant={"gray"} fontStyle={"bold"}>
							Saldo Disponível:
						</Text>
						<View
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 8,
								paddingLeft: 8,
							}}
						>
							{["BRL", "USDT", "USDC", "BTC", "ETH"].map((currency: any) => {
								return (
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											gap: 4,
										}}
									>
										<Text fontStyle={"bold"}>{currency}</Text>
										{currency && (
											<Text size={"12px"}>
												{cryptohubData?.balances[currency] &&
													Util.formatCurrency(
														cryptohubData?.balances[currency]?.available,
														currency
													)}
											</Text>
										)}
									</View>
								);
							})}
						</View>
					</View>

					<View
						style={{
							gap: 8,
						}}
					>
						<Text variant={"gray"} fontStyle={"bold"}>
							Endereço das Carteiras para Depósito:
						</Text>
						<View
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 8,
								paddingLeft: 8,
								justifyContent: "space-between",
							}}
						>
							{["USDT", "USDC", "BTC", "ETH"].map((currency: any) => {
								const address = cryptohubData.details.internal[currency]
									? cryptohubData.details.internal[currency][0].networks[0]
											.address
									: "";

								return (
									<View
										style={{
											display: "flex",
											flexDirection: "row",
											gap: 4,
											justifyContent: "space-between",
										}}
									>
										<Text fontStyle={"bold"}>{currency}</Text>

										<Text size={"12px"}>
											{address.substring(0, 16) + "..." + address.slice(-16)}
										</Text>
										<a
											data-tooltip-id={"tooltip-on-click"}
											data-tooltip-content={t(`general.tooltip.copied`)}
											data-tooltip-variant="success"
											style={{
												zIndex: 100,
											}}
										>
											<TouchableOpacity
												style={{ marginLeft: 4 }}
												onPress={() => {
													setStringAsync(address);
												}}
											>
												<Icon name={"CopyIcon"} fontSize={"12px"} />
											</TouchableOpacity>
										</a>
									</View>
								);
							})}
						</View>
					</View>
				</View>
			)}

			{cryptohubData?.accountType === "e-wallet" && (
				<View style={{ ...styles.detailsContent, minHeight: "120px" }}>
					<DetailRowTransferModal
						label={
							t("transfer-modal.details.name") +
							" / " +
							t("transfer-modal.details.email")
						}
						value={[
							userData?.user?.personLegalName
								? userData?.user?.personLegalName
								: "",
							`${userData?.user?.email ? userData?.user?.email : "-"}`,
						].join(" ")}
					/>

					<View
						style={{
							gap: 8,
						}}
					>
						<Text variant={"gray"} fontStyle={"bold"}>
							Saldo Disponível:
						</Text>
						<View
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 8,
								paddingLeft: 8,
							}}
						>
							{userData
								? ["BRL", "USDT", "USDC", "BTC", "ETH"].map((currency: any) => {
										return (
											cryptohubData?.balances?.[currency]?.available && (
												<View
													style={{
														display: "flex",
														flexDirection: "row",
														gap: 4,
													}}
												>
													<Text fontStyle={"bold"}>{currency}</Text>
													<Text size={"12px"}>
														{Util.formatCurrency(
															cryptohubData.balances[currency].available,
															currency
														)}
													</Text>
												</View>
											)
										);
								  })
								: "-"}
						</View>
					</View>
				</View>
			)}
		</View>
	);
};

export default DetailsCardTransferModal;
