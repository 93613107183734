import { CurrencyModel } from "../../models/CurrencyModel";
import { TCurrencySymbol } from "../../types/currency";
import { DefaultFilter } from "../../models/DefaultFilter";
import Util from "../../utils";
import API, { corePrefix } from "../api";
import { AxiosResponse } from "axios";
import { RequestStatusModel, RequestTypeModel } from "../../models/RequestModel";
import { isYesOrNoEnum } from "src/types/types";
import { QuoteGetResponse, SwapModel } from "../swap/swapService";
import CurrencyUtil from "../../utils/CurrencyUtil";
import { NetworkNameId, smartContractERC20ImplementationType } from "src/types/network";
// import { NetworkNameId } from "src/types/network"; // <==  Não usar, pois não build depois!

export interface InvoiceModel {
  id: string,
  code: string,
  createdAt: string,
  updatedAt: string,
  expiredAt: string,
  status: string,
  isPaid: isYesOrNoEnum,
  paidAt: string,
  amount: string,
  currency: TCurrencySymbol,
  externalId: string,
  memo: string,
  fields: RequestInvoiceAdditionalFieldItem[],
  allowedPaymentMethods: IInvoiceAllowedPaymentMethod,
  paymentUrl: string,
  payments: {
    status: string,
    totalAmount: string,
    receivedAmount: string,
    pendingAmount: string,
    currency: string,
    amountPrice: string,
    currencyPrice: string,
    autoConvert: {
      enabled: isYesOrNoEnum,
      status: string,
      netAmountOut: string,
      netCurrencyOut: string,
    },
    transactions: {
      id: string,
      isPaid: isYesOrNoEnum,
      paidAt: string,
      createdAt: string,
      updatedAt: string,
      autoConvert: {
        enabled: isYesOrNoEnum.YES,
        status: string,
        netAmountOut: string,
        netCurrencyOut: string,
      },
      address: string,
      totalAmount: string,
      receivedAmount: string,
      pendingAmount: string,
      amount?: string,
      currency: string,
      fees: {
        service: {
          amount: string,
          currency: TCurrencySymbol,
        },
        networkIn: {
          amount: string,
          currency: TCurrencySymbol,
        }
      },
      block: {
        height: number,
        hash: string,
        confirmations: number,
        minConfirmations: number,
      },
      network: string,
      transactionId: string,
      transactionIdExplorerUrl: string,
    }[]
  }[],
  paymentMethods: {
    name: string,
    currency: string,
    networks: {
      enabled?: isYesOrNoEnum,
      address: string,
      addressExplorerUrl: string,
      amount: string,
      contactAddress?: string,
      contactType?: smartContractERC20ImplementationType,
      decimals: number,
      minConfirmations: number,
      network: {
        nameId: NetworkNameId,
        chainId?: number,
        name: string,
        currencySymbol?: TCurrencySymbol,
      },
      fees: {
        service: {
          amount: string,
          currency: string
        },
        networkIn: {
          amount: string,
          currency: string
        }
      },
    }[]
  }[],
  user: {
    id: string,
    name: string,
    email: string,
    companyLegalName: string,
    personLegalName: string,
  },
  createdBy: {
    id: string,
    name: string,
    email: string,
  },
  updatedBy: {
    id: string,
    name: string,
    email: string,
  },
}

export interface RequestInvoiceModel {
  id: string,
  swap: SwapModel
  invoice: InvoiceModel
}

interface RequestInvoiceListResponse {
  data: {
    rows: RequestInvoiceModel[],
    count: number,
    page: number,
    limit: number
  },
  status: string
}

export interface RequestInvoiceAdditionalFieldItem extends InvoiceAdditionalFieldItem {
  name: string,
}
export interface InvoiceAdditionalFieldItem {
  value: string,
  isRequired: isYesOrNoEnum
  isReadonly: isYesOrNoEnum,
  isVisible: isYesOrNoEnum,
}

export type IInvoiceAllowedPaymentMethod = ['*'] | IInvoiceAllowedPaymentMethodItemByCurrencyAndNetworks[] | TCurrencySymbol[];
// ex.:
//     (1) => All Cryptocurrencies Available
//              ['*']
//     (2) => Allowed Currencies and their respective available networks
//              ['BTC', 'UST']
//     (3) => Allowed Currencies and their respective allowed network
//              [{ currency: 'BTC', networks: ['btc-mainnet'] }, { currency: 'USDT', networks: ['trx-mainnet'] }]
// PS.: [{ currency: 'BTC', networks: ['*'] }]  means the same that ['BTC']


export interface IInvoiceAllowedPaymentMethodItemByCurrencyAndNetworks {
  currency: TCurrencySymbol,  // ex.: BTC, USDT
  networks: ['*'] | string[]  // ex.: ['*] or ['btc-mainnet'] or ['eth-mainnet', 'trx-mainnet']
}

interface InvoiceCreateRequest {
  amount?: number,
  currency: TCurrencySymbol,
  allowedPaymentMethods: any[],
  user: string,
  memo?: string,
  externalId?: string,
  fields: any
  // payerName?: InvoiceAdditionalField,
  // payerPhone?: InvoiceAdditionalField,
  // payerEmail?: InvoiceAdditionalField,
  // payerPersonCompanyId?: InvoiceAdditionalField,
}

interface InvoiceUpdateRequest extends InvoiceCreateRequest {
  id: string,
}

interface QuoteInvoiceData {
  amountIn: number,
  currencyIn: TCurrencySymbol,
  networkIn: string,
  user: string
}

export interface IQuoteInvoice {
  amountIn: number,
  currencyIn: TCurrencySymbol,
  netAmountOut: number,
  currencyOut: TCurrencySymbol,
  amountInMin: number,
  amountInMax: number,
  netAmountOutMin: number,
  netAmountOutMax: number,
}

export default class InvoiceService {

  static async list(filter?: DefaultFilter, options: { invoicesOnly: boolean } = { invoicesOnly: false }): Promise<RequestInvoiceListResponse> {
    const search = Util.defineSearch(filter);
    // const { data }: AxiosResponse<RequestSwapListResponse> = await API.get(`${corePrefix}/reports/requests/all?page=1&limit=10&search=[{"requestType":[{"id":[200, 201, 202, 203, 204, 205, 206, 207]}]},"user":[{"id":["50c55509-f20a-460d-a815-fd48bca7109a"]}]]&orderBy=[{"createdAt":"DESC"}]`)
    // const { data }: AxiosResponse<RequestSwapListResponse> = await API.get(`${corePrefix}/reports/requests/all?page=1&limit=10&search=[{"requestType":[{"id":[200, 201, 202, 203, 204, 205, 206, 207]}]}]&orderBy=[{"createdAt":"DESC"}]`)

    const { data }: AxiosResponse<RequestInvoiceListResponse> = await API.get(`${corePrefix}/reports/requests/${options.invoicesOnly === true ? 'invoices' : 'swaps'}?page=${filter.page || 1}&limit=${filter.limit || 100}&search=${search}`)

    return data;
  }

  static async create(data: InvoiceCreateRequest) {
    return API.post(`${corePrefix}/admin/requests/invoice`, data);
  }

  static async cancel(id: string | number) {
    return API.put(`${corePrefix}/admin/requests/invoice/${id}/cancel`);
  }

  static async update(data: InvoiceUpdateRequest) {
    return API.put(`${corePrefix}/admin/requests/invoice/${data.id}`, data);
  }

  static async quoteInvoice({ amountIn, currencyIn, networkIn, user: userId }: QuoteInvoiceData): Promise<IQuoteInvoice> {
    if (!amountIn || amountIn <= 0) {
      return;
    }

    const { data: { data: { quote } } }: AxiosResponse<QuoteGetResponse> = await API.get(`${corePrefix}/requests/swap/quote?amountIn=${amountIn}&currencyIn=${currencyIn}&currencyOut=${TCurrencySymbol.BRL}&networkIn=${networkIn || 'eth-mainnet' /* NetworkNameId.ETH_MAINNET */}${(userId || '') === '' ? '' : ('&user=' + userId)}`);

    return {
      amountIn: amountIn,
      currencyIn: currencyIn,
      netAmountOut: CurrencyUtil.getNumberByValue(quote.netAmountOut),
      currencyOut: TCurrencySymbol.BRL,
      amountInMin: CurrencyUtil.getNumberByValue(quote.amountInMin),
      amountInMax: CurrencyUtil.getNumberByValue(quote.amountInMax),
      netAmountOutMin: CurrencyUtil.getNumberByValue(quote.netAmountOutMin),
      netAmountOutMax: CurrencyUtil.getNumberByValue(quote.netAmountOutMax),
    }
  }
}
