import { View, Text, Icon } from "hubchain-storybook-design-pattern";
import SwapModalTitle from "./TitleTransferModal";
import { useTranslation } from "react-i18next";
import styles from "../styles";
import React, { useState } from "react";
import { CustomHoverableText } from "../../../components/LinkTouchableOpacity";
import { TouchableOpacity } from "react-native";
import { setStringAsync } from "expo-clipboard";
import CurrencyUtil from "../../../utils/CurrencyUtil";
import Util from "../../../utils";
import { TCurrencySymbol } from "../../../types/currency";
import { useMultiTenancy } from "../../../../src/services/multi-tenancy/multi-tenancy";
import { TenantAppDomainEnum } from "../../../../src/services/multi-tenancy/types";
import { useAuth } from "../../../../src/services/auth";

const TransferHistoryCard = ({ title }) => {
	const { t } = useTranslation();
	const { tenant } = useMultiTenancy();
	const { user } = useAuth();

	const [transferHistory, setTransferHistory] = useState(
		!!user.isHubchainUser
			? [
					{
						from: {
							serviceName: "exchange",
							accountUniqueId: "hubchain",
							address: {
								enviromentStack: "production",
								userId: "8618036655070289",
								accountUniqueId: "hubchain",
								accountName: "Hubchain CryptoHub",
								accountEmail: "admin@hubchain.com",
								accountDescription: "Mesa OTC",
							},
							network: "pix-mainnet",
						},
						to: {
							serviceName: "ezzebank",
							accountUniqueId: "ezzepay",
							address: [TenantAppDomainEnum.EZZEPAY].includes(
								tenant.name as TenantAppDomainEnum
							)
								? {
										type: "pix",
										name: "XXXPAY S PAGAMENTO LTDA",
										taxId: "11.111.11/0001-11",
										pix: {
											type: "CHAVE_ALEATORIA",
											key: "eca81a71-10b8-4992-985a-b76b110a1cbd",
											transactionId: "F301A4AA14E20A7B",
											description: "via CryptoHub",
										},
								  }
								: [TenantAppDomainEnum.BANKEI].includes(
										tenant.name as TenantAppDomainEnum
								  )
								? {
										type: "pix",
										name: "BANKEI S TECNOLOGIA LTDA",
										taxId: "24.454.190/0001-85",
										pix: {
											type: "CHAVE_ALEATORIA",
											key: "3df7d552-f2fb-4dbe-9433-84d8804fe62f",
											transactionId: "0000000000000000",
											description: "via CryptoHub",
										},
								  }
								: {
										type: "pix",
										name: "HUBCHAIN TECNOLOGIA LTDA",
										taxId: "28.643.027/0001-11",
										pix: {
											type: "EMAIL",
											key: "pix@hubchain.com",
											transactionId: "0000000000000000",
											description: "via CryptoHub",
										},
								  },
							network: "pix-mainnet",
						},
						amount: "150.00",
						currency: "BRL",
					},
					{
						from: {
							serviceName: "exchange",
							accountUniqueId: "hubchain",
							address: {
								enviromentStack: "production",
								userId: "8618036655070289",
								accountUniqueId: "hubchain",
								accountName: "Hubchain CryptoHub",
								accountEmail: "admin@hubchain.com",
								accountDescription: "Mesa OTC",
							},
							network: "internal-mainnet",
						},
						to: {
							serviceName: "cryptohub",
							accountUniqueId: "hubchain",
							address: {
								type: "internal",
								internal: {
									id: "ae14c228-9ada-44d4-a78c-f00baccbb7b2",
									email: "henrique.silva@hubchain.io",
								},
							},
							network: "internal-mainnet",
						},
						amount: "99.00",
						currency: "BRL",
					},
					{
						from: {
							serviceName: "ezzebank",
							accountUniqueId: "ezzepay",
							address: [TenantAppDomainEnum.EZZEPAY].includes(
								tenant.name as TenantAppDomainEnum
							)
								? {
										type: "pix",
										name: "XXXPAY S PAGAMENTO LTDA",
										taxId: "11.111.11/0001-11",
										pix: {
											type: "CHAVE_ALEATORIA",
											key: "eca81a71-10b8-4992-985a-b76b110a1cbd",
											transactionId: "F301A4AA14E20A7B",
											description: "via CryptoHub",
										},
								  }
								: [TenantAppDomainEnum.BANKEI].includes(
										tenant.name as TenantAppDomainEnum
								  )
								? {
										type: "pix",
										name: "BANKEI S TECNOLOGIA LTDA",
										taxId: "24.454.190/0001-85",
										pix: {
											type: "CHAVE_ALEATORIA",
											key: "3df7d552-f2fb-4dbe-9433-84d8804fe62f",
											transactionId: "0000000000000000",
											description: "via CryptoHub",
										},
								  }
								: {
										type: "pix",
										name: "HUBCHAIN TECNOLOGIA LTDA",
										taxId: "28.643.027/0001-11",
										pix: {
											type: "EMAIL",
											key: "pix@hubchain.com",
											transactionId: "0000000000000000",
											description: "via CryptoHub",
										},
								  },
							network: "pix-mainnet",
						},
						to: {
							address: "479.493.658-38",
						},
						amount: "1500.00",
						currency: "BRL",
					},
			  ]
			: []
	);

	return (
		<View style={[styles.card, styles.detailsCard, { flex: "flex" }]}>
			<SwapModalTitle title={title} />

			{transferHistory.length === 0 && (
				<View
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Text>Nenhum registro encontrado.</Text>
				</View>
			)}

			{transferHistory.map((transfer: any) => {
				// {transferHistory.map((transfer: ICryptoHubAccountTransfer) => {
				const fromData = transfer.from;

				const fromAddress = fromData?.address?.name
					? fromData?.address?.name
					: fromData?.address?.accountName
					? fromData?.address?.accountName
					: fromData?.address?.internal?.id
					? fromData?.address?.internal?.id
					: fromData?.address;

				const toData = transfer?.to;

				const toAddress = toData?.address?.name
					? toData?.address?.name
					: toData?.address?.accountName
					? toData?.address?.accountName
					: toData?.address?.internal?.id
					? toData?.address?.internal?.id
					: toData?.address;

				return (
					<View
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<View
							style={{
								display: "flex",
								flexDirection: "row",

								alignItems: "center",
							}}
						>
							<View
								style={{
									display: "flex",
									flexDirection: "row",

									flex: 1,
									justifyContent: "space-between",
									gap: "8px",
									alignItems: "center",
								}}
							>
								<View>
									<Text size={"12px"}>{fromAddress}</Text>
								</View>
								<View>
									<Icon name={"ArrowForwardIcon"} fontSize={16} />
								</View>
								<View>
									{Util.isUUID(toAddress) ? (
										<View
											style={{
												display: "flex",
												flexDirection: "row",
												gap: "4px",
											}}
										>
											<a
												data-tooltip-id="tooltip"
												data-tooltip-html={`<div><span>${toAddress}</span></div>`}
											>
												<TouchableOpacity onPress={() => {}}>
													<Text size={"12px"}>
														{toAddress.substring(0, 8) +
															"..." +
															toAddress.slice(-8)}
													</Text>
												</TouchableOpacity>
											</a>
											<a
												data-tooltip-id={"tooltip-on-click"}
												data-tooltip-content={t(`general.tooltip.copied`)}
												data-tooltip-variant="success"
												style={{
													zIndex: 100,
												}}
											>
												<TouchableOpacity
													style={{ marginLeft: 4 }}
													onPress={() => {
														setStringAsync(toAddress);
													}}
												>
													<Icon name={"CopyIcon"} fontSize={"12px"} />
												</TouchableOpacity>
											</a>
										</View>
									) : (
										<Text size={"12px"}>{toAddress}</Text>
									)}
								</View>
							</View>
						</View>
						<View
							style={{
								display: "flex",
							}}
						>
							<Text variant={"gray"} size={"12x"}>
								{CurrencyUtil.formatCurrency(
									transfer.amount,
									TCurrencySymbol?.[transfer.currency]
								)}
							</Text>
						</View>
					</View>
				);
			})}
		</View>
	);
};

export default TransferHistoryCard;
