import React, { useContext, useState } from "react";
import { Image, Pressable } from "react-native";
import {
	Checkbox,
	Icon,
	Input,
	Text,
	Toggle,
	TooltipConfig,
	View,
} from "hubchain-storybook-design-pattern";
import CurrencyUtil from "../../../utils/CurrencyUtil";
import { useTranslation } from "react-i18next";
import { InvoiceFormData, IPaymentModalCurrencyOption } from "../models";
import { PaymentModalContext } from "../PaymentModal";
import { useQuote } from "../../../hooks/useQuote";
import { FormikProps } from "formik";
import { CurrencyTypeEnum } from "../../../models/CurrencyModel";
import CurrencyIcon from "../../CurrencyIcon";

const CurrencyOption = ({
	currency,
	invoiceForm,
}: {
	currency: IPaymentModalCurrencyOption;
	invoiceForm: FormikProps<InvoiceFormData>;
}) => {
	const {
		toggleSelectedCurrency,
		selectedCurrencies,
		allowAllCurrencies,
		isReadOnly,
	} = useContext(PaymentModalContext);
	const { quotes } = useQuote();

	const quote =
		quotes[`${currency.currency}/${invoiceForm.values.currency.currency}`];

	const price = CurrencyUtil.getNumberByValue(quote?.price || "0");
	const amount = CurrencyUtil.getNumberByValue(invoiceForm.values.amount);

	const formCurrency = invoiceForm.values?.currency;

	const value =
		quote && price
			? amount / CurrencyUtil.getNumberByValue(quote?.price || "0")
			: 0;

	const checked =
		allowAllCurrencies ||
		selectedCurrencies.findIndex((item) => item.value === currency.currency) !==
		-1 ||
		selectedCurrencies.findIndex((item) => item.value === currency.value) !==
		-1;
	const disabled =
		allowAllCurrencies ||
		formCurrency?.type === CurrencyTypeEnum.CRYPTO ||
		isReadOnly;

	return (
		<Pressable
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
				backgroundColor: "#F3F6F9",
				paddingHorizontal: 16,
				height: 48,
				borderRadius: 10,
				gap: 8,
				opacity: disabled && !isReadOnly ? 0.6 : 1,
			}}
			disabled={disabled}
			onPress={() => {
				!disabled && toggleSelectedCurrency(currency);
			}}
		>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: 8,
				}}
			>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						width: 36,
						height: 36,
					}}
				>
					<CurrencyIcon
						name={currency.icon}
						style={{ width: 36, height: 36 }}
					/>

					{currency.icon !== currency.network.currencySymbol && (
						<View
							style={{
								position: "absolute",
								right: -4,
								bottom: -4,
								borderWidth: 1,
								borderRadius: 10,
								borderColor: "#FFF",
							}}
						>
							<CurrencyIcon
								name={currency.network.currencySymbol}
								style={{ width: 20, height: 20 }}
							/>
						</View>
					)}
				</View>

				<View style={{ display: "flex", flexDirection: "column" }}>
					<Text style={{ fontSize: "16px" }}>
						{currency.subLabel}{" "}
						{`${
							currency.network.contractType
								? "(" + currency.network.contractType + ")"
								: ""
						}`}
					</Text>
					<Text
						style={{
							fontSize: "12px",
							lineHeight: "12px",
							color: "#79747e",
						}}
					>
						{`${currency.currency} • via `}
						<Text
							style={{
								fontWeight: "500",
								color: "#000",
								fontSize: "12px",
								lineHeight: "12px",
							}}
						>{`${currency.network.description}`}</Text>
					</Text>
				</View>
			</View>

			<View
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: 8,
				}}
			>
				{formCurrency.type !== CurrencyTypeEnum.CRYPTO && (
					<>
						<Text size={"12px"} variant={"gray"} fontStyle={"bold"}>
							~{" "}
							{CurrencyUtil.formatCurrency(
								value,
								currency.currency,
								true,
								1,
								6
							)}
						</Text>
					</>
				)}
				<Toggle
					size={"small"}
					checked={checked}
					disabled={disabled}
					onChange={() => !disabled && toggleSelectedCurrency(currency)}
				/>
			</View>
		</Pressable>
	);
};

export const PaymentCurrencyList = ({
	currencies,
	invoiceForm,
}: {
	currencies: IPaymentModalCurrencyOption[];
	invoiceForm: FormikProps<InvoiceFormData>;
}) => {
	const { t } = useTranslation();
	const {
		selectedCurrencies,
		allowAllCurrencies,
		handleToggleAllowAllCurrencies,
		isReadOnly,
	} = useContext(PaymentModalContext);

	//currencies = [...currencies, ...currencies, ...currencies];//enable scroll for tests

	const [search, setSearch] = useState("");

	const currency = invoiceForm.values?.currency;
	const currencyIsCrypto = currency?.type === CurrencyTypeEnum.CRYPTO;

	const searchIsEnabled =
		!allowAllCurrencies && currencies.length > 4 && !currencyIsCrypto;

	currencies = currencies.filter((currency) =>
		(currency.label + " " + currency.subLabel)
			.toLowerCase()
			.includes(search.toLowerCase())
	); //search

	return (
		<>
			<View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						gap: 8,
						height: 32,
						borderBottomWidth: 1,
						borderColor: "#8484844D",
						paddingBottom: 0,
					}}
				>
					<Text
						variant={"primary"}
						fontStyle={"bold"}
						style={{ whiteSpace: "nowrap" }}
					>
						{t(`payment-gateway-crypto-modal.fields.currencies`) +
							` (${
								allowAllCurrencies
									? currencies.length
									: currencyIsCrypto
									? 1
									: selectedCurrencies.length
							}/${currencies.length})`}
					</Text>
					{searchIsEnabled && (
						<Input
							size={"small"}
							placeholder={t(
								`payment-gateway-crypto-modal.fields.currencies-search`
							)}
							value={search}
							onChange={setSearch}
							maxLength={48}
							height={"24px"}
							cleanMessageIcon={true}
						/>
					)}
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<Checkbox
							size={"small"}
							checked={allowAllCurrencies}
							readonly={isReadOnly || currencyIsCrypto}
							disabled={currencyIsCrypto}
							onChange={() => {
								if (isReadOnly || currencyIsCrypto) return;
								handleToggleAllowAllCurrencies();
								setSearch("");
							}}
							label={t(
								`payment-gateway-crypto-modal.fields.currencies-allow-all`
							)}
						/>
					</View>
				</View>

				<View
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 8,
						overflow: "auto",
						height: 240,
					}}
				>
					{currencies.map((currency) => (
						<CurrencyOption
							id={currency.value}
							currency={currency}
							invoiceForm={invoiceForm}
						/>
					))}

					{!currencies?.length && !!search?.trim() ? (
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								paddingHorizontal: 16,
								height: 48,
								borderRadius: 10,
							}}
						>
							<Text style={{ textAlign: "center" }}>
								{t(
									`payment-gateway-crypto-modal.fields.currencies-search-empty`,
									{ replace: { search: `"${search}"` } }
								)}
							</Text>
						</View>
					) : null}
				</View>
			</View>
		</>
	);
};
