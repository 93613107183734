import { StyleSheet } from "react-native";

import Colors from "../../src/global-constants/Colors";

const styles = StyleSheet.create({
	container: {
		display: "flex",
		flexDirection: "row",
		gap: 16,
		flexWrap: "wrap",
	},
	header: {
		backgroundColor: Colors.light.danger,
	},
	footer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		gap: 8,
	},
	footerButtons: {
		flex: 1,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		gap: 8,
	},
	card: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#FFF",
		borderRadius: 8,
		padding: 12,
		gap: 12,
		boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
	},
	titleView: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: 8,
		justifyContent: "space-between",
		borderBottomWidth: 1,
		borderColor: "#8484844D",
		paddingBottom: 8,
		minHeight: 32,
		maxHeight: 32,
	},
	title: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: 4,
	},
	titleText: {
		fontFamily: "Poppins-Bold",
	},
	divider: {
		borderBottomWidth: 1,
		borderColor: "#8484844D",
	},
	inputTopView: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		gap: 8,
	},
	inputTopAvailable: {
		display: "flex",
		flexDirection: "row",
		gap: 4,
	},
	detailsCard: {
		maxWidth: 272,
		height: "fit-content",
		minWidth: 260,
	},
	detailsContent: {
		display: "flex",
		flexDirection: "column",
		gap: 10,
	},
	detailRow: {
		display: "flex",
		flexDirection: "column",
	},
	detailValue: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		height: 26,
		paddingLeft: 8,
		gap: 4,
	},
});

export default styles;
