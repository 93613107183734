import React, { createContext, ReactNode, useContext, useState } from "react";
import { TCurrencySymbol } from "../types/currency";
import { BrokerServices, IPrice } from "../services/broker";
import moment from "moment-timezone";
import { useQuery } from "react-query";

interface QuoteContextData {
    refreshQuote: (currencyIn: TCurrencySymbol, currencyOut: TCurrencySymbol) => void,
    refreshQuotes: () => Promise<IPrice[]>
    quotes: QuoteListModel
}

interface QuoteListModel {
    [key: string]: IPrice
}

const QuoteContext = createContext<QuoteContextData>({} as QuoteContextData);

const QuoteProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const brokerServices = BrokerServices.getInstance();

    const [quotes, setQuotes] = useState<QuoteListModel>({});

    const { refetch } = useQuery("quote/prices", {
        queryFn: brokerServices.getPrices,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data: IPrice[]) => {
            const updatedQuotes = { ...quotes };

            data?.forEach(quote => {
                updatedQuotes[quote.symbol] = quote;
            });

            setQuotes(updatedQuotes);
        }
    })

    const refreshQuotes = async () => {
        const { data } = await refetch();
        return data;
    }

    const refreshQuote = async (currencyIn: TCurrencySymbol, currencyOut: TCurrencySymbol): Promise<string> => {

        const quoteKey = currencyOut === TCurrencySymbol.BRL ? `${currencyIn}/${currencyOut}` : `${currencyOut}/${currencyIn}`;
        const quoteItem = quotes[quoteKey] as IPrice;

        if (quoteItem && moment(Date.now()).diff(quoteItem.updatedAt, "seconds") < 10) {
            console.log("Too many request, try again in 10 seconds...");
            return quoteItem.price ?? "0.00";
        }

        try {

            const quote = await brokerServices.getPrice(
                (currencyOut === TCurrencySymbol.BRL ? currencyIn : currencyOut),
                (currencyOut === TCurrencySymbol.BRL ? currencyOut : currencyIn)
            );

            setQuotes(state => ({
                ...state,
                [quoteKey]: {
                    ...quote?.data?.find((x: IPrice) => x.symbol === quoteKey),
                    updateAt: Date.now()
                }
            }));

            return quote?.data?.find((x: IPrice) => x.symbol === quoteKey)?.price ?? "0.00";

        } catch (error) {
            console.error(error);
            return quoteItem.price ?? "0.00";
        }
    }

    return (
        <QuoteContext.Provider value={{ refreshQuote, refreshQuotes, quotes }}>
            {children}
        </QuoteContext.Provider>
    );
}

const useQuote = () => {
    const context = useContext(QuoteContext);
    if (!context) {
        throw new Error('useQuote must be used within an QuoteProvider.');
    }
    return context;
}


export { QuoteProvider, useQuote };
