import React from "react";
import { View } from "react-native";
import { UserAuthService } from "../../src/services/user/userAuthService";
import { useAuth } from "../../src/services/auth";
import { useNavigation } from "@react-navigation/native";


export default function LogOutScreen() {

  const { logout } = useAuth();
  const navigation = useNavigation();

  const doLogout = async () => {
    UserAuthService.getInstance().logout();
    await logout();
    navigation.navigate("signIn" as any);
  };
  
  doLogout();

  return (
    <View></View>
  );
}
