import React, {memo, useState} from "react";
import {Animated, Easing} from "react-native";
import {Icon, View} from "hubchain-storybook-design-pattern";
import {TouchableRipple} from "react-native-paper";
import {useAuth} from "../../../services/auth";
import {isMobile} from "react-device-detect";

const SwapChangeTypeButton = memo(({ onPress, disabled }: { onPress: () => void, disabled?: boolean }) => {
  const { user } = useAuth();
  
  const enableChangeType = true; // !!user?.isHubchainUser; //enable change button only for hubchain user (for development)

  const rotateAnim = new Animated.Value(0);
  const fadeAnim = new Animated.Value(1);
  const fadeAnim2 = new Animated.Value(0);

  const [isOnPress, setIsOnPress] = useState(false);

  const RotateData = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  const handleEnterAnimate = () => {
    if(disabled) {
      return;
    }

    Animated.parallel([
      Animated.timing(rotateAnim, {
        toValue: 1,
        duration: 200,
        easing: Easing.linear,
        useNativeDriver: false
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 100,
        easing: Easing.linear,
        useNativeDriver: false
      }),
      Animated.timing(fadeAnim2, {
        toValue: 1,
        duration: 300,
        easing: Easing.ease,
        useNativeDriver: false
      })
    ]).start();
  }

  const handleLeaveAnimate = () => {
    if(disabled) {
      return;
    }

    Animated.parallel([
      Animated.timing(rotateAnim, {
        toValue: 0,
        duration: 200,
        easing: Easing.linear,
        useNativeDriver: false
      }),
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.linear,
        useNativeDriver: false
      }),
      Animated.timing(fadeAnim2, {
        toValue: 0,
        duration: 100,
        easing: Easing.linear,
        useNativeDriver: false
      })
    ]).start();
  }

  const handleOnPress = () => {
    if(isOnPress) {
      return;
    }
    setIsOnPress(true);
    onPress();
    setTimeout(() => {
      setIsOnPress(false);
    }, 300)
  }

  if(!enableChangeType) {
    return null;
  }

  return (
    <View style={{
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: isMobile ? "flex-end" : "flex-start"
    }}>
      <TouchableRipple
        style={{
          display: "flex",
          borderRadius: 32,
          borderWidth: 1,
          borderColor: "rgba(132,132,132,0.3)",
          backgroundColor: "#FFF"
        }}
        onPress={handleOnPress}
        disabled={disabled}
      >
        <Animated.View
          onPointerEnter={handleEnterAnimate}
          onPointerLeave={handleLeaveAnimate}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: 60,
            height: 60,
            transform: [{ rotate: RotateData }],
          }}
        >
          <Animated.View style={{ position: "absolute", opacity: fadeAnim }}>
            <Icon name={"ArrowDownIcon"} fontSize={24} variant={disabled ? "gray" : undefined}/>
          </Animated.View>
          <Animated.View style={{ position: "absolute", opacity: fadeAnim2 }}>
            <Icon name={"SwapVertIcon"} fontSize={24} variant={disabled ? "gray" : undefined}/>
          </Animated.View>
        </Animated.View>
      </TouchableRipple>
    </View>
  )
})

export default SwapChangeTypeButton;
