import { StyleSheet } from "react-native";
import Colors from "../../global-constants/Colors";
import {LayoutConfig} from "../../global-constants/Layout";

const styles = StyleSheet.create({
  header: {
    position: "fixed" as any,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingHorizontal: "1rem",
    zIndex: 30,
    minHeight: LayoutConfig.header.size.desktop,
    maxHeight: LayoutConfig.header.size.desktop,
    shadowColor: Colors.light.primary,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,

    elevation: 7,
  },
  logo: {
    width: 96,
    height: 30
  },
  boxButtonAndFlags: {
    alignItems: "flex-end",
    justifyContent: "flex-end"
  },
  selectFlag: {
    alignItems: "flex-start",
    justifyContent: "center",
  },
  menuButton: {
    marginRight: "1rem",
    alignItems: "center",
    justifyContent: "center"

  },
  menuButtonIcon: {

  }
});

export default styles;
