//import moment from "moment-timezone";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { pt, en } from "./locales";

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  debug: false,
  fallbackLng: "pt",
  detection: {
    order: ["queryString", "cookie"],
    cache: ["cookie"],
  },
  resources: {
    pt,
    en,
  },
};
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;