import API, { corePrefix } from "../api";
import {UserModel} from "../../models/UserModel";

export interface CreateAdminUserData {
  email: string,
  name: string,
  personId: string,
  personLegalName?: string,
  birthdate: string,
  phone?: string,
  phoneCountryCode?: number,
  lang: string,
  country: number
}

export interface UpdateAdminUserData {
  email: string,
  name: string,
  personLegalName?: string,
  birthdate?: string,
  phone?: string,
  phoneCountryCode?: number,
  lang?: string,
  country: number
}

class AdminUserService {
  async getUserInfo(): Promise<UserModel> {
    const response = await API.get(`${corePrefix}/users/me`);
    const { data } = response;
    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async create(user: CreateAdminUserData) {
    const response = await API.post(`${corePrefix}/admin/users/admin`, { ...user });
    const responseData = response.data.data;

    if (response.data.status == "ok") {
      return {
        message: responseData.message,
      };
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async update({ userId, user }: { userId: string, user: UpdateAdminUserData }) {
    const response = await API.put(`${corePrefix}/admin/user/admin/${userId}`, { ...user });
    const responseData = response.data.data;

    if (response.data.status == "ok") {
      return {
        message: responseData.message,
      };
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async generateNewPassword({ userId }: { userId: string }) {
    const response = await API.post(`${corePrefix}/admin/users/admin/generatePassword?id=${userId}`);
    const responseData = response?.data?.data;

    if (response.data.status == "ok") {
      return {
        password: responseData,
      };
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }
}

class UserPhoneService {
  async verifyPhone(values: any) {
    const { data } = await API.post(`${corePrefix}/auth/verify-phone`, values);

    if (data.status == "ok") {
      return data.data.message;
    } else {
      throw new Error(data.reason || data.data);
    }
  }
}

export { AdminUserService, UserPhoneService };
