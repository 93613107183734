import { CurrencyModel } from "../../models/CurrencyModel";

import {
	TCurrencySymbol
} from "../../types/currency";
import { DefaultFilter } from "../../models/DefaultFilter";
import Util from "../../utils";
import API, { corePrefix } from "../api";
import { AxiosResponse } from "axios";
import CurrencyUtil from "../../utils/CurrencyUtil";
import {
	RequestStatusModel,
	RequestTypeModel,
} from "../../models/RequestModel";
import { isYesOrNoEnum } from "../../types/types";
import { ICurrencyTicker } from "../currency/currencyService";
// import { NetworkNameId } from "src/types/network"; // <==  Não usar, pois não build depois!

export enum RequestSwapOnProcessSteps {
	//
	CommissionInGateway_ON = "CommissionInGateway_ON",
	CommissionInGateway_OK = "CommissionInGateway_OK",
	CommissionInReferral0_ON = "CommissionInReferral0_ON",
	CommissionInReferral0_OK = "CommissionInReferral0_OK",
	CommissionInReferral1_ON = "CommissionInReferral1_ON",
	CommissionInReferral1_OK = "CommissionInReferral1_OK",
	NetAmountIn_ON = "NetAmountIn_ON",
	NetAmountIn_OK = "NetAmountIn_OK",
	//
	CommissionOutGateway_ON = "CommissionOutGateway_ON",
	CommissionOutGateway_OK = "CommissionOutGateway_OK",
	CommissionOutReferral0_ON = "CommissionOutReferral0_ON",
	CommissionOutReferral0_OK = "CommissionOutReferral0_OK",
	CommissionOutReferral1_ON = "CommissionOutReferral1_ON",
	CommissionOutReferral1_OK = "CommissionOutReferral1_OK",
	NetAmountOut_ON = "NetAmountOut_ON",
	NetAmountOut_OK = "NetAmountOut_OK",
	//
	Trade_ON = "Trade_ON",
	Trade_OK = "Trade_OK",
	//
	Withdrawal_PENDING = "Withdrawal_PENDING",
	Withdrawal_ON = "Withdrawal_ON",
	Withdrawal_CONFIRMING = "Withdrawal_CONFIRMING",
	Withdrawal_OK = "Withdrawal_OK",
	//
	Done = "Done",
}

export interface SwapModel {
	createdAt: Date;
	updatedAt: Date;
	isPaid: isYesOrNoEnum;
	paidAt?: Date;
	user: {
		id: string;
		name: string;
		email: string;
		companyLegalName: string;
		personCompanyId: string;
	};
	currencyIn: CurrencyModel;
	currencyOut: CurrencyModel;
	amountIn: string;
	paidAmount?: number;
	netAmountOut: string;
	addressOut: string;
	networkOut: {
		nameId: string;
		description: string;
		currencySymbol: string;
	};
	addressIn: string;
	networkIn: {
		nameId: string;
		description: string;
		currencySymbol: string;
	};
	addressInExplorerUrl: string;
	addressOutExplorerUrl: string;
	transactionsIn: {
		totalAmount: string;
		totalReceived: string;
		totalPending: string;
		transactions: Array<{
			totalAmount: string;
			totalReceived: string;
			totalPending: string;
			address: string;
			network: string;
			transactionId: string;
			transactionIdExplorerUrl: string;
			createdAt: Date;
			isDue: isYesOrNoEnum;
			dueAt: Date;
			isPaid: isYesOrNoEnum;
			paidAt?: Date;
			metadata: {
				name: string;
				taxId?: string;
				personCompanyId?: string;
			};
		}>;
	};
	transactionsOut?: {
		transactions: Array<{
			address: string; // "0xCc64AbA8FD200cC32ab665868928ffD1dec27137"
			addressExplorerUrl: string; // "https://etherscan.io/address/0xCc64AbA8FD200cC32ab665868928ffD1dec27137"
			createdAt: string; // "2023-07-18 13:50:20"
			network: string; // "eth-mainnet"
			transactionId: string; // "0x559b50f09c31065f4e46f77b2f23503823283ace7f49a8c67130c67319199b0f"
			transactionIdExplorerUrl: string; // "https://etherscan.io/tx/0x559b50f09c31065f4e46f77b2f23503823283ace7f49a8c67130c67319199b0f"]
		}>;
	};
	fees: {
		service: {
			percentage: string;
			amount: string;
			currency: CurrencyModel;
		};
		network: {
			amount: string;
			currency: CurrencyModel;
		};
	};
	price: {
		amount: string;
		currency: CurrencyModel;
	};
	rate: {
		amount: string;
		currency: CurrencyModel;
	};
	status: RequestStatusModel;
	step?: RequestSwapOnProcessSteps;
	type: RequestTypeModel;
	isOnHold: isYesOrNoEnum;
}

export interface RequestSwapModel {
	id: string;
	swap: SwapModel;
}

interface RequestSwapListResponse {
	data: {
		rows: RequestSwapModel[];
		count: number;
		page: number;
		limit: number;
	};
	status: string;
}

interface GetSwapInvoiceResponse {
	data: RequestSwapModel;
	status: string;
}

interface SwapCreateRequest {
	type: string; // RequestTypeEnum.swapExactFiatForCrypto | RequestTypeEnum.swapFiatForExactCrypto | RequestTypeEnum.swapExactCryptoForFiat | RequestTypeEnum.swapCryptoForExactFiat
	amountIn?: number;
	netAmountOut?: number;
	currencyIn: TCurrencySymbol;
	currencyOut: TCurrencySymbol;
	networkOut?: string;
	networkIn?: string;
	addressOut?: string;
	user: string;
}

interface SwapUpdateRequest {
	id: string;
	type?: string;
	amountIn?: number;
	netAmountOut?: number;
	currencyIn?: TCurrencySymbol;
	currencyOut?: TCurrencySymbol;
	networkOut?: string;
	networkIn?: string;
	addressOut?: string;
	user?: string;
	rate?: number;
}

export interface CryptoHubBalance {
	currency: TCurrencySymbol;
	exchange: {
		available: number;
		onHold: number;
		total: number;
		pending?: {
			deposit?: {
				total: number;
				transactions: {
					count: number;
				};
			};
			withdraw?: {
				total: number;
				transactions: {
					count: number;
				};
			};
		};
	};
	exchangeLimits: {
		SPOT: {
			currencies: {
				deposit: {
					total: number;
					available: number;
				};
				withdraw: {
					total: number;
					available: number;
				};
			};
		};
	};
	baas: {
		available: number;
		pendingDeposits: number;
		pendingWithdrawals: number;
	};
	swaps: number;
	diff: number;
	userId: string;
}

export interface GetCryptoHubBalancesResponse {
	data: {
		rows: CryptoHubBalance[];
		count: number;
		userId: string;
		isOnHold: isYesOrNoEnum;
		currencies: Array<TCurrencySymbol>;
		cashIn: {
			pix: {
				[currency in TCurrencySymbol]?: CryptoHubInfoBalancesCashInPixInfo[];
			};
			external: {
				[currency in TCurrencySymbol]?: CryptoHubInfoBalancesCashInPixInfo[];
			};
			internal: {
				[currency in TCurrencySymbol]?: CryptoHubInfoBalancesCashInPixInfo[];
			};
		};
	};
}

export enum IBaasTransferTypeEnum {
	PIX = "pix",
	INTERNAL = "internal",
	EXTERNAL = "external",
}

interface CryptoHubInfoBalancesCashInPixInfo {
	type: IBaasTransferTypeEnum.PIX;
	key: string;
	name: string;
	personCompanyId?: string;
	taxId?: string;
	city: string;
	postalCode: string;
	transactionId: string;
	message: string;
}

interface CryptoHubInfoBalancesCashInExternalInfo {
	type: IBaasTransferTypeEnum.EXTERNAL;
	name: string;
	personCompanyId?: string;
	taxId?: string;
	bankCode: string; // 341
	bankName: string; // "ITAÚ UNIBANCO S.A.",
	branchNumber: string; // "0001",
	branchDigit: string; // "",
	accountType: "CHECK" | "SAVING"; // CHECKING / SAVING
	accountNumber: string; // "00611833",
	accountDigit: string; // "5",
}

interface GetCryptoHubInfoBalancesServiceResponse {
	status: string;
	data: {
		BAAS: {
			default: {
				accountUniqueId: string;
				serviceName: string;
			};
			instances: Array<{
				credential: {
					enviromentStack: string;
					accountUniqueId: string;
					accountName: string;
					accountEmail: string;
				};
				balances: {
					BRL: {
						total: number;
						available: number;
						onHold: number;
					};
				};
				cashIn: {
					pix: {
						BRL?: CryptoHubInfoBalancesCashInPixInfo[];
					};
					external: {
						BRL?: CryptoHubInfoBalancesCashInExternalInfo[];
					};
				};
			}>;
		};
		EXCHANGE: {
			default: {
				accountUniqueId: string;
				serviceName: string;
			};
			instances: Array<{
				credential: {
					enviromentStack: string;
					userId: string;
					accountUniqueId: string;
					accountName: string;
					accountEmail: string;
				};
				currencies: Array<TCurrencySymbol>;
				limits: {
					SPOT: {
						currencies: {
							BRL: {
								deposit: {
									total: string;
									available: string;
								};
								withdraw: {
									total: string;
									available: string;
								};
							};
						};
					};
				};
				balances: {
					SPOT: {
						BRL: {
							total: number;
							available: number;
							onHold: number;
						};
					};
				};
			}>;
		};
		SWAPS: {
			default: {
				accountUniqueId: string;
				serviceName: string;
				user: {
					id: string;
				};
			};
			instances: Array<{
				credential: {
					enviromentStack: string;
					userId: string;
					accountUniqueId: string;
					accountName: string;
					accountEmail: string;
				};
				balances: {
					SPOT: {
						BRL: {
							total: number;
							available: number;
							onHold: number;
						};
					};
				};
				isOnHold: isYesOrNoEnum;
			}>;
		};
	};
}

interface WalletAddressValidateResponse {
	data: {
		data: {
			address: string;
			network: string;
			isValid: boolean;
		};
		status: string;
	};
}

interface QuoteSwapDataRequest {
	amountIn?: number;
	netAmountOut?: number;
	currencyIn: TCurrencySymbol;
	currencyOut: TCurrencySymbol;
	networkOut?: string | null;
	networkIn?: string | null;
	addressOut?: string | null;
	user: string;
}

export interface QuoteSwapDataResponse {
	entry: "amountIn" | "netAmountOut";
	amountIn: string;
	netAmountOut: string;
	currencyIn: TCurrencySymbol;
	currencyOut: TCurrencySymbol;
	networkAmountFee: string;
	networkCurrencyFee: TCurrencySymbol;
	amountInMin: number;
	amountInMax: number;
	netAmountOutMin: number;
	netAmountOutMax: number;
	serviceAmountFee: string;
	serviceCurrencyFee: TCurrencySymbol;
	servicePercentageFee: string;
	quotes: {
		[key: string]: string;
	};
	ticker?: {
		[currency in TCurrencySymbol]?: ICurrencyTicker;
	};
	requestedAt?: Date;
}

export interface QuoteGetResponse {
	status: "ok" | "error";
	message?: string;
	data: {
		fees: {};
		request: {};
		response: {};
		quote: {
			amountIn: string;
			netAmountIn: string;
			netRateAmountOut: string;
			netRateCurrencyOut: TCurrencySymbol;
			netInverseRateAmountOut: string;
			netInserveRateCurrencyOut: string;
			netAmountOut: string;
			netCurrencyOut: string;
			amountInMin: string;
			amountInMax: string;
			netAmountOutMin: string;
			netAmountOutMax: string;
			swap: [
				{
					market: {
						side: string;
						baseCurrency: TCurrencySymbol;
						quoteCurrency: TCurrencySymbol;
					};
				}
			];
			networkOut: string;
			addressOut: string;
			ticker: {
				[currency in TCurrencySymbol]?: ICurrencyTicker;
			};
			fees: {
				service: {
					percentage: string;
					amount: string;
					currency: TCurrencySymbol;
				};
				comissionIn: {
					total: {
						percentage: string;
						amount: string;
						currency: TCurrencySymbol;
					};
					gateway: {
						percentage: string;
						amount: string;
						currency: TCurrencySymbol;
					};
					referral0: {
						percentage: string;
						amount: string;
						currency: TCurrencySymbol;
					};
				};
				network: {
					amount: string;
					amountMin: string;
					currency: TCurrencySymbol;
				};
				networkIn: {
					amount: string;
					amountMin: string;
					currency: TCurrencySymbol;
				};
				networkOut: {
					amount: string;
					amountMin: string;
					currency: TCurrencySymbol;
				};
			};
		};
	};
}

export interface SwapTransactionModel {
	id: string;
	uniqueIdentifier: string;
	value: number;
	isValid: boolean;
}

export interface LinkTransactionsToSwapResponse {
	data: RequestSwapModel;
	status: string;
}

interface LinkTransactionsToSwapRequest {
	id: string;
	transactions: { transactionId: string; network: string }[];
}

export default class RequestSwapService {
	static async list(
		filter?: DefaultFilter,
		options: { invoicesOnly: boolean } = { invoicesOnly: false }
	): Promise<RequestSwapListResponse> {
		const search = Util.defineSearch(filter);
		// const { data }: AxiosResponse<RequestSwapListResponse> = await API.get(`${corePrefix}/reports/requests/all?page=1&limit=10&search=[{"requestType":[{"id":[200, 201, 202, 203, 204, 205, 206, 207]}]},"user":[{"id":["50c55509-f20a-460d-a815-fd48bca7109a"]}]]&orderBy=[{"createdAt":"DESC"}]`)
		// const { data }: AxiosResponse<RequestSwapListResponse> = await API.get(`${corePrefix}/reports/requests/all?page=1&limit=10&search=[{"requestType":[{"id":[200, 201, 202, 203, 204, 205, 206, 207]}]}]&orderBy=[{"createdAt":"DESC"}]`)
		const { data }: AxiosResponse<RequestSwapListResponse> = await API.get(
			`${corePrefix}/reports/requests/${
				options.invoicesOnly === true ? "invoices" : "swaps"
			}?page=${filter.page || 1}&limit=${filter.limit || 100}&search=${search}`
		);

		return data;
	}

	static async create(data: SwapCreateRequest) {
		return API.post(`${corePrefix}/admin/requests/swap`, data);
	}

	static async cancel(id: string | number) {
		return API.put(`${corePrefix}/admin/requests/swap/${id}/cancel`);
	}

	static async update(swap: SwapUpdateRequest) {
		const id = swap.id;
		delete swap.id;

		return API.put(`${corePrefix}/admin/requests/swap/${id}`, swap);
	}

	static async refreshRequestTransaction(transactionId: string) {
		return API.get(
			`${corePrefix}/services/webhooks/baas/ezzebank/ezzepay/${
				"pix-mainnet" /* NetworkNameId.PIX_MAINNET */
			}/${transactionId}`
		);
	}

	static async rerun(id: string) {
		console.log("rerunning swap request...");
		await new Promise((r) => setTimeout(r, 6000));
		console.log("swap request rerunning successfully...");

		return "ok";
	}

	static async resume(id: string) {
		return API.put(`${corePrefix}/admin/requests/swap/${id}/resume`);
	}

	static async stop(id: string) {
		return API.put(`${corePrefix}/admin/requests/swap/${id}/pause`);
	}

	static async payment({ id, isPaid }: { id: string; isPaid: isYesOrNoEnum }) {
		return API.put(`${corePrefix}/admin/requests/swap/${id}/payment`, {
			isPaid,
		});
	}

	static async doneWithdrawal({
		id,
		transactionId,
	}: {
		id: string;
		transactionId: string;
	}) {
		return API.put(`${corePrefix}/admin/requests/swap/${id}/withdrawal/done`, {
			transactionId,
		});
	}

	static async toggleSwapsAutorun() {
		//return API.post(`${corePrefix}/admin/requests/swap/toggle`);

		console.log("changing swap autorun...");
		await new Promise((r) => setTimeout(r, 3000));
		console.log("swap autorun changed successfully...");

		return "ok";
	}

	static async rateEdit({ id, newValue }: { id: string; newValue: number }) {
		console.log("updating swap request rate...");
		await new Promise((r) => setTimeout(r, 2000));
		console.log("swap request rate updated successfully...");

		return "ok";
	}

	static async getDepositData() {
		const { data }: AxiosResponse = await API.get(
			`${corePrefix}/admin/cryptohub/info`
		);

		const depositData = data.data;

		const networksData = depositData.EXCHANGE.instances[0].cashIn.internal;

		const baasData = depositData.BAAS.instances.find(
			(instance: any) =>
				instance.credential.accountUniqueId ===
				depositData.BAAS.default.accountUniqueId
		).cashIn.pix.BRL[0];

		if (data.status === "ok") {
			return { networksData, baasData };
		} else {
			return {};
		}
	}

	static async getAvailableCryptoHubInfoBalances() {
		const { data }: AxiosResponse = await API.get(
			`${corePrefix}/admin/cryptohub/info`
		);

		let cryptoHub = data.data;

		if (data.status === "ok") {
			const exchangeData = cryptoHub.EXCHANGE.instances.find(
				(instance: any) =>
					instance.credential.accountUniqueId ===
					cryptoHub.EXCHANGE.default.accountUniqueId
			);

			const exchangeAddress = {};

			const baasData = cryptoHub.BAAS.instances.map((instance: any) => {
				const addressData = instance?.cashIn;

				const pixKey = addressData?.pix?.BRL[0]?.pix?.key;

				const pixKeyType = Util.identifyPixKeyType(pixKey);

				const pixAddress = {
					type: "pix",
					name: addressData?.pix?.BRL[0]?.name,
					taxId:
						addressData?.pix?.BRL[0]?.personCompanyId ??
						addressData?.pix?.BRL[0]?.taxId,
					pix: {
						key: addressData?.pix?.BRL[0]?.pix?.key,
						keyType: addressData?.pix?.BRL[0]?.pix?.keyType,
						transactionId: addressData?.pix?.BRL[0]?.pix?.transactionId,
						description: addressData?.pix?.BRL[0]?.pix?.message,
					},
				};

				const bankAddress = {
					type: "bank",
					name: addressData?.external?.BRL[0]?.name,
					taxId:
						addressData?.external?.BRL[0]?.personCompanyId ??
						addressData?.external?.BRL[0]?.taxId,
					bank: {
						type: addressData?.external?.BRL[0]?.accountType,
						code: addressData?.external?.BRL[0]?.bankCode,
						branch: addressData?.external?.BRL[0]?.branchNumber,
						account: `${addressData?.external?.BRL[0]?.accountNumber}-${addressData?.external?.BRL[0]?.accountDigit}`,
					},
				};

				const p2pAddress = {
					type: "p2p",
					name: addressData?.pix?.BRL[0]?.name, // "HUBCHAIN TECNOLOGIA LTDA"
					taxId:
						addressData?.pix?.BRL[0]?.personCompanyId ??
						addressData?.pix?.BRL[0]?.taxId, // "28643027000111",
					p2p: {
						account: "",
					},
				};

				const address = instance.cashIn.pix.BRL
					? pixAddress
					: bankAddress
					? bankAddress
					: p2pAddress;

				return {
					accountType: "baas",
					serviceName: instance.serviceName ?? instance.credential?.serviceName,
					accountUniqueId: instance.credential.accountUniqueId,
					credential: instance.credential,

					network: "pix-mainnet",
					address: address,
					cashIn: instance.cashIn,

					accountDescription: instance.credential.accountDescription,
					balances: instance.balances,
				};
			});

			const cryptoHubsData = [
				{
					accountType: "exchange",
					serviceName: "exchange",
					accountUniqueId: exchangeData.credential.accountUniqueId,
					credential: exchangeData.credential,

					network: undefined,
					address: exchangeData.credential,
					cashIn: exchangeData.cashIn,

					accountDescription: exchangeData.credential.accountDescription,
					balances: exchangeData.balances.SPOT,
				},
				...baasData,
				{
					accountType: "e-wallet",
					serviceName: "internal",
					accountUniqueId:
						cryptoHub?.SWAPS?.instances[0]?.credential?.accountUniqueId,
					credential: null,

					network: "internal-mainnet",
					address: null,
					cashIn: null,

					accountDescription: "e-Wallet",
					balances: null,
				},
			];

			return cryptoHubsData;
		} else {
			const EXCHANGE = [];
			return EXCHANGE;
		}
	}

	static async getCryptoHubInfoBalances(): Promise<any> {
		const { data }: AxiosResponse<GetCryptoHubInfoBalancesServiceResponse> =
			await API.get(`${corePrefix}/admin/cryptohub/info`);

		let _currencies = /* data?.data?.EXCHANGE?.instances[0]?.currencies ?? */ [
			TCurrencySymbol.BRL,
			TCurrencySymbol.BTC,
			TCurrencySymbol.USDT,
			TCurrencySymbol.USDC,
			TCurrencySymbol.ETH,
		];

		let _balances = [];
		let cashIn;
		let userId = ""; //default swap user id
		let isOnHold: isYesOrNoEnum = isYesOrNoEnum.YES;

		let _allowedCurrencies = [];

		if (data.status === "ok") {
			_allowedCurrencies = data?.data?.EXCHANGE?.instances
				? data?.data?.EXCHANGE?.instances?.find(
						(instance) =>
							instance.credential.accountUniqueId ===
							data.data.EXCHANGE.default.accountUniqueId
				  ).currencies
				: _currencies;

			const EXCHANGE = data.data.EXCHANGE.instances.find(
				(instance) =>
					instance.credential.accountUniqueId ===
					data.data.EXCHANGE.default.accountUniqueId
			).balances.SPOT;

			const EXCHANGE_LIMITS_SPOT_CURRRENCIES =
				data.data.EXCHANGE.instances.find(
					(instance) =>
						instance.credential.accountUniqueId ===
						data.data.EXCHANGE.default.accountUniqueId
				).limits.SPOT.currencies;

			const BAAS = data.data.BAAS.instances.find(
				(instance) =>
					instance.credential.accountUniqueId ===
					data.data.BAAS.default.accountUniqueId
			).balances;

			const SWAPS = data.data.SWAPS.instances.find(
				(instance) =>
					instance.credential.accountUniqueId ===
					data.data.SWAPS.default.accountUniqueId
			).balances.SPOT;

			isOnHold = data.data.SWAPS.instances[0].isOnHold || isYesOrNoEnum.NO;

			userId = data.data.SWAPS.default.user?.id || "";

			cashIn = data.data.BAAS.instances[0].cashIn;

			[EXCHANGE, EXCHANGE_LIMITS_SPOT_CURRRENCIES, BAAS, SWAPS].forEach(
				(source) => {
					Object.keys(source).forEach((currency) => {
						if (
							!_currencies.includes(currency.toUpperCase() as TCurrencySymbol)
						)
							_currencies.push(currency.toUpperCase() as TCurrencySymbol);
					});
				}
			);

			_balances = _currencies.map((currency) => {
				// console.log(currency)

				EXCHANGE[currency] = EXCHANGE[currency] || {
					currency: currency,
					exchange: 0,
					baas: 0,
					swaps: 0,
					diff: 0,
				};

				BAAS[currency] = BAAS[currency] || {
					currency: currency,
					exchange: 0,
					baas: 0,
					swaps: 0,
					diff: 0,
				};

				SWAPS[currency] = SWAPS[currency] || {
					currency: currency,
					exchange: 0,
					baas: 0,
					swaps: 0,
					diff: 0,
				};

				return {
					currency: currency,
					exchange: {
						available: EXCHANGE[currency]?.available ?? 0,
						onHold: EXCHANGE[currency]?.onHold ?? 0,
						total: EXCHANGE[currency]?.total ?? 0,
						pending: {
							deposit: {
								total: EXCHANGE[currency]?.pending?.deposit?.total ?? 0,
								transactions: {
									count:
										EXCHANGE[currency]?.pending?.deposit?.transactions?.count ??
										0,
								},
							},
							withdraw: {
								total: EXCHANGE[currency]?.pending?.withdraw?.total ?? 0,
								transactions: {
									count:
										EXCHANGE[currency]?.pending?.withdraw?.transactions
											?.count ?? 0,
								},
							},
						},
					},
					exchangeLimits: {
						SPOT: {
							currencies: {
								deposit: {
									total: Number(
										EXCHANGE_LIMITS_SPOT_CURRRENCIES[currency]?.deposit
											?.total ?? "0"
									),
									available: Number(
										EXCHANGE_LIMITS_SPOT_CURRRENCIES[currency]?.deposit
											?.available ?? "0"
									),
								},
								withdraw: {
									total: Number(
										EXCHANGE_LIMITS_SPOT_CURRRENCIES[currency]?.withdraw
											?.total ?? "0"
									),
									available: Number(
										EXCHANGE_LIMITS_SPOT_CURRRENCIES[currency]?.withdraw
											?.available ?? "0"
									),
								},
							},
						},
					},
					baas: {
						available: BAAS[currency]?.available ?? 0,
						pendingDeposits: BAAS[currency]?.pendingDeposits ?? 0,
						pendingWithdrawals: BAAS[currency]?.pendingWithdrawals ?? 0,
					},
					swaps: SWAPS[currency]?.onHold ?? 0,
					diff:
						Number(SWAPS[currency]?.onHold ?? "0") <
						Number(EXCHANGE[currency]?.available ?? "0") +
							Number(BAAS[currency]?.available ?? "0")
							? 0
							: Number(SWAPS[currency]?.onHold ?? "0") -
							  (Number(EXCHANGE[currency]?.available ?? "0") +
									Number(BAAS[currency]?.available ?? "0")),
				} as CryptoHubBalance;
			});
		} else {
			_balances = _currencies.map((currency) => {
				return { currency: currency, exchange: 0, baas: 0, swaps: 0, diff: 0 };
			});
		}

		return {
			data: {
				rows: _balances,
				count: _balances.length,
				currencies: _allowedCurrencies,
				cashIn,
				userId,
				isOnHold,
			},
		};
	}

	static async walletAddressValidate({
		address,
		network,
	}: {
		address: string;
		network: TCurrencySymbol;
	}): Promise<WalletAddressValidateResponse> {
		return API.get(
			`${corePrefix}/blockchain/${network}/address/${address}/validate`
		);
	}

	static async quoteSwap({
		amountIn,
		netAmountOut,
		currencyIn,
		currencyOut,
		networkOut,
		networkIn,
		addressOut,
		user: userId,
	}: QuoteSwapDataRequest): Promise<QuoteSwapDataResponse> {
		let amount;
		let entry;

		if (amountIn !== undefined) {
			amount = "amountIn=" + amountIn;
			entry = "amountIn";
			if (amountIn <= 0) return null;
		} else {
			amount = "netAmountOut=" + netAmountOut;
			entry = "netAmountOut";
			if (netAmountOut <= 0) return null;
		}

		if (netAmountOut <= 0 && amountIn <= 0) return null;
		const {
			data: {
				data: { quote },
			},
		}: AxiosResponse<QuoteGetResponse> = await API.get(
			`${corePrefix}/requests/swap/quote?${amount}&currencyIn=${currencyIn}&currencyOut=${currencyOut}${
				networkIn ? "&networkIn=" + networkIn : ""
			}${networkOut ? "&networkOut=" + networkOut : ""}${
				(addressOut || "") === "" ? "" : "&addressOut=" + addressOut
			}${(userId || "") === "" ? "" : "&user=" + userId}`
		);

		// console.log(`::quoteSwap::`, quote);

		return {
			entry: entry,
			amountIn:
				CurrencyUtil.getNumberByValue(quote.amountIn) < 0
					? "0"
					: currencyIn === TCurrencySymbol.BRL
					? Number(quote.amountIn).toFixed(2)
					: quote.amountIn,
			netAmountOut:
				CurrencyUtil.getNumberByValue(quote.netAmountOut) < 0
					? "0"
					: currencyOut === TCurrencySymbol.BRL
					? Number(quote.netAmountOut).toFixed(2)
					: quote.netAmountOut,
			networkAmountFee: quote.fees.network.amount,
			networkCurrencyFee: quote.fees.network.currency,
			amountInMin: CurrencyUtil.getNumberByValue(quote.amountInMin),
			amountInMax: CurrencyUtil.getNumberByValue(quote.amountInMax),
			netAmountOutMin: CurrencyUtil.getNumberByValue(quote.netAmountOutMin),
			netAmountOutMax: CurrencyUtil.getNumberByValue(quote.netAmountOutMax),
			serviceAmountFee: quote.fees.service.amount,
			serviceCurrencyFee: quote.fees.service.currency,
			servicePercentageFee: quote.fees.service.percentage,
			currencyIn: currencyIn,
			currencyOut: currencyOut,
			quotes: {
				[currencyIn + currencyOut]: quote.netInverseRateAmountOut,
				[currencyOut + currencyIn]: quote.netRateAmountOut,
			},
			ticker: quote.ticker,
		};
	}

	static async linkTransactionsToSwap({
		transactions,
		id,
	}: LinkTransactionsToSwapRequest): Promise<
		AxiosResponse<LinkTransactionsToSwapResponse>
	> {
		return API.put(`${corePrefix}/requests/swap/${id}/payment`, transactions);
	}

	static async getSwapInvoice({
		requestId,
	}: {
		requestId: string;
	}): Promise<AxiosResponse<GetSwapInvoiceResponse>> {
		return API.get(`${corePrefix}/requests/swap/${requestId}/invoice`);
	}
}
