import { Select } from "hubchain-storybook-design-pattern";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import {
	INetworkOption,
	SwapModalCalculatePairAmountParams,
	SwapModalFormData,
} from "../UserSwapModal";

interface SwapModalInputNetworkProps {
	networkField: "networkIn" | "networkOut";
	disabled: boolean;
	readonly: boolean;
	swapForm: FormikProps<SwapModalFormData>;
	networkOptions: INetworkOption[];
	portal: HTMLDivElement;
	calculatePairAmount: (params: SwapModalCalculatePairAmountParams) => void;
}

export const SwapModalInputNetwork = ({
	disabled,
	readonly,
	swapForm,
	networkField,
	networkOptions,
	calculatePairAmount,
	...props
}: SwapModalInputNetworkProps) => {
	const { t } = useTranslation();

	const [swapNetwork, setSwapNetwork] = useState<INetworkOption | undefined>(
		undefined
	);

	const _options: INetworkOption[] =
		networkOptions?.filter((network) =>
			swapForm.values[
				networkField === "networkOut" ? "currencyOut" : "currencyIn"
			].networks?.includes(network.value)
		) || [];

	const handleChangeNetwork = async (option: INetworkOption) => {
		console.log("::handleChangeNetwork::", networkField, option.value);

		if (readonly) return;

		swapForm.setFieldValue(networkField, option);
		swapForm.setFieldValue("addressOut", "");

		calculatePairAmount({
			field: "network",
			[networkField]: option.value,
			addressOut: "",
		});
	};

	return (
		<Select
			size={"large"}
			label={t("swap-modal.destination.network")}
			labelStyle="default"
			inputWidth={"100%"}
			placeholder={""}
			iconSize={28}
			iconSet={"currency"}
			isSearchable={false}
			disabled={disabled}
			readonly={readonly || _options.length === 1}
			options={_options}
			value={
				swapForm.values[networkField].label
					? swapForm.values[networkField]
					: _options.find(
							(network) =>
								network.value === swapForm.values[networkField]?.nameId
					  )
			}
			onChange={handleChangeNetwork}
			paddingRight={"10px"}
			menuPortalTarget={props.portal}
		/>
	);
};
