import { StyleSheet } from "react-native";

import Colors from "../../../src/global-constants/Colors";

const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "row",
    minHeight: "65vh"
  },
  page: {
    flex: 1,
    width: "100%",
    padding: "1rem 1.25rem"
  },
  container: {
    flex: 1,
    flexDirection: "row",
    maxHeight: "100vh",
    backgroundColor: Colors.backgroundGray
  }
})

export default styles;