import {PaymentDetailsModel} from "../models";
import {useTranslation} from "react-i18next";
import {Text, View} from "hubchain-storybook-design-pattern";
import styles from "../styles";
import {DetailRow, Title} from "./common";
import CurrencyUtil from "../../../utils/CurrencyUtil";
import {TCurrencySymbol} from "../../../types/currency";
import React, {useMemo} from "react";
import {ActivityIndicator} from "react-native";
import Util from "../../../utils";

export const PaymentDetailsCard = ({
	invoiceForm,
	paidAmount,
	quote,
	isQuoting,
	request,
}: PaymentDetailsModel) => {
	const { t } = useTranslation();

	const currency = invoiceForm.values.currency;

	const amount = useMemo(() => {
		if (currency.value === TCurrencySymbol.BRL) {
			return (
				CurrencyUtil.getNumberByValue(invoiceForm.values?.amount || "0") || 0
			);
		} else {
			return quote?.netAmountOut || 0;
		}
	}, [currency.value, quote, invoiceForm.values.amount]);

	const networks = request?.invoice?.paymentMethods;

	const parseToNetworkFeeData = (data) => {
		if (data) {
			const network = data?.networks[0];

			return {
				networkName: network?.network?.name,
				networkFee: network?.fees?.networkIn?.amount,
				networkFeeCurrency: network?.fees?.networkIn?.currency,
			};
		}
	};

	const networkFees = useMemo(() => {
		return networks
			? networks.map((network) => parseToNetworkFeeData(network))
			: [];
	}, [networks]);

	return (
		<View
			style={[
				styles.card,
				styles.detailsCard,
				{ height: "fit-content", flex: "unset" },
			]}
		>
			<Title title={t(`payment-gateway-crypto-modal.details.title`)} />

			<View style={[styles.detailsContent, { gap: 6 }]}>
				<DetailRow
					label={t("payment-gateway-crypto-modal.details.send")}
					value={
						currency.value !== TCurrencySymbol.BRL
							? `${CurrencyUtil.getNumberByValue(
									invoiceForm.values?.amount || "0"
							  )} ${currency.label} (${currency.subLabel})`
							: t("payment-gateway-crypto-modal.details.send-value")
					}
				/>

				<DetailRow
					label={t("payment-gateway-crypto-modal.details.service")}
					value={CurrencyUtil.formatCurrency(
						request?.swap?.fees?.service?.amount,
						TCurrencySymbol.BRL
					)}
				/>

				<Text variant={"gray"} fontStyle={"bold"}>
					{t("payment-gateway-crypto-modal.details.network")}
				</Text>

				<View
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(2, 1fr)",
						// gap: 8,
						paddingLeft: 8,
					}}
				>
					{networkFees.map((network, index) => (
						<View
							key={index}
							style={{
								paddingTop: 4,
								paddingBottom: 4,

								paddingRight: index % 2 === 0 ? 16 : 0,
								paddingLeft: index % 2 > 0 ? 16 : 0,
								borderRightWidth: index % 2 === 0 ? 1 : 0,
								borderColor: "#8484844D",
							}}
						>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									gap: 4,
									justifyContent: "space-between",
								}}
							>
								<Text fontStyle={"bold"}>{network.networkName}</Text>
								<Text size={"12px"}>
									{Util.formatCurrency(
										network.networkFee,
										network.networkFeeCurrency
									)}
								</Text>
							</View>
						</View>
					))}
				</View>

				{/* <DetailRow
					label={t("payment-gateway-crypto-modal.details.network")}
					value={t("payment-gateway-crypto-modal.details.network-value")}
				/> */}

				<View style={[styles.divider]} />

				<DetailRow
					label={t("payment-gateway-crypto-modal.details.receive")}
					value={
						(currency.value !== TCurrencySymbol.BRL ? "≈ " : "") +
						CurrencyUtil.formatCurrency(amount, TCurrencySymbol.BRL)
					}
					subValue={
						paidAmount !== undefined
							? {
									value: `(${t(
										`payment-gateway-crypto-modal.details.received`
									)}: ${CurrencyUtil.formatCurrency(
										paidAmount,
										TCurrencySymbol.BRL
									)})`,
									variant: "success",
							  }
							: undefined
					}
					rightAddon={
						isQuoting ? <ActivityIndicator size={12} color={"#000"} /> : null
					}
				/>
			</View>
		</View>
	);
};
