import {AvailableIconsType} from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import React, {ReactNode} from "react";
import {Icon, Text, View} from "hubchain-storybook-design-pattern";
import styles from "../styles";
import {VariantsType} from "hubchain-storybook-design-pattern/lib/components/Text/types";

export const Title = ({ title, icon, rightAddon, badgeText }: { title: string, icon?: AvailableIconsType, rightAddon?: ReactNode, badgeText?: string }) => {
  return (
    <View style={styles.titleView}>
      <View style={styles.title}>
        {icon && <Icon name={icon} fontSize={"13px"} variant={"primary"} />}
        <Text variant={"primary"} size={"13px"} fontStyle={"bold"}>{title}</Text>
        {badgeText &&
            <View style={{ height: 18, backgroundColor: "#0052cc", padding: 2 }}>
                <Text size={"12px"} fontStyle={"bold"} variant={"white"}>
                  {badgeText}
                </Text>
            </View>}
      </View>
      {rightAddon}
    </View>
  )
}

export const DetailRow = ({ label, value, subValue, rightAddon, fontSize, height }: { label: string, value: string | ReactNode, subValue?: { value: string, variant?: VariantsType, fontSize?: string }, rightAddon?: ReactNode, fontSize?: string, height?: string }) => {
  return (
    <View style={[styles.detailRow]}>
      <Text variant={"gray"} fontStyle={"bold"} size={fontSize}>{label}</Text>
      <View style={[styles.detailValue, height ? { height: height } : {}]}>
        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 1, flexWrap: "wrap", gap: 4 }}>
          {
            typeof value === "string" ? <Text fontStyle={"bold"} size={fontSize} style={{ overflowWrap: "anywhere" }}>{value}</Text> : value
          }
          {
            subValue && <Text variant={subValue.variant} size={subValue.fontSize || "10px"} style={{ overflowWrap: "anywhere" }}>{subValue.value}</Text>
          }
        </View>
        {rightAddon}
      </View>
    </View>
  )
}
