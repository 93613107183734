import React, { Suspense } from "react";
import {
	useNavigationBuilder,
	createNavigatorFactory,
	StackRouter,
} from "@react-navigation/native";
import { Sidebar } from "../components/sidebar";
import styles from "../layouts/default-layout/styles";
import { View } from "react-native";
import Header from "../components/header/header";
import { createRef } from "react";
import LoadingScreen from "../components/Loading";

function CustomNavigator({
	initialRouteName,
	backBehavior,
	children,
	screenOptions,
	...rest
}) {
	const { state, descriptors, navigation, NavigationContent } =
		useNavigationBuilder(StackRouter, {
			initialRouteName,
			children,
			screenOptions,
		});

	const sidenavRef = createRef<any>();
	const toggleMenu = () => {
		sidenavRef.current.toggleSidenav();
	};

	return (
		<NavigationContent>
			<Header onToggleMenu={toggleMenu} />
			<View style={[styles.container]}>
				<Sidebar
					ref={sidenavRef}
					route={state.routes[state.index]}
					navigation={navigation}
					hide={state.routes[0].name === "calc"}
				>
					{state.routes.map((route, i) => (
						<View
							key={route.key}
							style={{ display: i === state.index ? "flex" : "none" }}
						>
							<Suspense fallback={<LoadingScreen useHeaderOffset={true} />}>
								{descriptors[route.key].render()}
							</Suspense>
						</View>
					))}
				</Sidebar>
			</View>
		</NavigationContent>
	);
}

export const createCustomNavigator = createNavigatorFactory(CustomNavigator);
