import { TCurrencySymbol } from "src/types/currency";

import { ICryptoHubTransferCreateServiceRequest } from "../../components/TreasurySwapModal/CryptoHubTransferModal";
import API, { corePrefix } from "../api";

export default class RequestTransferService {
	static async create(data: ICryptoHubTransferCreateServiceRequest) {
		if (data) {
			return API.post(`${corePrefix}/admin/cryptohub/transfer`, data);
		} else {
			console.error("Error creating transfer request.");
		}
	}
}
