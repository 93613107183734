import API, { corePrefix } from "../api";
import { UserSignUpModel } from "../../models/UserSignUpModel";
import { MultiTenancyService } from "../multi-tenancy/multi-tenancy.service";
import { OneSignalService } from "../onesignal";
import Util from "../../utils";

class UserAuthService {
  private static instance: UserAuthService;

  public static getInstance(): UserAuthService {
    if (!UserAuthService.instance) {
      UserAuthService.instance = new UserAuthService();
    }

    return UserAuthService.instance;
  }

  async login(username: string, password: string, recaptcha: string, token?: string) {
    const _appDomain = (await MultiTenancyService.getTenant()).appDomain;
    const headers = {
      recaptcha,
      "x-tenant-id": _appDomain
    }

    const data = {
      username,
      password
    }

    if (token) {
      Object.assign(data, { token })
    }

    let response;
    let responseData;

    response = await API.post(`${corePrefix}/auth/login`, data, { headers });
    responseData = response.data.data;

    if (response.data.status === "ok") {

      OneSignalService.login({
        id: responseData.id,
        email: responseData.email,
      }, {
        tenant: { appDomain: _appDomain },
        hostname: window.location.hostname,
        env: Util.getEnvironment(),
      });

      return {
        authorization: responseData.authorization,
        email: responseData.email,
        name: responseData.name,
        message: responseData.message
      }
    } else {
      throw new Error(response.data.reason);
    }
  }

  async registerPerson(user: UserSignUpModel, recaptcha: string) {
    // const headers = {
    //   "recaptcha": reCaptchaToken
    // }
    //, reCaptchaToken: string

    const headers = {
      "recaptcha": recaptcha
    }

    const data = { ...user };
    const response = await API.post(`${corePrefix}/users`, data, { headers });

    if (response.data.status == "ok") {
      return true;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }

  }

  async registerCompany(user: UserSignUpModel, recaptcha: string) {

    const headers = {
      "recaptcha": recaptcha
    }

    const data = { ...user };
    const response = await API.post(`${corePrefix}/users/company`, data, { headers });

    if (response.data.status == "ok") {
      return true;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }

  }

  async logout() {
    OneSignalService.logout();
    return await API.post(`${corePrefix}/auth/logout`);
  }

  async requestPasswordReset(email: string, recaptcha: string = "") {
    const headers = {
      recaptcha
    }

    let { data } = await API.post(`${corePrefix}/auth/forgot-password`, { email }, { headers });

    if (data.status == "ok") {
      return true;
    } else {
      throw new Error(data.reason || data.message);
    }
  }

  async changePassword(password: string, passwordConfirmation: string, recaptcha: string, authCode: string, token: string = "") {
    const headers = {
      recaptcha
    }

    const data = {
      password,
      passwordConfirmation,
      authCode,
      token: token || ""
    };

    const response = await API.post(`${corePrefix}/auth/change-password-logged-in`, data, { headers });
    const responseData = response.data;

    if (responseData.status == "ok") {
      return responseData;
    } else {
      throw new Error(responseData.reason || responseData.message);
    }
  }

  async changePasswordForgot(password: string, passwordConfirmation: string, authCode: string, recaptcha: string) {
    const headers = {
      recaptcha
    }

    const data = {
      password,
      passwordConfirmation,
      authCode,
    };

    const response = await API.post(`${corePrefix}/auth/change-password`, data, { headers });
    const responseData = response.data;

    if (responseData.status == "ok") {
      return responseData;
    } else {
      throw new Error(responseData.reason || responseData.message);
    }
  }



}



export { UserAuthService }
