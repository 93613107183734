import {
	Button,
	Icon,
	Modal,
	Text,
	Select,
	Input,
	View,
} from "hubchain-storybook-design-pattern";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "../webhookModal/styles";
import { t } from "i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import WebhookService from "../../services/webhook/webhooksServices";
import { useAlert } from "../../hooks/useAlert";
import Util from "../../utils";

interface WebhookFormData {
	url: string;
	event_type: SelectOptionModel[];
	white_list_ips: string;
}

export function WebhookModal({ onClose, webhook, isDelete }) {
	const [started, setStarted] = useState(false);
	const modalRef = useRef(null);

	const { showAlert } = useAlert();

	const action = webhook ? (isDelete ? "delete" : "edit") : "new";

	const queryClient = useQueryClient();

	const { data: webhookEventListResponse } = useQuery("webhooks/events", {
		queryFn: WebhookService.listEvents,
		staleTime: 1000 * 60,
		retry: false,
		retryDelay: 1000 * 10,
		refetchOnWindowFocus: false,
		keepPreviousData: false,
	});

	const events: SelectOptionModel[] = useMemo(() => {
		const events: SelectOptionModel[] =
			webhookEventListResponse?.data.data.map((event) => ({
				value: event,
				label: event,
			})) || [];
		return events;
	}, [webhookEventListResponse]);

	const webhookFormValidationSchema = yup.object({
		url: yup
			.string()
			.required("")
			.test("valid-url", "error-url", (value: string) => {
				const urlPattern = /^(http(s?):\/\/)[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

				return urlPattern.test(value);
			}),
		white_list_ips: yup
			.string()
			.required("")
			.test("valid-ip", "error-ip", (value: string) => {
				const ipArray = value.split(",");

				if (ipArray.length === 0) {
					return false;
				}

				return ipArray.every((ip: string) => {
					return Util.validateIp(ip.trim());
				});
			}),
		event_type: yup
			.array()
			.required()
			.test("valid-events", "error-events", (values: string[]) => {
				return values.length > 0;
			}),
	});

	const { mutate: webhookDelete } = useMutation({
		mutationFn: WebhookService.delete,
		onSuccess: () => {
			showAlert(t(`webhooks.actions.delete.success`), "success");
			queryClient.refetchQueries(["webhooks"]);
			onClose();
		},
		onError: () => {
			showAlert(t(`webhooks.actions.delete.error`), "danger");
		},
	});

	const { mutate: webhookCreate, isLoading: isCreatingInvoice } = useMutation({
		mutationFn: WebhookService.create,
		onSuccess: () => {
			showAlert(t(`webhooks.actions.new.success`), "success");
			queryClient.refetchQueries(["webhooks"]);
			onClose();
		},
		onError: () => {
			showAlert(t(`webhooks.actions.new.error`), "danger");
		},
	});

	const { mutate: webhookUpdate } = useMutation({
		mutationFn: WebhookService.update,
		onSuccess: () => {
			showAlert(t(`webhooks.actions.update.success`), "success");
			queryClient.refetchQueries(["webhooks"]);
			onClose();
		},
		onError: () => {
			showAlert(t(`webhooks.actions.update.error`), "danger");
		},
	});

	const handleSubmit = (values: WebhookFormData) => {
		if (isDelete) return;

		const whiteListIps = values.white_list_ips.replace(/[ ]/g, "").split(",");

		const eventsType = values.event_type.map((event) => {
			return event.value.toString();
		});

		if (action === "edit") {
			webhookUpdate({
				id: webhook.id,
				url: values.url,
				ip_whitelist: whiteListIps,
				event_type: eventsType,
			});
		} else {
			webhookCreate({
				url: values.url,
				ip_whitelist: whiteListIps,
				event_type: eventsType,
			});
		}
	};

	const handleDelete = () => {
		webhookDelete(webhook);
	};

	const webhookForm = useFormik<WebhookFormData>({
		initialValues: {
			url: "",
			event_type: [],
			white_list_ips: "",
		},
		validationSchema: webhookFormValidationSchema,
		validateOnChange: false,
		validateOnMount: true,
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		setStarted(true);

		if (webhook && !!events.length) {
			webhookForm.setFieldValue("url", webhook.url);

			webhookForm.setFieldValue(
				"event_type",
				webhook.eventTypes.map((event) => ({
					value: event,
					label: event,
				}))
			);

			webhookForm.setFieldValue(
				"white_list_ips",
				webhook.ipWhitelist?.join(", ")
			);
		}
	}, [webhook, events]);

	return (
		<Modal
			ref={modalRef}
			title={<>{t(`webhooks.modal.title.${action}`)}</>}
			titleIcon={
				action === "edit" ? "Edit" : action === "delete" ? "Trash" : "AddIcon"
			}
			visible={true}
			onClose={onClose}
			contentOverflowY={"auto"}
			fullHeightModal={true}
			onClickOutside={() => {}}
			backgroundColor={"new"}
			customWidth={{
				width: "100%",
				minWidth: "240px",
				maxWidth: "500px",
			}}
			// isLoading={isSubmitting}
			footer={
				<View style={[styles.footer]}>
					<View style={[styles.footerButtons]}>
						<Button
							variant={"secondary"}
							size={"small"}
							fillVariant={"ghost"}
							label={t("webhooks.modal.close")}
							onClick={onClose}
						/>

						<Button
							icon={
								action === "edit" ? (
									<Icon name="SaveDisk" fontSize={20} />
								) : action === "delete" ? (
									<Icon name="CloseCircleIcon" fontSize={20} />
								) : (
									<Icon name="Send2" fontSize={20} />
								)
							}
							variant={isDelete ? "danger" : undefined}
							label={
								action === "edit"
									? t("webhooks.modal.save")
									: action === "delete"
									? t("webhooks.modal.delete")
									: t("webhooks.modal.add")
							}
							size={"small"}
							onClick={
								action === "delete" ? handleDelete : webhookForm.handleSubmit
							}
							disabled={action === "delete" ? false : !webhookForm.isValid}
							disableHover={!webhookForm.isValid}
						/>
					</View>
				</View>
			}
			footerDivider={true}
		>
			<View style={[styles.container]}>
				<View style={[styles.card, styles.detailsCard]}>
					<View style={[styles.detailsContent]}>
						<View preset={"warning-box"}>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "flex-start",
									gap: 8,
								}}
							>
								<Icon name={"WarningIcon"} variant={"warning"} />
								<Text style={{ lineHeight: 1.5 }}>
									<>{t("webhooks.modal.warning")}</>
									<View
										style={{
											width: "fit-content",
											backgroundColor: "#00000018",
											padding: "0px 8px",
										}}
									>{`{"test": true}`}</View>
								</Text>
							</View>
						</View>
						<View style={[styles.divider]} />
						<View>
							<Input
								label={"URL"}
								placeholder={
									webhookForm.values.url
										? webhookForm.values.url
										: "http://www.webhook.com"
								}
								value={webhookForm.values.url}
								readOnly={isDelete}
								size="large"
								onChange={(value: string) => {
									webhookForm.setFieldValue("url", value);
								}}
								variant={
									webhookForm.touched.url &&
									webhookForm.errors.url !== undefined
										? "danger"
										: undefined
								}
								onKeyUp={async () => {
									await webhookForm.setFieldTouched("url");
								}}
							/>
						</View>
						<Select
							options={events}
							label={t("webhooks.modal.events.label")}
							labelStyle="default"
							inputWidth="100%"
							size="large"
							menuPortalTarget={modalRef?.current}
							readonly={isDelete}
							isMulti
							value={webhookForm.values.event_type}
							onChange={(value: string) => {
								webhookForm.setFieldValue("event_type", value).finally(() => {
									webhookForm.setFieldTouched("event_type");
								});
							}}
							variant={
								webhookForm.touched.event_type &&
								webhookForm.errors.event_type !== undefined
									? "danger"
									: undefined
							}
						/>
						<View>
							<Input
								label={t("webhooks.modal.ips.label")}
								placeholder={t("webhooks.modal.ips.placeholder")}
								value={webhookForm.values.white_list_ips}
								size="large"
								readOnly={isDelete}
								onChange={(value: string) => {
									webhookForm.setFieldValue("white_list_ips", value);
								}}
								onKeyUp={async () => {
									await webhookForm.setFieldTouched("white_list_ips");
								}}
								variant={
									webhookForm.touched.white_list_ips &&
									webhookForm.errors.white_list_ips !== undefined
										? "danger"
										: undefined
								}
							/>
						</View>
					</View>
				</View>
			</View>
		</Modal>
	);
}
