export enum RequestStatusId {
  PENDING = 1,
  COMPLETED = 2,
  CANCELLED = 3,
  EMAIL_CONFIRMATION = 4,
  AWAITING_DEPOSIT = 5,
  SCHEDULED = 6,
  VERIFICATION_PENDING = 7,
  ERROR = 8,
  RUNNING = 9,
  EXPIRED = 10,
  AWAITING_WITHDRAWAL = 11,
  PAID = 12,
}

