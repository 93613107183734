import React, {ReactNode} from "react";
import {Text, View} from "hubchain-storybook-design-pattern";
import styles from "../styles";

interface SwapModalDetailRowProps {
  label: string;
  value: string | ReactNode;
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
  fontSize?: string;
  height?: string;
}

const SwapModalDetailRow = ({
    label,
    value,
    leftAddon,
    rightAddon,
    fontSize,
    height,
  }: SwapModalDetailRowProps) => {
  return (
    <View style={[styles.detailRow]}>
      <Text variant={"gray"} fontStyle={"bold"} size={fontSize}>
        {label}
      </Text>
      <View style={[styles.detailValue, height ? {height: height} : {}]}>
        {leftAddon}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            flexWrap: "wrap",
          }}
        >
            {typeof value === "string" ? (
            <Text
              fontStyle={"bold"}
              size={fontSize}
              style={{overflowWrap: "anywhere"}}
            >
              {value}
            </Text>
          ) : (
            value
          )}
        </View>
        {rightAddon}
      </View>
    </View>
  );
};

export default SwapModalDetailRow;
