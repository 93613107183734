import { createContext, useContext, useEffect, useState } from "react";
import { Theme } from "hubchain-storybook-design-pattern";
import { TenantConfig, TenantAppDomainEnum } from "./types";

import { Helmet } from "react-helmet";
import { MultiTenancyService } from "./multi-tenancy.service";
import LoadingScreen from "../../components/Loading";
import * as React from "react";
import Util from "../../utils";

interface MultiTenancyContextData {
	tenant: TenantConfig;
	tenantLoading: boolean;
}

const MultiTenancyContext = createContext<MultiTenancyContextData>({} as MultiTenancyContextData);

const MultiTenancyProvider: React.FC<any> = ({ children }) => {
	const [tenant, setTenant] = useState<TenantConfig>({} as TenantConfig);
	const tenantLoading = !tenant.name;

	Util.tenant = tenant;

	useEffect(() => {
		handleConfigTenant();
	}, []);

	const handleConfigTenant = async () => {
		const _tenantName =
			(await MultiTenancyService.getTenant())?.name ?? "hubchainbank";

		import(`../../config/tenants/${_tenantName}`)
			.then((config) => {
				setTenant(config.default);
			})
			.catch(() => {
				alert(`Tenant '${_tenantName}' not found!`); // src/config/tenants/hubchainbank.ts not found !
			});
	};

	return (
		<MultiTenancyContext.Provider value={{ tenantLoading, tenant: tenant }}>
			<Helmet>

				{tenant.favicon && (
					<link
						rel="icon"
						type="image/png"
						href={tenant.favicon}
						sizes="16x16"
					/>
				)}

				{tenant.favicon && (
					<link
						rel="icon"
						type="image/png"
						href={tenant.favicon}
						sizes="32x32"
					/>
				)}

				{tenant.favicon && (
					<link rel="shortcut icon" type="image/png" href={tenant.favicon} />
				)}

			</Helmet>

			<Theme theme={tenant.theme || "Hubchain"}>
				{!tenantLoading ? children : <LoadingScreen />}
			</Theme>

		</MultiTenancyContext.Provider>
	);
};

let context: MultiTenancyContextData = null;

const useMultiTenancy = () => {
	if (context === null) {
		context = useContext(MultiTenancyContext);
	}

	if (!context) {
		throw new Error(
			"useMultiTenancy must be used within an MultiTenancyProvider."
		);
	}

	return context;
};

export { MultiTenancyProvider, useMultiTenancy };
