import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Logo, Modal } from "hubchain-storybook-design-pattern";
import { View, TouchableOpacity } from "react-native";

import { MdMenu } from "react-icons/md";

import styles from "./styles";

import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";

import "../../assets/specific-css/flag/flag.css";

import { Header as StorybookHeader } from "hubchain-storybook-design-pattern";
import { useAuth } from "../../services/auth";
import { useNavigation } from "@react-navigation/native";
import { UserAuthService } from "../../services/user/userAuthService";

interface HeaderProps {
	onToggleMenu?: any;
}

export default function Header(props: HeaderProps | any) {
	const { i18n, t } = useTranslation();
	const { user, logout } = useAuth();
	const navigation = useNavigation();

	const [language, setLanguage] = useState("BR");

	const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);

	const userAuthService = UserAuthService.getInstance();

	const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);

	useEffect(() => {
		getLanguage();
	}, []);

	const handleLogout = async () => {
		userAuthService.logout();
		await logout();
	};

	const toggleSettingsMenu = (newState?: boolean) => {
		if (newState !== undefined) {
			setSettingsMenuOpen(newState);
		} else {
			setSettingsMenuOpen((state) => !state);
		}
	};

	async function getLanguage() {
		const languageStorage = await AsyncStorage.getItem("language");
		if (!languageStorage) {
			const request = await fetch(
				"https://ipinfo.io/json?token=1b3b6a43c395e5"
			);
			const jsonResponse = await request.json();
			if (jsonResponse.country === "BR") {
				changeLanguage("BR");
			} else {
				changeLanguage("US");
			}
		} else {
			changeLanguage(languageStorage);
		}
	}

	const changeLanguage = (lng: any) => {
		setLanguage(lng);
		(async () => {
			await AsyncStorage.setItem("language", lng);
		})();
		i18n.changeLanguage(lng == "BR" ? "pt" : "en");
	};

	return (
		<>
			<StorybookHeader
				isMobile={isMobile}
				userInfo={
					user
						? { email: user?.email || "", name: user?.name || "" }
						: undefined
				}
				settingsMenu={
					user
						? {
								isOpen: settingsMenuOpen,
								entries: {
									logout: {
										onClick: () => setLogoutDialogVisible(true),
										label: t("header-settings-menu.logout"),
									},
									language: {
										label: t("header-settings-menu.language"),
										values: ["BR", "US"],
										selectedValue: language,
										onSelectValue: (lng) => changeLanguage(lng),
									},
								},
								onMenuButtonPress: (newState) => toggleSettingsMenu(newState),
						  }
						: undefined
				}
			>
				<View style={{ flexDirection: "row" }}>
					{isMobile && props.onToggleMenu && (
						<TouchableOpacity
							style={[styles.menuButton]}
							onPress={() => props.onToggleMenu("header")}
						>
							<MdMenu color={"#FFF"} size={24} />
						</TouchableOpacity>
					)}
					<TouchableOpacity
						onPress={() =>
							user
								? navigation.navigate("dashboard")
								: navigation.navigate("signIn")
						}
					>
						<Logo
							variant={"flush"}
							size={"small"}
							background={{ type: "none" }}
						/>
					</TouchableOpacity>
				</View>
			</StorybookHeader>

			<Modal
				contentOverflowY={"auto"}
				visible={logoutDialogVisible}
				onClose={() => setLogoutDialogVisible(false)}
				onSubmit={() => handleLogout()}
				icon="interrogation"
				header={"clean"}
				customWidth={{
					maxWidth: "324px",
					minWidth: "80px",
				}}
				title={t("logout-modal.message")}
			>
				<View
					style={
						{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							gap: 12,
							marginTop: 20,
						} as any
					}
				>
					<Button
						label={t("logout-modal.buttons.cancel")}
						size={"small"}
						variant={"danger"}
						onClick={() => {
							setLogoutDialogVisible(false);
						}}
					/>

					<Button
						label={t("logout-modal.buttons.confirm")}
						size={"small"}
						variant={"primary"}
						onClick={() => {
							handleLogout();
						}}
					/>
				</View>
			</Modal>
		</>
	);
}
