import { AvailableThemes } from "hubchain-storybook-design-pattern/lib/components/Theme/Theme";
import { PermissionType } from "../../permissions/permissions";

export enum TenantAppDomainEnum {
  HUBCHAIN = "hubchain.com",          // 0017 
  HUBCHAINBANK = "hubchainbank.com",  // 0001
  ALPINEC = "alpinec.com",            // 0002
  AVILABANK = "avilabank.com",        // 0003
  BETRADERCLUB = "betraderclub.com",  // 0004
  CINKINVEST = "cinkinvest.com",      // 0005
  ALEXIAPAY = "alexiapay.com",        // 0006
  EZZEPAY = "ezzepay.io",             // 0007
  LIBERPAG = "liberpag.io",           // 0008
  BANKEI = "bankei.io",               // 0009
  PINBANK = "pinbank.org",            // 0010
  DZEROPAY = "dzeropay.io",           // 0011
}

export enum UsernameTypes {
  EMAIL,
  CPF_CNPJ,
  EMAIL_CPF_CNPJ
}

interface TenantConfigPaymentGatewayRoutes {
  "payment-gateway": boolean,
  "payment-gateway-crypto": boolean,
}


interface TenantConfigReportsRoutes {
  "reports": boolean,
  "reports-user-registration": boolean,
  "reports-account-banks": boolean,
  "reports-input-of-values": boolean,
  "reports-output-of-values": boolean,
  "reports-financial-transactions": boolean,
  "reports-deposits-crypto": boolean,
  "reports-withdrawal-commissions": boolean,
  "reports-deposit-commissions": boolean,
}

interface TenantConfigApprovalRoutes {
  "approved-users": boolean,
  "approved-withdrawals": boolean,
  "approved-deposits": boolean,
  "approved-withdrawals-crypto": boolean,
}

export interface TenantConfigUsername {
  "login": UsernameTypes,
  "recoveryPassword": UsernameTypes
}

export interface TenantConfigUserAccountEWallet {
  "user-accounts-ewallet-enabled": boolean,
}


export interface TenantConfigRoutes extends TenantConfigReportsRoutes, TenantConfigApprovalRoutes, TenantConfigPaymentGatewayRoutes {
  "dashboard": boolean,
  "sac": boolean,
  "administrators": boolean,
  "users-approval": boolean,
  "users-accounts": boolean,
  "blocked-admins": boolean,
  "permission-management": boolean,
  "approved-order-buy": boolean,
  "approved-order-sell": boolean,
  "trade": boolean,
  "trade-buy": boolean,
  "trade-sell": boolean,
  "trade-swap": boolean,
  "settings": boolean,
  "settings-icons": boolean,
  "settings-webhooks": boolean,
  "settings-currencies": boolean,
  "settings-markets": boolean,
  "settings-ticker": boolean,
}

export interface TenantContents {
  "about-us": any,
  "digital-bank": any,
  "reason": any,
  "security": any,
  "terms-and-conditions": any,
  "fees-and-commissions": any,
  "politically-exposed": any,
  "term-of-adhesion-and-risk-science": any
}

export interface TenantConfig {
  name: TenantAppDomainEnum,
  theme: AvailableThemes,
  title: string,
  companyName?: string, // Usado nos termos e condições de uso.
  favicon: any,
  legalPersonUsers: boolean,
  userDocumentId: "default" | "generic",
  username: TenantConfigUsername,
  routes: TenantConfigRoutes,
  permissions: PermissionType[],
  contents: TenantContents,
}
