import { RequestInvoiceModel } from "../../../src/services/invoice/invoiceService";
import { useTranslation } from "react-i18next";
import { Icon, Text, View } from "hubchain-storybook-design-pattern";
import React, { useState } from "react";
import { TouchableOpacity } from "react-native";
import { CustomHoverableText } from "../../../src/components/LinkTouchableOpacity";
import CurrencyIcon from "../../../src/components/CurrencyIcon";
import { setStringAsync } from "expo-clipboard";
import Util from "../../../src/utils";

const PaymentGatewayCryptoAllowedMethods = ({
  request: { invoice },
}: {
  request: RequestInvoiceModel;
}) => {
  const { t } = useTranslation();

  const paymentMethods: { label: string; item: any, toolTip: string[] }[] = [];

  invoice?.paymentMethods?.forEach((paymentMethod, index, array) => {
    let label = "";
    let toolTip = [];
    let item = null;

    if (typeof invoice?.allowedPaymentMethods[0] === "string" && invoice?.allowedPaymentMethods[0] === "*") {
      label = "all";
    } else if ("currency" in paymentMethod) {
      label = paymentMethod.currency;
      item = paymentMethod;
    } else {
      label = paymentMethod;
      item = paymentMethod;
    }

    const exists =
      paymentMethods.findIndex((item) => item.label === label) !== -1; //ignore methods already listed

    if (!exists) {
      if ((typeof invoice?.allowedPaymentMethods[0] === "string" && invoice?.allowedPaymentMethods[0] === "*") && "currency" in paymentMethod) {
        toolTip = array
          .filter((item: any) => {
            if (item === "*") {
              return false;
            } else if ("currency" in item && item.currency === label) {
              return true;
            }
          })
          .map((item: any) => item.networks)
          .flat();
      }
      paymentMethods.push({ label, item, toolTip });
    }
  });

  const paymentMethodsLength = paymentMethods.length;


  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {paymentMethods?.slice(0, 2).map((paymentMethod) => {

        const [imagePaymentMethodLabelSrc, setImagePaymentMethodLabelSrc] = useState<string>(`/static/images/currencies/${paymentMethod?.label ?? 'UNKNOWN'}.svg`);

        return (
          <>

            <a
              data-tooltip-id={"tooltip-default"}
              data-tooltip-content={paymentMethod.toolTip.join(", ")}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 14,
                  backgroundColor: "gray",
                  width: "fit-content",
                  padding: 2,
                  gap: 2,
                }}
              >

                <Text size={"8px"} fontStyle={"bold"} variant={"white"}>
                  {paymentMethod.label === "all" ? (t("payment-gateway-crypto.table.rows.allowed-payment-methods.all")
                  ) : (
                    <Text
                      style={{ display: "flex", alignItems: "center", gap: 2 }}
                      size={"8px"}
                      fontStyle={"bold"}
                      variant={"white"}
                    >
                      <CurrencyIcon name={paymentMethod.label} style={{ width: 12, height: 12 }} />
                      {paymentMethod.label}
                    </Text>
                  )}

                </Text>

              </View>
            </a>

            {
              paymentMethod?.item?.networks?.[0]?.address &&
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 14,
                  width: "fit-content",
                  padding: 2,
                  gap: 2,
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <a
                    data-tooltip-id={"tooltip-on-click"}
                    data-tooltip-content={t(`general.tooltip.copied`)}
                  >
                    <TouchableOpacity
                      onPress={() =>
                        paymentMethod?.item?.networks?.[0]?.addressExplorerUrl
                        && window.open(paymentMethod?.item?.networks?.[0]?.addressExplorerUrl, "_blank", "noreferrer")
                      }
                    >

                      <View
                        style={{ display: "flex", flexDirection: "row", gap: 2 }}
                      >
                        <CustomHoverableText size={"10px"} variant={"dark"}>
                          {
                            Util.maskBlockchainAddressOrTransactionId(paymentMethod?.item?.networks?.[0]?.address)
                          }
                        </CustomHoverableText>
                      </View>

                    </TouchableOpacity>
                  </a>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <a
                    data-tooltip-id={"tooltip-on-click"}
                    data-tooltip-content={t(`general.tooltip.copied`)}
                  >
                    <TouchableOpacity
                      style={{ marginLeft: 4 }}
                      onPress={() => setStringAsync(paymentMethod?.item?.networks?.[0]?.address)}
                    >
                      <Icon name={"CopyIcon"} fontSize={"11px"} />
                    </TouchableOpacity>
                  </a>
                </View>
              </View>
            }

          </>


        );
      })}

      {paymentMethodsLength - 2 > 0 ? (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 14,
            backgroundColor: "gray",
            width: "fit-content",
            padding: 2,
            gap: 2,
          }}
        >
          <Text size={"8px"} fontStyle={"bold"} variant={"white"}>
            {t("payment-gateway-crypto.table.rows.allowed-payment-methods.all")}{" "}
            {paymentMethodsLength - 1}
          </Text>
        </View>
      ) : null}
    </View>
  );
};

export default PaymentGatewayCryptoAllowedMethods;
