// import { PUSHER_API_KEY, PUSHER_API_CLUSTER } from "@env";
import OneSignal from 'react-onesignal';
import { TenantAppDomainEnum, TenantConfig } from '../multi-tenancy/types';
import { hostname } from 'os';
// import { UserSessionStorage } from "../auth";
// import { TenantConfig } from "../multi-tenancy/types";


interface OneSignalCredential {
  appId: string,
  safari_web_id: string,
}

class OneSignalServiceClass {

  private static _instance = null;
  private static _initialized = false;

  private static _credentials: { [host: string]: OneSignalCredential } = {
    ["backoffice.ezzepay.io"]: { // https://dashboard.onesignal.com/apps/29d60cba-5674-416d-8ca4-ca9028bf232e/settings/webpush/install
      appId: "29d60cba-5674-416d-8ca4-ca9028bf232e",
      safari_web_id: "web.onesignal.auto.32d5e245-163c-40cb-8f57-67057af17423",
    },
    ["ezzepay-backoffice.local.hubchainbank.com"]: { // https://dashboard.onesignal.com/apps/81b5a2dc-2135-4a89-8d72-3966f737af27/settings/webpush/install      
      appId: "81b5a2dc-2135-4a89-8d72-3966f737af27",
      safari_web_id: "web.onesignal.auto.5ccade99-0f35-4775-9ae0-5e2c3bfd110b"
    },
    ["backoffice.bankei.io"]: { // https://dashboard.onesignal.com/apps/0b6d1a23-7d0b-4d67-ad33-b4582b4a46a9/settings/webpush/install
      appId: "0b6d1a23-7d0b-4d67-ad33-b4582b4a46a9",
      safari_web_id: "web.onesignal.auto.1cd53ad7-1124-4d48-8712-0a155e6245f9",
    },
    ["bankei-backoffice.local.hubchainbank.com"]: { // https://dashboard.onesignal.com/apps/a4616b56-0ea5-4fee-b40c-b6f7add6663e/settings/webpush/install      
      appId: "a4616b56-0ea5-4fee-b40c-b6f7add6663e",
      safari_web_id: "web.onesignal.auto.014ea808-90d0-46f9-a566-af81aa1c1278",
    },
  }

  constructor() {

  }

  async init(tenant: TenantConfig) {

    // console.log("::OneSignalServiceClass::", "init[0]");

    if (OneSignalServiceClass._initialized === true) return;
    OneSignalServiceClass._initialized = true;

    // OneSignal.Notifications.addEventListener('click', (event) => {
    //   console.log("::OneSignalServiceClass::", "The notification was clicked!", event);
    // });

    console.log("::OneSignalServiceClass::::init()", `[${window.location.hostname}]`, OneSignalServiceClass._credentials?.[window.location.hostname] ?? {});

    return OneSignal.init({
      ...(OneSignalServiceClass._credentials?.[window.location.hostname] ?? {} as OneSignalCredential),
      notifyButton: {
        enable: true, /* Required to use the Subscription Bell */
        size: 'large', /* One of 'small', 'medium', or 'large' */
        theme: 'inverse', /* One of 'default' (red-white) or 'inverse" (white-red) */
        position: 'bottom-right', /* Either 'bottom-left' or 'bottom-right' */
        // offset: {
        //   bottom: '0px',
        //   left: '0px', /* Only applied if bottom-left */
        //   right: '0px' /* Only applied if bottom-right */
        // },
        showCredit: false, /* Hide the OneSignal logo */
        autoResubscribe: true,
        autoRegister: true,
        persistNotification: true,
        // subdomainName: tenant.name,  
        displayPredicate: function () {
          /* The user is subscribed, so we want to return "false" to hide the Subscription Bell */
          return !OneSignal.Notifications.permission
        },
        text: {
          'tip.state.unsubscribed': 'Ativar sua Notificações',
          'tip.state.subscribed': "Suas notificações estão ativas.",
          'tip.state.blocked': "Suas notificações estão desabilitadas.",
          'message.prenotify': 'Clique para ativar suas notificações',
          'message.action.subscribed': "Suas notificações estão ativas!",
          'message.action.resubscribed': "Suas notificações estão ativas.",
          'message.action.unsubscribed': "You won't receive notifications again",
          'dialog.main.title': 'Manage Site Notifications',
          'dialog.main.button.subscribe': 'SUBSCRIBE',
          'dialog.main.button.unsubscribe': 'UNSUBSCRIBE',
          'dialog.blocked.title': 'Ativar suas Notificações',
          'dialog.blocked.message': "Siga estas instruções para habilitar suas Notificações:"
        }
      },
      colors: { // Customize the colors of the main button and dialog popup button
        'circle.background': 'rgb(84,110,123)',
        'circle.foreground': 'white',
        'badge.background': 'rgb(84,110,123)',
        'badge.foreground': 'white',
        'badge.bordercolor': 'white',
        'pulse.color': 'white',
        'dialog.button.background.hovering': 'rgb(77, 101, 113)',
        'dialog.button.background.active': 'rgb(70, 92, 103)',
        'dialog.button.background': 'rgb(84,110,123)',
        'dialog.button.foreground': 'white'
      },
      promptOptions: {
        /* Change bold title, limited to 30 characters */
        siteName: tenant.companyName,
        /* Subtitle, limited to 90 characters */
        actionMessage: "Deseja ativar as notificações importantes",
        /* Example notification title */
        exampleNotificationTitle: 'Título: Exemplo de Notificação',
        /* Example notification message */
        exampleNotificationMessage: 'Este é um exemplo de notificação',
        /* Text below example notification, limited to 50 characters */
        exampleNotificationCaption: 'Você pode desativar as Notificações a qualquer momento',
        /* Accept button text, limited to 15 characters */
        acceptButtonText: "Ativar",
        /* Cancel button text, limited to 15 characters */
        cancelButtonText: "Não, obrigado.",
        autoAcceptTitle: 'Clique Ativar'
      },
      // // Your other init options here
      // promptOptions: {
      //   customlink: {
      //     enabled: true, /* Required to use the Custom Link */
      //     style: "button", /* Has value of 'button' or 'link' */
      //     size: "medium", /* One of 'small', 'medium', or 'large' */
      //     color: {
      //       button: '#E12D30', /* Color of the button background if style = "button" */
      //       text: '#FFFFFF', /* Color of the prompt's text */
      //     },
      //     text: {
      //       subscribe: "Ativar notifications push", /* Prompt's text when not subscribed */
      //       unsubscribe: "Desativar notifications push", /* Prompt's text when subscribed */
      //       explanation: "Receba atualizações de todos os tipos de coisas que são importantes para você", /* Optional text appearing before the prompt button */
      //     },
      //     unsubscribeEnabled: true, /* Controls whether the prompt is visible after subscription */
      //   }
      // }
      // promptOptions: {
      //   slidedown: {
      //     prompts: [{
      //       text: {
      //         actionMessage: "The callout asking the user to opt-in. Up to 90 characters.",
      //         acceptButton: "Triggers the opt-in. Up to 15 characters.",
      //         cancelButton: "Cancels opt-in. Up to 15 characters."
      //       }
      //     }]
      //   }
      // },
    }).then(() => {
      console.log("::OneSignalServiceClass::", "[OK]");
      OneSignal.Slidedown.promptPush();
      // OneSignal.Slidedown.promptPushCategories();
    }).catch(
      error => console.log("::OneSignalServiceClass::", "[ERROR]", error)
    );

  }

  async login(user: { id: string, email: string }, context: { tenant: { appDomain: string }, env: "development" | "sandbox" | "production", hostname: string }) {
    await OneSignal.login(user.id);
    if (user?.email) OneSignal.User.addEmail(user?.email);
    OneSignal.User.addTags({
      env: context.env,
      tenant: context.tenant.appDomain,
      hostname: context.hostname,
    });
  }

  async logout() {
    await OneSignal.logout();
  }
}

export const OneSignalService = new OneSignalServiceClass();
