import axios from "axios";
import 'dotenv/config';
import { API_URL } from "@env";
import { MultiTenancyService } from "./multi-tenancy/multi-tenancy.service";

const defaultPrefix = "v2";
const corePrefix = "v3/core";

const API = axios.create({
  baseURL: API_URL
});

API.interceptors.request.use(async (request) => {
  return request;
});

export default API;

export { defaultPrefix, corePrefix };
