import {VariantsType} from "hubchain-storybook-design-pattern/lib/components/Input/types";
import React, {useState} from "react";
import {Icon, Text, View} from "hubchain-storybook-design-pattern";
import {TouchableOpacity} from "react-native";
import {setStringAsync} from "expo-clipboard";

const ConfirmModalInfoRow = ({ label, value, subValues, variant, allowCopy }: { label: string, value: string, subValues?: string[], variant?: VariantsType, allowCopy?: boolean }) => {
  const [copied, setCopied] = useState(false);

  return (
    <View style={{ display: "flex", flexDirection: "row", alignItems: subValues?.length > 0 ? "flex-start" : "center" }}>
      <Text fontStyle={"bold"}>{ label + ":" }</Text>
      <View style={{ display: "flex", flexDirection: "column", gap: 4, paddingLeft: 8, flex: 1 }}>
        <Text variant={variant} numberOfLines={1}>{ value }</Text>
        {
          subValues?.map(subValue => (
            <Text key={subValue} variant={"gray"} size={"12px"} numberOfLines={1}>{ subValue }</Text>
          ))
        }
      </View>
      {
        allowCopy && (
          <TouchableOpacity
            style={{ marginLeft: 4 }}
            onPress={() => {
              setStringAsync(value);
              setCopied(true);
            }}
          >
            <Icon name={"CopyIcon"} fontSize={"14px"} variant={copied ? "success" : "primary"}/>
          </TouchableOpacity>
        )
      }
    </View>
  );
}

export { ConfirmModalInfoRow }
