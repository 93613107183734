import React, { useEffect, useMemo, useRef, useState } from "react";

import DefaultLayout from "../../src/layouts/default-layout";
import { useTranslation } from "react-i18next";

import { TCurrencySymbol } from "../../src/types/currency";
import { useQuery } from "react-query";
import SwapService from "../../src/services/swap/swapService";
import { DataTable, ProgressBar } from "react-native-paper";
import GeneralCSS from "../../src/global-constants/Styles";
import { Text, TooltipConfig, View } from "hubchain-storybook-design-pattern";
import Colors from "../../src/global-constants/Colors";
import { DefaultFilter } from "../../src/models/DefaultFilter";
import { TextStyle } from "react-native";
import Util from "../../src/utils";

import { RequestStatusId } from "../../src/enums/RequestStatusId.enum";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";
import { useAuth } from "../../src/services/auth";
import InvoiceService, {
	RequestInvoiceModel,
} from "../../src/services/invoice/invoiceService";
import { FiltersEntries } from "hubchain-storybook-design-pattern/lib/components/Filter/types";
import { PaymentModal } from "../../src/components/PaymentModal/PaymentModal";
import { useCurrency } from "../../src/hooks/useCurrency";
import PaymentGatewayCryptoTableBody from "./components/PaymentGatewayCryptoTableBody";
import { useIsFocused } from "@react-navigation/native";

export type InvoiceModalActionType = "new" | "edit" | "view" | "cancel";

export interface InvoiceModalConfig {
	visible: boolean;
	currencyIn: TCurrencySymbol | null;
	user?: string;
	request?: RequestInvoiceModel;
	action: InvoiceModalActionType;
}

type CustomTableTextStyle = TextStyle & {
	display: string;
	whiteSpace: string;
	maxHeight: string;
	lineHeight: number;
};
export default function PaymentGatewayCryptoScreen() {
	const { user } = useAuth();
	const { t } = useTranslation();
	const { currencies, markets } = useCurrency();
	const isFocused = useIsFocused();

	const [filter, setFilter] = useState<DefaultFilter>({
		page: 1,
		limit: 20,
		total: 0,
		search: {},
	});

	const {
		data: invoiceListResponse,
		isFetching,
		isLoading,
		refetch,
	} = useQuery(["payment-gateway/invoices", filter.page, filter.search], {
		queryFn: () => InvoiceService.list(filter, { invoicesOnly: true }),
		enabled: !!user?.isHubchainUser,
		staleTime: 60 * 5 * 1000, // 5 minutes
		retry: false,
		retryDelay: 1000 * 10,
		refetchOnWindowFocus: false,
	});

	filter.total = invoiceListResponse?.data?.count || 0;

	useEffect(() => {
		if (isFocused && !isLoading) {
			refetch();
		}
	}, [isFocused]);

	const statusOptions: SelectOptionModel[] = useMemo(() => {
		return [
			{ value: "", label: t("general.filter-options.all"), color: "primary" },
			{
				value: RequestStatusId[RequestStatusId.COMPLETED],
				label: t(`swap.status.${RequestStatusId.COMPLETED}`),
				color: "success",
				icon: (
					<View
						variant={"success"}
						style={{ width: 8, height: 6, borderRadius: 10, marginRight: 6 }}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.PENDING],
				label: t(`swap.status.${RequestStatusId.PENDING}`),
				color: "warning",
				icon: (
					<View
						variant={"warning"}
						style={{ width: 8, height: 6, borderRadius: 10, marginRight: 6 }}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.CANCELLED],
				label: t(`swap.status.${RequestStatusId.CANCELLED}`),
				color: "danger",
				icon: (
					<View
						variant={"danger"}
						style={{ width: 8, height: 6, borderRadius: 10, marginRight: 6 }}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.EXPIRED],
				label: t(`swap.status.${RequestStatusId.EXPIRED}`),
				icon: (
					<View
						style={{
							width: 8,
							height: 6,
							borderRadius: 10,
							marginRight: 6,
							backgroundColor: "#ffa500",
						}}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.AWAITING_DEPOSIT],
				label: t(`swap.status.${RequestStatusId.AWAITING_DEPOSIT}`),
				icon: (
					<View
						style={{
							width: 8,
							height: 6,
							borderRadius: 10,
							marginRight: 6,
							backgroundColor: "#7393B3",
						}}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.AWAITING_WITHDRAWAL],
				label: t(`swap.status.${RequestStatusId.AWAITING_WITHDRAWAL}`),
				icon: (
					<View
						style={{
							width: 8,
							height: 6,
							borderRadius: 10,
							marginRight: 6,
							backgroundColor: "#B2BEB5",
						}}
					/>
				),
			},
		];
	}, []);

	const [filterStatus, setFilterStatus] = useState(statusOptions[0]);

	const currencyInOptions: SelectOptionModel[] = useMemo(() => {
		const options: SelectOptionModel[] = [
			{ value: "", label: t("general.filter-options.all"), color: "primary" },
			...currencies
				.filter((currency) => currency.type === "crypto")
				.map((currency) => ({
					value: currency.currency,
					label: currency.name,
					subLabel: currency.currency,
					icon: currency.currency,
				})),
		];

		return options;
	}, [currencies]);

	const [filterCurrencyIn, setFilterCurrencyIn] = useState(
		currencyInOptions[0]
	);

	const filters: FiltersEntries[] = useMemo(() => {
		const filters: FiltersEntries[] = [
			{
				label: t(`payment-gateway-crypto.table.filters.currency-in`),
				visible: !!user?.isHubchainUser,
				icon: {
					name: "Filter",
				},
				type: "select",
				params: {
					options: currencyInOptions,
					placeholder: "",
					selected: filterCurrencyIn,
					type: "currencyIn",
					iconSet: "currency",
					iconSize: 24,
				},
				customWidth: {
					minWidth: "144px",
				},
			},
			{
				label: t(`payment-gateway-crypto.table.filters.status`),
				visible: !!user?.isHubchainUser,
				icon: {
					name: "Filter",
				},
				type: "select",
				params: {
					options: statusOptions,
					placeholder: "",
					selected: filterStatus,
					type: "status",
				},
			},
			{
				name: "searchInput",
				placeholder: t(`payment-gateway-crypto.table.filters.search`),
				icon: {
					name: "SearchIcon",
				},
				visible: !!user?.isHubchainUser,
				type: "input",
				params: {
					action: "search",
					type: "search",
				},
			},
			{
				name: "newPaymentGatewayCryptoButton",
				label: t("payment-gateway-crypto.buttons.new-invoice"),
				icon: {
					name: "AddIcon",
					web: {
						size: "19px",
					},
					mobile: {
						size: "19px",
					},
				},
				params: {
					action: "new-invoice",
				},
				disabled: false /* !user?.isHubchainUser */,
				visible: true,
				type: "button",
			},
			{
				name: "refreshButton",
				icon: {
					name: "Redo",
					web: {
						size: "19px",
					},
					mobile: {
						size: "19px",
					},
				},
				params: {
					action: "refresh",
				},
				disabled: isLoading || isFetching,
				visible: true,
				type: "button",
				isLoading: isLoading || isFetching,
			},
		];

		return filters;
	}, [
		user,
		isFetching,
		isLoading,
		currencyInOptions,
		statusOptions,
		filterStatus,
		filterCurrencyIn,
	]);

	useEffect(() => {
		let updatedSearch = { ...filter.search };

		if (filterCurrencyIn.value) {
			updatedSearch.currencyIn = filterCurrencyIn.value;
		} else {
			delete updatedSearch.currencyIn;
		}

		if (filterStatus.value) {
			updatedSearch.requestStatus = { key: filterStatus.value };
		} else {
			delete updatedSearch.requestStatus;
		}

		if (JSON.stringify(updatedSearch) !== JSON.stringify(filter.search)) {
			setFilter((state) => ({
				...state,
				search: updatedSearch,
			}));
		}
	}, [filterCurrencyIn, filterStatus]);

	const onFilterChange = (option: SelectOptionModel, type: any) => {
		if (type === "status") {
			setFilterStatus(option);
		} else if (type === "currencyIn") {
			setFilterCurrencyIn(option);
		}
	};

	const searchTimeout = useRef<any>();

	const onFilterSearchChange = (text: string) => {
		let updatedSearch = { ...filter.search };
		clearTimeout(searchTimeout.current);

		searchTimeout.current = setTimeout(() => {
			if (Util.isUUID(text)) {
				updatedSearch.id = text;
				delete updatedSearch.user;
			} else {
				updatedSearch.user = Util.mountDefaultUserSearch(
					text,
					updatedSearch.user
				);
				delete updatedSearch.id;
				if (!updatedSearch.user || text.trim() === "") {
					delete updatedSearch.user;
				}
			}

			if (text?.length >= 3 || !text?.length) {
				setFilter((state) => ({
					...state,
					page: 1,
					search: updatedSearch,
				}));
			}
		}, 300);
	};

	const { data: _cryptohubInfoBalancesData } = useQuery(
		["CryptoHubInfoBalances"],
		{
			queryFn: SwapService.getCryptoHubInfoBalances,
			staleTime: 60 * 1000 * 5, // 5 minutes
			retry: 2,
			retryDelay: 60 * 1000 * 2,
			refetchOnWindowFocus: false,
		}
	);

	const [invoiceModalConfig, setInvoiceModalConfig] =
		useState<InvoiceModalConfig>({
			visible: false,
			currencyIn: null,
			request: undefined,
			action: "new",
		});

	const openInvoiceModal = (
		currency?: TCurrencySymbol,
		action?: InvoiceModalActionType
	) => {
		setInvoiceModalConfig({
			visible: true,
			currencyIn: currency || TCurrencySymbol.BRL,
			action: action || "new",
		});
	};

	const closeInvoiceModal = () => {
		setInvoiceModalConfig({
			visible: false,
			request: undefined,
			currencyIn: null,
			action: "new",
		});
	};

	const requests = invoiceListResponse?.data?.rows || [];

	const columns = [
		"identifier",
		"account-in",
		"amount",
		"amount-in",
		"address-in-balance-amount",
		"currency-in",
		"destination",
		"fee",
		"method",
		"status",
		"",
	];
	const columnsMinWidth = [38, 70, 70, 96, 96, 70, 70, 150, 132, 76, 48];
	const columnsMaxWidth = [
		38,
		"unset",
		"unset",
		"unset",
		"unset",
		"unset",
		"unset",
		112,
		192,
		"unset",
		48,
	];

	const TableHeader = () => {
		return (
			<DataTable.Header
				style={[GeneralCSS.defaultTableHeader, { gap: 6 } as any]}
			>
				{columns.map((column, index) => {
					return (
						<DataTable.Title
							key={column}
							style={[
								{
									minWidth: columnsMinWidth[index],
									maxWidth: columnsMaxWidth[index],
									padding: 0,
									display: "flex",
									alignItems: "center",
								} as any,
							]}
							textStyle={
								{
									display: "flex",
									overflow: "visible",
									whiteSpace: "normal",
									maxHeight: "unset",
									lineHeight: 14,
								} as CustomTableTextStyle
							}
						>
							<Text
								style={[GeneralCSS.tableColumnTitle, { padding: 0 }]}
								typeStyle={{ type: "table", name: "table-header" }}
							>
								{column && t(`payment-gateway-crypto.table.columns.${column}`)}
							</Text>
						</DataTable.Title>
					);
				})}
			</DataTable.Header>
		);
	};

	return (
		<>
			<DefaultLayout
				titlePath={t("payment-gateway-crypto.titlePath", {
					returnObjects: true,
					replace: { count: filter.total || "0" },
				})}
				title={t(`payment-gateway-crypto.titlePath`)}
				filters={filters}
				onChange={(option: SelectOptionModel, type: any) =>
					onFilterChange(option, type)
				}
				onChangeText={onFilterSearchChange}
				onClick={(params: any) => {
					if (params.action === "refresh") {
						refetch();
					} else if (params.action === "new-invoice") {
						openInvoiceModal();
					}
				}}
				handleBack={() => {}}
				handleRefresh={() => {}}
			>
				<View style={[{ display: "flex", flexDirection: "column", gap: 16 }]}>
					<View style={[GeneralCSS.card]}>
						<View style={{ overflow: "auto" }}>
							<TableHeader />
							<View style={{ zIndex: 2 }}>
								<ProgressBar
									visible={isFetching || isLoading}
									color={Colors.light.primary}
									indeterminate={true}
								/>
							</View>
							<View style={{ opacity: isFetching || isLoading ? 0.5 : 1 }}>
								<PaymentGatewayCryptoTableBody
									requests={requests}
									columnsMinWidth={columnsMinWidth}
									columnsMaxWidth={columnsMaxWidth}
									isLoading={isLoading}
									isFetching={isFetching}
									setInvoiceModalConfig={setInvoiceModalConfig}
								/>
							</View>
						</View>
						<DataTable.Pagination
							page={filter.page}
							numberOfPages={Math.ceil(filter.total / filter.limit)}
							numberOfItemsPerPage={filter.limit}
							onPageChange={(page) => () => {}}
							showFastPaginationControls={true}
							label={``}
						/>
					</View>
				</View>
			</DefaultLayout>

			<TooltipConfig />

			{invoiceModalConfig.visible && (
				<PaymentModal
					request={invoiceModalConfig.request}
					currencyIn={invoiceModalConfig.currencyIn}
					action={invoiceModalConfig.action}
					onClose={closeInvoiceModal}
				/>
			)}
		</>
	);
}
