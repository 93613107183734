import React, {useRef, useState} from "react";
import styles from "./styles";
import {useTranslation} from "react-i18next";
import {ActivityIndicator, TouchableOpacity, View} from "react-native";
import GeneralCSS from "../../src/global-constants/Styles";

import "../../src/i18n";
import Header from "../../src/components/header/header";

import {Formik} from "formik";
import * as Yup from "yup";

import ReCAPTCHA from "react-google-recaptcha";
import {AuthTypeEnum} from "../../src/enums/AuthType.enum";
import {recaptchaSiteKey} from "../../src/global-constants/RecaptchaVars";
import {UserAuthService} from "../../src/services/user/userAuthService";
import TwoFactorModal from "../../src/components/TwoFactorModal";
import {useAuth} from "../../src/services/auth";

import {Alert, Button, Icon, Input, Text} from "hubchain-storybook-design-pattern";

import {useMultiTenancy} from "../../src/services/multi-tenancy/multi-tenancy";
import {isMobile} from "react-device-detect";
import {Variants} from "hubchain-storybook-design-pattern/lib/components/Alert/types";
import {validateCNPJ, validateCPF, validateEmail} from "validations-br";


import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";
import {useAlert} from "../../src/hooks/useAlert";

type UsernameInputType = "email" | "emailOrCpf" | "emailOrCpfOrCnpj" | "CpfOrCnpj" | "Cpf" | "Cnpj";

export default function SignInScreen(props: any) {
  const {tenant} = useMultiTenancy();
  const {showAlert} = useAlert();

  const { t } = useTranslation();
  const { login } = useAuth();

  const [lookPass, setLookPass] = useState(false);

  const userAuthService = UserAuthService.getInstance();
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [authModalType, setAuthModalType] = useState < AuthTypeEnum > (AuthTypeEnum.PIN);
  const [signInRequest, setSignInRequest] = useState({
    email: "",
    password: ""
  });

  let _usernameInputType: UsernameInputType = "email";

  const errorMessages = {
    emptyField: t("snackBar.errors.empty-field"),
    invalidEmail: t("snackBar.errors.invalid-email"),
    invalidCPF: t("snackBar.errors.invalid-cpf"),
    invalidCNPJ: t("snackBar.errors.invalid-cnpj"),
    invalidCPForCNPJ: t("snackBar.errors.invalid-cpf-cnpj"),
    invalidCPForEmail: t("snackBar.errors.invalid-cpf-email"),
    invalidCPForCNPJorEmail: t("snackBar.errors.invalid-cpf-cnpj-email"),
    invalidField: t("snackBar.errors.invalid-field")
  }

  const getUsernameValidationByUsernameInputType = () => {
    let validation = Yup.string().email(errorMessages.invalidEmail).required(errorMessages.emptyField);

    switch (_usernameInputType) {
      case "Cpf":
        validation = Yup.string().required(errorMessages.emptyField).test(
            "is-cpf",
            errorMessages.invalidCPF,
            value => validateCPF(value)
        );
        break;
      case "Cnpj":
        validation = Yup.string().required(errorMessages.emptyField).test(
            "is-cnpj",
            errorMessages.invalidCNPJ,
            value => validateCNPJ(value)
        );
        break;
      case "CpfOrCnpj":
        validation = Yup.string().required(errorMessages.emptyField).test(
            "is-cpf-or-cnpj",
            errorMessages.invalidCPForCNPJ,
            value => validateCPF(value) || validateCNPJ(value)
        );
        break;
      case "emailOrCpf":
        validation = Yup.string().required(errorMessages.emptyField).test(
            "is-email-or-cpf",
            errorMessages.invalidCPForEmail,
            value => validateEmail(value) || validateCPF(value)
        );
        break;
      case "emailOrCpfOrCnpj":
        validation = Yup.string().required(errorMessages.emptyField).test(
            "is-email-or-cpf-or-cnpj",
            errorMessages.invalidCPForCNPJorEmail,
            value => validateEmail(value) || validateCPF(value) || validateCNPJ(value)
        );
        break;
    }

    return validation;
  }

  const SignInSchema = Yup.object().shape({
    email: getUsernameValidationByUsernameInputType(),
    password: Yup.string().required(errorMessages.emptyField)
  });

  const reRef:any = useRef < ReCAPTCHA > ();

  const typeStyle:typesStyle = "signIn";

  const [isLoading, setLoading] = useState(false);

  const handleRecaptchaLoadError = () => {
    showAlert(t("snackBar.errors.captcha-load-error"));
    setTimeout(() => {
      window.location.reload();
    }, 5000)
  }

  const getNewRecaptcha = async () => {
    if (reRef.current.getValue()) {
      reRef.current.reset();
    }
    return await reRef.current.executeAsync();
  }

  const handleErrorMessage = (message: string) => {
    return !message.includes(".errors.") ? message : t("snackBar.errors.sign-in-default-error")
  }

  const signInAuth = async (token: string) => {
    try {
      const recaptcha = await getNewRecaptcha();
      const { email, password } = signInRequest;
      const { authorization, name } = await userAuthService.login(email, password, recaptcha, token);

      await login(authorization, email, name);
      setAuthModalVisible(false);

      props.navigation.navigate("dashboard");
    } catch (error) {
      return handleErrorMessage(t(`signIn.errors.${error.response?.data?.reason || error.message}`));
    }
  }

  const submitForm = async (values: any) => {
    try {
      setLoading(true);
      const recaptcha = await getNewRecaptcha();
      const response = await userAuthService.login(values.email, values.password, recaptcha);
      setSignInRequest({
        email: values.email,
        password: values.password
      });
      if (response.message) {
        setAuthModalVisible(true);
        setAuthModalType(response.message);
      } else {
        await login(response.authorization, response.email, response.name);
        props.navigation.navigate("dashboard");
      }
    } catch (error: any) {
      setLoading(false);
      showAlert(handleErrorMessage(t('snackBar.errors.' + ( error.response?.data?.reason || error.message ))));
      return;
    }
  }

  const closeTwoAuthModal = (success: any) => {
    setAuthModalVisible(false);
    if (!success) {
      setLoading(false);
    }
  }

  return (
    <>
      <View style={[GeneralCSS.notLoggedContainer]}>
        <TwoFactorModal
          visible={authModalVisible}
          type={authModalType}
          onRequestClose={(success: any) => closeTwoAuthModal(success)}
          onSubmit={(token: any) => signInAuth(token)}
        />

        <Header props={props} />

        {
          !isMobile &&
            <View style={[GeneralCSS.section, GeneralCSS.titleCenterPageBox, { backgroundColor: "none" }]}>
              <Text style={GeneralCSS.titleCenterPageText} variant="primary"
              typeStyle={{type:typeStyle, name:"title"}}
              >Login</Text>
            </View>
        }

        <View style={[GeneralCSS.sectionForm]}>
          <View style={[GeneralCSS.formBox]}>

            <View style={[styles.loginBox]}>

              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={SignInSchema}
                onSubmit={(values) => submitForm(values)}
                validateOnMount={true}
              >
                {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, setFieldValue, isValid }) => (

                  <>
                    <View style={[GeneralCSS.boxInput, {marginBottom: "1rem"}]}>
                      <Input
                        label={t(`signIn.username.label.${tenant.username.login || "0"}`)}
                        placeholder={t(`signIn.username.placeholder.${tenant.username.login || "0"}`)}

                        maxLength={120}

                        size={"large"}

                        value={values.email}
                        mask={"email"}

                        type={"text"}
                        onChange={value => {
                          setFieldTouched("email");
                          setFieldValue("email", value || "");
                        }}

                        variant={errors.email && touched.email ? "danger" : "primary"}

                        cleanMessageIcon={true}
                        errorMessage={touched.email && (errors.email != errorMessages.emptyField) && errors.email}
                      />
                    </View>

                    <View style={[GeneralCSS.boxInput]}>
                      <Input
                        placeholder={t("signIn.password-placeholder")}
                        label={"Senha"}

                        size={"large"}

                        maxLength={30}

                        value={values.password}

                        type={!lookPass ? "password" : "text"}
                        onChange={handleChange("password")}

                        variant={errors.password && touched.password ? "danger" : "primary"}
                      />

                      <TouchableOpacity onPress={() => { setLookPass(!lookPass) }} style={styles.lookPassView}>
                        {lookPass ? (
                            <Icon name="EyeFill" fontSize={'20px'} variant={"primary"}></Icon>
                        ) : (
                            <Icon name="EyeSlashFill" fontSize={'20px'} variant={"primary"}></Icon>
                        )}
                      </TouchableOpacity>
                    </View>
                    <TouchableOpacity onPress={() => { props.navigation.navigate("forgotPass") }} style={styles.forgetPassBox}>
                      <Text style={[styles.forgetPassText]} typeStyle={{type:typeStyle, name:"item"}}>
                        {" "}
                        Esqueceu sua senha ?
                      </Text>
                    </TouchableOpacity>

                    <View style={[GeneralCSS.row, styles.boxButtons]}>
                      <Button
                          label={t("signIn.go-in")}
                          variant={"primary"}
                          fontWeight={"bold"}

                          disabled={isLoading || !isValid}
                          disableHover={isLoading || !isValid}

                          size={"large"}

                          width={"100%"}

                          icon={isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}

                          onClick={async () => {
                            await handleSubmit();

                            if (Object.values(errors)?.length > 0) {
                              const error: any = Object.values(errors)[0];
                              showAlert(error.id ? error.id : error);
                            }
                          }}
                      />
                    </View>
                  </>
                )}

              </Formik>

            </View>

          </View>
        </View>

      </View>
      <ReCAPTCHA
        sitekey={recaptchaSiteKey || "invalid"}
        size="invisible"
        ref={reRef}
        style={{zIndex: 30}}
        onErrored={() => handleRecaptchaLoadError()}
        hl={t("internalization.locale") || "en"}
      />
    </>
  );
}
