import { GoMail } from "react-icons/go";

import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {View} from "react-native";
import GeneralCSS from "../../../src/global-constants/Styles";
import styles from "./styles";
import "../../../src/i18n";
import * as Yup from "yup";
import Header from "../../../src/components/header/header";
import {Formik, FormikValues} from "formik";
import { UserAuthService } from "../../../src/services/user/userAuthService";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaSiteKey } from "../../../src/global-constants/RecaptchaVars";
import PasswordStrengthBar from 'react-password-strength-bar';
import { maskPassword } from "../../../src/utils/masks";

import {Alert, Button, Input, Text} from "hubchain-storybook-design-pattern";
import {useMultiTenancy} from "../../../src/services/multi-tenancy/multi-tenancy";
import {isMobile} from "react-device-detect";
import {Variants} from "hubchain-storybook-design-pattern/lib/components/Alert/types";

import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";


const initialRedirectTime = 20; // seconds

export default function ForgotPasswordScreen(props: any) {
  const { t } = useTranslation();
  const { tenant } = useMultiTenancy();

  const [mailSent, setMailSent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState<Variants>("danger");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Aviso");

  const [timeToRedirect, setTimeToRedirect] = useState(initialRedirectTime);

  const authCode = props.route.params?.authcode || "";

  const userAuthService = new UserAuthService();

  const reRef:any = useRef < ReCAPTCHA > ();


  const typeStyle:typesStyle = "signIn";

  useEffect(() => {
    if(success) {
      const interval = setInterval(() => {
        setTimeToRedirect(time => time - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
        props.navigation.replace("signIn");
      }, initialRedirectTime * 1000);
    }
  }, [success])

  const handleRecaptchaLoadError = () => {
    showSnackBar(t("snackBar.errors.captcha-load-error"));
    setTimeout(() => {
      window.location.reload();
    }, 5000)
  }

  const getNewRecaptcha = async () => {
    if (reRef.current.getValue()) {
      reRef.current.reset();
    }
    return await reRef.current.executeAsync();
  }

  const showSnackBar = (message: string = "", type: "success" | "danger" = "danger") => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertVisible(true);
  };

  const changePassword = async (password: string, passwordConfirmation: string, recaptcha: string) => {
    setLoading(true);
    try {
      if (await userAuthService.changePasswordForgot(password, passwordConfirmation, authCode, recaptcha)) {
        setSuccess(true);
      }
    } catch (error) {
      let message = t(`snackBar.errors.${error.message}`);

      if(message.includes("snackBar.")) {
        message = "Falha ao alterar senha, tente novamente mais tarde"
      }

      showSnackBar(message);
      setLoading(false);
    }
  }

  const submitForm = async (values: FormikValues) => {
    setLoading(true);
    const recaptcha = await getNewRecaptcha();

    if (authCode == "") {

      try {
        if (await userAuthService.requestPasswordReset(values.email, recaptcha)) {
          setMailSent(true);
        }
      } catch (error) {
        let message = t(`snackBar.errors.${error.message}`);

        if(message.includes("snackBar.")) {
          message = t("forgot-password.email-sent.error");
        }

        showSnackBar(message);
        setLoading(false);
      }

    } else {
      await changePassword(values.password, values.passwordConfirmation, recaptcha);
    }

  }


  const errorMessages = {
    emptyField: t("snackBar.errors.empty-field"),
    invalidEmail: t("snackBar.errors.invalid-email"),
    passwordMatch: t("snackBar.errors.passwords-must-match"),
    passwordContains: t("snackBar.errors.passwords-must-contains"),
  }

  const ForgotPassSchema = Yup.object().shape({

    email: Yup.string().email(errorMessages.invalidEmail).required(errorMessages.invalidEmail),

  });
  const ChangePassSchema = Yup.object().shape({

    password: Yup.string().required(errorMessages.emptyField).matches(maskPassword, errorMessages.passwordContains),
    passwordConfirmation: Yup.string()
      .required(errorMessages.emptyField)
      .oneOf([Yup.ref("password"), null], errorMessages.passwordMatch),

  });

  return (
    <>
      <View style={[GeneralCSS.notLoggedContainer]}>
        <Header props={props} />

        {
          !isMobile &&
            <View style={[GeneralCSS.section, GeneralCSS.titleCenterPageBox, { backgroundColor: "none" }]}>
              <Text style={GeneralCSS.titleCenterPageText} variant="primary" typeStyle={{type:typeStyle, name:"title"}}>{t("forgot-password.title")}</Text>
            </View>
        }

        <View style={[GeneralCSS.sectionForm]}>
          <View style={GeneralCSS.formBox}>

            <View style={[styles.forgotPassBox]}>

              {!mailSent && authCode == "" ?
                  <></>
                : authCode == "" ?
                  <>

                    <View style={[styles.iconBox]}>
                      <GoMail size={60} color="black" style={styles.iconMail} />
                    </View>

                    <View style={[{ flexDirection: "column", gap: 8, marginTop: "1rem", lineHeight: "14px" } as any]}>
                      <View style={styles.subTitleBox}>
                        <Text style={styles.subTitleTextBold}
                        typeStyle={{type:typeStyle, name:"textBold"}}
                        >{ t("forgot-password.email-sent.title") }</Text>
                      </View>

                      <View style={styles.subTitleBox}>
                        <Text style={styles.descriptionMailAccept}
                        typeStyle={{type:typeStyle, name:"text"}}
                        >{ t("forgot-password.email-sent.instructions") }</Text>
                      </View>
                    </View>

                  </>
                  : success ?
                    <View style={[{ flexDirection: "column", gap: 8, marginTop: 4, lineHeight: "14px" } as any]}>
                      <View style={[styles.subTitleBox]}>
                        <Text style={[styles.subTitleTextBold]}
                         typeStyle={{type:typeStyle, name:"message"}}
                        >{ t("forgot-password.password-changed-success.title") }</Text>
                      </View>

                      <View style={[styles.subTitleBox, { marginTop: "0.5rem" }]}>
                        <Text style={styles.descriptionMailAccept}

                        typeStyle={{type:typeStyle, name:"text"}}
                        >{ t("forgot-password.email-sent.redirect-advice", { value: timeToRedirect, label: t(`forgot-password.email-sent.${timeToRedirect != 0 ? "seconds" : "second"}`) }) }</Text>
                      </View>
                    </View>
                    : null

              }
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  passwordConfirmation: ""
                }}
                validationSchema={authCode == "" ? ForgotPassSchema : ChangePassSchema}
                onSubmit={(values) => submitForm(values)}
              >
                {({ handleChange, handleSubmit, values, errors, touched, isValid }) => (

                  !mailSent && authCode == "" ?
                    <>
                      <View style={[GeneralCSS.boxInput]}>
                        <Input
                          size={"large"}
                          maxLength={120}

                          label={t(`forgot-password.instructions-by-username-type.${tenant.username.recoveryPassword || "0"}`)}
                          placeholder={t(`forgot-password.placeholder-by-username-type.${tenant.username.recoveryPassword || "0"}`)}

                          value={values.email}
                          onChange={handleChange("email")}

                          type={"text"}
                          mask={"email"}

                          variant={errors.email && touched.email ? "danger" : "primary"}
                        />
                      </View>

                      <View style={[styles.boxButtons, { gap: 10 } as any]}>
                        <Button
                          label={t("forgot-password.buttons.back")}
                          size={"large"}
                          fontWeight={"bold"}
                          onClick={() => props.navigation.replace('signIn')}
                          variant={"danger"}
                        />

                        <Button
                          label={t("forgot-password.buttons.send-email")}
                          fontWeight={"bold"}
                          size={"large"}

                          width={"100%"}

                          disabled={isLoading || !isValid}
                          disableHover={isLoading || !isValid}

                          onClick={async () => {
                            await handleSubmit();

                            if (Object.values(errors)?.length > 0) {
                              const error: any = Object.values(errors)[0];
                              showSnackBar(error.id ? error.id : error);
                            }
                          }}

                          variant={"primary"}
                        />
                      </View>
                    </>

                    : authCode == "" || success ?

                      <View style={{flexDirection: "row", justifyContent: "center", marginTop: "2rem" }}>
                        <Button
                          label={t("forgot-password.buttons.sign-in")}
                          fontWeight={"bold"}
                          size={"large"}

                          width={"100%"}

                          onClick={() => props.navigation.replace('signIn')}

                          variant={"primary"}
                        />
                      </View>

                      :


                      <>
                        <View style={[GeneralCSS.boxInput]}>
                          <Input
                            size={"large"}
                            maxLength={40}

                            label={t("forgot-password.password.label")}
                            placeholder={t("forgot-password.confirm-password.placeholder")}

                            value={values.password}
                            onChange={handleChange("password")}

                            type={"password"}

                            variant={errors.password && touched.password ? "danger" : "primary"}
                          />
                          <PasswordStrengthBar scoreWordStyle={{ fontFamily: "Arial", fontSize:12}} password={values.password} scoreWords={['Fraco', 'Fraco', 'Bom', 'Forte', 'Muito forte']} shortScoreWord={'Muito Curto'} />
                        </View>
                        <View style={[GeneralCSS.boxInput]}>
                          <Input
                            size={"large"}
                            maxLength={40}

                            label={t("forgot-password.confirm-password.label")}
                            placeholder={t("forgot-password.confirm-password.placeholder")}

                            value={values.passwordConfirmation}
                            onChange={handleChange("passwordConfirmation")}

                            type={"password"}

                            variant={errors.passwordConfirmation && touched.passwordConfirmation ? "danger" : "primary"}
                          />
                        </View>

                        <View style={[{ flexDirection: "row", justifyContent: "center", marginTop: 32 }]}>
                          <Button
                            label={t("forgot-password.buttons.confirm-passwords")}
                            fontWeight={"bold"}
                            size={"large"}

                            width={"100%"}

                            disabled={isLoading || !isValid}
                            disableHover={isLoading || !isValid}

                            onClick={async () => {
                              await handleSubmit();

                              if (Object.values(errors)?.length > 0) {
                                const error: any = Object.values(errors)[0];
                                showSnackBar(error.id ? error.id : error);
                              }
                            }}

                            variant={"primary"}
                          />
                        </View>
                      </>



                )}

              </Formik>

            </View>

          </View>
        </View>
      </View>

      <ReCAPTCHA
        sitekey={recaptchaSiteKey || "invalid"}
        size="invisible"
        ref={reRef}
        style={{zIndex: 30}}
        onErrored={() => handleRecaptchaLoadError()}
        hl={t("internalization.locale") || "en"}
      />

      <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />
    </>
  );
}
