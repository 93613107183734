import { useCallback, useEffect, useRef, useState } from 'react'

export function useDebouncedValue<T>(value: T, delay?: number): T {

  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(timer)
    }

  }, [value, delay])

  return debouncedValue
}


export const useDebouncedCallback = (func: Function, wait?: number) => {
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(
    (..._args: any) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(..._args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait]
  );

};
