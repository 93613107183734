import { useTranslation } from "react-i18next";
import { Icon, Text, View } from "hubchain-storybook-design-pattern";
import styles from "../styles";
import CurrencyUtil from "../../../utils/CurrencyUtil";
import { TCurrencySymbol } from "../../../types/currency";
import { ActivityIndicator, TouchableOpacity } from "react-native";
import React from "react";

interface SwapAvailableBalanceViewProps {
	balanceVisible?: boolean;
	setBalanceVisible: React.Dispatch<React.SetStateAction<boolean>>;
	isReadOnly: boolean;
	value: string;
	currency: TCurrencySymbol;
	handleRefreshAvailable: () => void;
	isFetchingCryptoHubInfoBalances: boolean;
}

const SwapAvailableBalanceView = ({
	value,
	currency,
	isReadOnly,
	balanceVisible,
	setBalanceVisible,
	handleRefreshAvailable,
	isFetchingCryptoHubInfoBalances,
}: SwapAvailableBalanceViewProps) => {
	const { t } = useTranslation();

	return (
		<View style={[styles.inputTopView]}>
			<View style={[styles.inputTopAvailable]}>
				<Text fontStyle={"bold"} size={"12px"}>
					{t(`swap-modal.origin.available`)}:
				</Text>
				<Text size={"12px"} variant={"gray"}>
					{balanceVisible
						? CurrencyUtil.formatCurrency(value, currency)
						: currency === TCurrencySymbol.BRL
						? `R$ ***,**`
						: `***,**`}
				</Text>
			</View>

			<TouchableOpacity onPress={() => setBalanceVisible((state) => !state)}>
				<Icon
					name={balanceVisible ? "EyeSlashFill" : "EyeFill"}
					variant={"dark"}
					fontSize={"16px"}
				/>
			</TouchableOpacity>

			{!isReadOnly && (
				<TouchableOpacity
					disabled={isReadOnly}
					onPress={handleRefreshAvailable}
				>
					{isFetchingCryptoHubInfoBalances ? (
						<ActivityIndicator size={14} color={"#000"} />
					) : (
						<Icon name={"Redo"} variant={"dark"} fontSize={"14px"} />
					)}
				</TouchableOpacity>
			)}
		</View>
	);
};

export default SwapAvailableBalanceView;
