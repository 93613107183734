import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	QuoteSwapDataResponse,
	RequestSwapModel,
} from "../../../services/swap/swapService";
import CurrencyUtil from "../../../utils/CurrencyUtil";
import { Button, Icon, Text, View } from "hubchain-storybook-design-pattern";
import { TCurrencySymbol } from "../../../types/currency";
import styles from "../styles";
import SwapModalTitle from "./SwapModalTitle";
import { useTranslation } from "react-i18next";
import SwapModalDetailRow from "./SwapModalDetailRow";
import { FormikProps } from "formik";
import {
	SwapModalActionType,
	SwapModalFormData,
	SwapModalQuoteAmountIn,
} from "../UserSwapModal";
import { ActivityIndicator } from "react-native";
import { RequestTypeEnum } from "../../../enums/RequestType.enum";

interface SwapModalDetailsCardProps {
	calculatedSwapQuote: QuoteSwapDataResponse | null;
	swapForm: FormikProps<SwapModalFormData>;
	swapQuote: SwapModalQuoteAmountIn;
	action: SwapModalActionType;
	request?: RequestSwapModel;
	isQuotingSwap: boolean;
	toggleExchangeRateMode: () => void;
	exchangeRateMode: string;
}

const SwapModalDetailsCard = ({
	calculatedSwapQuote,
	swapForm,
	swapQuote,
	action,
	request,
	isQuotingSwap,
	toggleExchangeRateMode,
	exchangeRateMode,
}: SwapModalDetailsCardProps) => {
	const { t } = useTranslation();
	const swap = request?.swap;

	const type = (
		swapQuote
			? swapQuote.entry === "amountIn"
			: calculatedSwapQuote?.entry === "amountIn"
	)
		? RequestTypeEnum.swapExactFiatForCrypto
		: RequestTypeEnum.swapFiatForExactCrypto;

	const [exchangeTickerPriceCurrency, setExchangeTickerPriceCurrency] =
		useState<{ amountIn: "USD" | "EUR"; netAmountOut: "USD" | "EUR" }>({
			amountIn: "USD",
			netAmountOut: "USD",
		});

	const toggleCurrency = (field: "amountIn" | "netAmountOut") => {
		setExchangeTickerPriceCurrency((state) => ({
			...state,
			[field]: state[field] === "USD" ? "EUR" : "USD",
		}));
	};

	const FiatPriceTickerView = useCallback(
		({
			field,
			currency,
			calculatedSwapQuote,
		}: {
			field: "amountIn" | "netAmountOut",
			currency: TCurrencySymbol,
			calculatedSwapQuote: QuoteSwapDataResponse,
		}) => {

			const _fiatCurrencyOut: "USD" | "EUR" = exchangeTickerPriceCurrency[field];

			let value = 0;

			if (calculatedSwapQuote) {

				const fieldValue = CurrencyUtil.getNumberByValue(calculatedSwapQuote[field]);

				const ticker = (
					calculatedSwapQuote
					&& calculatedSwapQuote.ticker
					&& calculatedSwapQuote.ticker[currency]
					&& calculatedSwapQuote.ticker[currency]![_fiatCurrencyOut]
				) ? calculatedSwapQuote.ticker[currency]![_fiatCurrencyOut] : 0;

				if (ticker > 0) {
					value = (currency === TCurrencySymbol.BRL)
						? (fieldValue / ticker)
						: (fieldValue * ticker);
				}
			}

			return (
				<View>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							gap: 4,
							alignItems: "center",
						}}
					>
						<Text size={"11px"} variant={"gray"}>
							≈{" "}
							{CurrencyUtil.formatCurrency(
								value,
								TCurrencySymbol.USDT,
								false,
								2,
								2
							)}
						</Text>

						<Button
							size={"table"}
							fillVariant={"outline-solid"}
							label={_fiatCurrencyOut}
							height={"18px"}
							width={"32px"}
							fontSize={"10px"}
							onClick={() => toggleCurrency(field)}
						/>
					</View>
				</View>
			);
		},
		[calculatedSwapQuote, exchangeTickerPriceCurrency]
	);

	const details = useMemo(() => {
		const currencyIn =
			calculatedSwapQuote?.currencyIn || swapForm.values.currencyIn.value;
		const currencyOut =
			calculatedSwapQuote?.currencyOut || swapForm.values.currencyOut.value;
		const amountIn =
			calculatedSwapQuote?.amountIn ||
			CurrencyUtil.getNumberByValue(swapForm.values.amountIn);
		const quotes = calculatedSwapQuote?.quotes;
		let quoteInOut = 0;
		let quoteOutIn = 0;

		if (quotes) {
			quoteInOut = CurrencyUtil.getNumberByValue(
				quotes[currencyIn + currencyOut]
			);
			quoteOutIn = CurrencyUtil.getNumberByValue(
				quotes[currencyOut + currencyIn]
			);
		}

		const serviceAmountFee = calculatedSwapQuote?.serviceAmountFee || 0;
		const serviceCurrencyFee = (calculatedSwapQuote?.serviceCurrencyFee as any)?.currency ?? calculatedSwapQuote?.serviceCurrencyFee ?? TCurrencySymbol.BRL;
		const servicePercentageFee = calculatedSwapQuote?.servicePercentageFee
			? CurrencyUtil.getNumberByValue(calculatedSwapQuote.servicePercentageFee)
			: 0;

		const networkAmountFee = calculatedSwapQuote?.networkAmountFee || 0;
		const networkCurrencyFee =
			calculatedSwapQuote?.networkCurrencyFee ||
			(currencyOut !== TCurrencySymbol.BRL ? currencyOut : currencyIn);
		const netAmountOut = calculatedSwapQuote?.netAmountOut || 0;

		let exchangeRateInOut = (
			<>
				<Text
					fontStyle={"bold"}
					style={{ overflowWrap: "anywhere", whiteSpace: "nowrap" }}
				>
					{CurrencyUtil.formatCurrency(1, currencyIn, true, 0, 0) + " = "}
				</Text>
				<Text
					fontStyle={"bold"}
					style={{ overflowWrap: "anywhere", whiteSpace: "nowrap" }}
				>
					&nbsp;
					{"" +
						CurrencyUtil.formatCurrency(quoteInOut, currencyOut, true, 6, 6)}
				</Text>
			</>
		);

		let exchangeRateOutIn = (
			<>
				<Text
					fontStyle={"bold"}
					style={{ overflowWrap: "anywhere", whiteSpace: "nowrap" }}
				>
					{CurrencyUtil.formatCurrency(1, currencyOut, true, 0, 0) + " = "}
				</Text>
				<Text
					fontStyle={"bold"}
					style={{ overflowWrap: "anywhere", whiteSpace: "nowrap" }}
				>
					&nbsp;
					{"" +
						CurrencyUtil.formatCurrency(quoteOutIn, currencyIn, true, 6, 6)}
				</Text>
			</>
		);

		console.log("calculatedSwapQuote?.netAmountOut", calculatedSwapQuote)

		console.log("swapQuote", swapQuote)

		return {
			amountIn:
				(Number(netAmountOut) > 0 ? "" : "≈ ") +
				CurrencyUtil.formatCurrency(
					amountIn,
					currencyIn,
					true,
					currencyIn === "BRL" ? 2 : 4,
					currencyIn === "BRL" ? 2 : 4
				),
			currencyIn,
			serviceAmountFee: CurrencyUtil.formatCurrency(
				serviceAmountFee,
				serviceCurrencyFee,
				true,
				serviceCurrencyFee === "BRL" ? 2 : 4,
				serviceCurrencyFee === "BRL" ? 2 : 4
			),
			servicePercentageFee: CurrencyUtil.formatCurrency(
				servicePercentageFee * 100,
				"PERCENTAGE",
				false,
				0,
				2
			),
			networkAmountFee: CurrencyUtil.formatCurrency(
				networkAmountFee,
				(networkCurrencyFee as any).currency ?? networkCurrencyFee
			),
			networkCurrencyFee: networkCurrencyFee,
			netAmountOut:
				(Number(netAmountOut) > 0 ? "≈ " : "") +
				CurrencyUtil.formatCurrency(
					Number(netAmountOut) > 0 ? netAmountOut : 0,
					currencyOut,
					true,
					currencyOut === "BRL" ? 2 : 4,
					currencyOut === "BRL" ? 2 : 4
				),
			currencyOut,
			exchangeRateOutIn,
			exchangeRateInOut,
		};
	}, [calculatedSwapQuote, exchangeRateMode]);

	return (
		<View style={[styles.card, styles.detailsCard, { flex: "none" }]}>
			<SwapModalTitle title={t("swap-modal.details.title")} />

			<View style={[styles.detailsContent]}>
				<SwapModalDetailRow
					leftAddon={
						isQuotingSwap &&
						type === RequestTypeEnum.swapFiatForExactCrypto && (
							<ActivityIndicator size={12} color={"#000"} />
						)
					}
					label={
						type === RequestTypeEnum.swapExactFiatForCrypto
							? t("swap-modal.details.will-be-sent-exactly")
							: t("swap-modal.details.will-be-sent")
					}
					value={details.amountIn}
					rightAddon={
						<>
							<FiatPriceTickerView
								field={"amountIn"}
								currency={calculatedSwapQuote?.currencyIn}
								calculatedSwapQuote={calculatedSwapQuote!}
							/>
						</>
					}
				/>

				<SwapModalDetailRow
					leftAddon={
						isQuotingSwap &&
						type === RequestTypeEnum.swapExactFiatForCrypto && (
							<ActivityIndicator size={12} color={"#000"} />
						)
					}
					label={
						type === RequestTypeEnum.swapFiatForExactCrypto
							? t("swap-modal.details.will-be-received-exactly")
							: t("swap-modal.details.will-be-received")
					}
					value={details.netAmountOut}
					rightAddon={
						<>
							<FiatPriceTickerView
								field={"netAmountOut"}
								currency={calculatedSwapQuote?.currencyOut}
								calculatedSwapQuote={calculatedSwapQuote!}
							/>
						</>
					}
				/>

				<View style={[styles.divider]} />
				<SwapModalDetailRow
					label={t("swap-modal.details.rate")}
					value={
						isQuotingSwap ? (
							<>
								<ActivityIndicator
									size={12}
									color={"#000"}
									style={{ marginRight: 8 }}
								/>
								{details["exchangeRate" + exchangeRateMode]}
							</>
						) : (
							details["exchangeRate" + exchangeRateMode]
						)
					}
					rightAddon={
						<Button
							size={"table"}
							fillVariant={"ghost"}
							icon={<Icon name={"SwapHorizontal"} fontSize={"16px"} />}
							label={""}
							onClick={toggleExchangeRateMode}
						/>
					}
				/>

				<SwapModalDetailRow
					label={t("swap-modal.details.service-fee", {
						value: details.servicePercentageFee,
					})}
					value={details.serviceAmountFee}
				/>

				<SwapModalDetailRow
					label={t("swap-modal.details.network-fee")}
					value={details.networkAmountFee}
				/>
			</View>
		</View>
	);
};

export default SwapModalDetailsCard;
