import {Dimensions, StyleSheet, ViewStyle} from "react-native";
import Colors from "./Colors";
import { isMobile } from "react-device-detect";
import { LayoutConfig } from "./Layout";

const { width } = Dimensions.get("window");

const GeneralStylesMobile = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    paddingVertical: "1rem",
    paddingHorizontal: "1.25rem"
  },
  notLoggedContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFF",
  },
  flex: {
    flex: 1,
  },
  section: {
    width: "100%",
    alignItems: "center",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    height: "auto",
    backgroundColor: Colors.backgroundGray,
    marginTop: 0,
  },
  defaultTableValueForLabelText: {
    fontSize: 12
  },
  titleViewSection: {
    flex: 1,
    height: "auto",
  },
  titleTextSection: {
    textAlign: "center",
    fontSize: 18,
    paddingTop: 25,
    color: "#282828",
  },
  descriptionViewSection: {
    justifyContent: "center",
  },
  descriptionTextSection: {
    textAlign: "center",
    fontSize: 13,
    color: "#282828",
    paddingTop: 15,
  },
  itemsRowSection: {
    height: "auto",
    marginTop: 20,
  },
  itemSection: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
    cursor: "pointer",
    marginLeft: 20,
    marginRight: 20,
  },
  titleLeftPageBox: {
    height: 110,
    width: "100%",
    marginTop: 90,
    justifyContent: "space-around",
  },
  titleLeftPageText: {
    color: Colors.light.text,
    fontSize: 22,
    paddingTop: 15,
    textAlign: "left",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    textTransform: "uppercase",
    fontWeight: 500,
  },
  titleCenterPageBox: {
    height: 111,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    alignSelf: "center",
    lineHeight: "21px",
    marginTop: 56,
  },
  titleCenterPageText: {
    paddingTop: 0,
    fontWeight: "bold",
    textAlign: "center",
  },
  subTitleCenterPageText: {
    color: Colors.light.text,
    fontSize: 15,
    paddingTop: 0,
    textTransform: "uppercase",
    fontWeight: 200,
    textAlign: "center",
  },
  titleLeftPageTextNew: {
    color: Colors.light.text,
    textAlign: "left",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  } as any,
  titleCenterPageTextNew: {
    color: Colors.light.text,
    paddingTop: 0,
    textAlign: "center",
  },

  sectionForm: {
    justifyContent: "center",
    width: "100%",
    flexDirection: "row",
    height: "auto",
    marginTop: 121,
    paddingHorizontal: "1rem"
  },
  sectionContent: {
    width: "100%",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    height: "auto",
    backgroundColor: Colors.backgroundGray,
    marginTop: 0,
    marginBottom: 40,
  },
  boldNear: {
    paddingBottom: 0,
    marginBottom: -5
  },

  formBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
  },
  row: {
    flexDirection: "row",
  },

  col: {
    flex: 1,
    flexDirection: "row",
  },
  col05: {
    flex: 0.05,
    flexDirection: "column",
  },
  col10: {
    flex: 0.1,
    flexDirection: "column",
  },
  col15: {
    flex: 0.15,
    flexDirection: "column",
  },
  col20: {
    flex: 0.2,
    flexDirection: "column",
  },
  col25: {
    flex: 0.25,
    flexDirection: "column",
  },
  col30: {
    flex: 0.3,
    flexDirection: "column",
  },
  col33: {
    flex: 0.33333,
    flexDirection: "column",
  },
  col35: {
    flex: 0.35,
    flexDirection: "column",
  },
  col40: {
    flex: 0.4,
    flexDirection: "column",
  },
  col45: {
    flex: 0.45,
    flexDirection: "column",
  },
  col50: {
    flex: 0.5,
    flexDirection: "column",
  },
  col55: {
    flex: 0.55,
    flexDirection: "column",
  },
  col60: {
    flex: 0.6,
    flexDirection: "column",
  },
  col65: {
    flex: 0.65,
    flexDirection: "column",
  },
  col70: {
    flex: 0.7,
    flexDirection: "column",
  },
  col80: {
    flex: 0.8,
    flexDirection: "column",
  },
  col90: {
    flex: 0.9,
    flexDirection: "column",
  },

  rowsm: {
    flexDirection: "column",
  },
  col05sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col10sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col15sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col20sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col25sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col30sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col33sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col35sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col40sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col45sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col50sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col55sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col60sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col65sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col70sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col80sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col90sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  left: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  right: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  boxInput: {
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 12.5,
    fontWeight: "600",
    marginBottom: 5,
  },
  textInput: {
    height: 35,
    borderRadius: 2,
    paddingHorizontal: 20,
    fontSize: 12,
    backgroundColor: Colors.backgroundGray
  },
  indicatorWrapper: {
    position: "absolute",
    zIndex: 900,
    right: 10,
    borderRadius: 10,
    backgroundColor: "#e1e1e1",
    width: 6,
    pointerEvents: "none",
  },
  indicator: {
    position: "absolute",
    zIndex: 999,
    right: 10,
    borderRadius: 10,
    backgroundColor: Colors.dark.background,
    width: 6,
    pointerEvents: "none",
  },
  indicatorHide: {
    position: "absolute",
    zIndex: 800,
    right: 7,
    backgroundColor: "#FFF",
    width: 20,
    pointerEvents: "none",
    marginTop: -5,
  },
  externalModal: {
    backgroundColor: "#00000095",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999999999999999,
    elevation: 5000,
    cursor: "auto",
  } as any,
  boxModal: {
    width: 300,
    height: 300,
    position: "fixed",
    alignContent: "center",
    zIndex: 999999999999999,
    elevation: 50000,
    alignItems: "center",
    textAlignVertical: "center",
    padding: 30,
    backgroundColor: Colors.light.background,
    borderRadius: 5,
    margin: 20,
    left: "50%",
    top: "50%",
    marginLeft: -150,
    marginTop: -150,
    border: "none",
    boxShadow: "0 2px 2px rgba(0, 0, 0, .3)",
  } as any,
  titleModal: {
    color: Colors.light.text,
    paddingTop: 0,
    textTransform: "uppercase",
    fontWeight: "500",
    textAlign: "center",
    fontSize: 13,
    lineHeight: 13,
    marginBottom: 20,
    width: "100%",
  },
  paddingModal: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  defaultTableHeader: {
    // paddingLeft: 8,
    // paddingRight: 8,
    zIndex: 21,
    width: "fit-content",
    minWidth: "100%"
  },
  defaultTableColumn: {
    flex: 1,
    fontSize: 13,
    paddingTop: 12,
    paddingBottom: 12,
    paddingHorizontal: 8,
    fontWeight: "bold"
  },
  defaultTableColumnLabel: {
    fontSize: 13,
    paddingLeft: 8,
    paddingRight: 8,
    fontWeight: "bold"
  },
  tableColumnTitle: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  defaultTableRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.light.background,
    minWidth: "fit-content",
    paddingHorizontal: "0.5rem",
    minHeight: 48,
    zIndex: 20,
  },
  defaultTableValue: {
    flex: 1,
    // paddingHorizontal: 8,
    // paddingVertical: 8,
    gap: 4,
  },
  defaultTableValueLabel: {
    fontSize: 12
  },
  defaultTableValueBold: {
    fontSize: 12,
    fontWeight: "bold"
  },
  defaultTableTextValue: {
    fontSize: 12
  },
  wrap: {
    flexWrap: "wrap",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: 24,
    paddingHorizontal: 24,
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary20,
    gap: 8,
  },
  titleText: {},
  subtitleText: {
    color: Colors.light.secondary90,
    borderLeftWidth: 1,
    borderLeftColor: Colors.light.secondary50,
    paddingLeft: 8,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingHorizontal: 24,
    borderRadius: 5,
    paddingVertical: 6,
    borderWidth: 2,
    borderColor: Colors.light.primary,
  },
  buttonText: {
    color: "#FFF",
    fontSize: 12,
  },
  tableInfoIconView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dashedButton: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 18,
    paddingVertical: 6,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.light.primary,
    borderStyle: "dashed",
  },
  dashedButtonText: {
    color: "#000",
    fontSize: 12,
  },

  iconStatusTable: {
    borderRadius: 25,
    width: 17,
    height: 17,
    marginTop: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },

  viewStatusTableButtonSuccess: {
    borderWidth: 1,
    borderRadius: 5,
    padding: 3,
    borderColor: Colors.light.primary,
    borderStyle: "dashed",
  },
  viewStatusTableButtonDanger: {
    borderWidth: 1,
    borderRadius: 5,
    padding: 3,
    borderColor: Colors.light.danger,
    borderStyle: "dashed",
  },
  orderTableValueLabel: {
    fontSize: 12,
  },
  cardsView: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  userInfoWithIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6
  },
  userInfoEllipsesText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  modalSubtitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: 24,
    maxHeight: 24,
    borderBottomWidth: 2,
    borderBottomColor: "#EFF2F5",
  },
  cardHeader: {
    height: 46,
    flexDirection: "row",
    paddingHorizontal: "1rem",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 2,
    borderColor: Colors.light.secondary20,
  },
  cardHeaderText: {
    // fontSize: 13,
    textAlign: "left",
    // fontWeight: "bold"
  },
  searchIcon: {
    position: "absolute",
    bottom: 4,
    left: 7,
  },
  filterRow: {
    zIndex: 1,
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 5
  },
  modalPagination: {
    flex: 1,
    flexDirection: "row",
    paddingRight: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    gap: 10,
  } as ViewStyle,
  card: {
    backgroundColor: "#FFF",
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8,
    borderRadius: 10
  },
});

const GeneralStylesWeb = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    paddingVertical: "1rem",
    paddingHorizontal: width > 1380 ? 32 : 16
  },
  notLoggedContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: Colors.backgroundGray,
  },
  defaultTableTextValue: {
    fontSize: 12
  },
  flex: {
    flex: 1,
  },
  section: {
    width: "100%",
    alignItems: "center",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    height: "auto",
    backgroundColor: Colors.backgroundGray,
    marginTop: 15,
  },

  cap: {
    position: "absolute",
  },
  titleViewSection: {
    flex: 1,
    height: "auto",
  },
  titleTextSection: {
    textAlign: "center",
    fontSize: 18,
    color: "#282828",
  },
  descriptionViewSection: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    width: 800,
  },
  descriptionTextSection: {
    textAlign: "center",
    fontSize: 17,
    color: "#282828",
    paddingTop: 15,

    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  itemsBoxSection: {
    height: "auto",
    paddingBottom: 160,
  },
  itemsRowSection: {
    height: "auto",
    marginTop: 20,
  },
  itemSection: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
    cursor: "pointer",
    marginLeft: 35,
    marginRight: 35,
  },

  titleLeftPageBox: {
    height: 195,
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingLeft: 140,
    paddingTop: 100,
  },

  titleLeftPageText: {
    color: Colors.light.text,
    fontSize: 24,
    paddingTop: 5,
    textAlign: "flex-start",
    textTransform: "uppercase",
    fontWeight: "500",
  },

  titleCenterPageBox: {
    height: "auto",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    paddingTop: 128,
    marginTop: LayoutConfig.header.size.desktop,
    marginBottom: 40
  },
  titleLeftPageTextNew: {
    color: Colors.light.text,
    textAlign: "left",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  } as any,
  titleCenterPageTextNew: {
    color: Colors.light.text,
    paddingTop: 0,
    textAlign: "center",
  },

  titleCenterPageText: {
    paddingTop: 0,
    fontWeight: "bold",
    textAlign: "center",
  },
  subTitleCenterPageText: {
    color: Colors.light.text,
    fontSize: 14,
    paddingTop: 0,
    textTransform: "uppercase",
    fontWeight: "200",
    textAlign: "center",
  },
  sectionForm: {
    display: "flex",
    height: "auto",
    flexWrap: "nowrap",
    width: "100%",
  },
  sectionContent: {
    width: "100%",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    height: "auto",
    backgroundColor: Colors.backgroundGray,
    marginTop: 0,
    marginBottom: 40,
  },
  boldNear: {
    paddingBottom: 0,
    marginBottom: -5
  },

  formBox: {
    flex: 1,
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: 5,
    alignSelf: "center",
    paddingHorizontal: 20,
  },
  row: {
    flexDirection: "row",
  },
  col: {
    flex: 1,
    flexDirection: "row",
  },
  col05: {
    flex: 0.05,
    flexDirection: "column",
  },
  col10: {
    flex: 0.1,
    flexDirection: "column",
  },
  col15: {
    flex: 0.15,
    flexDirection: "column",
  },
  col20: {
    flex: 0.2,
    flexDirection: "column",
  },
  col25: {
    flex: 0.25,
    flexDirection: "column",
  },
  col30: {
    flex: 0.3,
    flexDirection: "column",
  },
  col33: {
    flex: 0.33333,
    flexDirection: "column",
  },
  col35: {
    flex: 0.35,
    flexDirection: "column",
  },
  col40: {
    flex: 0.4,
    flexDirection: "column",
  },
  col45: {
    flex: 0.45,
    flexDirection: "column",
  },
  col50: {
    flex: 0.5,
    flexDirection: "column",
  },
  col55: {
    flex: 0.55,
    flexDirection: "column",
  },
  col60: {
    flex: 0.6,
    flexDirection: "column",
  },
  col65: {
    flex: 0.65,
    flexDirection: "column",
  },
  col70: {
    flex: 0.7,
    flexDirection: "column",
  },
  col80: {
    flex: 0.8,
    flexDirection: "column",
  },
  col90: {
    flex: 0.9,
    flexDirection: "column",
  },

  rowsm: {
    flexDirection: "row",
  },
  col05sm: {
    flex: 0.05,
    flexDirection: "column",
  },
  col10sm: {
    flex: 0.05,
    flexDirection: "column",
  },
  col15sm: {
    flex: 0.15,
    flexDirection: "column",
  },
  col20sm: {
    flex: 0.2,
    flexDirection: "column",
  },
  col25sm: {
    flex: 0.25,
    flexDirection: "column",
  },
  col30sm: {
    flex: 0.3,
    flexDirection: "column",
  },
  col33sm: {
    flex: 0.33333,
    flexDirection: "column",
  },
  col35sm: {
    flex: 0.35,
    flexDirection: "column",
  },
  col40sm: {
    flex: 0.4,
    flexDirection: "column",
  },
  col45sm: {
    flex: 0.45,
    flexDirection: "column",
  },
  col50sm: {
    flex: 0.5,
    flexDirection: "column",
  },
  col55sm: {
    flex: 0.55,
    flexDirection: "column",
  },
  col60sm: {
    flex: 0.6,
    flexDirection: "column",
  },
  col65sm: {
    flex: 0.65,
    flexDirection: "column",
  },
  col70sm: {
    flex: 0.7,
    flexDirection: "column",
  },
  col80sm: {
    flex: 0.8,
    flexDirection: "column",
  },
  col90sm: {
    flex: 0.9,
    flexDirection: "column",
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  left: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  right: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  boxInput: {
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 13.5,
    fontWeight: "600",
    marginBottom: 5,
  },
  textInput: {
    height: 40,
    borderRadius: 2,
    paddingHorizontal: 20,
    fontSize: 12,
    backgroundColor: Colors.backgroundGray
  },
  indicatorWrapper: {
    position: "absolute",
    zIndex: 900,
    right: 15,
    borderRadius: 10,
    backgroundColor: "#e1e1e1",
    width: 8,
    pointerEvents: "none",
  },
  indicator: {
    position: "absolute",
    zIndex: 999,
    right: 15,
    borderRadius: 10,
    backgroundColor: Colors.dark.background,
    width: 8,
    pointerEvents: "none",
  },
  indicatorHide: {
    position: "absolute",
    zIndex: 800,
    right: 7,
    backgroundColor: "#FFF",
    width: 25,
    pointerEvents: "none",
    marginTop: -5,
  },
  externalModal: {
    backgroundColor: "#00000095",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999999999999999,
    elevation: 5000,
    cursor: "auto",
  } as any,
  boxModal: {
    width: 400,
    height: 400,
    position: "fixed",
    alignContent: "center",
    zIndex: 999999999999999,
    elevation: 50000,
    alignItems: "center",
    textAlignVertical: "center",
    padding: 30,
    backgroundColor: Colors.light.background,
    borderRadius: 5,
    margin: 20,
    left: "50%",
    top: "50%",
    marginLeft: -200,
    marginTop: -200,
    border: "none",
    boxShadow: "0 2px 2px rgba(0, 0, 0, .3)",
  } as any,
  titleModal: {
    color: Colors.light.text,
    paddingTop: 0,
    textTransform: "uppercase",
    fontWeight: "500",
    textAlign: "center",
    fontSize: 17,
    lineHeight: 22,
  },
  paddingModal: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  defaultTableHeader: {
    paddingLeft: 8,
    paddingRight: 8,
    zIndex: 21,
    width: "fit-content",
    minWidth: "100%"
  },
  defaultTableColumn: {
    flex: 1,
    fontSize: 13,
    paddingTop: 12,
    paddingBottom: 12,
    fontWeight: "bold"
  },
  defaultTableColumnLabel: {
    fontSize: 13,
    paddingLeft: 8,
    paddingRight: 8,
    fontWeight: "bold"
  },
  tableColumnTitle: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  defaultTableRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.light.background,
    minWidth: "fit-content",
    paddingHorizontal: "0.5rem",
    minHeight: 48,
    zIndex: 20,
  },
  defaultTableValue: {
    flex: 1,
    paddingHorizontal: 8,
    paddingVertical: 8,
    gap: 4,
  },
  defaultTableValueLabel: {
    fontSize: 12
  },
  orderTableValueLabel: {
    fontSize: 12,
  },
  wrap: {
    whiteSpace: "wrap",
  } as any,
  defaultTableValueForLabelText: {
    fontSize: 12
  },
  defaultTableValueBold: {
    fontSize: 12,
    fontWeight: "bold"
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: 24,
    paddingHorizontal: 24,
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary20,
    gap: 8,
  },
  titleText: {},
  subtitleText: {
    color: Colors.light.secondary90,
    borderLeftWidth: 1,
    borderLeftColor: Colors.light.secondary50,
    paddingLeft: 8,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingHorizontal: 24,
    borderRadius: 5,
    paddingTop: 8,
    paddingBottom: 8,
    paddingVertical: 6,
    borderWidth: 2,
    borderColor: Colors.light.primary,
  },
  buttonText: {
    color: "#FFF",
    fontSize: 12,
  },
  tableInfoIconView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dashedButton: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 18,
    paddingVertical: 6,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.light.primary,
    borderStyle: "dashed",
  },
  dashedButtonText: {
    color: "#000",
    fontWeight: "500",
    fontSize: 12,
  },
  iconStatusTable: {
    borderRadius: 25,
    width: 17,
    height: 17,
    marginTop: 1,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },

  viewStatusTableButtonSuccess: {
    borderWidth: 1,
    borderRadius: 5,
    padding: 3,
    borderColor: Colors.light.primary,
    borderStyle: "dashed",
  },
  viewStatusTableButtonDanger: {
    borderWidth: 1,
    borderRadius: 5,
    padding: 3,
    borderColor: Colors.light.primary,
    borderStyle: "dashed",
  },
  cardsView: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  userInfoWithIcon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6
  },
  userInfoEllipsesText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  modalSubtitle: {
    width: "100%",
    alignItems: "center",
    height: 24,
    maxHeight: 24,
    borderBottomWidth: 2,
    borderBottomColor: "#EFF2F5"
  },
  cardHeader: {
    height: 46,
    flexDirection: "row",
    paddingHorizontal: "1rem",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 2,
    borderColor: Colors.light.secondary20,
  },
  cardHeaderText: {
    fontSize: 13,
    textAlign: "left",
    fontWeight: "bold"
  },
  searchIcon: {
    position: "absolute",
    bottom: 4,
    left: 7,
  },
  filterRow: {
    zIndex: 1,
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 10
  },
  modalPagination: {
    flex: 1,
    flexDirection: "row",
    paddingRight: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    gap: 10,
  } as ViewStyle,
  card: {
    backgroundColor: "#FFF",
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8,
    borderRadius: 10
  },
});

const GeneralCSS = isMobile ? GeneralStylesMobile : GeneralStylesWeb;
export default GeneralCSS;
