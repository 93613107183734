import { Button, Icon, Modal, Text, View } from "hubchain-storybook-design-pattern";
import { TCurrencySymbol } from "../../types/currency";
import { ActivityIndicator, Image, StyleSheet } from "react-native";
import CurrencyUtil from "../../utils/CurrencyUtil";

import { setStringAsync } from "expo-clipboard";
import { useAlert } from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";
import { useMultiTenancy } from "../../services/multi-tenancy/multi-tenancy";
import { QrCodePix } from 'qrcode-pix';
import QRCode from "qrcode";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import RequestSwapService, { RequestSwapModel } from "../../services/swap/swapService";
import moment from "moment-timezone";
import { isYesOrNoEnum } from "../../types/types";
import Util from "../../utils";
import { VariantsType } from "hubchain-storybook-design-pattern/lib/components/Text/types";
import { RequestStatusId } from "../../enums/RequestStatusId.enum";
import { CurrencyData } from "../../services/currency/currencyService";
import { useCurrency } from "../../hooks/useCurrency";
import { BaasPixKeyTypeEnum } from "../TreasurySwapModal/CryptoHubTransferModal";
import React from "react";


export interface CryptoHubDepositModalConfig {
  isDue?: isYesOrNoEnum,
  dueAt?: Date,
  isPaid?: isYesOrNoEnum,
  paidAt?: Date

  amount: number,
  currency: TCurrencySymbol,
  network?: string,
  transactionId?: string,

  pix: {
    info: {
      type: string,
      name: string,
      taxId?: string,
      pix: {
        key: string,
        keyType?: BaasPixKeyTypeEnum,
        city: string,
        postalCode: string,
        message: string,
        transactionId: string,
      }
      personCompanyId: string,
      companyId?: string,
    },
    payload?: string,
    base64?: string,
  }
  request: RequestSwapModel
}
export interface CryptoHubDepositModalProps {
  data: CryptoHubDepositModalConfig,
  onClose: () => void
}

const QRCodeFallback = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAXNSR0IArs4c6QAABJlJREFUeF7tndFu4zAMBJv//+gUd29WgAwWK7mWMH2lJZM7JCUrcfp6v9/vH/+OUeAl0GNY/g9EoGfxFOhhPAUq0NMUOCwe11CBHqbAYeFYoQI9TIHDwrFCBXqYAoeFY4UK9DAFDgvHChXoYQocFk5doa/X61ZJxo9vx/vTx7vkL82/Oljyn+4v0EEhgVqhVDSR3QodEooEseVCfqVrWJSu/75SEQIb5//r8RRv699HvO13imY79DQgT49PoOGXHAUaCra6JbVA2vGr47u9QmkTMjpEjw3ppqe9noDeHR8myOo19O6ACUC6RtN8d8cnUFgCUmDUQUhwGk8dheafflJEDpNDNJ4CJkBWaPkcurolpfPTmt0CX52wNP/2FSrQK2KBTl5jqYLaJYXmF6hAhxKHs9a0JaYZ3G6CaE2l+VfHRxV53MECCU6bHIEOCpCgqzOY7i/QsMZJUIFeBU2XlBBH/34oAU0doooiQaiFtvPTwUYa72z9lu9y0wBbwQVaptzsDBNo96M0Vmj42FXm/0fDml0Q04G2LZbG0xq62k7+tfY2YQQaVmgLjMYLNATSVjABae0CFeglh+qW22ZkO749uGjv/7TxAn0akdIfgZYCPm24QJ9GpPSnBto+GNP41WtkOn+qN+1aKf70fgJd/DqkQIevhKQVRALSWXFaEXQ9+WOFgoIkoEBBwNUVRBVAgAhwWyGPi3/2uy0EgASm8WRPAaXXUwKRf8vjF+j1V1xSwa3Qye+PUsUQICt0UDAVhDK6/TTkaePbhKMW/jH/7JabVsTo0NOAUMKSXaDwHLobcIEKNO2yl+vro7/bM3DyB9rU8kndtOXSfK1doIOCtAe4O4FTwAIV6PecWd2CaP7UbssNe0ArMN2Odr2zx9P9qGWTHuQv2ae33HSNoYMGCoAEnj2e7ifQ8gNmEligpEBopxZjhXYvJ00/+gv5Tr+cEoaWgNWbojRhqWWTgMvXUHKgtQv0qqBAFz+HWqFhyVqhkys0zcCQ18fltKsle7pmUsK08bdr5vRNURtQCpiAkV2goLhAu/8sZYWWn5daoWGFTs+48P+2UMcg/9LxtMamS0p7ff3YsjqgdP4USHvwkPrXAqPxAh0UShNCoJRiIPDslmmFhoJTxtMmhSqA5m8TgPwL87P+X250v+UtlwQnwQRKCBefFKUP9gJ92MdnbQUJVKBfexC1+Kd1EErorOH+rP8BZBKYAqIOMHuXSgJSQpA9nZ+u/4h/9ctKAs3OemlXToC33+VaoYftcjFjw3dhaAmgBKKW21Ygxntay20FF2hZASRguikSaNkDSPC7N0UCXQyUej7ZKWH+GiB1GIpvtn35Lrd1WKCZggId9KKGRUsIjc/w5FcLVKDDg2z59liag+lzXltR1PLJTvG145cf/VEArV2g3xWc3nJbYDReoAL9qgBtYqglkp0StB0/veWSw9rvVaBuufe6691IAYGSQpvZBboZMHJXoKTQZnaBbgaM3BUoKbSZXaCbASN3BUoKbWYX6GbAyF2BkkKb2QW6GTByV6Ck0GZ2gW4GjNwVKCm0mV2gmwEjd38BfQBXDkLpFO0AAAAASUVORK5CYII=";

export default function CryptoHubDepositModal({ onClose, data }: CryptoHubDepositModalProps) {

  const { showAlert } = useAlert();
  const { t } = useTranslation();
  const { currencies, markets } = useCurrency();
  const { tenant } = useMultiTenancy();

  const interval = useRef<any>();

  const [modalData, setModalData] = useState<CryptoHubDepositModalConfig>(data);

  const pixBase64 = modalData.pix.base64;

  const request = data.request;
  const requestTransaction = data.request?.swap.transactionsIn?.transactions[0];

  if (!!requestTransaction && request?.swap.status.id === RequestStatusId.COMPLETED) {
    requestTransaction.isDue = moment(requestTransaction.dueAt).isBefore(new Date()) ? isYesOrNoEnum.YES : isYesOrNoEnum.NO;
  }

  const handleClose = () => {
    clearInterval(interval.current);

    onClose();
  }

  const queryClient = useQueryClient();

  const { mutate: createInvoice, isLoading: isCreatingInvoice } = useMutation({
    mutationFn: RequestSwapService.getSwapInvoice,
    onSuccess: ({ data: response }) => {

      handleMountQrCodePixByRequest(response.data);
      queryClient.refetchQueries(["swaps"]);
    },
    onError: () => {
      mountQrCodePix(data);
    }
  })

  const handleCopyQrCode = (type: "pix" | "metamask" | "trustwallet" = "pix") => {
    setStringAsync(modalData.pix.payload!);
    showAlert("", "success", t(`cryptohub-card.deposit-modal.qrcode-copied`));
  }

  const handleMountQrCodePixByRequest = async (request: RequestSwapModel) => {

    let newData = data;
    const transaction = request.swap.transactionsIn?.transactions[0];

    if (transaction) {
      newData.amount = CurrencyUtil.getNumberByValue(transaction.totalAmount);
      newData.network = transaction.network;
      newData.transactionId = transaction.transactionId;
      newData.pix.info.pix.transactionId = newData.transactionId;

      newData.pix.info.personCompanyId = transaction.metadata?.taxId ?? transaction.metadata?.personCompanyId ?? newData.pix.info?.taxId ?? newData.pix.info?.companyId ?? "00.000.000/0000-00";
      newData.pix.info.name = transaction.metadata?.name ?? newData.pix.info.name;

      newData.isDue = transaction.isDue;
      newData.dueAt = transaction.dueAt;

      newData.isPaid = transaction.isPaid;
      newData.paidAt = transaction.paidAt;
    }

    if (request.swap?.currencyIn.currency === TCurrencySymbol.BRL) {
      if (transaction?.address) {
				newData.pix.base64 = await QRCode.toDataURL(transaction.address, {
					errorCorrectionLevel: "H",
				});
				newData.pix.payload = transaction.address;
				setModalData((state) => ({ ...newData }));
			} else {
				mountQrCodePix(newData);
			}
    } else if (request?.swap?.addressIn) {
      newData.pix.base64 = await QRCode.toDataURL(request?.swap?.addressIn, { errorCorrectionLevel: 'H' })
      newData.pix.payload = request?.swap?.addressIn;
      newData.request.swap.addressIn = request?.swap?.addressIn;
      setModalData(state => ({ ...newData }));
    }

    initializeVerifyDue();
  }

  const mountQrCodePix = async (info: CryptoHubDepositModalConfig) => {
    const mountedQrCodePix = QrCodePix({
      version: '01',
      key: info.pix.info.pix.key,
      name: info.pix.info.name,
      city: info.pix.info.pix.city,
      transactionId: info.pix.info.pix.transactionId,
      cep: info.pix.info.pix.postalCode,
      value: info.amount,
      message: info.pix.info.pix.message
    });

    const base64Image = await mountedQrCodePix.base64({ errorCorrectionLevel: 'H' });

    setModalData(state => ({ ...info, pix: { ...info.pix, base64: base64Image, payload: mountedQrCodePix.payload() } }));
  }

  const handleCreateInvoice = () => {
    createInvoice({
      requestId: request?.id!
    })
  }

  const verifyDue = async () => {
    const isDue = moment().isAfter(moment(modalData.dueAt));

    if (isDue) {
      const newQrCode = await QRCode.toDataURL("https://hubchain.com/");
      setModalData(state => ({
        ...state,
        pix: { ...state.pix, base64: newQrCode, info: { ...state.pix.info, isDue: isYesOrNoEnum.YES } }
      }))

      clearInterval(interval.current);
    }
  }

  const initializeVerifyDue = () => {
    verifyDue();

    interval.current = setInterval(() => {
      verifyDue();
    }, 60000 * 10)//10 minutes
  }

  useEffect(() => {
    if (!request) {
      data.isDue = isYesOrNoEnum.NO;
      data.dueAt = moment().add(1, "day").toDate();
      mountQrCodePix(data);
    } else {
      if (!!request.swap.transactionsIn.transactions[0]
        || (request.swap.currencyIn.currency !== TCurrencySymbol.BRL && request.swap.addressIn)) {
        handleMountQrCodePixByRequest(request)
      }
    }
  }, [data, request]);

  console.log("request", request);

  const InfosView = ({ amountColor }: { amountColor?: VariantsType }) => {
    return modalData.currency === TCurrencySymbol.BRL ? (
			<>
				<Text
					style={{ textAlign: "center" }}
					fontStyle={"bold"}
					size={"24px"}
					variant={amountColor}
				>
					{CurrencyUtil.formatCurrency(modalData.amount, modalData.currency)}
				</Text>
				<Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"14px"}>
					{modalData.pix.info.name}
				</Text>
				<Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"14px"}>
					{t(`cryptohub-card.deposit-modal.person-company-id`)}{" "}
					{Util.maskCpfOrCnpj(modalData.pix.info.personCompanyId)}
				</Text>
			</>
		) : (
			<>
				<Text
					style={{ textAlign: "center" }}
					fontStyle={"bold"}
					size={"24px"}
					variant={amountColor}
				>
					{Number(Number(modalData.amount).toFixed(6)).toString()}{" "}
					{modalData.currency}
					{currencies
						.filter((x) => x.currency === modalData.currency)[0]
						?.deposit?.networks?.filter(
							(x) => x.nameId === modalData.request?.swap.networkIn.nameId
						)[0]?.contractType
						? " (" +
						  currencies
								.filter((x) => x.currency === modalData.currency)[0]
								?.deposit?.networks?.filter(
									(x) => x.nameId === modalData.request?.swap.networkIn.nameId
								)[0]?.contractType +
						  ")"
						: ""}
				</Text>
				<Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"14px"}>
					network: {modalData.request?.swap.networkIn.description}
				</Text>
			</>
		);
  }

  const PaidView = () => {

    const commonViewStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%"
    }

    return (
      <>
        <View style={[commonViewStyle, { width: "100%" }]}>
          <View style={[commonViewStyle, { width: 144, height: 144, backgroundColor: "#ebf4ef" }]}>
            <View style={[commonViewStyle, { width: 120, height: 120, backgroundColor: "#c3ded1" }]}>
              <View style={[commonViewStyle, { width: 96, height: 96, backgroundColor: "#9bc9b3" }]}>
                <View style={[commonViewStyle, { width: 80, height: 80, backgroundColor: "#4b9e77" }]}>
                  <Icon name={"CheckmarkIcon"} fontSize={"64px"} variant={"white"} />
                </View>
              </View>
            </View>
          </View>
        </View>

        <Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"14px"} variant={"success"}>{t(`cryptohub-card.deposit-modal.invoice-paid`)}</Text>

        <InfosView amountColor={"success"} />

        <Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"12px"}>{modalData.transactionId ?? modalData.pix.info.pix.transactionId} ({modalData.network})</Text>

        {
          modalData.paidAt && (
            <Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"11px"}
            >{t(`cryptohub-card.deposit-modal.invoice-paid-at`)} {Util.formatDate(modalData.paidAt, undefined, "L HH[h]mm")}</Text>
          )
        }
      </>
    )
  }

  const NotPaidView = () => {

    const isDue = requestTransaction?.isDue === isYesOrNoEnum.YES;

    const commonViewStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%"
    }

    return (
      <>
        <View style={[commonViewStyle, { width: "100%" }]}>
          <View style={[commonViewStyle, { width: 144, height: 144, backgroundColor: "#faeaec" }]}>
            <View style={[commonViewStyle, { width: 120, height: 120, backgroundColor: "#eeadb3" }]}>
              <View style={[commonViewStyle, { width: 96, height: 96, backgroundColor: "#e1707b" }]}>
                <View style={[commonViewStyle, { width: 80, height: 80, backgroundColor: "#d53343" }]}>
                  <Icon name={isDue ? "ClockHistory" : "CloseIcon"} fontSize={"64px"} variant={"white"} />
                </View>
              </View>
            </View>
          </View>
        </View>

        <Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"14px"} variant={"danger"}>{t(`cryptohub-card.deposit-modal.${isDue ? "invoice-expired" : "invoice-not-paid"}`)}</Text>

        <InfosView amountColor={"danger"} />

        <Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"12px"}>{modalData.transactionId ?? modalData.pix.info.pix.transactionId} ({modalData.network})</Text>

        {
          modalData.paidAt && (
            <Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"11px"}
            >{t(`cryptohub-card.deposit-modal.invoice-paid-at`)} {Util.formatDate(modalData.paidAt, undefined, "L HH[h]mm")}</Text>
          )
        }

        {
          requestTransaction.isDue === isYesOrNoEnum.YES && (
            <Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"11px"}
            >{t(`cryptohub-card.deposit-modal.due`)} {Util.formatDate(requestTransaction.dueAt, undefined, "L HH[h]mm")}</Text>
          )
        }
      </>
    )
  }

  const AwaitingPaymentView = () => {
    return (
			<>
				{pixBase64 ? (
					<>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Image
								source={{
									uri:
										modalData.isDue === isYesOrNoEnum.YES
											? QRCodeFallback
											: pixBase64,
								}}
								style={{
									width: 160,
									height: 160,
								}}
								blurRadius={modalData.isDue === isYesOrNoEnum.YES ? 5 : 0}
							/>

							<View
								style={{
									position: "absolute",
									borderRadius: "50%",
									overflow: "hidden",
									backgroundColor: "#FFF",
									padding: 1,
									border: "1px solid #F1F1F1",
								}}
							>
								<Image
									source={{
										uri:
											modalData.currency === TCurrencySymbol.BRL
												? tenant.favicon
												: `/static/images/currencies/${
														modalData?.currency ?? "UNKNOWN"
												  }.svg`,
									}}
									style={{
										width: 42,
										height: 42,
									}}
								/>
							</View>

							<View
								style={{ position: "absolute", bottom: "0", marginBottom: -6 }}
							>
								{modalData.currency === TCurrencySymbol.BRL && (
									<Text
										style={{ textAlign: "center" }}
										fontStyle={"bold"}
										size={"12px"}
									>
										{modalData.transactionId ??
											modalData.pix.info.pix.transactionId}{" "}
										({modalData.network})
									</Text>
								)}
								{modalData.currency !== TCurrencySymbol.BRL && (
									<Text
										style={{ textAlign: "center" }}
										fontStyle={"bold"}
										size={"12px"}
									>
										{modalData.request?.swap.addressIn}
									</Text>
								)}
							</View>
						</View>

						<InfosView />

						{modalData.dueAt && (
							<Text
								style={{ textAlign: "center" }}
								fontStyle={"bold"}
								size={"11px"}
								variant={
									modalData.isDue === isYesOrNoEnum.YES ? "danger" : undefined
								}
							>
								{t(`cryptohub-card.deposit-modal.due`)}{" "}
								{Util.formatDate(modalData.dueAt, undefined, "L HH[h]mm")}
							</Text>
						)}
					</>
				) : (
					<>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Image
								source={{ uri: QRCodeFallback }}
								style={{
									width: 160,
									height: 160,
								}}
								blurRadius={5}
							/>

							<View
								style={{
									position: "absolute",
									borderRadius: "50%",
									overflow: "hidden",
									backgroundColor: "#FFF",
									padding: 1,
									border: "1px solid #F1F1F1",
								}}
							>
								<Image
									source={{ uri: tenant.favicon }}
									style={{
										width: 42,
										height: 42,
									}}
								/>
							</View>

							<View
								style={{
									position: "absolute",
									filter: "blur(0.5)",
									width: "100%",
									height: "100%",
								}}
							/>
						</View>
					</>
				)}

				{pixBase64 &&
				(modalData.isDue ?? isYesOrNoEnum.NO) === isYesOrNoEnum.NO ? (
					<>
						<Button
							size={"medium"}
							label={t(
								modalData.currency === TCurrencySymbol.BRL
									? `cryptohub-card.deposit-modal.qrcode-copy`
									: `cryptohub-card.deposit-modal.qrcode-address-copy`
							)}
							icon={<Icon name={"CopyIcon"} fontSize={"14px"} color={"#FFF"} />}
							disabled={isCreatingInvoice}
							disableHover={isCreatingInvoice}
							onClick={() => handleCopyQrCode()}
						/>
						{false && modalData.currency !== TCurrencySymbol.BRL && (
							<Button
								size={"medium"}
								label={t(
									"cryptohub-card.deposit-modal.qrcode-metamask-mobile-link-copy"
								)}
								icon={
									<Icon name={"CopyIcon"} fontSize={"14px"} color={"#FFF"} />
								}
								disabled={isCreatingInvoice}
								disableHover={isCreatingInvoice}
								onClick={() => handleCopyQrCode()}
							/>
						)}
						{false && modalData.currency !== TCurrencySymbol.BRL && (
							<Button
								size={"medium"}
								label={t(
									"cryptohub-card.deposit-modal.qrcode-trustwallet-mobile-link-copy"
								)}
								icon={
									<Icon name={"CopyIcon"} fontSize={"14px"} color={"#FFF"} />
								}
								disabled={isCreatingInvoice}
								disableHover={isCreatingInvoice}
								onClick={() => handleCopyQrCode()}
							/>
						)}
					</>
				) : (
					<View style={{ marginTop: 8 }}>
						<Button
							size={"medium"}
							label={t(
								isCreatingInvoice
									? `cryptohub-card.deposit-modal.qrcode-generating`
									: `cryptohub-card.deposit-modal.qrcode-generate${
											(modalData.isDue ?? isYesOrNoEnum.NO) ===
											isYesOrNoEnum.YES
												? "-due"
												: ""
									  }`
							)}
							icon={
								isCreatingInvoice ? (
									<ActivityIndicator color={"#FFF"} size={14} />
								) : (
									<Icon name={"QrCode"} fontSize={"14px"} color={"#FFF"} />
								)
							}
							disabled={isCreatingInvoice}
							disableHover={isCreatingInvoice}
							onClick={() => handleCreateInvoice()}
						/>
					</View>
				)}

				{pixBase64 && (
					<View preset={"warning-box"}>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								gap: 8,
								marginBottom: 8,
							}}
						>
							<Icon name={"WarningIcon"} variant={"warning"} />
							<Text fontStyle={"bold"}>
								{t(`cryptohub-card.deposit-modal.rules.title`)}
							</Text>
						</View>

						<View
							style={{
								display: "flex",
								flexDirection: "column",
								lineHeight: 1.5,
								paddingLeft: 8,
								paddingRight: 8,
								textAlign: "left",
							}}
						>
							{modalData.currency !== TCurrencySymbol.BRL && (
								<Text>
									• {t(`cryptohub-card.deposit-modal.rules.rule-1-1-crypto`)}
								</Text>
							)}

							{modalData.currency !== TCurrencySymbol.BRL && (
								<>
									<Text>
										• {t(`cryptohub-card.deposit-modal.rules.rule-0`)}
										<Text style={{ color: "red", fontWeight: "bold" }}>
											{modalData.amount} {modalData.currency}
											{currencies
												.filter((x) => x.currency === modalData.currency)[0]
												?.deposit?.networks?.filter(
													(x) =>
														x.nameId ===
														modalData.request?.swap.networkIn.nameId
												)[0]?.contractType
												? " (" +
												  currencies
														.filter((x) => x.currency === modalData.currency)[0]
														?.deposit?.networks?.filter(
															(x) =>
																x.nameId ===
																modalData.request?.swap.networkIn.nameId
														)[0]?.contractType +
												  ")"
												: ""}
										</Text>
										.
									</Text>
								</>
							)}
							{modalData.currency === TCurrencySymbol.BRL && (
								<Text>
									•{" "}
									{t(`cryptohub-card.deposit-modal.rules.rule-1-1-fiat`, {
										tenant: tenant.companyName || tenant.name,
									})}
								</Text>
							)}
							{modalData.currency !== TCurrencySymbol.BRL && (
								<>
									<Text>
										• {t(`cryptohub-card.deposit-modal.rules.rule-1-2-crypto`)}{" "}
										<Text style={{ color: "red", fontWeight: "bold" }}>
											{modalData.request?.swap.networkIn.description}
										</Text>
										.
									</Text>
								</>
							)}
						</View>
					</View>
				)}
			</>
		);
  }

  return (
    <Modal
      visible={true}
      title={t(`cryptohub-card.deposit-modal.title`)}
      titleIcon={"BoxArrowInDown"}

      onClose={handleClose}

      backgroundColor={"new"}
      footerDivider={true}
      customWidth={{
        width: "100%",
        maxWidth: "448px"
      }}
      fullHeightModal={true}
      contentOverflowY={"auto"}
      footer={
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flex: 1 }}>
          <Button
            label={t(`cryptohub-card.deposit-modal.close`)}
            fillVariant={"ghost"}
            variant={"secondary"}
            size={"small"}
            onClick={handleClose}
          />
        </View>
      }
    >
      <View style={[styles.card]}>
        <Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"16px"}>{t(`cryptohub-card.deposit-modal.exchange`)}</Text>

        {
          request?.swap.status.id === RequestStatusId.PENDING || (request?.swap.status.id === RequestStatusId.COMPLETED && requestTransaction?.isDue === isYesOrNoEnum.NO) || modalData?.isPaid === isYesOrNoEnum.YES ? (
            <>
              {modalData?.isPaid === isYesOrNoEnum.YES ? <PaidView /> : <AwaitingPaymentView />}
            </>
          ) : (
            <>
              <NotPaidView />
            </>
          )
        }


      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    borderRadius: 8,
    padding: 16,
    gap: 8,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)"
  },
});
