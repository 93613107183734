import Util from ".";
import { TCurrencies, TCurrency, TCurrencySymbol } from "../types/currency";

export default class CurrencyUtil {

    static getNumberByValue(value: string) {

        if (!value) {
            return 0;
        }

        if (!isNaN(Number(value))) {
            return Number(value);
        }

        const decimals = value.split(",")[1]?.length || 0;

        let formattedValue = value.replace(/\D+/g, "");
        const replaceRegex = new RegExp(`([0-9]{${decimals}})$`, "g");
        formattedValue = formattedValue.replace(replaceRegex, ".$1");
        const numberValue = Number(formattedValue);
        return !isNaN(numberValue) ? numberValue : 0;
    }

    /**
     *
     * @param amount
     * @param currency
     * @param showSymbol
     * @param minimumDecimals
     * @param maximumDecimals
     */
    static formatCurrency(
        amount: number | string = 0,
        currency: TCurrencySymbol | "PERCENTAGE",
        showSymbol: boolean = true,
        minimumDecimals?: number,
        maximumDecimals?: number
    ): string {

        amount = Number(amount);

        const options: Intl.NumberFormatOptions = showSymbol ? {
            minimumFractionDigits: minimumDecimals ?? 2,
            maximumFractionDigits: maximumDecimals,
            style: "currency",
            currency: "BRL"
        } : {
            minimumFractionDigits: 2,
            maximumFractionDigits: maximumDecimals,
            useGrouping: true,
        }

        if (isNaN(amount)) { amount = 0; }

        switch (currency) {
            case "PERCENTAGE":
                return Number(amount).toLocaleString("pt-BR", showSymbol ? { style: "percent", minimumFractionDigits: minimumDecimals ?? 2, useGrouping: false } : { minimumFractionDigits: minimumDecimals ?? 2, useGrouping: false });
            case "BRL":
                return Number(Util.truncate(amount, maximumDecimals ?? 2)).toLocaleString("pt-BR", options);
            default:
                return Number(Util.truncate(amount, maximumDecimals ?? (TCurrency[currency]?.decimals ?? 0)))
                    .toLocaleString("pt-BR", { minimumFractionDigits: minimumDecimals ?? 0, maximumFractionDigits: 20, useGrouping: true }) + (showSymbol ? ` ${currency}` : ``);

        }
    }
}
