import styled from "rn-css";
import Colors from "../../global-constants/Colors";
import { Button, Icon } from "hubchain-storybook-design-pattern";
import React from "react";

interface TableRowProps {
    index?: number,
    disableHover?: boolean,
    zIndex?: number,
    height?: number,
    gap?: number,
    minWidth?: string,
    opacity?: number
    backgroundColor?: string
}

const TableRow = styled.View<TableRowProps>`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.backgroundColor ?? Colors.light.background};
  min-width: ${props => props.minWidth || "fit-content"};
  padding: 0 0.5rem;
  min-Height: ${props => props.height ?? "48px"};
  z-index: ${props => props.zIndex === undefined ? -1 : props.zIndex};
  opacity: ${props => props.opacity ?? 1};
  
  border-bottom: 1px solid #00000012;
  margin-top: ${props => props.index === 0 ? "-4px" : 0};
  gap: ${props => props.gap ?? 0};
  
  -webkit-transition: background-color 1.5s ease-out;
  -moz-transition: background-color 1.5s ease-out;
  -o-transition: background-color 1.5s ease-out;  
  transition: background-color 1.5s ease-out;

  &:hover {
    background-color: ${props => (!props.disableHover && !props.backgroundColor) ? "#F6F6F6" : ""};
  }  
`

interface TableDetailsButtonProps {
    onClick: () => void
}

const TableDetailsButton = ({ onClick }: TableDetailsButtonProps) => {
    return (
        <Button
            label={""}
            size={"table"}
            icon={<Icon name={"ChevronRightLarge"} variant={"gray"} fontSize={"16"} />}

            variant={"primary"}
            fillVariant={"ghost"}

            onClick={onClick}
        />
    )
}

export { TableRow, TableDetailsButton };
