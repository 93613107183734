import React, { useEffect, useMemo, useRef, useState } from "react";

import "react-toastify/dist/ReactToastify.css";

import DefaultLayout from "../../src/layouts/default-layout";
import { useTranslation } from "react-i18next";

import { TCurrencySymbol } from "../../src/types/currency";
import { UserSwapModal } from "../../src/components/UserSwapModal/UserSwapModal";
import CryptoHub from "../../src/components/CryptoHub/CryptoHub";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SwapService, {
	GetCryptoHubBalancesResponse,
} from "../../src/services/swap/swapService";
import RequestSwapService, {
	RequestSwapModel,
} from "../../src/services/swap/swapService";
import { PusherService } from "../../src/services/pusher";
import { DataTable, ProgressBar } from "react-native-paper";
import GeneralCSS from "../../src/global-constants/Styles";
import {
	Icon,
	Text,
	TooltipConfig,
	View,
} from "hubchain-storybook-design-pattern";
import Colors from "../../src/global-constants/Colors";
import { DefaultFilter } from "../../src/models/DefaultFilter";
import { TextStyle } from "react-native";
import Util from "../../src/utils";

import { RequestStatusId } from "../../src/enums/RequestStatusId.enum";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";
import { RateEditConfirmModal } from "./confirmModals";
import { useAuth } from "../../src/services/auth";
import CryptoHubDepositModal, {
	CryptoHubDepositModalConfig,
} from "../../src/components/CryptoHub/CryptoHubDepositModal";
import { useCurrency } from "../../src/hooks/useCurrency";
import { FiltersEntries } from "hubchain-storybook-design-pattern/lib/components/Filter/types";
import { useAlert } from "../../src/hooks/useAlert";
import { isYesOrNoEnum } from "../../src/types/types";
import DoneWithdrawalModal from "./doneWithdrawalModal";
import moment from "moment-timezone";
import { useIsFocused } from "@react-navigation/native";
import SwapTableBody from "./components/SwapTableBody";
import { isMobile } from "react-device-detect";
import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";
import { CryptoHubTransferModal } from "../../src/components/TreasurySwapModal/CryptoHubTransferModal";
import { TenantAppDomainEnum } from "../../src/services/multi-tenancy/types";
import { Flip, toast, ToastContainer } from "react-toastify";
import { ToastAlert, ToastAlertDefaults } from "./components/ToastAlert";
import { WebhookEventType } from "../../src/services/webhook/webhooksServices";
import { useDebouncedCallback } from "../../src/hooks/useDebounce";

// import { NetworkNameId } from "../../src/types/network"; // Não usar!

export interface SwapModalConfig {
	visible: boolean;
	currencyIn: TCurrencySymbol | null | undefined;
	request?: RequestSwapModel;
	isCancel?: boolean;
	action: "edit" | "cancel" | "view" | "new";
}

export interface SwapTreasuryModalConfig {
	visible: boolean;
	currencyIn: TCurrencySymbol | null;
	request?: RequestSwapModel;
	isCancel?: boolean;
	action: "edit" | "cancel" | "view" | "new";
}

type CustomTableTextStyle = TextStyle & {
	display: string;
	whiteSpace: string;
	maxHeight: string;
	lineHeight: number;
};

export interface SwapRateEditConfig {
	request: RequestSwapModel;
	newRateValue: string;
	confirm: boolean;
}

export interface WithdrawalModalConfig {
	request: RequestSwapModel;
}

export interface RequestSwapModelExtended extends RequestSwapModel {
	isLoading?: boolean;
	isBlinking?: boolean;
}

const isRequestInError = (request: RequestSwapModel) => {
	const transaction = request?.swap?.transactionsIn?.transactions[0];

	if (!transaction) {
		return false; //transactions not exists
	}

	if (transaction.network !== "pix-mainnet" /* NetworkNameId.PIX_MAINNET */) {
		return false;
	}

	if (transaction.isPaid === isYesOrNoEnum.YES) {
		return false;
	}

	if (
		!!transaction.dueAt &&
		moment(transaction.dueAt).isBefore(new Date()) &&
		transaction.isDue !== isYesOrNoEnum.YES
	) {
		return true;
	}

	return false;
};

export default function SwapScreen() {
	const { user } = useAuth();
	const { showAlert } = useAlert();
	const { t } = useTranslation();
	const { currencies, markets } = useCurrency();
	const isFocused = useIsFocused();
	const { tenant } = useMultiTenancy();

	const isFetchingCryptoHubInfoBalancesRef = useRef<boolean>(false);

	const isFetchingSwapRequestsDataRef = useRef<boolean>(false);

	const [filter, setFilter] = useState<DefaultFilter>({
		page: 1,
		limit: 20,
		total: 0,
		search: {},
	});

	const {
		data: _cryptohubInfoBalancesData,
		isFetching: isFetchingCryptoHubInfoBalances,
		refetch: refetchCryptoHubInfoBalances,
	} =
		useQuery(["CryptoHubInfoBalances"], {
			queryFn: RequestSwapService.getCryptoHubInfoBalances,
			staleTime: 60 * 1000 * 5, // 5 minutes
			retry: 2,
			retryDelay: 60 * 1000 * 2,
			onSuccess: (data) => {
				setIsRunning(true /* data?.data?.isOnHold === isYesOrNoEnum.NO */);
			},
		});

	const [updatedSwapRequestsData, setUpdatedSwapRequestsData] = useState<RequestSwapModelExtended[]>([]);

	const options = {
		style: {
			background: "#fff",
			width: "auto",
			minWidth: "144px",
			maxWidth: "384px",
			height: "auto",
			minHeight: "55px",
			borderRadius: "10px",
			boxShadow:
				"0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)",
			paddingLeft: "0px",
			paddingRight: "0px",
			padding: "0px",
			div: {
				padding: "0px",
				background: "red",
			},
		},
	};

	const {
		data: SwapRequestsData,
		isFetching: isFetchingSwapRequestsData,
		// isLoading,
		refetch: refetchSwapRequestsData,
	} = useQuery(["swaps", filter.page, filter.search], {
		queryFn: () => SwapService.list(filter),
		staleTime: 0,
		retry: false,
		refetchOnWindowFocus: false,
		keepPreviousData: false,
		onSuccess: async (data) => {
			if (!!data?.data?.rows) {
				try {
					const requests = data.data.rows;
					let transactionsIdInError: string[] = [];
					let requestIdInError: string[] = [];

					requests.forEach((request) => {
						if (isRequestInError(request)) {
							transactionsIdInError.push(
								request.swap.transactionsIn.transactions[0].transactionId
							);
							requestIdInError.push(request.id);
						}
					});

					if (transactionsIdInError.length > 0) {
						await Promise.all(
							transactionsIdInError.map(SwapService.refreshRequestTransaction)
						);

						const updatedResponse = await SwapService.list(filter);

						if (updatedResponse?.data?.rows?.length > 0) {
							setUpdatedSwapRequestsData(
								updatedResponse.data.rows.filter((updatedRequest) =>
									requestIdInError.includes(updatedRequest.id)
								) as RequestSwapModelExtended[]
							);
						}
					} else {
						setUpdatedSwapRequestsData([]);
					}
				} catch (e) {
					setUpdatedSwapRequestsData([]);
				}
			}
		},
		onError: () => {
			showAlert(
				t(`swap.table.errors.on-fetch.message`),
				"danger",
				t(`swap.table.errors.on-fetch.title`)
			);
		},
	});

	useEffect(() => {
		if (isFocused && !isFetchingSwapRequestsData) {
			refetchSwapRequestsData();
		}
	}, [isFocused]);

	// Pusher

	const _pusherMessages = {};


	useEffect(() => {
		isFetchingSwapRequestsDataRef.current = isFetchingSwapRequestsData;
	}, [isFetchingSwapRequestsData]);

	const refetchSwapRequestsDataDebounced = useDebouncedCallback(async () => {
		if (isFetchingSwapRequestsDataRef.current === true) {
			console.log(`refetchSwapRequestsDataDebounced`, "waiting...");
			refetchSwapRequestsDataDebounced();
		} else {
			console.log(`refetchSwapRequestsDataDebounced`, "done!");
			refetchSwapRequestsData();
		}
	}, 1000);


	useEffect(() => {
		isFetchingCryptoHubInfoBalancesRef.current = isFetchingCryptoHubInfoBalances;
	}, [isFetchingCryptoHubInfoBalances]);

	const refetchCryptoHubInfoBalancesDebounced = useDebouncedCallback(async () => {
		if (isFetchingCryptoHubInfoBalancesRef.current === true) {
			console.log(`refetchCryptoHubInfoBalances`, "waiting...");
			refetchCryptoHubInfoBalancesDebounced();
		} else {
			console.log(`refetchCryptoHubInfoBalances`, "done!");
			refetchCryptoHubInfoBalances();
		}
	}, 2 * 1000);

	useEffect(() => {

		// ex.: channel_name = ezzepay-backoffice-development
		const _channel = PusherService.subscribe(PusherService.getChannelNameByTenant("backoffice", tenant));

		// *** To EmultateEvent: https://dashboard.pusher.com/apps/706073/console
		// * Channel: ezzepay-backoffice-development
		// * Event  : WebhookEventType.REQUEST_SWAP_STATUS_CHANGED
		// const _data = 
		/*
		{
			"message": {
				"type": "success",
				"title": "Teste",
				"content": "Teste2\nTeste3"
			},
			"data": {
				"request": {
					"id": "06c79dd6-b32d-4967-a4a6-4c887f93eb35",
					"swap": {
						"status": { "id": 2, "key": "COMPLETED", "namePt": "Concluída" },
						"step": "Trade_OK"
					}
				}
			}
		}
		*/

		_channel.bind(WebhookEventType.REQUEST_SWAP_STATUS_CHANGED, (eventData: any) => {

			console.log(`::${WebhookEventType.REQUEST_SWAP_STATUS_CHANGED}::`, eventData?.data?.request);

			if (eventData?.data?.request?.id) {
				setUpdatedSwapRequestsData([
					{ ...eventData?.data?.request, isLoading: true, isBlinking: true }
				]);
			}

			refetchSwapRequestsDataDebounced();

		});

		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		// *** To EmultateEvent: https://dashboard.pusher.com/apps/706073/console
		// * Channel: ezzepay-backoffice-development
		// * Event  : WebhookEventType.CRYPTOHUB_BAAS_CASH_IN		 
		// const data =
		/*
		{
			"message": {
				"type": "success",
				"title": "Teste",
				"content": "Teste2"
			},
			"data": {
				"transaction": {
					"transactionId": "222"
				}
			}
		}
		*/

		_channel.bind(WebhookEventType.CRYPTOHUB_BAAS_CASH_IN, (eventData: any) => {

			console.log(`::${WebhookEventType.CRYPTOHUB_BAAS_CASH_IN}::`, Util.tryCastStringToObject(eventData));

			const _eventId = eventData?.eventId ?? eventData?.data?.transaction?.transactionId ?? null;

			if (_eventId === null || !_pusherMessages[_eventId]) {

				_pusherMessages[_eventId] = eventData;

				if (eventData?.message?.content) {
					toast(
						<ToastAlert
							title={eventData?.message?.title ?? null}
							message={`${eventData?.message?.content ?? ""}${eventData?.data?.transaction?.transactionId
								? " (#" + eventData?.data?.transaction?.transactionId + ")"
								: ""
								}`}
						/>,
						{ style: ToastAlertDefaults.style }
					);
				}

			}

			refetchCryptoHubInfoBalancesDebounced();

		});

		return () => {
			_channel.unbind_all();
			_channel.unsubscribe();
		};

	}, []);

	filter.total = SwapRequestsData?.data?.count || 0;

	const requests: RequestSwapModelExtended[] = useMemo(() => {

		let requests: RequestSwapModelExtended[] = SwapRequestsData?.data?.rows!;

		if (!!updatedSwapRequestsData.length && !!requests.length) {

			requests = requests.map(
				(request) => {

					let _request = updatedSwapRequestsData.find(
						(updatedRequest: RequestSwapModelExtended) => updatedRequest.id! === request.id
					);

					_request = _request ? {
						...request,
						isLoading: _request.isLoading ?? request.isLoading,
						isBlinking: _request.isBlinking ?? request.isBlinking,
						swap: { ...request?.swap, ...(_request.swap ?? {}) }
					} : request;


					if (_request?.isBlinking === true) {
						setTimeout(() => {
							setUpdatedSwapRequestsData([{ ..._request, isLoading: false, isBlinking: false }]);
						}, 0.5 * 1000);
					}

					return _request;

				}
			);
		}

		return requests || [];

	}, [SwapRequestsData, updatedSwapRequestsData]);

	const { mutateAsync: handleResume } = useMutation({
		mutationFn: SwapService.resume,
	});

	const { mutateAsync: handleStop } = useMutation({
		mutationFn: SwapService.stop,
	});

	const { mutateAsync: handlePayment } = useMutation({
		mutationFn: SwapService.payment,
	});

	const { mutate: handleToggleSwapsAutorun, isLoading: isTogglingAutorun } =
		useMutation({
			mutationFn: SwapService.toggleSwapsAutorun,
			onSuccess: () => {
				setIsRunning((prev) => !prev);
			},
			onError: () => {
				showAlert(
					t(`swap.table.errors.on-toggle-autorun.message`),
					"danger",
					t(`swap.table.errors.on-toggle-autorun.title`)
				);
			},
		});

	const queryClient = useQueryClient();

	const [swapModalConfig, setSwapModalConfig] = useState<SwapModalConfig>({
		visible: false,
		currencyIn: null,
		request: undefined,
		isCancel: false,
		action: "edit",
	});

	const [withdrawalModalConfig, setWithdrawalModalConfig] =
		useState<WithdrawalModalConfig | null>(null);

	const [rateEditConfig, setRateEditConfig] =
		useState<SwapRateEditConfig | null>(null);

	const [depositModalConfig, setDepositModalConfig] =
		useState<CryptoHubDepositModalConfig | null>(null);

	const [isRunning, setIsRunning] = useState(false);

	// Modal System

	const [swapTreasuryModal, setSwapTreasuryModal] =
		useState<SwapTreasuryModalConfig>({
			visible: false,
			currencyIn: null,
			request: undefined,
			isCancel: false,
			action: "edit",
		});

	const openSwapTreasuryModal = (
		currency?: TCurrencySymbol,
		action: "edit" | "cancel" | "view" | "new" = "new"
	) => {
		setSwapTreasuryModal({
			visible: true,
			currencyIn: currency || TCurrencySymbol.BRL,
			action: action,
		});
	};

	const closeSwapTreasuryModal = () => {
		setSwapTreasuryModal({
			visible: false,
			currencyIn: null,
			action: "view",
		});
	};

	const openSwapModal = (
		currency?: TCurrencySymbol,
		action: "edit" | "cancel" | "view" | "new" = "new"
	) => {
		setSwapModalConfig({
			visible: true,
			currencyIn: currency || TCurrencySymbol.BRL,
			action: action,
		});
	};

	const closeSwapModal = () => {
		setSwapModalConfig({
			visible: false,
			currencyIn: null,
			action: "view",
		});
	};

	const handleOpenDepositModal = ({
		amount,
		currency,
		request,
	}: {
		amount: number;
		currency: TCurrencySymbol;
		request: RequestSwapModel;
	}) => {
		const _cryptohubInfoBalancesData: GetCryptoHubBalancesResponse | undefined =
			queryClient.getQueryData("CryptoHubInfoBalances");

		const pixInfo = _cryptohubInfoBalancesData?.data?.cashIn?.pix?.BRL![0];

		if (pixInfo) {
			setDepositModalConfig({
				amount: amount,
				currency: currency,
				pix: {
					info: pixInfo,
				},
				request: request,
			});
		}
	};

	const statusOptions: SelectOptionModel[] = useMemo(() => {
		return [
			{ value: "", label: t("general.filter-options.all"), color: "primary" },
			{
				value: RequestStatusId[RequestStatusId.COMPLETED],
				label: t(`swap.status.${RequestStatusId.COMPLETED}`),
				color: "success",
				icon: (
					<View
						variant={"success"}
						style={{ width: 8, height: 6, borderRadius: 10, marginRight: 6 }}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.PENDING],
				label: t(`swap.status.${RequestStatusId.PENDING}`),
				color: "warning",
				icon: (
					<View
						variant={"warning"}
						style={{ width: 8, height: 6, borderRadius: 10, marginRight: 6 }}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.CANCELLED],
				label: t(`swap.status.${RequestStatusId.CANCELLED}`),
				color: "danger",
				icon: (
					<View
						variant={"danger"}
						style={{ width: 8, height: 6, borderRadius: 10, marginRight: 6 }}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.EXPIRED],
				label: t(`swap.status.${RequestStatusId.EXPIRED}`),
				icon: (
					<View
						style={{
							width: 8,
							height: 6,
							borderRadius: 10,
							marginRight: 6,
							backgroundColor: "#ffa500",
						}}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.AWAITING_DEPOSIT],
				label: t(`swap.status.${RequestStatusId.AWAITING_DEPOSIT}`),
				icon: (
					<View
						style={{
							width: 8,
							height: 6,
							borderRadius: 10,
							marginRight: 6,
							backgroundColor: "#7393B3",
						}}
					/>
				),
			},
			{
				value: RequestStatusId[RequestStatusId.AWAITING_WITHDRAWAL],
				label: t(`swap.status.${RequestStatusId.AWAITING_WITHDRAWAL}`),
				icon: (
					<View
						style={{
							width: 8,
							height: 6,
							borderRadius: 10,
							marginRight: 6,
							backgroundColor: "#B2BEB5",
						}}
					/>
				),
			},
		];
	}, []);

	const [filterStatus, setFilterStatus] = useState(statusOptions[0]);

	const currencyInOptions: SelectOptionModel[] = [
		{ value: "", label: t("general.filter-options.all"), color: "primary" },
		{ value: "BRL", label: "Real", color: "primary" },
	];
	const [filterCurrencyIn, setFilterCurrencyIn] = useState(
		currencyInOptions[0]
	);

	const currencyOutOptions: SelectOptionModel[] = useMemo(() => {
		const options: SelectOptionModel[] = [
			{ value: "", label: t("general.filter-options.all"), color: "primary" },
			...currencies
				.filter((currency) => currency.type === "crypto")
				.map((currency) => ({
					value: currency.currency,
					label: currency.name,
					subLabel: currency.currency,
					icon: currency.currency,
				})),
		];

		return options;
	}, [currencies]);
	const [filterCurrencyOut, setFilterCurrencyOut] = useState(
		currencyOutOptions[0]
	);

	const columns = [
		"identifier",
		"account-in",
		"currency-in",
		"currency-out",
		"amount-in",
		"amount-out",
		"fee",
		"rate",
		"status",
		"",
	];

	const columnsMinWidth = [
		30,
		100,
		70,
		70,
		96,
		96,
		160,
		!!user?.isHubchainUser ? 148 : 96,
		100,
		32,
	];

	const columnsMaxWidth = [
		30,
		"unset",
		"unset",
		"unset",
		"unset",
		"unset",
		"unset",
		"unset",
		"unset",
		32,
	];

	const filters: FiltersEntries[] = [
		{
			label: t(`swap.table.filters.currency-in`),
			visible: false,
			icon: {
				name: "Filter",
			},
			type: "select",
			params: {
				options: currencyInOptions,
				placeholder: "",
				selected: filterCurrencyIn,
				type: "currencyIn",
				iconSet: "currency",
				iconSize: 24,
			},
			customWidth: {
				minWidth: "144px",
			},
		},
		{
			label: t(`swap.table.filters.currency-out`),
			visible: user?.isHubchainUser,
			icon: {
				name: "Filter",
			},
			type: "select",
			params: {
				options: currencyOutOptions,
				placeholder: "",
				selected: filterCurrencyOut,
				type: "currencyOut",
				iconSet: "currency",
				iconSize: 24,
			},
			customWidth: {
				minWidth: "144px",
			},
		},
		{
			label: t(`swap.table.filters.status`),
			visible: true,
			icon: {
				name: "Filter",
			},
			type: "select",
			params: {
				options: statusOptions,
				placeholder: "",
				selected: filterStatus,
				type: "status",
			},
			customWidth: {
				minWidth: "144px",
			},
		},
		{
			name: "searchInput",
			placeholder: t(`swap.table.filters.search`),
			icon: {
				name: "SearchIcon",
			},
			visible: !isMobile,
			type: "input",
			params: {
				action: "search",
				type: "search",
			},
			customWidth: {
				minWidth: "144px",
			},
		},
		{
			name: "newTreasurySwapButton",
			label: "Transferências",
			icon: {
				name: "SwapVertIcon",
				web: {
					size: "19px",
				},
				mobile: {
					size: "19px",
				},
			},
			params: {
				action: "new-treasury-swap",
			},
			disabled:
				(!user?.isHubchainUser &&
					[TenantAppDomainEnum.EZZEPAY].includes(tenant.name)) ||
				isTogglingAutorun ||
				isFetchingCryptoHubInfoBalances,
			visible: (
				!isMobile
				&&
				(![TenantAppDomainEnum.EZZEPAY].includes(tenant.name) || user?.isHubchainUser)
			),
			type: "button",
		},
		{
			name: "stopStatusButton",
			label: t(`swap.buttons.${isRunning ? "stop" : "run"}-swaps`),
			variant: isRunning ? "success" : "danger",
			icon: {
				name: isRunning ? "Pause" : "Play",
				web: {
					size: "19px",
				},
				mobile: {
					size: "19px",
				},
			},
			params: {
				action: "toggle-run-stop-swaps",
			},
			disabled: !user?.isHubchainUser || isTogglingAutorun || isFetchingCryptoHubInfoBalances,
			visible: (
				!isMobile
				&&
				user?.isHubchainUser
			),
			type: "button",
			whiteSpace: "nowrap",
		},
		{
			name: "newSwapButton",
			label: t("swap.buttons.new-swap"),
			icon: {
				name: "AddIcon",
				web: {
					size: "19px",
				},
				mobile: {
					size: "19px",
				},
			},
			params: {
				action: "new-swap",
			},
			disabled: !currencies?.length || !markets?.length,
			visible: true,
			type: "button",
			whiteSpace: "nowrap",
		},
		{
			name: "refreshButton",
			icon: {
				name: "Redo",
				web: {
					size: "19px",
				},
				mobile: {
					size: "19px",
				},
			},
			params: {
				action: "refresh",
			},
			disabled: isFetchingSwapRequestsData,
			visible: true,
			type: "button",
			isLoading: isFetchingSwapRequestsData,
		},
	];

	useEffect(() => {
		let updatedSearch = { ...filter.search };

		if (filterCurrencyIn.value) {
			updatedSearch.currencyIn = filterCurrencyIn.value;
		} else {
			delete updatedSearch.currencyIn;
		}

		if (filterCurrencyOut.value) {
			updatedSearch.currencyOut = filterCurrencyOut.value;
		} else {
			delete updatedSearch.currencyOut;
		}

		if (filterStatus.value) {
			updatedSearch.requestStatus = { key: filterStatus.value };
		} else {
			delete updatedSearch.requestStatus;
		}

		if (JSON.stringify(updatedSearch) !== JSON.stringify(filter.search)) {
			setFilter((state) => ({
				...state,
				search: updatedSearch,
			}));
		}
	}, [filterCurrencyIn, filterCurrencyOut, filterStatus]);

	const onFilterChange = (option: SelectOptionModel, type: any) => {
		if (type === "status") {
			setFilterStatus(option);
		} else if (type === "currencyIn") {
			setFilterCurrencyIn(option);
		} else if (type === "currencyOut") {
			setFilterCurrencyOut(option);
		}
	};

	// Search System

	const searchTimeout = useRef<any>();

	const onFilterSearchChange = (text: string) => {
		let updatedSearch = { ...filter.search };
		clearTimeout(searchTimeout.current);

		searchTimeout.current = setTimeout(() => {
			if (Util.isUUID(text)) {
				updatedSearch.id = text;
				delete updatedSearch.user;
			} else {
				updatedSearch.user = Util.mountDefaultUserSearch(
					text,
					updatedSearch.user
				);
				delete updatedSearch.id;
				if (!updatedSearch.user || text.trim() === "") {
					delete updatedSearch.user;
				}
			}

			if (text?.length >= 3 || !text?.length) {
				setFilter((state) => ({
					...state,
					page: 1,
					search: updatedSearch,
				}));
			}
		}, 500);
	};

	const handleToggleRunStopSwaps = () => {
		if (!user?.isHubchainUser || isTogglingAutorun) return;
		handleToggleSwapsAutorun();
	};

	const handleResumeSwap = async (request: RequestSwapModelExtended) => {
		request.isLoading = true;
		handleResume(request.id)
			.then(() => {
				request.swap.status.id = RequestStatusId.RUNNING;
				request.swap.status.namePt = t(`swap.table.rows.status.running`);
				request.swap.isOnHold = isYesOrNoEnum.NO;
			})
			.catch(() => {
				showAlert(
					t(`swap.table.errors.on-resume-swap.message`),
					"danger",
					t(`swap.table.errors.on-resume-swap.title`)
				);
			})
			.finally(() => {
				request.isLoading = false;
			});
	};

	const handlePaymentSwap = async (request: RequestSwapModelExtended, { isPaid }) => {
		request.isLoading = true;
		handlePayment({
			id: request.id,
			isPaid,
		})
			.then(() => {
				request.swap.isPaid = isYesOrNoEnum.YES;
			})
			.catch(() => {
				showAlert(
					t(`swap.table.errors.on-resume-swap.message`),
					"danger",
					t(`swap.table.errors.on-resume-swap.title`)
				);
			})
			.finally(() => {
				request.isLoading = false;
			});
	};

	const handleStopSwap = async (request: RequestSwapModelExtended) => {
		//if (!user.isHubchainUser) return;
		request.isLoading = true;
		handleStop(request.id)
			.then(() => {
				request.swap.status.id = RequestStatusId.RUNNING;
				request.swap.isOnHold = isYesOrNoEnum.YES;
			})
			.catch(() => {
				showAlert(
					t(`swap.table.errors.on-stop-swap.message`),
					"danger",
					t(`swap.table.errors.on-stop-swap.title`)
				);
			})
			.finally(() => {
				request.isLoading = false;
			});
	};

	const TableHeader = () => {
		return (
			<DataTable.Header
				style={[GeneralCSS.defaultTableHeader, { gap: 8 } as any]}
			>
				{columns.map((column, index) => {
					return (
						<DataTable.Title
							key={column}
							style={[
								{
									minWidth: columnsMinWidth[index],
									maxWidth: columnsMaxWidth[index] as number,
									padding: 0,
									display: "flex",
									alignItems: "center",
								},
							]}
							textStyle={
								{
									display: "flex",
									overflow: "visible",
									whiteSpace: "normal",
									maxHeight: "unset",
									lineHeight: 14,
								} as CustomTableTextStyle
							}
						>
							<Text
								style={[GeneralCSS.tableColumnTitle, { padding: 0 }]}
								typeStyle={{ type: "table", name: "table-header" }}
							>
								{column && t(`swap.table.columns.${column}`)}
							</Text>
						</DataTable.Title>
					);
				})}
			</DataTable.Header>
		);
	};

	return (
		<>
			<DefaultLayout
				titlePath={
					!isMobile
						? t("swap.titlePath", {
							returnObjects: true,
							replace: { count: filter.total || "0" },
						})
						: undefined
				}
				title={
					isMobile
						? t(`swap.title-mobile`, {
							returnObjects: true,
							replace: { count: filter.total || "0" },
						})
						: ``
				}
				filters={filters}
				onChange={(option: SelectOptionModel, type: any) =>
					onFilterChange(option, type)
				}
				onChangeText={onFilterSearchChange}
				onClick={(params) => {
					if (params.action === "refresh") {
						refetchSwapRequestsData();
					} else if (params.action === "new-swap") {
						openSwapModal();
					} else if (params.action === "toggle-run-stop-swaps") {
						handleToggleRunStopSwaps();
					} else if (params.action === "new-treasury-swap") {
						openSwapTreasuryModal();
					}
				}}
				handleBack={() => { }}
				handleRefresh={() => { }}
			>
				<View style={[{ display: "flex", flexDirection: "column", gap: 16 }]}>
					<CryptoHub />

					<View style={[GeneralCSS.card]}>
						<View style={{ overflow: "auto" }}>
							<TableHeader />

							<ToastContainer
								autoClose={false}
								hideProgressBar={true}
								closeButton={false}
								transition={Flip}
								style={{
									top: "70px",
									width: "auto",
									right: "70px",
									padding: "0px",
								}}
							/>

							<View style={{ zIndex: 2 }}>
								<ProgressBar
									visible={isFetchingSwapRequestsData}
									color={Colors.light.primary}
									indeterminate={true}
								/>
							</View>

							<View style={{ opacity: isFetchingSwapRequestsData ? 0.5 : 1 }}>
								<SwapTableBody
									requests={requests}
									services={{ currencies }}
									isFetching={isFetchingSwapRequestsData}
									isLoading={isFetchingSwapRequestsData}
									rateEditConfig={rateEditConfig}
									setRateEditConfig={setRateEditConfig}
									columnsMaxWidth={columnsMaxWidth as number[]}
									columnsMinWidth={columnsMinWidth as number[]}
									handleOpenDepositModal={handleOpenDepositModal}
									handleResumeSwap={handleResumeSwap}
									handleStopSwap={handleStopSwap}
									handlePaymentSwap={handlePaymentSwap}
									setSwapModalConfig={setSwapModalConfig}
									setWithdrawalModalConfig={setWithdrawalModalConfig}
								/>
							</View>
						</View>

						<DataTable.Pagination
							page={filter.page - 1}
							numberOfPages={Math.ceil(
								SwapRequestsData?.data?.count! / filter.limit
							)}
							numberOfItemsPerPage={filter.limit}
							onPageChange={(page) =>
								setFilter((prev) => ({ ...prev, page: page + 1 }))
							}
							showFastPaginationControls={true}
							label={
								isFetchingSwapRequestsData
									? ``
									: `${(filter.page - 1) * filter.limit + 1}-${(SwapRequestsData?.data?.rows.length || 0) +
									(filter.page - 1) * filter.limit
									} ${t(`internalization.pagination-of`)} ${SwapRequestsData?.data?.count || 0
									}`
							}
						/>
					</View>
				</View>
			</DefaultLayout>

			<TooltipConfig />

			{swapModalConfig.visible && (
				<UserSwapModal
					request={swapModalConfig.request}
					isCancel={swapModalConfig.isCancel}
					onClose={closeSwapModal}
					action={swapModalConfig.action}
					userId={swapModalConfig.request?.swap.user.id}
					userData={swapModalConfig.request?.swap.user}
				/>
			)}

			{swapTreasuryModal.visible && (
				<CryptoHubTransferModal onClose={closeSwapTreasuryModal} />
			)}

			{!!withdrawalModalConfig?.request && (
				<DoneWithdrawalModal
					request={withdrawalModalConfig.request}
					onClose={() => setWithdrawalModalConfig(null)}
				/>
			)}

			{rateEditConfig?.confirm && (
				<RateEditConfirmModal
					request={rateEditConfig.request}
					newRateValue={rateEditConfig.newRateValue}
					onClose={() => setRateEditConfig(null)}
					onSubmit={() => {
						setRateEditConfig(null);
						refetchSwapRequestsData();
					}}
				/>
			)}

			{depositModalConfig && (
				<CryptoHubDepositModal
					data={depositModalConfig}
					onClose={() => setDepositModalConfig(null)}
				/>
			)}
		</>
	);
}
