import {StyleSheet, TextInput, View} from "react-native";

import React, {useEffect, useRef, useState} from "react";

import { useFormik} from "formik";

import "../../src/i18n";
import {useTranslation} from "react-i18next";

import * as Yup from "yup";

import {AuthTypeEnum} from "../enums/AuthType.enum";

import Colors from "../../src/global-constants/Colors";

import {Text, Modal, Alert} from "hubchain-storybook-design-pattern";
import {useAuth} from "../services/auth";
import Util from "../utils";

interface TwoFactorModalProps {
  visible: boolean,
  type: AuthTypeEnum,
  onRequestClose: any,
  onSubmit: any
}

interface CodeInputProps {
  refNumber: number,
  value: string,
  setValue: any
}

export default function TwoFactorModal(props: TwoFactorModalProps) {

  const {t} = useTranslation();
  const { user } = useAuth();

  const formRef = useRef<any>();

  const types = Object.entries(AuthTypeEnum).find(value => value[1] === props.type);
  const typeKey = types ? types[0] : "PIN";

  const FormSchema = Yup.object().shape({
    first: Yup.string().required(),
    second: Yup.string().required(),
    third: Yup.string().required(),
    fourth: Yup.string().required(),
    fifth: Yup.string().required(),
    sixth: Yup.string().required()
  });

  const tokenForm = useFormik({
    initialValues: {
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: ""
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => submitForm(values),
    validateOnMount: true
  });

  const inputsRefs: any = [
    useRef<TextInput>(),
    useRef<TextInput>(),
    useRef<TextInput>(),
    useRef<TextInput>(),
    useRef<TextInput>(),
    useRef<TextInput>()
  ];

  const [isLoading, setLoading] = useState(false);

  useEffect(() => setLoading(false), [props.visible]);

  useEffect(() => {
    const token = getToken();

    if (token.length === 6) {
      tokenForm.handleSubmit();
    }
  }, [tokenForm.values]);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Aviso");

  const showSnackBar = (message: string = "") => {
    setAlertMessage(message);
    setAlertVisible(true);
  };

  const CodeInput = ({refNumber, value, setValue}: CodeInputProps) => {
    return (
      <TextInput
        ref={inputsRefs[refNumber]}
        style={[styles.inputBox, styles.inputText]}

        editable={!isLoading}

        value={value}

        onChangeText={async value => {
          const text = onlyAlphanumeric(value.substring(0, 1));
          setValue(text);

          if (text.length >= 1) {
            inputsRefs[refNumber + 1]?.current.focus()
          }

          if (value.length > 1 && refNumber != 5) {
            formRef.current.setValues({
              first: onlyAlphanumeric(value[0]),
              second: onlyAlphanumeric(value[1]),
              third: onlyAlphanumeric(value[2]),
              fourth: onlyAlphanumeric(value[3]),
              fifth: onlyAlphanumeric(value[4]),
              sixth: onlyAlphanumeric(value[5])
            })
          }
        }}

        onKeyPress={e => {
          if (e.nativeEvent.key == "Backspace" && value.length == 0) {
            inputsRefs[refNumber - 1]?.current.focus()
          }
        }}

        maxLength={6}
      />
    );
  }

  const onlyAlphanumeric = (value: string) => {
    return value ? value.replace(/[\W_]+/g,"") : "";
  }

  const getToken = () => {
    const { first, second, third, fourth, fifth, sixth } = tokenForm.values;
    return first + second + third + fourth + fifth + sixth;
  }

  const submitForm = async (values: any) => {
    const token = getToken();

    setLoading(true);
    const error = await props.onSubmit(token);

    if (error) {
      showSnackBar(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      visible={props.visible}
      title={t(`security.two-factor-modal.title.${typeKey}`)}
      size={"small"}
      variant={"primary"}
      header={"band"}
      isLoading={isLoading}
      onClose={() => props.onRequestClose()}
      contentOverflowY={"auto"}
    >
      <View style={[styles.container]}>
        <View style={[styles.mainView, styles.mainViewShadow]}>
          <View style={{ paddingVertical: 16, width: "100%" }}>
            <View style={[styles.inputsView]}>
              {
                CodeInput({
                  value: tokenForm.values.sixth,
                  setValue: tokenForm.handleChange("sixth"),
                  refNumber: 5
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.fifth,
                  setValue: tokenForm.handleChange("fifth"),
                  refNumber: 4
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.fourth,
                  setValue: tokenForm.handleChange("fourth"),
                  refNumber: 3
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.third,
                  setValue: tokenForm.handleChange("third"),
                  refNumber: 2
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.second,
                  setValue: tokenForm.handleChange("second"),
                  refNumber: 1
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.first,
                  setValue: tokenForm.handleChange("first"),
                  refNumber: 0
                })
              }
            </View>

            <View style={[styles.descriptionView]}>
              <Text style={[styles.descriptionText]}>{
                t(`security.two-factor-modal.description.${typeKey}`, {
                  email: user?.email || t("security.two-factor-modal.no-email"),
                  phone: user ? Util.formatPhone(user.phone) : t("security.two-factor-modal.no-phone")
                })
              }</Text>
            </View>

            {/*<View style={[styles.footerView]}>
                  <TouchableOpacity
                    style={[
                      styles.inputButton,
                      (!isValid || isLoading) && styles.inputButtonDisabled
                    ]}
                    onPress={() => handleSubmit()}
                    disabled={!isValid || isLoading}
                  >
                    {isLoading && <ActivityIndicator size="small" color="#FFFFFF" style={{marginRight: 10}}/>}
                    <Text style={[
                      styles.inputButtonText,
                      (!isValid || isLoading) && styles.inputButtonTextDisabled
                    ]}>
                      {t("signIn.confirm")}
                    </Text>
                  </TouchableOpacity>
                </View>*/}
          </View>
        </View>
      </View>

      <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 6,
  },
  mainView: {
    borderRadius: 10,
    width: "100%",
    maxWidth: 348,
    height: "auto",
  },
  mainViewShadow: {
    elevation: 5,
  },

  descriptionView: {
    marginTop: 8,
    marginBottom: 10,
    width: "105%",
    marginLeft:-7,
    marginRight:-5
  },
  descriptionText: {
    color: Colors.light.text,
    fontSize: 12,
    textAlign: "center",
    marginLeft: -1
  },
  inputsView: {
    width: "100%",
    height: 53,
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    gap: 8
  },
  inputBox: {
    minWidth: 30,
    height: "100%",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.light.text,
    outlineColor: Colors.light.text
  },
  close: {
    position: "absolute",
    justifyContent: "center",
    right: 10,
    top: "50%",
    marginTop: -10
  },
  inputText: {
    color: Colors.light.text,
    fontSize: 20,
    textAlign: "center"
  },
  footerView: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  inputButton: {
    backgroundColor: Colors.dark.background,
    borderRadius: 5,
    height: 40,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  inputButtonText: {
    color: Colors.dark.text,
    fontSize: 16,
  },
  inputButtonDisabled: {
    backgroundColor: "#58585890",
  },
  inputButtonTextDisabled: {
    color: "#FFFFFF90",
  }
});
