import { Icon, Text, View } from "hubchain-storybook-design-pattern";
import { ActivityIndicator, StyleSheet, TouchableOpacity } from "react-native";
import Util from "../../utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { TCurrency, TCurrencySymbol } from "../../types/currency";
import CurrencyUtil from "../../utils/CurrencyUtil";
import RequestSwapService, {
	CryptoHubBalance,
} from "../../services/swap/swapService";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import CryptoHubDepositModal, {
	CryptoHubDepositModalConfig,
} from "./CryptoHubDepositModal";
import React from "react";
import { PusherService } from "../../services/pusher";
import { useMultiTenancy } from "../../services/multi-tenancy/multi-tenancy";

import { Flip, toast, ToastContainer } from "react-toastify";
import { ToastAlert, ToastAlertDefaults } from "../../../pages/swap/components/ToastAlert";
import { WebhookEventType } from "../../services/webhook/webhooksServices";
import { useDebouncedCallback } from "../../hooks/useDebounce";


const hiddenValue = (
	value: string,
	isShowing: boolean,
	currency: TCurrencySymbol = TCurrencySymbol.BRL
) => {
	let decimals = 2;

	if (currency !== TCurrencySymbol.BRL) {
		decimals = 8;
	}

	return isShowing ? value : "*,".padEnd(2 + decimals, "*");
};

export default function CryptoHub() {

	const { t } = useTranslation();

	const { tenant } = useMultiTenancy();

	const [balanceVisible, setBalanceVisible] = useState(false);

	const [depositModalConfig, setDepositModalConfig] = useState<CryptoHubDepositModalConfig | null>(null);

	const isFetchingCryptoHubInfoBalancesRef = useRef<boolean>(false);

	const {
		data: _cryptohubInfoBalancesData,
		refetch: refetchCryptoHubInfoBalances,
		isFetching: isFetchingCryptoHubInfoBalances,
		dataUpdatedAt,
	} = useQuery(["CryptoHubInfoBalances"], {
		queryFn: RequestSwapService.getCryptoHubInfoBalances,
		staleTime: 60 * 1000 * 5, // 5 minutes
		retryDelay: 60 * 1000 * 2,
		retry: 2,
	});

	// console.log("::_cryptohubInfoBalancesData::", _cryptohubInfoBalancesData);

	// console.log("::_cryptohubInfoBalancesData::allowedCurrencies::", _cryptohubInfoBalancesData?.data?.currencies);

	const balances = _cryptohubInfoBalancesData?.data?.rows || [
		{
			currency: TCurrencySymbol.BRL,
			exchange: 0,
			exchangeLimits: {
				SPOT: {
					currencies: {
						deposit: {
							total: 0,
							available: 0,
						},
						withdraw: {
							total: 0,
							available: 0,
						},
					},
				},
			},
			baas: 0,
			swaps: 0,
			diff: 0,
			userId: "",
		},
		{
			currency: TCurrencySymbol.BTC,
			exchange: 0,
			exchangeLimits: {
				SPOT: {
					currencies: {
						deposit: {
							total: 0,
							available: 0,
						},
						withdraw: {
							total: 0,
							available: 0,
						},
					},
				},
			},
			baas: 0,
			swaps: 0,
			diff: 0,
			userId: "",
		},
		{
			currency: TCurrencySymbol.USDT,
			exchange: 0,
			exchangeLimits: {
				SPOT: {
					currencies: {
						deposit: {
							total: 0,
							available: 0,
						},
						withdraw: {
							total: 0,
							available: 0,
						},
					},
				},
			},
			baas: 0,
			swaps: 0,
			diff: 0,
			userId: "",
		},
	];

	const handleOpenFiatDepositModal = (balance: CryptoHubBalance) => {
		const pixInfo = _cryptohubInfoBalancesData?.data?.cashIn?.pix?.BRL![0]!;

		console.log(`::pixInfo::`, pixInfo);

		if (pixInfo) {
			setDepositModalConfig({
				amount: balance.diff,
				currency: balance.currency,
				pix: {
					info: pixInfo,
				},
			} as CryptoHubDepositModalConfig);
		}
	};


	const _pusherMessages = {};
	
	useEffect(() => {
		isFetchingCryptoHubInfoBalancesRef.current = isFetchingCryptoHubInfoBalances;
	}, [isFetchingCryptoHubInfoBalances]);	

	const refetchCryptoHubInfoBalancesDebounced = useDebouncedCallback(async () => {
		if (isFetchingCryptoHubInfoBalancesRef.current === true) {
			console.log(`refetchCryptoHubInfoBalances`, "waiting...");
			refetchCryptoHubInfoBalancesDebounced();
		} else {
			console.log(`refetchCryptoHubInfoBalances`, "done!");
			refetchCryptoHubInfoBalances();
		}
	}, 1000);

	useEffect(() => {

		// ex.: channel_name = ezzepay-backoffice-development
		const _channel = PusherService.subscribe(PusherService.getChannelNameByTenant("backoffice", tenant));

		// *** To EmultateEvent: https://dashboard.pusher.com/apps/706073/console
		// * Channel: ezzepay-backoffice-development
		// * Event  WebhookEventType.CRYPTOHUB_BALANCES_UPDATED
		// const _data =
		/*
		{
			"data": {
				"request": {
					"id": "1b49a15a-f077-4cc1-8c35-e8770dea0751",
						"swap": {
						"status": { "id": 2, "key": "COMPLETED", "namePt": "Concluída" }

					}
				}
			}
		}
		*/

		_channel.bind(WebhookEventType.CRYPTOHUB_BALANCES_UPDATED, async (eventData: any) => {

			console.log(`::${WebhookEventType.CRYPTOHUB_BALANCES_UPDATED}::`, eventData);

			if (eventData?.message?.content) {

				const _eventId = eventData?.eventId ?? null;
				if (_eventId !== null && _pusherMessages[_eventId]) return;
				_pusherMessages[_eventId] = eventData;

				toast(
					<ToastAlert
						title={eventData?.message?.title ?? null}
						message={`${eventData?.message?.content ?? ""}`}
					/>,
					{ style: ToastAlertDefaults.style }
				);
			}

			refetchCryptoHubInfoBalancesDebounced();

		});

		return () => {
			_channel.unbind_all();
			_channel.unsubscribe();
		};

	}, []);

	return (
		<>
			<View style={{ maxWidth: 1220 }}>
				<View style={[styles.cardContainer]}>
					<View style={[styles.card]}>
						<View style={[styles.table]}>
							<View style={[styles.tableColumn]}>
								<Text
									fontStyle={"bold"}
									style={[
										styles.tableText,
										{
											/* visibility: "hidden" */
											minHeight: 28,
										},
									]}
								>
									<View style={[styles.infoTitle]}>
										<Text fontStyle={"bold"}>{t("cryptohub-card.title")}</Text>

										<TouchableOpacity
											style={[styles.hideShowButton]}
											onPress={() => setBalanceVisible((state) => !state)}
										>
											<Icon
												name={balanceVisible ? "EyeFill" : "EyeSlashFill"}
												fontSize={"16px"}
												variant={"dark"}
											/>
										</TouchableOpacity>
									</View>

									<View
										style={{
											paddingTop: "2px",
											paddingLeft: "16px",
											paddingBottom: "10px",
										}}
									>
										<Text variant={"gray"} size={"9px"}>
											{Util.formatDate(dataUpdatedAt || new Date())}
										</Text>
									</View>
								</Text>

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t("cryptohub-card.baas-balance-available")}
								</Text>

								<View style={styles.divider} />

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t("cryptohub-card.exchange-balance-available")}
								</Text>

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t("cryptohub-card.exchange-balance-onhold")}
								</Text>

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t("cryptohub-card.exchange-balance-total")}
								</Text>

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t("cryptohub-card.exchange-balance-pending-withdrawals")}
								</Text>

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t("cryptohub-card.exchange-balance-pending-deposits")}
								</Text>

								<View style={styles.divider} />

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t("cryptohub-card.requested")}
								</Text>

								<View style={styles.divider} />

								<Text style={[styles.tableText, { paddingLeft: 0 }]}>
									{t("cryptohub-card.difference")}
								</Text>

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t(
										"cryptohub-card.exchange-currency-limits-deposit-available"
									)}
								</Text>

								<Text
									variant={"gray"}
									style={[styles.tableText, { paddingLeft: 0 }]}
								>
									{t(
										"cryptohub-card.exchange-currency-limits-withdraw-available"
									)}
								</Text>
							</View>

							{balances
								.filter(
									(x: any) =>
										!_cryptohubInfoBalancesData?.data?.currencies ||
										_cryptohubInfoBalancesData?.data?.currencies.includes(
											x.currency
										)
								)
								.map((balance: CryptoHubBalance, index, array) => {
									const isFirst = index === 0;
									const isLast = index === array.length - 1;

									return (
										<View key={balance.currency} style={[styles.tableColumn]}>
											<Text
												fontStyle={"bold"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
														minHeight: 28,
													},
												]}
											>
												{balance.currency === TCurrencySymbol.BRL
													? "R$"
													: balance.currency}
											</Text>

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{
													hiddenValue(
														CurrencyUtil.formatCurrency(
															balance.baas.available,
															balance.currency,
															false,
															balance.currency === TCurrencySymbol.BRL
																? 2
																: 8 /* TCurrency[balance.currency]?.decimals */,
															balance.currency === TCurrencySymbol.BRL
																? 2
																: 8 /* TCurrency[balance.currency]?.decimals */
														),
														balanceVisible,
														balance.currency
													)
												}
											</Text>

											<View style={styles.divider} />

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{hiddenValue(
													CurrencyUtil.formatCurrency(
														balance.exchange.available,
														balance.currency,
														false,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */
													),
													balanceVisible,
													balance.currency
												)}
											</Text>

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{hiddenValue(
													CurrencyUtil.formatCurrency(
														balance.exchange.onHold,
														balance.currency,
														false,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */
													),
													balanceVisible,
													balance.currency
												)}
											</Text>

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{hiddenValue(
													CurrencyUtil.formatCurrency(
														balance.exchange.total,
														balance.currency,
														false,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */
													),
													balanceVisible,
													balance.currency
												)}
											</Text>

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{hiddenValue(
													CurrencyUtil.formatCurrency(
														balance.exchange.pending?.withdraw?.total,
														balance.currency,
														false,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */
													),
													balanceVisible,
													balance.currency
												)}
											</Text>

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{hiddenValue(
													CurrencyUtil.formatCurrency(
														balance.exchange.pending?.deposit?.total,
														balance.currency,
														false,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */
													),
													balanceVisible,
													balance.currency
												)}
											</Text>

											<View style={styles.divider} />

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{hiddenValue(
													CurrencyUtil.formatCurrency(
														balance.swaps,
														balance.currency,
														false,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 8 /* TCurrency[balance.currency]?.decimals */,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 6 /* TCurrency[balance.currency]?.decimals */
													),
													balanceVisible,
													balance.currency
												)}
											</Text>

											<View style={styles.divider} />

											<View
												style={[
													styles.tableDiffView,
													isLast ? { paddingRight: 0 } : {},
												]}
											>
												<TouchableOpacity
													disabled={
														balance.currency !== TCurrencySymbol.BRL ||
														balance.diff === 0
													}
													onPress={() => {
														if (balance.currency === TCurrencySymbol.BRL) {
															handleOpenFiatDepositModal(balance);
														}
													}}
												>
													<Text
														variant={
															balance.baas.available >
																balance.exchange.available
																? "danger"
																: "dark"
														}
														style={[
															styles.tableText,
															{
																textAlign: "right",
																paddingRight: 0,
															},
														]}
													>
														{hiddenValue(
															CurrencyUtil.formatCurrency(
																balance.diff,
																balance.currency,
																false,
																balance.currency === TCurrencySymbol.BRL
																	? 2
																	: 8 /* TCurrency[balance.currency]?.decimals */,
																balance.currency === TCurrencySymbol.BRL
																	? 2
																	: 8 /* TCurrency[balance.currency]?.decimals */
															),
															balanceVisible,
															balance.currency
														)}
													</Text>
												</TouchableOpacity>
												<TouchableOpacity
													disabled={
														balance.currency !== TCurrencySymbol.BRL ||
														balance.diff === 0
													}
													onPress={() => {
														if (balance.currency === TCurrencySymbol.BRL) {
															handleOpenFiatDepositModal(balance);
														}
													}}
												>
													{balance.currency === TCurrencySymbol.BRL ? (
														<Icon
															name={"InfoFill"}
															fontSize={"14px"}
															variant={"gray"}
														/>
													) : (
														<View style={{ width: 14, height: 14 }} />
													)}
												</TouchableOpacity>
											</View>

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{hiddenValue(
													CurrencyUtil.formatCurrency(
														balance.exchangeLimits.SPOT.currencies.deposit
															.available,
														balance.currency === TCurrencySymbol.BRL
															? balance.currency
															: TCurrencySymbol.USDT,
														false,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 2 /* TCurrency[balance.currency]?.decimals */,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 2 /* TCurrency[balance.currency]?.decimals */
													),
													balanceVisible,
													balance.currency === TCurrencySymbol.BRL
														? balance.currency
														: TCurrencySymbol.USDT
												)}
											</Text>

											<Text
												variant={"gray"}
												style={[
													styles.tableText,
													{
														textAlign: "right",
														paddingRight: isLast ? 18 : 26,
													},
												]}
											>
												{hiddenValue(
													CurrencyUtil.formatCurrency(
														balance.exchangeLimits.SPOT.currencies.withdraw
															.available,
														balance.currency === TCurrencySymbol.BRL
															? balance.currency
															: TCurrencySymbol.USDT,
														false,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 2 /* TCurrency[balance.currency]?.decimals */,
														balance.currency === TCurrencySymbol.BRL
															? 2
															: 2 /* TCurrency[balance.currency]?.decimals */
													),
													balanceVisible,
													balance.currency === TCurrencySymbol.BRL
														? balance.currency
														: TCurrencySymbol.USDT
												)}
											</Text>
										</View>
									);
								})}
						</View>
					</View>
				</View>

				<TouchableOpacity
					style={[styles.refreshButton]}
					disabled={isFetchingCryptoHubInfoBalances}
					onPress={() => {
						if (!isFetchingCryptoHubInfoBalances) {
							refetchCryptoHubInfoBalances();
						}
					}}
				>
					{isFetchingCryptoHubInfoBalances ? (
						<ActivityIndicator size={16} color={"#000"} />
					) : (
						<Icon name={"Redo"} fontSize={"16px"} variant={"dark"} />
					)}
				</TouchableOpacity>
			</View>

			{depositModalConfig && (
				<CryptoHubDepositModal
					data={depositModalConfig}
					onClose={() => setDepositModalConfig(null)}
				/>
			)}
		</>
	);
}

const styles = StyleSheet.create({
	cardContainer: {
		backgroundColor: "#FFF",
		padding: 16,
		borderRadius: 10,
		width: "100%",
	} as any,
	card: {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "#FFF",
		padding: 8,
		borderRadius: 10,
		width: "100%",
		gap: 16,
		overflow: "auto",
	} as any,
	info: {
		display: "flex",
		flexDirection: "column",
		gap: 4,
	} as any,
	infoTitle: {
		display: "flex",
		flexDirection: "row",
		height: 14,
		alignItems: "center",
		gap: 4,
	} as any,
	hideShowButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 32,
		height: 32,
	},
	table: {
		display: "flex",
		flexDirection: "row",
		gap: 2,
		overFlow: "auto",
	} as any,
	tableColumn: {
		display: "flex",
		flexDirection: "column",
		gap: 4,
	} as any,
	tableText: {
		paddingLeft: 8,
		paddingRight: 26,
		flex: 1,
	},
	tableDiffView: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		gap: 4,
		paddingRight: 8,
	} as any,
	divider: {
		borderBottomWidth: 1,
		borderStyle: "dashed",
		borderColor: "#848484",
	},
	refreshButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 32,
		height: 32,
		position: "absolute",
		right: 0,
		top: 0,
	},
});
