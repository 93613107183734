import { View } from "react-native";

import React, { ReactElement } from "react";
import GeneralCSS from "../../global-constants/Styles";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import {
	Text,
	HeaderContent,
	Tooltip,
} from "hubchain-storybook-design-pattern";

interface LayoutData {
	children: ReactElement;
	title?: string;
	titlePath?: string[];
	[key: string]: any;

	handleBack: () => void;
	handleRefresh: () => void;
}

export default function DefaultLayout(props: LayoutData) {
	const { t } = useTranslation();
	let subtitle = "";
	if (props.title) {
		subtitle = props.title.includes("title")
			? t(props.title.replace("title", "sub-title"))
			: "";
	}

	const attrSubtitle = {
		subtitle: subtitle,
	};

	const Title = () => {
		if (props.title) {
			const subtitle = props.title.includes("title")
				? t(props.title.replace("title", "sub-title"))
				: "";

			return (
				<View style={[GeneralCSS.title]}>
					<Text size={"18px"} fontStyle={"bold"}>
						{t(props.title)}
					</Text>
					{subtitle !== "" && !subtitle.includes("sub-title") && (
						<>
							<Text
								size={"12px"}
								fontStyle={"bold"}
								style={[GeneralCSS.subtitleText]}
							>
								{subtitle}
							</Text>
						</>
					)}
				</View>
			);
		}
		return null;
	};
	return (
		<>
			<HeaderContent
				title={props.title ? t(props.title) : ""}
				{...(subtitle != "" && { ...attrSubtitle })}
				titlePath={props.titlePath}
				isMobile={isMobile}
				filters={props.filters}
				onClick={props.onClick}
				onChange={props.onChange}
				onChangeText={props.onChangeText}
			/>
			<View style={[GeneralCSS.container]}>{props.children}</View>
		</>
	);
}
