import { AxiosResponse } from "axios";
import API, { corePrefix } from "../api";

export enum WebhookEventType {
    BLOCKCHAIN_BLOCK_NOTIFY = "BLOCKCHAIN_BLOCK_NOTIFY",
    BLOCKCHAIN_TX_NOTIFY = "BLOCKCHAIN_TX_NOTIFY",
    BLOCKCHAIN_ADDRESS_NOTIFY = "BLOCKCHAIN_ADDRESS_NOTIFY",

    CRYPTOHUB_TRANSFER = "CRYPTOHUB_TRANSFER",

    CRYPTOHUB_BAAS_CASH_IN = "CRYPTOHUB_BAAS_CASH_IN",
    CRYPTOHUB_BAAS_CASH_OUT = "CRYPTOHUB_BAAS_CASH_OUT",

    CRYPTOHUB_EXCHANGE_CASH_IN = "CRYPTOHUB_EXCHANGE_CASH_IN",
    CRYPTOHUB_EXCHANGE_CASH_OUT = "CRYPTOHUB_EXCHANGE_CASH_OUT",

    CRYPTOHUB_BALANCES_UPDATED = "CRYPTOHUB_BALANCES_UPDATED",

    REQUEST_SWAP_STATUS_CHANGED = "REQUEST_SWAP_STATUS_CHANGED",
    REQUEST_SWAP_STATUS_DONE = "REQUEST_SWAP_STATUS_DONE",
}

interface IWebhook {
	url: string;
	ipWhitelist: string[];
	createdAt: Date;
	eventTypes: string[];
	deletedAt?: Date;
	id: string;
	updatedAt: Date;
}

interface WebhookListResponse {
	data: IWebhook[];
	status: string;
}

interface WebhookCreateRequest { // Ok
	url: string;
	ip_whitelist: string[];
	event_type: string[];
}

interface WebhookUpdateRequest extends WebhookCreateRequest {
	id: string;
}

interface WebhookDeleteRequest {
	id: string;
}

interface WebhookEventListResponse {
	data: string[];
	status: string;
}

export default class WebhookService {
	static async list(): Promise<AxiosResponse<WebhookListResponse>> {
		return API.get(`${corePrefix}/admin/webhook/`);
	}

	static async listEvents(): Promise<AxiosResponse<WebhookEventListResponse>> {
		return API.get(`${corePrefix}/admin/webhook/event_type`);
	}

	static async create(data: WebhookCreateRequest) {
		return API.post(`${corePrefix}/admin/webhook/`, data);
	}

	static async delete({ id }: WebhookDeleteRequest) {
		return API.delete(`${corePrefix}/admin/webhook/${id}`);
	}

	static async update(webhook: WebhookUpdateRequest) {
		return API.put(`${corePrefix}/admin/webhook/${webhook.id}`, webhook);
	}
}
