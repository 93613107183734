import {ActivityIndicator, View} from "react-native";
import Colors from "../global-constants/Colors";
import * as React from "react";
import {LayoutConfig} from "../global-constants/Layout";
import { isMobile } from "react-device-detect";

export default function LoadingScreen({ useHeaderOffset = false }: { useHeaderOffset?: boolean }) {
    return (
        <View style={{height: useHeaderOffset ? `calc(100vh - ${isMobile ? LayoutConfig.header.size.mobile : LayoutConfig.header.size.desktop})` : "100%", justifyContent: "center", alignItems: "center"}}>
            <ActivityIndicator size="large" color={Colors.light.primary}/>
        </View>
    )
}
