import React, { Suspense } from "react";

import { useAuth } from "../services/auth";

import { APP_PERMISSIONS } from "../permissions/permissions";

const AdministratorsScreen = React.lazy(() => import("../../pages/administrator"));
const Users = React.lazy(() => import("../../pages/users"));
const ApprovedDeposits = React.lazy(() => import("../../pages/approved-deposit"));
const ApprovedWithdrawal = React.lazy(() => import("../../pages/approved-withdrawal"));
const ApprovedUsers = React.lazy(() => import("../../pages/approved-users"));
const DashboardScreen = React.lazy(() => import("../../pages/dashboard"));
const SacScreen = React.lazy(() => import("../../pages/sac"));
const TradeScreen = React.lazy(() => import("../../pages/trade"));
const ApprovedOrderBuy = React.lazy(() => import("../../pages/approved-order"));
const ApprovedOrderSell = React.lazy(() => import("../../pages/approved-order"));
const AccountBanks = React.lazy(() => import("../../pages/reports/account-banks"));
const InputOutputOfValues = React.lazy(() => import("../../pages/reports/input-output-of-values"));
const PermissionManagementScreen = React.lazy(() => import("../../pages/permissionManagement"));
const FinancialTransactions = React.lazy(() => import("../../pages/reports/financial-transactions"));
const UserRegistrationScreen = React.lazy(() => import("../../pages/reports/user-registration"));
const SettingsScreen = React.lazy(() => import("../../pages/settings"));
const CommissionsScreen = React.lazy(() => import("../../pages/reports/commissions"));
const ApprovedDepositCrypto = React.lazy(() => import("../../pages/reports/deposit-crypto"));
const ApprovedWithdrawalsCrypto = React.lazy(() => import("../../pages/approved-withdrawal-crypto"));
const UserAccountScreen = React.lazy(() => import("../../pages/userAccount"));

import { useMultiTenancy } from "../services/multi-tenancy/multi-tenancy";

import { createCustomNavigator } from "./CustomNavigator";
import SwapScreen from "../../pages/swap";
import PaymentGatewayCryptoScreen from "../../pages/payment-gateway-crypto";
import WebhooksScreen from "../../pages/webhooks";
import CurrenciesScreen from "../../pages/currencies";
import MarketsScreen from "../../pages/markets";
import TickerScreen from "../../pages/ticker";
import IconsScreen from "../../pages/icons";
import LogOutScreen from "../../pages/log-out";
import CalcScreen from "../../pages/calc";

const LoggedStack = createCustomNavigator();

export interface LoggedParamList {
    administrators: {},
    blockedUsers: {},
    blockedAdmins: {},
    usersApproval: {},
    usersAccounts: {},

    approvedDeposits: {},
    approvedWithdrawals: {},
    approvedWithdrawalsCrypto: {},
    approvedOrderBuy: {},
    approvedOrderSell: {},
    approvedUsers: {},

    bankAccountReport: {},
    inputOutputOfValues: {},
    financialTransactions: {},
    inputOfValues: {},
    outputOfValues: {},
    userRegistrationReport: {},
    depositsCrypto: {},
    withdrawalCommissions: {},
    depositCommissions: {},
    permissions: {},
    dashboard: {},
    sac: {},
    "trade-buy": {},
    "trade-sell": {},
    swap: {},
    settings: {},
}

const LoggedRoutes: React.FC = () => {
    const { user: authUser } = useAuth();
    const { tenant } = useMultiTenancy();

    return (
        <LoggedStack.Navigator
            screenOptions={({ navigation }) => (
                {
                    headerShown: false
                }
            )}
            initialRouteName={"dashboard"}
        >
            {
                tenant.routes.dashboard &&
                <LoggedStack.Screen name="dashboard" component={DashboardScreen} />
            }

            {
                tenant.routes["sac"] &&
                <LoggedStack.Screen name="sac" component={SacScreen} />
            }

            {!!authUser?.isActionAllowed([APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_USER_DOC]) && (
                <>
                    {
                        tenant.routes["users-approval"] &&
                        <LoggedStack.Screen name="usersApproval" component={ApprovedUsers} />
                    }
                </>
            )}

            {!!authUser?.isActionAllowed([APP_PERMISSIONS.user.USER_BLOCK_UNLOCK]) && (
                <>

                    {
                        tenant.routes["users-accounts"] &&
                        <LoggedStack.Screen name="usersAccounts" component={Users} />
                    }

                    {
                        tenant.routes["users-accounts"] &&
                        <LoggedStack.Screen name="userAccount" component={UserAccountScreen} />
                    }

                </>
            )}


            {!!authUser?.isActionAllowed(
                APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT
            ) && (
                    <>
                        {
                            tenant.routes["approved-deposits"] &&
                            <LoggedStack.Screen
                                name="approvedDeposits"
                                component={ApprovedDeposits}
                            />
                        }

                        {
                            tenant.routes["reports-deposits-crypto"] &&
                            <LoggedStack.Screen
                                name="depositsCrypto"
                                component={ApprovedDepositCrypto}
                            />
                        }

                        {
                            tenant.routes["approved-withdrawals"] &&
                            <LoggedStack.Screen
                                name="approvedWithdrawals"
                                component={ApprovedWithdrawal}
                            />
                        }

                        {
                            tenant.routes["approved-withdrawals-crypto"] &&
                            <LoggedStack.Screen
                                name="approvedWithdrawalsCrypto"
                                component={ApprovedWithdrawalsCrypto}
                            />
                        }
                    </>
                )}

            {
                tenant.routes["reports"] &&
                <>
                    {
                        (tenant.routes["reports-account-banks"] && !!authUser?.isActionAllowed(APP_PERMISSIONS.reports.BANK_ACCOUNTS)) && (
                            <LoggedStack.Screen
                                name="bankAccountReport"
                                component={AccountBanks}
                            />
                        )
                    }

                    {
                        !!authUser?.isActionAllowed(
                            APP_PERMISSIONS.reports.FINANCIAL_INPUT_OUTPUT_VALUES
                        ) && (
                            <>
                                {
                                    tenant.routes["reports-input-of-values"] &&
                                    <LoggedStack.Screen
                                        name="inputOfValues"
                                        component={InputOutputOfValues}
                                    />
                                }

                                {
                                    tenant.routes["reports-output-of-values"] &&
                                    <LoggedStack.Screen
                                        name="outputOfValues"
                                        component={InputOutputOfValues}
                                    />
                                }
                            </>
                        )
                    }

                    {
                        (tenant.routes["reports-financial-transactions"] && !!authUser?.isActionAllowed(APP_PERMISSIONS.reports.FINANCIAL_TRANSACTIONS)) && (
                            <LoggedStack.Screen
                                name="financialTransactions"
                                component={FinancialTransactions}
                            />
                        )
                    }

                    {
                        (tenant.routes["reports-user-registration"] && !!authUser?.isActionAllowed(APP_PERMISSIONS.reports.USER_REGISTRATION)) && (
                            <LoggedStack.Screen
                                name="userRegistrationReport"
                                component={UserRegistrationScreen}
                            />
                        )
                    }

                    {
                        (!!authUser?.isActionAllowed(APP_PERMISSIONS.reports.FINANCIAL_COMMISSION)) && (
                            <>
                                {
                                    tenant.routes["reports-withdrawal-commissions"] &&
                                    <LoggedStack.Screen
                                        name="withdrawalCommissions"
                                        component={CommissionsScreen}
                                    />
                                }

                                {
                                    tenant.routes["reports-deposit-commissions"] &&
                                    <LoggedStack.Screen
                                        name="depositCommissions"
                                        component={CommissionsScreen}
                                    />
                                }
                            </>
                        )
                    }
                </>
            }

            {(tenant.routes["administrators"] && !!authUser?.isActionAllowed(APP_PERMISSIONS.admin.ADMIN_LIST)) && (
                <LoggedStack.Screen
                    name="administrators"
                    component={AdministratorsScreen}
                />
            )}

            {(tenant.routes["trade"] && !!authUser?.isActionAllowed(APP_PERMISSIONS.trade.TRADE_MAKE)) && (
                <>
                    {
                        tenant.routes["trade-buy"] &&
                        <LoggedStack.Screen
                            name="approvedOrderBuy"
                            component={ApprovedOrderBuy}
                        />
                    }

                    {
                        tenant.routes["trade-sell"] &&
                        <LoggedStack.Screen
                            name="approvedOrderSell"
                            component={ApprovedOrderSell}
                        />
                    }

                    {
                        tenant.routes["trade-buy"] &&
                        <LoggedStack.Screen name="trade-buy" component={TradeScreen} />
                    }

                    {
                        tenant.routes["trade-sell"] &&
                        <LoggedStack.Screen name="trade-sell" component={TradeScreen} />
                    }
                </>
            )}

            <LoggedStack.Screen name="trade-swap" component={SwapScreen} />

            <LoggedStack.Screen name="payment-gateway-crypto" component={PaymentGatewayCryptoScreen} />

            <LoggedStack.Screen name="calc" component={CalcScreen} options={{ headerShown: false }} />

            {(tenant.routes["permission-management"] && !!authUser?.isActionAllowed(APP_PERMISSIONS.role.ROLE_LIST)) && (
                <LoggedStack.Screen
                    name="permissions"
                    component={PermissionManagementScreen}
                />
            )}

            {/* {(tenant.routes["approved-users"] && !!user?.isActionAllowed([APP_PERMISSIONS.user.USER_APPROVE_REPPROVE_USER_DOC])) && (
                <LoggedStack.Screen name="approvedUsers" component={ApprovedUsers} />
            )} */}

            {
                (tenant.routes["settings"] && !!authUser?.isHubchainUser) &&
                <LoggedStack.Screen name="settings" component={SettingsScreen} />
            }

            {
                (tenant.routes["settings-icons"] && !!authUser?.isHubchainUser) &&
                <LoggedStack.Screen name="settingsIcons" component={IconsScreen} />
            }

            {
                (tenant.routes["settings-currencies"] && !!authUser?.isHubchainUser) &&
                <LoggedStack.Screen name="settingsCurrencies" component={CurrenciesScreen} />
            }

            {
                (tenant.routes["settings-markets"] && !!authUser?.isHubchainUser) &&
                <LoggedStack.Screen name="settingsMarkets" component={MarketsScreen} />
            }

            {
                (tenant.routes["settings-ticker"] && !!authUser?.isHubchainUser) &&
                <LoggedStack.Screen name="settingsTicker" component={TickerScreen} />
            }

            {
                (tenant.routes["settings-webhooks"] && !!authUser?.isHubchainUser) &&
                <LoggedStack.Screen name="settingsWebhooks" component={WebhooksScreen} />
            }
            
            {
                <LoggedStack.Screen name="logOut" component={LogOutScreen} />
            }
        </LoggedStack.Navigator>
    );
};

export default LoggedRoutes;
