import {AvailableIconsType} from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import React, {ReactNode} from "react";
import {Icon, Text, View} from "hubchain-storybook-design-pattern";
import styles from "../styles";

const SwapModalTitle = ({
                 title,
                 icon,
                 rightAddon,
                 badgeText,
                 showBadge = true
               }: {
  title: string;
  icon?: AvailableIconsType;
  rightAddon?: ReactNode;
  badgeText?: string;
  showBadge?: boolean;
}) => {
  return (
		<View style={styles.titleView}>
			<View style={styles.title}>
				{icon && <Icon name={icon} fontSize={"16px"} variant={"primary"} />}
				<Text variant={"primary"} size={"16px"} fontStyle={"bold"}>
					{title}
				</Text>
				{badgeText && (
					<View
						style={{
							height: 18,
							backgroundColor: "#0052cc",
							padding: 2,
							visibility: showBadge ? "visible" : "hidden",
						}}
					>
						<Text size={"12px"} fontStyle={"bold"} variant={"white"}>
							{badgeText}
						</Text>
					</View>
				)}
			</View>
			{rightAddon}
		</View>
	);
};

export default SwapModalTitle;
