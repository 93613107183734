enum RolePermissions {
    ROLE_LIST = "role-list",
    ROLE_CREATE = "role-create",
    ROLE_EDIT = "role-edit",
    ROLE_DELETE = "role-delete",
    ROLE_VIEW_DETAILS = "role-list-with-users-detail",
    ROLE_ASSIGN_USER = "role-assign-user",
    ROLE_UPDATE_PERMISSIONS = "update-role-permissions"
}

enum UserPermissions {
    USER_BLOCK_UNLOCK = "block-unlock-user",
    USER_APPROVE_REPPROVE_USER_DOC = "approved-reproved-document-user",
    USER_APPROVE_REPPROVE_WITHDRAWAL_DEPOSIT = "approved-reproved-withdrawal-deposit-requests"
}

enum AdminPermissions {
    ADMIN_LIST = "list-users-admin",
    ADMIN_CREATE_EDIT = "create-update-users-admin"
}

enum TradePermissions {
    TRADE_MAKE = "admin-trade"
}

enum ReportsPermissions {
    BANK_ACCOUNTS = "report-accounts-bank-users",
    FINANCIAL_INPUT_OUTPUT_VALUES = "report-financial-input-output-values",
    FINANCIAL_COMMISSION = "report-financial-commission",
    FINANCIAL_TRANSACTIONS = "report-financial-transactions",
    USER_REGISTRATION = "report-user-registration",
    USER_HIGHEST_BALANCE = "report-users-highest-balance"
}

enum SettingsPermission {
    DEPOSIT_BANK_ACCOUNT_AND_PIX = "configuration-request-method-management"
}

export type PermissionType =
    RolePermissions
    | UserPermissions
    | AdminPermissions
    | TradePermissions
    | ReportsPermissions
    | SettingsPermission;

export const APP_PERMISSIONS = {
    role: RolePermissions,
    user: UserPermissions,
    admin: AdminPermissions,
    trade: TradePermissions,
    reports: ReportsPermissions,
    settings: SettingsPermission
};

export const APP_PERMISSIONS_ARRAY: PermissionType[] = [
    ...Object.values(RolePermissions),
    ...Object.values(UserPermissions),
    ...Object.values(AdminPermissions),
    ...Object.values(TradePermissions),
    ...Object.values(ReportsPermissions),
    ...Object.values(SettingsPermission)
];


