const tintColorLight = "002435";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#002435",
    tableValue: "#A1A5B7",
    background: "#FFF",
    backgroundSecondary: "#F6F6F6",
    backgroundHover:"#2970b9",
    buttonDashed:"#3699FF00",
    buttonDashedHover:"#3699FF20",
    backgroundTertiary:"#575756",
    backgroundTertiaryHover:"#878784",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    primary: "#002435",
    secondary: "#575756",
    secondary20: "#57575620",
    secondary50: "#57575650",
    secondary90: "#57575690",
    success: "#aeedab90",
    successHover: "#5adf5490",
    warning: "#f8d89e90",
    danger: "#fcadb190",
    dangerHover: "#f6646d",
  },
  dark: {
    text: "#fff",
    background: "#002435",
    backgroundSecondary: "#F6F6F6",
    backgroundHover:"#2970b9",
    backgroundTertiary:"#575756",
    backgroundTertiaryHover:"#878784",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    primary: "#002435",
    secondary: "#575756",
    secondaryLight: "#EDEDED",
    success: "#3AAA35",
    successHover: "#4ecd48",
    warning: "#F9B233",
    danger: "#E30613",
    dangerHover: "#f6646d",
    title: "#f2f2f7",
  },
  footer: {
    text: "#fff",
    background: "#282828",

  },
  backgroundGray:"#f2f2f2",
  approvedManagement:{
    text:"#181C32",
    value:"#A1A5B7",
    success: "#3AAA35",
    warning: "#F9B233",
    danger: "#E30613",
  }
};
