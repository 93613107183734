import translation_en from "../locales/en/translation.json";
import translation_pt from "../locales/pt/translation.json";
// import homePt from '../../components/Home/locales/default.json';
// import homeEn from '../../components/Home/locales/translate.json';
// pt['pt-BR'].home = homePt['pt-BR'];
// en['en'].home = homeEn['en'];

const pt = { translation: translation_pt };
const en = { translation: translation_en };

export { pt, en };
