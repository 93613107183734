import React, { ReactElement } from "react";
import { TableRow } from "../../../src/components/table/CustomTable";
import { Icon, MenuBox, Text, View } from "hubchain-storybook-design-pattern";
import GeneralCSS from "../../../src/global-constants/Styles";
import CurrencyUtil from "../../../src/utils/CurrencyUtil";
import { TCurrency, TCurrencySymbol } from "../../../src/types/currency";
import CurrencyIcon from "../../../src/components/CurrencyIcon";
import Util from "../../../src/utils";
import { TouchableOpacity } from "react-native";
import { setStringAsync } from "expo-clipboard";
import { CustomHoverableText } from "../../../src/components/LinkTouchableOpacity";
import { RequestInvoiceModel } from "../../../src/services/invoice/invoiceService";
import { RequestStatusId } from "../../../src/enums/RequestStatusId.enum";
import { DataTable } from "react-native-paper";
import { useTranslation } from "react-i18next";
import PaymentGatewayCryptoAllowedMethods from "./PaymentGatewayCryptoAllowedMethods";
import { AvailableIconsType, IconVariants } from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import { useAuth } from "../../../src/services/auth";
import { InvoiceModalConfig } from "../index";

interface PaymentGatewayCryptoTableBodyProps {
  requests: RequestInvoiceModel[],
  columnsMinWidth: (number | string)[],
  columnsMaxWidth: (number | string)[],
  isLoading: boolean,
  isFetching: boolean,
  setInvoiceModalConfig: (config: InvoiceModalConfig) => void,
}

const iconByRequestStatus: { [key: string]: AvailableIconsType } = {
  [RequestStatusId.COMPLETED]: "CheckmarkIcon",
  [RequestStatusId.PENDING]: "Exclamation",
  [RequestStatusId.CANCELLED]: "Slash",
  [RequestStatusId.EXPIRED]: "ClockHistory",
};

const iconVariantByRequestStatus: { [key: string]: IconVariants | string } = {
  [RequestStatusId.COMPLETED]: "success",
  [RequestStatusId.PENDING]: "warning",
  [RequestStatusId.CANCELLED]: "danger",
  [RequestStatusId.EXPIRED]: "danger",
};

const PaymentGatewayCryptoTableBody = ({ columnsMinWidth, columnsMaxWidth, isFetching, isLoading, setInvoiceModalConfig, ...props }: PaymentGatewayCryptoTableBodyProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const rows: ReactElement[] = [];

  props.requests.map((request, index) => {
    const swap = request.swap;
    const invoice = request.invoice;

    rows.push(
      <TableRow key={request.id} index={index} zIndex={1} height={64} gap={6}>
        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[0],
              maxWidth: columnsMaxWidth[0],
              justifyContent: "center",
              gap: 2,
            },
          ]}
        >
          <Text size={"0.75rem"}>{index + 1}</Text>
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[1],
              maxWidth: columnsMaxWidth[1],
              justifyContent: "center",
            },
          ]}
        >
          <View style={{ position: "absolute", width: "100%" }}>
            <Text
              data-tooltip-id={"tooltip-default"}
              data-tooltip-content={swap.user.name}
              size={"13px"}
              numberOfLines={1}
            >
              {swap.user.name}
            </Text>
            <Text
              data-tooltip-id={"tooltip-default"}
              data-tooltip-content={swap.user.email}
              variant={"gray"}
              size={"13px"}
              numberOfLines={1}
            >
              {swap.user.email}
            </Text>
            <Text variant={"gray"} size={"9px"}>
              {request.id}
            </Text>
          </View>
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[2],
              maxWidth: columnsMaxWidth[2],
              justifyContent: "center",
            },
          ]}
        >
          <Text size={"0.75rem"}>
            {CurrencyUtil.formatCurrency(
              swap.netAmountOut,
              swap.currencyOut.currency
            )}
          </Text>
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[3],
              maxWidth: columnsMaxWidth[3],
              justifyContent: "center",
            },
          ]}
        >
          <Text size={"0.75rem"}>
            {CurrencyUtil.formatCurrency(
              swap.amountIn,
              swap.currencyIn.currency
            )}
          </Text>
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[4],
              maxWidth: columnsMaxWidth[4],
              justifyContent: "center",
            },
          ]}
        >
          <Text size={"0.75rem"}>
            {CurrencyUtil.formatCurrency(
              0,
              swap.currencyIn.currency,
              true,
              TCurrency[swap.currencyIn.currency]?.decimals ?? 6,
              TCurrency[swap.currencyIn.currency]?.decimals ?? 6
            )}
          </Text>
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[5],
              maxWidth: columnsMaxWidth[5],
              justifyContent: "center",
            },
          ]}
        >
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4,
              } as any,
            ]}
          >
            <CurrencyIcon name={swap.currencyOut.currency} style={{ width: 28, height: 28 }} />

            <Text
              style={[
                {
                  display: "flex",
                  flexDirection: "column",
                  lineHeight: "14px",
                },
              ]}
            >
              <Text fontStyle={"bold"} size={"0.75rem"}>
                {Util.getTranslatedProperty(
                  swap.currencyOut,
                  "name",
                  t(`internalization.language`)
                )}
              </Text>
              <Text size={"0.75rem"} variant={"gray"}>
                {swap.currencyOut.prefix || swap.currencyOut.currency}
              </Text>
            </Text>
          </View>
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[6],
              maxWidth: columnsMaxWidth[6],
              justifyContent: "center",
            },
          ]}
        >
          <View style={{ display: "flex", flexDirection: "row" }}>
            {swap.addressOut ? (
              <>
                <View>

                  <View style={{ display: "flex", flexDirection: "row" }}>

                    <Text size={"11px"}>
                      {
                        ["internal-mainnet", "pix-mainnet"].includes(swap.networkOut?.nameId)
                        &&
                        Util.maskBlockchainAddressOrTransactionId(user.autoFiatExternalWithdrawWalletAddress)
                      }
                    </Text>

                    <a
                      data-tooltip-id={"tooltip-on-click"}
                      data-tooltip-content={t(`general.tooltip.copied`)}
                      data-tooltip-variant="success"
                    >
                      <TouchableOpacity
                        style={{ marginLeft: 4 }}
                        onPress={() => {
                          setStringAsync(swap.addressOut);
                        }}
                      >
                        <Icon name={"CopyIcon"} fontSize={"11px"} />
                      </TouchableOpacity>
                    </a>

                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      height: 14,
                      backgroundColor: "gray",
                      width: "fit-content",
                      padding: 2,
                      gap: 2,
                      marginTop: 8,
                    }}
                  >
                    <CurrencyIcon
                      // name={swap.networkOut?.currencySymbol}
                      // currency={swap.currencyOut.currency}
                      name={swap.currencyOut.currency}
                      style={{ width: 14, height: 14 }}
                    />

                    <Text size={"8px"} fontStyle={"bold"} variant={"white"}>
                      {swap.networkOut?.description?.split("(")[0].trim() || ""}
                      {
                        (
                          !["internal-mainnet", "pix-mainnet"].includes(swap.networkOut?.nameId)
                          &&
                          swap.currencyOut.currency === TCurrencySymbol.BRL
                        )
                          ? (
                            " - "
                            +
                            (
                              Util.maskBlockchainAddressOrTransactionId(swap.addressOut) ?? "destinatário não informado"
                            )
                          )
                          : ""
                      }
                    </Text>

                  </View>

                </View>
              </>
            ) : (
              <>
                <Text size={"11px"} style={{ color: "#ef6400" }}>
                  {t(`swap.table.rows.wallet.empty2`)}
                </Text>
              </>
            )}

          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
            }}
          >
          </View>

          {!!swap.transactionsOut?.transactions[0]?.transactionId && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() =>
                  window.open(
                    swap.transactionsOut.transactions[0]
                      .transactionIdExplorerUrl,
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                <View
                  style={{ display: "flex", flexDirection: "row", gap: 2 }}
                >
                  <Text size={"10px"} fontStyle={"bold"} variant={"dark"}>
                    tx:
                  </Text>
                  <CustomHoverableText size={"10px"} variant={"dark"}>
                    {
                      Util.maskBlockchainAddressOrTransactionId(swap.transactionsOut.transactions[0].transactionId)
                    }
                  </CustomHoverableText>
                </View>
              </TouchableOpacity>

              <a
                data-tooltip-id={"tooltip-on-click"}
                data-tooltip-content={t(`general.tooltip.copied`)}
              >
                <TouchableOpacity
                  style={{ marginLeft: 4 }}
                  onPress={() =>
                    setStringAsync(
                      swap.transactionsOut.transactions[0]?.transactionId
                    )
                  }
                >
                  <Icon name={"CopyIcon"} fontSize={"11px"} />
                </TouchableOpacity>
              </a>

            </View>
          )}
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[7],
              maxWidth: columnsMaxWidth[7],
              justifyContent: "center",
            },
          ]}
        >
          <Text fontStyle={"bold"} size={"12px"}>
            {t("payment-gateway-crypto.table.rows.fees.service") + " "}
            <Text size={"12px"} variant={"gray"}>
              {CurrencyUtil.formatCurrency(
                swap.fees.service.amount,
                swap.fees.service.currency.currency
              )}
            </Text>
          </Text>

          <Text fontStyle={"bold"} size={"12px"}>
            {t("payment-gateway-crypto.table.rows.fees.network") + " "}
            <Text size={"12px"} variant={"gray"}>
              {CurrencyUtil.formatCurrency(
                swap.fees.network.amount,
                swap.fees.network.currency.currency
              )}
            </Text>
          </Text>
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[8],
              maxWidth: columnsMaxWidth[8],
              justifyContent: "center",
            },
          ]}
        >

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <a
              data-tooltip-id={"tooltip-on-click"}
              data-tooltip-content={t(`general.tooltip.copied`)}
            >
              <TouchableOpacity
                onPress={() =>
                  window.open(invoice?.paymentUrl, "_blank", "noreferrer")
                }
              >
                <View
                  style={{ display: "flex", flexDirection: "row", gap: 2 }}
                >
                  <Text size={"10px"} fontStyle={"bold"} variant={"dark"}>
                    link:
                  </Text>
                  <CustomHoverableText size={"10px"} variant={"dark"}>
                    {invoice?.code}
                  </CustomHoverableText>
                </View>
              </TouchableOpacity>
            </a>

            <a
              data-tooltip-id={"tooltip-on-click"}
              data-tooltip-content={t(`general.tooltip.copied`)}
            >
              <TouchableOpacity
                style={{ marginLeft: 4 }}
                onPress={() => setStringAsync(invoice.paymentUrl)}
              >
                <Icon name={"CopyIcon"} fontSize={"11px"} />
              </TouchableOpacity>
            </a>

          </View>

          <PaymentGatewayCryptoAllowedMethods request={request} />

        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[9],
              maxWidth: columnsMaxWidth[9],
              justifyContent: "center",
              gap: 0,
            },
          ]}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {
              <Icon
                name={iconByRequestStatus[swap.status.id]}
                fontSize={14}
                variant={iconVariantByRequestStatus[swap.status.id] as any}
                background={{ type: "circle" }}
              />
            }
            <Text style={{ marginLeft: 8 }} size={"0.75rem"}>
              {Util.getTranslatedProperty(
                swap.status,
                "name",
                t(`internalization.language`)
              )}
            </Text>
          </View>

          <Text variant={"gray"} size={"9px"} style={{ marginLeft: 24 }}>
            {Util.formatDate(
              swap.updatedAt || swap.createdAt,
              undefined,
              "L HH[h]mm"
            )}
          </Text>
        </View>

        <View
          style={[
            GeneralCSS.defaultTableValue,
            {
              minWidth: columnsMinWidth[10],
              maxWidth: columnsMaxWidth[10],
              alignItems: "flex-end",
              justifyContent: "center",
            },
          ]}
        >
          {!(isLoading || isFetching) && !!user?.isHubchainUser ? (
            <MenuBox
              options={[
                {
                  icon: "FileEarmarkText",
                  onPress: () => {
                    // console.log("request", request)
                    setInvoiceModalConfig({
                      visible: true,
                      request: request as RequestInvoiceModel,
                      user: request.invoice.user.id,
                      currencyIn: null,
                      action: "view",
                    })
                  },
                  title: t(`payment-gateway-crypto.table.menu.view`),
                },
                {
                  icon: "Edit",
                  onPress: () => {
                    return false; //force disable

                    setInvoiceModalConfig({
                      visible: true,
                      request: request as RequestInvoiceModel,
                      currencyIn: null,
                      action: "edit",
                    });
                  },
                  title: t(`payment-gateway-crypto.table.menu.edit`),
                  visible: false,
                },
                {
                  icon: "CloseCircleIcon",
                  onPress: () =>
                    setInvoiceModalConfig({
                      visible: true,
                      request: request as RequestInvoiceModel,
                      currencyIn: null,
                      action: "cancel",
                    }),
                  title: t(`payment-gateway-crypto.table.menu.cancel`),
                  visible: request.swap.status.id === RequestStatusId.PENDING,
                },
              ]}
            />
          ) : null}
        </View>
      </TableRow>
    );
  });

  if (!rows?.length) {
    rows.push(
      <TableRow key={0} style={[GeneralCSS.defaultTableRow]}>
        <DataTable.Cell>
          <Text style={{ marginTop: 12, marginLeft: 6 }} size={"0.75rem"}>
            {t(
              `payment-gateway-crypto.table.${isFetching ? "loading" : "no"
              }-rows`
            )}
          </Text>
        </DataTable.Cell>
      </TableRow>
    );
  }

  return <>{rows}</>;
}

export default PaymentGatewayCryptoTableBody;
